import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, useMediaQuery, } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Button, Checkbox, Typography } from '@castiron/components';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import ModalWrapper from '../RootModal/ModalWrapper';
import { Form, Formik } from 'formik';
import { useTracking, download } from '@castiron/utils';
import moment from "moment";
import Spinner from '../Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getService } from "../../firebase";


const exportContactListService = getService('customers',  'exportContactList', { type: 'request' });

export interface Props {
  show: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  container: {
    padding: '22px 32px',
  },
  actionsContainer: {
    marginTop: 16,
    display: 'flex',
    gap: 8,
  },
}));

const ExportContactListModal: React.FC<Props> = (props: Props) => {
  const { show } = props;
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { shop, contacts, transactions } = useAppSelector(state => ({
    shop: state.shops.shop,
    contacts: state.customers.customers,
    transactions: state.transactions.transactions,
  }));

  const handleClose = (): void => {
    trackEvent('Export Contact List Closed');
    dispatch(closeModal());
  };

  const onSubmit = useCallback(async values => {
    if (values.selectedStatuses.length === 0) return;
    try {
      setIsSubmitting(true);
      const csv = await exportContactListService({ statuses: values.selectedStatuses, contacts, transactions });

      const date = moment().format('MMDDYYYY');
      const filename = `${ shop.websiteUrl }-contacts-${ date }.csv`;

      download(filename, 'text/csv', csv);
      setIsSubmitting(false);
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: 'Contact list report exported.',
          },
        }),
      );
      trackEvent('Export Contact List Clicked', { subscriberStatuses: values.selectedStatuses });
    } catch (err) {
      console.debug(err);
      setIsSubmitting(false);
    }
  }, [shop]);

  const exportSchema = yup.object().shape({
    subscriberStatuses: yup.string(),
  });

  const subscriberStatusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Opted Out', value: 'opted out' },
    { label: 'Pending', value: 'pending' },
    { label: 'Yes', value: 'yes' },
  ];

  const createCheckboxes = (options, selectedStatuses, setFieldValue) => {
    return options.map(option => {
      const checked = selectedStatuses.includes(option.value);
      return (
        <Checkbox
          label={ option.label }
          checked={ checked }
          onChange={ () =>
            setFieldValue(
              'selectedStatuses',
              checked ? selectedStatuses.filter(s => s !== option.value) : [...selectedStatuses, option.value],
            )
          }
        />
      );
    });
  };

  return (
    <ModalWrapper size={ 'md' } show={ show }>
      <Grid style={ { position: 'relative' } }>
        <Spinner show={ isSubmitting } size={ 'fullscreen' } label="Gathering your contact list data…"/>
        <IconButton onClick={ handleClose } className={ classes.closeIcon }>
          <Close/>
        </IconButton>
        <Grid style={ { padding: '21px' } }>
          <DialogTitle>
            <Typography variant='h3'>Select your contacts by status that you’d like included in your exported
              report</Typography>
          </DialogTitle>
          <DialogContent>
            <Formik
              validationSchema={ exportSchema }
              onSubmit={ onSubmit }
              initialValues={ {
                selectedStatuses: [],
              } }
            >
              { ({ values, setFieldValue }) => (
                <Form>
                  <Grid container direction="column" style={ { marginBottom: 16 } }>
                    <Typography variant='subtitle1'>Subscriber statuses</Typography>
                    { createCheckboxes(subscriberStatusOptions, values.selectedStatuses, setFieldValue) }
                  </Grid>
                  <DialogActions className={ classes.actionsContainer }>
                    <Button
                      fullWidth={ isMobile }
                      onClick={ handleClose }
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      fullWidth={ isMobile }
                      type="submit"
                      variant="contained"
                    >
                      Export
                    </Button>
                  </DialogActions>
                </Form>
              ) }
            </Formik>
          </DialogContent>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default ExportContactListModal;

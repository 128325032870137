import React, {useCallback, useState} from "react";
import {Button, Grid, TextField, Theme} from "@material-ui/core";
import {getService} from "../../firebase";
import {Typography} from "@castiron/components";
import {productRepository, shopRepository} from "../../domain";
import {useAppSelector} from "../../hooks";
import {CustomProduct, Product, Shop} from "@castiron/domain";
import {removeEmpty, slugify} from "@castiron/utils";
import firebase from "firebase/compat/app";
import {nanoid} from "nanoid";

const productGenService = getService('products', 'random');
const copyToStorage = getService('utils', 'copytostorage');


interface Props {

}

async function getByteArrayFromURL(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const byteArray = new Uint8Array(arrayBuffer);
    return byteArray;
  } catch (error) {
    console.error("Error fetching or processing the URL:", error);
    throw error;
  }
}

const GenerateProduct: React.FC<Props> = (props: Props) => {
  const {  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>();
  const [result, setResult] = useState<any>();
  const [message, setMessage] = useState<string>();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop as Shop
  }));

  const onSubmit = useCallback(async () => {
    setLoading(true);
    setResult(undefined);
    setMessage(notes ? 'Ok! Using your notes and what I know about your shop. Just a sec . . . ' : 'No Notes, no prob! Coming up with a new product based on what I know about your shop! Just a sec . . .');
    const resp = await productGenService({
      notes
    });
    setResult(resp);
    setMessage(undefined);
    setLoading(false);
  }, [notes]);

  const createProduct = useCallback(async () => {
    if (!result || !shop) return;

    const categoryMap = Object.fromEntries((shop.categories || []).map(c => [c.name, c.id]));

    if (!categoryMap[result.category]) {
      const newCatId = nanoid();
      await shopRepository.updateProps(shop.id, {
        categories: firebase.firestore.FieldValue.arrayUnion({
          id: newCatId,
          name: result.category,
          shopId: shop.id
        })
      });
      categoryMap[result.category] = newCatId;
    }

    const product = await productRepository.create(removeEmpty({
      type: 'standard',
      status: 'active',
      shopId: shop.id,
      title: result.title,
      description: result.description,
      price: parseInt(result.price),
      category: {
        name: result.category,
        id: categoryMap[result.category],
        shopId: shop.id
      },
      approval: {
        approved: false,
        reviewed: false
      },
      fulfillment: {
        pickup: true,
        delivery: false,
        shipping: true
      },
      unlimitedInventory: true
    }) as Product | CustomProduct);

    const imageName = `${slugify(product.title)}.png`;

    const {url} = await copyToStorage({
      url: result.imageUrl,
      filename: imageName,
      metadata: {
        shopId: shop.id,
        productId: product.id,
        assetType: 'product',
        originalFilename: imageName,
        type: 'image/png',
      }
    })


    await productRepository.updateProps(product.id, {
      images: [{
        downloadUrl: url,
        metadata: {
          shopId: shop.id,
          productId: product.id,
          assetType: 'product',
          originalFilename: imageName,
          type: 'image/png',
        },
        shopId: shop.id
      }],
    })

    setResult(undefined);
    setNotes(undefined);
    setMessage(`Created Product with ID [${product.id}]`);
  }, [result, shop]);

  return <Grid container xs={12} direction="column" style={{width: '100%'}}>
    <Grid item container direction="row" xs={12} style={{width: '100%'}}>
      <Grid item xs={12}><TextField label="Notes" variant="outlined" id="notes" fullWidth={true} onChange={(e) => setNotes(e.target.value)}/></Grid>
    </Grid>
    <Grid item container direction="row" xs={12} style={{width: '100%'}}>
      <Grid item xs={12}><Button value="Generate" disabled={loading} onClick={onSubmit}>Generate</Button></Grid>
    </Grid>
    {message && <Grid item container xs={12} style={{width: '100%'}}>{message}</Grid> }
    {result && (<>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Title:</Typography></Grid>
        <Grid item xs={9}>{result?.title}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Description:</Typography></Grid>
        <Grid item xs={9}>{result?.description}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Price:</Typography></Grid>
        <Grid item xs={9}>{result?.price}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Type:</Typography></Grid>
        <Grid item xs={9}>{result?.type}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Category:</Typography></Grid>
        <Grid item xs={9}>{result?.category}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item>
          <img src={result?.imageUrl}/>
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item>
          <Button onClick={createProduct}>Add product to my shop</Button>
        </Grid>
      </Grid>
    </>)}
  </Grid>;
}

export default GenerateProduct;

import React, { useEffect, useRef } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BusinessAddressInput,
  Link,
  PhoneInput,
  TextInput,
} from "@castiron/components";
import { Formik, Form, FormikProps } from "formik";
import {
  Address,
  Shop,
  addressSchema,
  formatPhoneNumber,
  phoneRegExp,
} from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { accountRepository, shopRepository } from "../../../../domain";
import { useAppSelector, useAppDispatch } from "../../../../hooks";
import { updateShopAction } from "../../../../store/reducers/shops";
import { trackHubSpotContactPage } from "../../../../lib/trackHubSpotContactEvent";
import { StickyFooterProps } from "../OnboardingFooter";

const getShopNameFromBusinessName = async (
  businessName: string
): Promise<string> => {
  let proposedShopUrl = businessName
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^0-9a-zA-Z_-]/gi, "")
    .toLowerCase();
  const existingShops = await shopRepository.findWebsiteUrlsStartsWith(
    proposedShopUrl
  );
  const numExisting = existingShops.length;
  if (numExisting > 0) proposedShopUrl = `${proposedShopUrl}-${numExisting}`;
  return proposedShopUrl;
};

interface Props {
  step?: number;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddressUpdate: (internationa: boolean) => void;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  nextStep?: () => void;
}

export interface FormProps {
  address: Address;
  businessName: string;
  instagramHandle?: string;
  phoneNumber?: string;
}

const businessInfoSchema = yup.object().shape({
  address: addressSchema(true),
  businessName: yup
    .string()
    .min(1)
    .required("Please enter your business name."),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Must be a valid 10-digit phone number format"),
});

const useStyles = makeStyles((theme: Theme) => ({
  alternateEmailIcon: {
    fontSize: 16,
  },
  caption: {
    color: theme.branding.v2.gray[600],
    fontWeight: 400,
  },
  container: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: 24,
  },
  inputContainer: {
    paddingBottom: 8,
  },
  subtitle: {
    margin: "24px 0px 8px 0px",
  },
}));

const BusinessInfo: React.FC<Props> = (props: Props) => {
  const {
    step,
    setLoading,
    setHeader,
    setSubHeader,
    setStickyFooterProps,
    nextStep,
    handleAddressUpdate,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<FormProps>>();
  const { trackEvent } = useTracking();

  const onNextClick = async () => {
    await formRef.current.submitForm();
  };

  useEffect(() => {
    setHeader(`👋🏾 Welcome ${shop?.owner.firstName}!`);
    setSubHeader("");

    setStickyFooterProps({
      onNextClick,
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader("");
      setSubHeader("");
    };
  }, [formRef]);

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const shopAddress = shop?.physicalAddress;

  const initialValues = {
    address: {
      fullAddress: shopAddress?.fullAddress || "",
      addressLine1: shopAddress?.addressLine1 || "",
      addressLine2: shopAddress?.addressLine2 || "",
      city: shopAddress?.city || "",
      region: shopAddress?.region || "",
      regionName: shopAddress?.regionName || "",
      postalCode: shopAddress?.postalCode || "",
      country: shopAddress?.country || "",
    },
    businessName: shop?.businessName ? shop?.businessName : "",
    phoneNumber: account?.phoneNumber || "",
  };

  const submit = async (values, errors) => {
    try {
      setLoading(true);

      const newCompletions = [];
      const newDescription = `Welcome to ${values?.businessName}! ${
        shop?.owner?.firstName ? "I'm " + shop?.owner?.firstName + ". " : ""
      }Thanks for stopping by! Please don't hesitate to reach out if you have any questions and be sure to subscribe to my email list for discounts and shop updates. Thank you for supporting ${
        values?.businessName
      }! `;

      let newShop: Shop = {
        ...shop,
        businessName: values.businessName,
        description: newDescription,
        physicalAddress: { ...values.address },
        seoMetadata: {
          ...shop?.seoMetadata,
          address: {
            addressCountry: values.address.country,
            addressLocality: values.address.city,
            addressRegion: values.address.region,
            postalCode: values.address.postalCode,
          },
        },
        shopSubpageData: {
          ...shop.shopSubpageData,
          home: {
            ...shop.shopSubpageData?.home,
            headline: values.businessName,
          },
        },
      };

      if (values.businessName !== initialValues.businessName) {
        newShop.websiteUrl = await getShopNameFromBusinessName(
          values.businessName
        );
      }

      const shopUpdate = await Promise.resolve(
        dispatch(updateShopAction({ shop: newShop, newCompletions }))
      );

      await accountRepository.updateProps(account.id, {
        billingAddress: { ...values.address },
        ...(values.phoneNumber && { phoneNumber: values.phoneNumber }),
        ...(values.phoneNumber && {
          "config.messagingPreferences.sms.smsPhoneNumber": formatPhoneNumber(
            values.phoneNumber
          ),
        }),
      });

      if (shopUpdate.meta.requestStatus === "fulfilled") {
        trackEvent("Business Questions Captured", {
          ...values,
        });

        trackHubSpotContactPage(
          {
            email: shop.email,
            company: values.businessName,
            zip: values.address.postalCode,
            city: values.address.city,
            state: values.address.regionName,
            ...(values.phoneNumber && { mobilephone: values.phoneNumber }),
          },
          `/signup/info/${step}`
        );
      }

      if (newShop?.physicalAddress?.country !== "US") {
        handleAddressUpdate(true);
      } else {
        handleAddressUpdate(false);
      }

      nextStep();
    } catch (error) {
      console.error("Error Submitting Shop Form: ", error);
    }

    setLoading(false);
  };

  return (
    <Grid container style={{ marginBottom: "120px" }}>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={businessInfoSchema}
        innerRef={formRef}
      >
        {({ errors, touched }: FormikProps<FormProps>) => (
          <Form noValidate>
            <Typography className={classes.subtitle} variant="subtitle1">
              Basics
            </Typography>
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.inputContainer}>
                <TextInput
                  label="Business Name"
                  placeholder="Enter Business Name"
                  error={touched.businessName && errors.businessName}
                  name="businessName"
                  required
                />
                {!(touched.businessName && errors.businessName) && (
                  <Typography variant="caption" className={classes.caption}>
                    We'll use this for your shop name. Don’t worry, you can
                    always change this later.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} className={classes.inputContainer}>
                <BusinessAddressInput removeBusinessInfo />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  error={touched.phoneNumber && errors.phoneNumber}
                  label="Phone Number"
                  name="phoneNumber"
                />
                <Typography variant="caption" className={classes.caption}>
                  By signing up via text, you agree to receive recurring
                  automated promotional and personalized marketing text messages
                  (e.g. help setting up your store) from Nourysh at the cell
                  number used when signing up. Reply HELP for help and STOP to
                  cancel. Msg frequency varies. Msg and data rates may apply.{" "}
                  <Link
                    underline={false}
                    href="https://nourysh.com/terms-and-conditions"
                    target="_blank"
                  >
                    View Terms & Privacy
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default BusinessInfo;

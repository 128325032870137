/* eslint-disable react/display-name */
import React, { ReactElement, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  DataGrid as MUIDataGrid,
  GridRowIdGetter,
  GridSelectionModelChangeParams,
  GridSortDirection,
  GridSortModel,
} from "@material-ui/data-grid";
import SortIcon from "./SortIcon";
import Spinner from "../Spinner";
import { SortOption } from "../Messaging/MarketingStats";

type Props = {
  rows: any;
  columns: any;
  onCellClick?: (params: any) => void;
  rowHeight?: number;
  rowCount?: number;
  checkboxSelection?: boolean;
  gridClass?: string;
  pageSize?: number;
  sortModel?: GridSortModel;
  setSortModel?: (sort: GridSortModel) => void;
  sortingOrder?: GridSortDirection[];
  hideFooter?: boolean;
  striped?: boolean;
  loading?: boolean;
  getRowId?: GridRowIdGetter;
  onSelectionModelChange?: (param: GridSelectionModelChangeParams) => void;
  onPageChange?:
    | ((page: number, searchText?: string) => Promise<void>)
    | ((newPage: number, updateSort?: SortOption) => Promise<void>);
  selectionModel?: any[];
  page?: number;
  noRowsLabel?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  striped: {
    "& .MuiDataGrid-row": {
      "&:nth-of-type(even)": {
        backgroundColor: theme.branding.gray[200],
      },
    },
  },
  table: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.branding.gray[200],
      borderBottomColor: "#EEEEEE",
    },
    border: "none",
    "& .MuiDataGrid-row": {
      cursor: "pointer",
      border: "none",
      width: "100%",
      backgroundColor: theme.palette.common.white,
      "& > .MuiDataGrid-cell": {
        borderBottomColor: "#EEEEEE",
      },
      "&:hover": {
        // adds 30% opacity
        backgroundColor: `${theme.branding.gray[400]}4D`,
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCellTitle": {
      textTransform: "uppercase",
      fontSize: 12,
      color: theme.branding.gray[700],
      fontWeight: 900,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      display: "none",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      border: `1px solid ${theme.branding.v2.plum[500]}4D`,
      backgroundColor: "unset",
      "&:hover": {
        backgroundColor: `${theme.branding.gray[600]}4D`,
      },
    },
  },
}));

const DataGrid: React.FC<Props> = (props: Props) => {
  const {
    rows,
    columns,
    onCellClick,
    rowHeight,
    rowCount,
    checkboxSelection,
    gridClass,
    striped,
    sortModel,
    setSortModel,
    sortingOrder,
    hideFooter,
    loading,
    pageSize,
    getRowId,
    onSelectionModelChange,
    onPageChange,
    selectionModel,
    page,
    noRowsLabel,
  } = props;
  const classes = useStyles();

  const headerHeight = 40;

  const [showSpinner, setShowSpinner] = useState(false);

  const handleOnPageChange = async (page: number) => {
    setShowSpinner(true);
    await onPageChange(page);
    setShowSpinner(false);
  };

  return (
    // Workaround to fix the DataGrid's container's absolute position that overlaps with other components
    // <div style={{ height: (rows.length % 25) * rowHeight + headerHeight + 40, width: '100%' }}>
    <div style={{ width: "100%" }}>
      <Spinner show={showSpinner} size="fullscreen" />
      <MUIDataGrid
        hideFooter={hideFooter}
        disableSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={
          setSortModel ? (params) => setSortModel(params.sortModel) : null
        }
        sortingOrder={sortingOrder}
        components={{
          ColumnSortedAscendingIcon: (): ReactElement => (
            <SortIcon direction="up" />
          ),
          ColumnSortedDescendingIcon: (): ReactElement => (
            <SortIcon direction="down" />
          ),
        }}
        pagination
        pageSize={pageSize}
        loading={loading}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        rowCount={rowCount}
        onCellClick={onCellClick}
        className={`${classes.table} ${striped ? classes.striped : ""}`}
        autoHeight
        columns={columns}
        rows={rows}
        checkboxSelection={checkboxSelection}
        getRowId={getRowId}
        onSelectionModelChange={onSelectionModelChange}
        onPageChange={(gridPageChangeParams) =>
          onPageChange ? handleOnPageChange(gridPageChangeParams.page) : null
        }
        selectionModel={selectionModel}
        page={page}
        localeText={{ noRowsLabel: noRowsLabel || "No rows" }}
      />
    </div>
  );
};

export default DataGrid;

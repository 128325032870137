/* eslint-disable react/display-name */
import React, { ReactElement, useEffect, useState, ReactNode } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import DataGrid from "../DataGrid/DataGrid";
import {
  GridCellParams,
  GridColumns,
  GridRowsProp,
  GridSortModel,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import Dinero from "dinero.js";
import _ from "lodash";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import ListHeader from "../ListHeader";
import ActionsMenu from "../ActionsMenu";
import {
  Button,
  Checkbox,
  SubscriberStatusPill,
  SendButton,
  CancelButton,
} from "@castiron/components";
import SortHeader from "../SortHeader";
import moment from "moment";
import {
  listCustomTransactionsAction,
  listTransactionsAction,
} from "../../store/reducers/transactions";
import {
  Customer,
  subscriberStatusState,
  SelectedCustomerProps,
  newSubscriberStatusState,
} from "@castiron/domain";
import { openModal } from "../../store/reducers/modalConductor";
import SubscriberStats from "./SubscriberStats";
import RequireStripe from "../RequireStripe";

type Props = {
  customerData: Customer[];
  isSubcomponent?: boolean;
  pageSize?: number;
  hideFooter?: boolean;
  sortModel?: GridSortModel;
  setSortModel?: (sort: GridSortModel) => void;
  isMobile: boolean;
  showAnalytics: boolean;
  setFooterCTAs?: (ctas: ReactNode[]) => void;
  source: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  name: {
    fontWeight: 700,
    fontSize: 14,
  },
  subheader: {
    color: theme.palette.text.secondary,
    flexShrink: 2,
  },
  headerCheckbox: {
    marginTop: 10,
  },
}));

const CustomerList: React.FC<Props> = (props: Props) => {
  const {
    customerData,
    isSubcomponent,
    pageSize,
    hideFooter,
    sortModel,
    setSortModel,
    isMobile,
    showAnalytics,
    source,
    setFooterCTAs,
  } = props;
  const [filters, setFilters] = useState(["All"]);
  const customersState = localStorage.getItem("customersState");
  const params = new URLSearchParams(customersState);
  const savedPage = parseInt(params.get("page") || "0", 10);
  const [page, setPage] = useState(savedPage);
  const [customers, setCustomers] = useState<GridRowsProp>(customerData);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState<
    SelectedCustomerProps[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
  const classes = useStyles();
  const { shop, transactions } = useAppSelector((state) => ({
    shop: state.shops.shop,
    transactions: state.transactions.transactions,
  }));
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shop) {
      (async () => {
        await dispatch(listTransactionsAction(shop.id));
        await dispatch(listCustomTransactionsAction(shop.id));
      })();
    }
  }, [shop]);

  const onAddCustomerClick = (): void => {
    history.push("/contacts/add");
  };

  const onSendClick = async () => {
    setSubmitting(true);
    await dispatch(
      openModal({
        modalType: "BULK_CONTACT_MODAL",
        modalProps: {
          customerList: selectedCustomers,
          show: true,
        },
      })
    );
    setSubmitting(false);
    setSelectedCustomers([]);
  };

  useEffect(() => {
    if (isMobile && !isSubcomponent) {
      !_.isEmpty(selectedCustomers)
        ? setFooterCTAs([
            <CancelButton setSelectedCustomers={setSelectedCustomers} />,
            <RequireStripe>
              <SendButton
                isSubmitting={submitting}
                selectedCustomers={selectedCustomers}
                sendAction={onSendClick}
              />
            </RequireStripe>,
          ])
        : setFooterCTAs([
            <Button onClick={onAddCustomerClick} fullWidth variant="contained">
              Add contacts
            </Button>,
          ]);
    } else if (!isSubcomponent) {
      !_.isEmpty(selectedCustomers)
        ? setFooterCTAs([
            <CancelButton setSelectedCustomers={setSelectedCustomers} />,
            <RequireStripe>
              <SendButton
                isSubmitting={submitting}
                selectedCustomers={selectedCustomers}
                sendAction={onSendClick}
              />
            </RequireStripe>,
          ])
        : setFooterCTAs([]);
    }
  }, [selectedCustomers, isMobile, submitting]);

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Checkbox",
      flex: 0.4,
      sortable: false,
      renderCell: (params: GridCellParams): ReactElement => {
        const rowCustomerId = params.value as string;
        const rowCustomer = params.row as Customer;
        const isChecked = !!selectedCustomers.find(
          (c) => c.id === rowCustomerId
        );
        return (
          <Checkbox
            checked={isChecked}
            onChange={(e, checked) =>
              setSelectedCustomers(
                isChecked
                  ? selectedCustomers.filter((c) => c.id !== rowCustomerId)
                  : [
                      ...selectedCustomers,
                      {
                        email: rowCustomer.email,
                        id: rowCustomer.id,
                        name:
                          rowCustomer.firstName + " " + rowCustomer.lastName,
                      },
                    ]
              )
            }
          />
        );
      },
      renderHeader: (params: GridCellParams): ReactElement => {
        const isChecked = customers.every(
          (c) => !!selectedCustomers.find((sc) => sc.id === c.id)
        );
        return (
          <Checkbox
            checked={isChecked}
            onChange={(e, checked) =>
              setSelectedCustomers(
                isChecked
                  ? []
                  : customers.map((c) => ({
                      email: c.email,
                      id: c.id,
                      name: c.firstName + " " + c.lastName,
                    }))
              )
            }
            checkboxClass={classes.headerCheckbox}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: GridCellParams): ReactElement => (
        <Typography className={classes.name}>{params.row.name}</Typography>
      ),
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "lastOrder",
      headerName: "Last Order",
      flex: 1,
      valueFormatter: (params: GridCellParams): string => {
        return params.row.lastOrder === 0
          ? " "
          : moment.unix(params.row.lastOrder).format("MM/DD/YYYY");
      },
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "totalOrders",
      headerName: "Total Orders",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams): string =>
        `${params.value || 0} order${params.value !== 1 ? "s" : ""}`,
      align: isMobile ? "right" : "left",
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "totalSpent",
      headerName: "Total Spent",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams): string =>
        Dinero({ amount: (params.value as number) || 0 }).toFormat("$0.00"),
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "subscriber",
      headerName: "Subscriber",
      flex: 1,
      renderCell: (params: GridCellParams): ReactElement => {
        const customer = params.row as Customer;
        return <SubscriberStatusPill customer={customer} />;
      },
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "actionMenu",
      renderHeader: (): ReactElement => <div style={{ display: "none" }} />,
      flex: 0.3,
      sortable: false,
      renderCell: (params: GridCellParams): ReactElement => (
        <ActionsMenu id={params.row.id} type="customers" />
      ),
    },
  ];

  const onCellClick = (params: GridCellParams): void => {
    if (params.field !== "actionMenu" && params.field !== "id")
      history.push(`/contacts/details/${params.row.id}?from=${source}`);
  };

  const columnsMobile: GridColumns = columns.filter(
    (column) =>
      column.field === "id" ||
      column.field === "name" ||
      column.field === "lastOrder"
  );

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ): void => {
    setFilters(value);

    const filteredCustomers =
      customerData?.filter((customer) => {
        const subscriberState =
          newSubscriberStatusState(customer)?.toLowerCase();
        const isInFilteredStatus =
          subscriberState === "email/sms"
            ? value.map((status) => status.toLowerCase()).includes("email") ||
              value.map((status) => status.toLowerCase()).includes("sms")
            : value
                .map((status) => status.toLowerCase())
                .includes(subscriberState);
        return isInFilteredStatus;
      }) || [];

    if (value.includes("All")) setCustomers(customerData);
    else setCustomers(filteredCustomers);
  };

  const handleInputChange = (e): void => {
    setCustomers(
      customers.filter((row) => {
        if (row && row.firstName) {
          if (e && e.target && e.target.value) {
            row.name.toUpperCase().includes(e.target.value.toUpperCase());
          }
        }
      })
    );
  };

  useEffect(() => {
    //need a deep clone in order to extend
    console.debug("Original List", customers);
    const newCustomerArray = JSON.parse(JSON.stringify(customers));
    console.debug("Copied List", newCustomerArray);

    for (let i = 0; i < newCustomerArray.length; i++) {
      const subscriberState = subscriberStatusState(
        newCustomerArray[i]
      )?.toLowerCase();
      const customerID = newCustomerArray[i].id;
      if (customerID) {
        const curCustomerTransactions = transactions
          ? transactions.filter(
              (curTransaction) =>
                curTransaction.customer === customerID &&
                curTransaction.transactionStatus === "succeeded"
            )
          : [];
        let curTotalOrderValue = 0;
        let latestDate = 0;
        if (curCustomerTransactions?.length > 0) {
          curTotalOrderValue = curCustomerTransactions.reduce(
            (acc, transaction) => acc + transaction.totals?.total,
            0
          );
          if (curCustomerTransactions?.length > 1) {
            curCustomerTransactions.sort((a, b) => b.createdAt - a.createdAt);
          }
          latestDate = curCustomerTransactions[0].createdAt;
        }
        //create the new properties on the object we need
        newCustomerArray[i].name = newCustomerArray[i].firstName
          ? newCustomerArray[i].firstName + " " + newCustomerArray[i].lastName
          : newCustomerArray[i].email;
        newCustomerArray[i].totalOrders = curCustomerTransactions.length;
        newCustomerArray[i].totalSpent = curTotalOrderValue;
        newCustomerArray[i].lastOrder = latestDate;
        newCustomerArray[i].subscriber = subscriberState;
      }
    }

    const filteredCustomerArr =
      source === "analytics"
        ? newCustomerArray.filter((c) => c.totalOrders > 0)
        : newCustomerArray;
    setCustomerOrders(filteredCustomerArr);

    console.debug("New List", newCustomerArray);
  }, [customers, transactions]);

  useEffect(() => {
    if (!localStorageLoaded) {
      const customersState = localStorage.getItem("customersState");
      if (customersState) {
        const params = new URLSearchParams(customersState);
        const savedFilters = params.get("filters");
        const savedPage = parseInt(params.get("page") || "0", 10);
        const savedSortModel = params.get("sortModel");

        if (savedFilters) {
          handleFilterChange(null, JSON.parse(savedFilters));
        }
        setPage(savedPage);
        setSortModel(JSON.parse(savedSortModel));
      }
      setLocalStorageLoaded(true);
    }
  }, []);

  useEffect(() => {
    const filtersString = JSON.stringify(filters);
    const sortModelString = JSON.stringify(sortModel);
    localStorage.setItem(
      "customersState",
      `filters=${filtersString}&page=${page}&sortModel=${sortModelString}`
    );
  }, [filters, page, sortModel]);

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  return (
    <Grid
      className={classes.container}
      item
      xs={12}
      container
      direction="column"
    >
      {customerData.length > 0 && showAnalytics && (
        <Grid item>
          <SubscriberStats />
        </Grid>
      )}

      {!isSubcomponent && (
        <Grid item style={{ marginBottom: "16px" }}>
          <ListHeader
            onSearchInputChange={handleInputChange}
            // search
            filters={filters}
            filterOptions={["All", "Email", "SMS", "None"]}
            isMobile={isMobile}
            onFilterChange={handleFilterChange}
            placeholderPrefix={"Subscriber"}
          />
        </Grid>
      )}
      {isMobile !== undefined && (
        <Grid item>
          <DataGrid
            sortModel={sortModel}
            setSortModel={setSortModel}
            striped
            rowHeight={67}
            onCellClick={onCellClick}
            columns={isMobile ? columnsMobile : columns}
            rows={customerOrders}
            pageSize={pageSize}
            hideFooter={hideFooter}
            onPageChange={handlePageChange}
            page={page}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CustomerList);

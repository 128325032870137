import { useConfig } from "@castiron/castiron-firebase";
import { ButtonV2, FeatureGate, Typography } from "@castiron/components";
import { useTracking } from "@castiron/utils";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getService } from "../../../../firebase";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { openModal } from "../../../../store/reducers/modalConductor";
import { existingAnnualUser } from "../StartDomainConnectModal";

const domainSearchService = getService("shops", "domainsearch", { version: 2 });

type Props = {
  className?: string;
  context: "home" | "domains" | "plansAndPricing";
};

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    background: theme.branding.v2.gray[50],
    borderRadius: 16,
    gap: 16,
    padding: 16,
  },
}));

const FreeCustomDomainBanner: React.FC<Props> = (props: Props) => {
  const { className, context } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const ffconfig = useConfig();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [availableDomain, setAvailableDomain] = useState<string>("");
  const [searchFinished, setSearchFinished] = useState<boolean>(false);

  const { account, liveMode, shop, userState } = useAppSelector((state) => ({
    account: state.shops.account,
    liveMode: state.debug?.domains?.liveMode,
    shop: state.shops.shop,
    userState: state.shops.userState,
  }));

  const isFreeCustomDomainsEnabled = ffconfig?.featureFlag(
    "feature_free_custom_domains",
    shop
  );
  const isExistingAnnualUser = existingAnnualUser(account?.subscription);
  const hasFreeCustomDomain = !!shop?.config?.freeCustomDomain;
  const domainDisabled =
    shop?.config?.domains && shop?.config?.domains?.length !== 0;
  const hideBanner =
    !isFreeCustomDomainsEnabled ||
    isExistingAnnualUser ||
    hasFreeCustomDomain ||
    domainDisabled ||
    userState === "legacyNewSubscriber" ||
    !searchFinished;

  const getAvailableDomain = async () => {
    try {
      const results = await domainSearchService({
        search: shop?.businessName,
        liveMode,
      });
      /* Filter out any results that start with "shop" for now since it's causing an issue with our castiron domain and rerouting */
      const filteredResults = [
        ...results.matches,
        ...results.suggestions,
      ].filter((result) => !result.startsWith("shop"));
      if (filteredResults.includes(`${shop?.businessName}.com`)) {
        setAvailableDomain(`${shop?.businessName}.com`);
      } else if (filteredResults?.length >= 1) {
        setAvailableDomain(filteredResults[0]);
      }
      setSearchFinished(true);
    } catch (err) {
      console.error(err);
      setSearchFinished(true);
    }
  };

  useEffect(() => {
    getAvailableDomain();
  }, []);

  const openFreeCustomDomainModal = () => {
    dispatch(
      openModal({
        modalType: "FREE_CUSTOM_DOMAIN_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const getBannerAction = () => {
    trackEvent("Domain Marketing Banner Clicked", { location: context });
    openFreeCustomDomainModal();
  };

  const helperText = availableDomain && !/.com$/.test(availableDomain) && (
    <Grid item>
      <Typography variant="body2">
        <span style={{ color: theme.branding.v2.gray[500] }}>
          Looking for something else?
        </span>{" "}
        <b>Search Available Domains</b>
      </Typography>
    </Grid>
  );

  return (
    !hideBanner && (
      <FeatureGate name="shop.freeDomain">
        <Grid
          className={`${classes.banner} ${className ? className : ""}`}
          container
          item
          onClick={getBannerAction}
          style={{ cursor: "pointer" }}
        >
          <Grid
            alignItems={isMobile ? "stretch" : "center"}
            container
            direction={isMobile ? "column" : "row"}
            justify="space-between"
            item
            style={{ gap: 16 }}
            wrap="nowrap"
          >
            <Grid container direction="column" item wrap="nowrap">
              <Typography variant="subtitle2">
                Claim Your Custom Domain!
              </Typography>
              {availableDomain && (
                <>
                  <Typography variant="body2">
                    <span>
                      <span
                        style={{
                          color: theme.branding.v2.plum[500],
                          wordBreak: "break-word",
                        }}
                      >
                        <b>{availableDomain}</b>
                      </span>{" "}
                      is available.{" "}
                    </span>
                    Claim it now before it's gone!
                  </Typography>
                  {!isMobile && helperText}
                </>
              )}
            </Grid>
            <ButtonV2
              fullWidth={isMobile}
              onClick={getBannerAction}
              style={{
                border: `1px solid ${theme.branding.v2.plum[500]}`,
                minWidth: 96,
              }}
              variant="outlined"
            >
              Claim It
            </ButtonV2>
          </Grid>
          {isMobile && helperText}
        </Grid>
      </FeatureGate>
    )
  );
};

export default FreeCustomDomainBanner;

import React, { ReactNode } from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  bodyContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '31px 15px',
    },
  },
}));

type Props = {
  children: ReactNode;
};

const Marketing: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Grid justify="center" className={classes.container} container>
      <Helmet>
        <title>Marketing | Nourysh</title>
      </Helmet>
      <Spinner show={false} />
      <Grid container direction="column" className={classes.bodyContainer}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default Marketing;

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { LayoutPageProps } from '../../Layout';
import { Formik, FormikProps } from 'formik';
import { Button, Card, Checkbox, DiscardButton, Link, SaveButton, Typography } from '@castiron/components';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { Asserts } from 'yup';
import { accountRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { getService } from '../../../firebase';
import { formatPhoneNumber } from '@castiron/domain';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '16px',
    },
  },
}));

const formSchema = yup.object().shape({
  smsNotificationOptIn: yup.boolean(),
});

type NotificationValues = Asserts<typeof formSchema>;

const sendSmsVerificationService = getService('messaging', 'sendsmsverification');

const Notifications: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();

  const [submitting, setSubmitting] = useState<boolean>();

  const formikRef = useRef<FormikProps<NotificationValues>>();

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  const [hasCode, setHasCode] = useState<boolean>(false);
  const phoneNumber =
    account?.config?.messagingPreferences?.sms?.smsPhoneNumber || formatPhoneNumber(account?.phoneNumber || '');

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle('Notifications');
    setBackLocation(true);
    setHeaderCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    if (account && account?.config?.messagingPreferences?.sms?.verificationCode) {
      setHasCode(true);
    }
  }, [account]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation="/store/dashboard" />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} />,
    ]);
  }, [submitting]);

  const submit = async (values: NotificationValues) => {
    setSubmitting(true);

    if (values.smsNotificationOptIn && !account?.config?.messagingPreferences?.sms?.enabled) {
      sendSmsVerificationService({
        mobilePhoneNumber: phoneNumber,
      })
        .then(() => {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                content: (
                  <Typography variant="body1">
                    A verification message has been sent to {phoneNumber}. Please follow its instructions to
                    complete setup!
                  </Typography>
                ),
              },
            }),
          );
          setSubmitting(false);
        })
        .catch(err => {
          setSubmitting(false);
          console.error('Error', err);
        });
    } else {
      if (!values.smsNotificationOptIn) {
        await accountRepository.updateProps(account.id, {
          'config.messagingPreferences.sms.enabled': false,
        });
      }
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: <Typography variant="subtitle1">Preferences Saved!</Typography>,
          },
        }),
      );
      setSubmitting(false);
    }
  };

  const resend = () => {
    if (account) {
      setSubmitting(true);
      sendSmsVerificationService({
        mobilePhoneNumber: phoneNumber,
      })
        .then(() => {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                content: (
                  <Typography variant="body1">
                    A verification message has been sent to {phoneNumber}. Please follow its
                    instructions to complete setup!
                  </Typography>
                ),
              },
            }),
          );
          setSubmitting(false);
        })
        .catch(err => {
          setSubmitting(false);
          console.error('Error', err);
        });
    }
  };

  return (
    <Grid container>
      <Helmet>
        <title>Notifications | Nourysh</title>
      </Helmet>
      <Formik
        initialValues={{
          smsNotificationOptIn: account?.config?.messagingPreferences?.sms?.enabled || false,
        }}
        validationSchema={formSchema}
        onSubmit={submit}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }): ReactElement => (
          <>
            <Card title="Text Notifications" className={classes.container}>
              <Grid item style={{ marginBottom: '16px' }} xs={12}>
                <Typography variant="body1">
                  {!phoneNumber ? (
                    <>
                      Please enter a phone number in your{' '}
                      <Link underline={false} href="/store/business-details">
                        Business Details
                      </Link>{' '}
                      to get started.
                    </>
                  ) : hasCode ? (
                    <>
                      A text message has been sent to {phoneNumber}. Please follow its instructions to enable
                      text notifications!
                    </>
                  ) : (
                    <>Get notified of new quote requests and orders ASAP! Sign up for Nourysh text notifications!</>
                  )}
                </Typography>
              </Grid>
              {hasCode && (
                <Grid item style={{ marginBottom: '16px' }}>
                  <Button variant="contained" onClick={resend}>
                    Re-Send Verification Text
                  </Button>
                </Grid>
              )}
              <Grid item container direction="row">
                <Grid item xs={12}>
                  <Checkbox
                    name="smsNotificationOptIn"
                    label="Yes! Please send me text notifications!"
                    checked={values.smsNotificationOptIn || hasCode}
                    onChange={e => setFieldValue('smsNotificationOptIn', e.target.checked)}
                    disabled={hasCode || !phoneNumber}
                  />
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Formik>
    </Grid>
  );
};

export default Notifications;

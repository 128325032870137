import React, { useEffect } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Typography } from "@castiron/components";
import { StickyFooterProps } from "./OnboardingFooter";

interface Props {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "24px 0px",
    gap: "24px",
    [theme.breakpoints.up("md")]: {
      padding: "48px 16px",
    },
  },
}));

const InternationalUserPage: React.FC<Props> = (props: Props) => {
  const { setLoading, setStickyFooterProps } = props;

  const classes = useStyles();

  const handleNextClick = () => {
    setLoading(true);
    window.open("https://nourysh.com/", "_self");
  };

  useEffect(() => {
    setStickyFooterProps({
      onNextClick: handleNextClick,
    });
  }, []);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Typography variant="h1" component="h2">
        🛍️💘
      </Typography>
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        style={{ gap: "16px", textAlign: "center" }}
      >
        <Typography variant="body1" style={{ fontSize: "18px" }}>
          We currently only support shops based in the United States.
        </Typography>
        <Typography variant="body1" style={{ fontSize: "18px" }}>
          We’ll notify you when Nourysh launches in your area!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InternationalUserPage;

import React, { ReactNode, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Grid, Typography, Hidden, Link } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Banner, NouryshLogoBlack} from "@castiron/components";
import Footer from "./Footer";
import "./AuthLayout.scss";
import SocialLoginButtons from "../Auth/SocialLoginButtons/SocialLoginButtons";
import setDomainAuth from "../Auth/domainAuth";
import { useAppSelector } from "../../hooks";

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  contentContainer: {
    maxWidth: "1600px",
  },
  content: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      height: "80vh",
      borderRight: "1px solid #f6f6f6",
      padding: theme.spacing(5),
      "&:after": {
        content: '""',
        background:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAJOCAYAAAC+6IfPAAAD0ElEQVR4nO1d25LDIAi1/f9vbncys+mm2ahcFcnhsU2OiAoIaB6v1+v9eDyKlJ7iN38JAKU8txF4v98QYiliOdhxIF0PT83LH4Ct/yoZYDkDwAxAqpkyjIJGI9twcP6By1GWtRDHNkqUKzQSAC4BuMr1o5Hm+0gAiAJwnAvUeeHTBY5uhGUKKQO1g8G1j5ldHKosfPfOFIHCW69wwLLOquYpAD1uugC9GVk1LFSCfzBiGEkcxLVMFL0YeBjFTpbYtM1z982UqiqmWrDlaVJvHxm8C+M8lHNLscw7CwDWWQgwJKrbWpGZUmXxAOaFQMx3rmP8xAQASJ0bArSEWfPiyEqVpROvHq4pVgxjGCerRyRP1cy0IWU6i4OkAOMcDLWnqqkEKhS7cPztqjG2DM6NkAGqhiWWEFtUNa6q5k2EyHm4CYAdixIggVo3rU80Ne8kHyleVZgKgNKd84jdxbwvHFM901XlEAJRlgDqDIeKg3h2YbHYesJiX3UtDpe6XUBEMxKAf4LCBaDG9nG6f1kmdaoMRd80Eu/ezTjwAZCU1e3/w7QBoAoQz0dKtOVZAEBinWwtE1WNVQEkFBDA3Fc2D43bA3BmHnKuDQDJMt7nBIQIAH+A5IdAAjnbkpc29v31wdzDgQAAgAkAp9TMh4OY51z9k/dSugwLJzgsPH/3fscT12qARJVxosh2rHCgOIKBa66MpnKS5D0ORLVJXV5Yu3yGvRqzlhOtYViCl5kmUOuLOxjDCp79AJDxTAEwfybuE2lucZ8aAL7yH4k3HCq1nkCpAiANgLk+QCjMQAZzu1Brff5+ISnAJti7ywAA4QFaWmn/zy/XVjqK9eigBx6FjT2KgsViCl/IEvv2wsUrYUwAvkppWiuvy4H0RYzCSIDjKAUttT3/MPdWmhjnmeIdT2QDzO+CC0CrW+O7cJfbB1pSz3iyjhtwuOQAkW0ZBT1+MOw2kst7MFyyPAvedk7Z8vty4Aow/9z7QkI0d3G636CImSpTB+fZ6YFzi3ct9sXlQhE+n0DhYH9mXkSze0y11w1W/MD0A0+xNl132nCIORDZRsr1NWQOuIRyIgDYAvSCr/4cxAcwc/f/bX17t0z0qKsTz/8vZBeo8aVgHoo7B1UA6VK24wAARgCu9crzPNV5PpKbaVMlLP0iWdT+jolkcVIjXwDwVJ0BWF+UlZC/f8C+lHZ8SJTVXAtg8XMsPWpdLIEjGN5aefHv+rIAan1X1yfGEaLKzVPf9L2QYamxSpaBODgfR6WZGRYz42oanJ8HkEihdAHieusmZee+Kq2U8gOgl9aGmi6z6gAAAABJRU5ErkJggg==")',
        display: "block",
        height: "590px",
        width: "16px",
        position: "absolute",
        top: "15%",
        right: "-17px",
      },
    },
  },
  contentText: {
    marginTop: theme.spacing(3),
    paddingLeft: "7%",
    paddingRight: "6%",
  },
  logoContainer: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: theme.spacing(3),
      "& svg": {
        width: "33%",
      },
    },
  },
  login: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
      height: "100vh",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "600px",
    },
  },
  headline: {
    marginTop: 24,
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    fontSize: "43px",
    fontWeight: 800,
  },
  divider: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "space-evenly",
    alignItems: "center",
    textTransform: "uppercase",
    "&::before, &::after": {
      content: '""',
      flexGrow: 1,
      height: "1px",
      background: "#dfdfdf",
    },
    "& span": {
      fontWeight: 700,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  container: {
    marginBottom: 68,
  },
  disabledButton: {
    opacity: 0.5,
  },
}));

const AuthLayout: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const [isSignUp, setIsSignup] = useState(location.pathname === "/sign-up");
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    setIsSignup(location.pathname === "/sign-up");
    const params = new URLSearchParams(window.location.search);
    if (params.get("invalidEmail") == "true") {
      setInvalidEmail(true);
    }
  }, [isSignUp]);

  const { shopError } = useAppSelector((state) => ({
    shopError: state.shops.error,
  }));

  return (
    <Grid
      id="layout-auth"
      container
      justify="flex-start"
      alignItems="center"
      className={`admin ${classes.root}`}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.contentContainer}
      >
        {(shopError || invalidEmail) && (
          <Banner variant="error">
            This email you selected is not associated to a user account. Please
            try another email.
          </Banner>
        )}
        <Grid item xs={12} md={5} className={classes.content}>
          <Grid container item>
            <Grid container justify="center" className={classes.logoContainer}>
              <Grid item xs={12} md={10}>
                <NouryshLogoBlack height="fit-content" width="100%" />
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid container justify="flex-end">
                <Grid item md={9} className={classes.contentText}>
                  <Typography style={{ marginBottom: "48px" }}>
                    Nourysh is a simple, cost effective all-in-one tool to
                    start, run, and grow your business. More than that though,
                    it’s a community where entrepreneurs just like you can be
                    inspired, find shoppers, and cultivate their businesses.
                  </Typography>
                  <Typography>
                    Sign up and start selling today, or learn more about how{" "}
                    <Link
                      target="_blank"
                      href="https://nourysh.com"
                      style={{ color: theme.branding.v2.plum[500] }}
                    >
                      Nourysh
                    </Link>{" "}
                    can save you time, money and streamline your business.
                  </Typography>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} className={classes.login}>
          <Grid className={classes.container} container justify="center">
            <Grid item xs={12} md={8}>
              <Typography className={classes.headline} align="center">
                {isSignUp ? "Sign up for free" : "Welcome back!"}
              </Typography>
              <Typography
                className={classes.divider}
                align="center"
                variant="subtitle2"
              >
                <span>{isSignUp ? "Sign up with" : "Log In With"}</span>
              </Typography>
              <SocialLoginButtons
                onAuth={async (user) => {
                  await setDomainAuth();
                }}
              />
              <Typography
                className={classes.divider}
                align="center"
                variant="subtitle2"
              >
                <span>or</span>
              </Typography>
              <Grid container>{props.children}</Grid>
            </Grid>
          </Grid>
          <Footer isAuth />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { NouryshLogoPurple } from "@castiron/components";
import { useAppSelector } from "../../../hooks";

type Props = {
  stickyFooterClass?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
      marginTop: 40,
    },
  },
  copyright: {
    marginTop: 12,
    justifyContent: "center",
  },
}));

const FooterMobile: React.FC<Props> = (props: Props) => {
  const { stickyFooterClass } = props;
  const classes = useStyles();
  const { me } = useAppSelector((state) => ({
    me: state.users.me,
  }));

  return (
    <div className={`${classes.root} ${stickyFooterClass}`}>
      <NouryshLogoPurple style={{ width: "7em" }} />
      <div>
        <div className={classes.copyright}>
          <Typography variant="caption" color="textSecondary">
            &copy; {new Date().getFullYear()} Nourysh
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <Link to={{ pathname: "/contact-us" }}>
            {me && (
              <Typography variant="caption" color="textSecondary">
                Contact us
              </Typography>
            )}
          </Link>
          <Link
            target="_blank"
            to={{ pathname: "https://nourysh.com/privacy-policy" }}
          >
            <Typography variant="caption" color="textSecondary">
              Privacy Policy
            </Typography>
          </Link>
          <Link
            target="_blank"
            to={{ pathname: "https://nourysh.com/terms-and-conditions" }}
          >
            <Typography variant="caption" color="textSecondary">
              Terms &amp; Conditions
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;

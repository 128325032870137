import { Banner, ButtonV2, Typography } from '@castiron/components';
import { addressSchema } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { CircularProgress, DialogContent, Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { planRepository, shopRepository } from '../../../../domain';
import { getService } from '../../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import { getShopAction } from '../../../../store/reducers/shops';
import AdminForm from '../../../AdminForm';
import ModalActions from '../../../RootModal/ModalActions';
import ModalHeader from '../../../RootModal/ModalHeader';
import ModalWrapper from '../../../RootModal/ModalWrapper';
import { eligibleForFreeCustomDomain } from '../StartDomainConnectModal';
import Registration from './Registration';
import Review from './Review';
import Search from './Search';
import Success from './Success';
import UpdatePlan from './UpdatePlan';

const registerDomainService = getService('shops', 'registerdomain', { version: 2 });

export type Props = {
  show?: boolean;
};

export type Step = 'search' | 'updatePlan' | 'registration' | 'review' | 'submitting' | 'success';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    marginBottom: 16,
  },
  modalContent: {
    padding: 24,
  },
  modalWrapper: {
    [theme.breakpoints.up('sm')]: {
      height: 656,
      width: 504,
    },
  },
  modalWrapperUpdatePlan: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: 656,
      width: 504,
    },
  },
  submitting: {
    gap: 16,
    height: '100%',
    minHeight: 250,
    padding: 24,
  },
}));

const CustomDomainModal: React.FC<Props> = (props: Props) => {
  const { show } = props;

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<any>>();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const [step, setStep] = useState<Step>('search');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { account, liveMode, shop, userState } = useAppSelector(state => ({
    account: state.shops.account,
    liveMode: state.debug?.domains?.liveMode,
    shop: state.shops.shop,
    userState: state.shops.userState,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleSubmit = async values => {
    try {
      const nameParts = values?.fullName?.split(' ');
      const firstName = nameParts.shift();
      const lastName = nameParts.length > 0 ? nameParts.join(' ') : '';

      const resp = await registerDomainService({
        domain: values?.domain,
        phoneNumber: values?.phoneNumber,
        address: values?.address,
        liveMode,
        autoRenew: values?.autoRenew,
        firstName,
        lastName,
      });

      if (resp?.registeredDomain) {
        await shopRepository.updateProps(shop.id, {
          'config.freeCustomDomain': values?.domain,
          'config.domainRenew': values?.autoRenew,
          owner: {
            firstName,
            lastName,
          },
        });
      }
      setStep(resp?.success ? 'success' : 'review');
    } catch (err) {
      console.error('Error Setting up Custom Domain', err);
      setErrorMessage('An error has occurred, please reach out to support@nourysh.com.');
      setStep('review');
    }
  };

  const openStartDomainConnectModal = () => {
    dispatch(
      openModal({
        modalType: 'START_DOMAIN_CONNECT_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const handleBackClick = () => {
    setErrorMessage('');
    switch (step) {
      case 'search':
        openStartDomainConnectModal();
        break;
      case 'registration':
        setStep('search');
        break;
      case 'review':
        setStep('registration');
        break;
    }
  };

  const scrollTo = (id: string) => {
    let element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const openChangePlansModal = async () => {
    const plans = await planRepository.list();
    /* Castiron Plan */
    const plan = plans?.find(plan => plan.id === 'O2YMyGxedkS6cV2cZGp6');
    const price = plan?.prices?.find(price => price.frequency === 'yearly');
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          context: 'freeCustomDomainModal',
          open: true,
          ...(plan
            ? {
                selectedPlan: {
                  plan,
                  price,
                },
                step: 2,
              }
            : {}),
        },
      }),
    );
  };

  const handleNextClick = async () => {
    scrollTo('custom-domain-modal-top');
    switch (step) {
      case 'search':
        if (!formRef?.current?.values?.domain) {
          setErrorMessage('Please select an available domain to continue.');
        } else {
          setErrorMessage('');
          if (eligibleForFreeCustomDomain(account?.subscription)) {
            setStep('registration');
          } else {
            setStep('updatePlan');
          }
        }
        break;
      case 'updatePlan':
        if (userState === 'cancellingSubscriber') {
          dispatch(closeModal());
          history.push('/store/plans');
        } else {
          openChangePlansModal();
        }
        break;
      case 'registration':
        if (_.isEmpty(formRef?.current?.errors)) {
          setErrorMessage('');
          setStep('review');
        } else {
          /* trigger error validation */
          formRef?.current?.handleSubmit();
          setErrorMessage('Please fill out all required information.');
        }
        break;
      case 'review':
        formRef?.current?.handleSubmit();
        setStep('submitting');
        break;
      case 'success': {
        trackEvent('Free Custom Domain Connected');
        await dispatch(getShopAction(shop.id));
        handleClose();
      }
    }
  };

  const validationSchema = yup.object().shape({
    searchTerm: yup.string(),
    domain: yup.string().required(),
    fullName: yup.string().required('Please enter a full name.'),
    phoneNumber: yup.string().required('Please enter a phone number.'),
    address: addressSchema(true),
    termsOfService: yup
      .boolean()
      .oneOf([true], 'Please agree to our Terms of Service and Privacy Policy to continue.')
      .required(),
    autoRenew: yup.boolean(),
  });

  /* only use address info if we have a full address */
  const useAccountBillingAddress =
    account?.billingAddress?.fullAddress && !account?.billingAddress?.fullAddress?.trim().startsWith(',');

  const initialValues = {
    searchTerm: shop?.businessName,
    domain: '',
    fullName: `${shop?.owner?.firstName || ''}${shop?.owner?.lastName ? ' ' + shop?.owner?.lastName : ''}`,
    phoneNumber: account?.phoneNumber || '',
    address: {
      fullAddress: (useAccountBillingAddress && account?.billingAddress?.fullAddress) || '',
      addressLine1: (useAccountBillingAddress && account?.billingAddress?.addressLine1) || '',
      addressLine2: (useAccountBillingAddress && account?.billingAddress?.addressLine2) || '',
      city: (useAccountBillingAddress && account?.billingAddress?.city) || '',
      region: (useAccountBillingAddress && account?.billingAddress?.region) || '',
      regionName: (useAccountBillingAddress && account?.billingAddress?.regionName) || '',
      country: (useAccountBillingAddress && account?.billingAddress?.country) || '',
      postalCode: (useAccountBillingAddress && account?.billingAddress?.postalCode) || '',
    },
    termsOfService: true,
    autoRenew: true,
  };

  const nextButtonText = {
    search: 'Next',
    updatePlan: 'Update Plan',
    registration: 'Review',
    review: 'Confirm',
    submitting: '',
    success: 'Close',
  };

  return (
    <ModalWrapper
      fullScreen={isMobile}
      show={show}
      onClose={handleClose}
      paperClass={step === 'updatePlan' ? classes.modalWrapperUpdatePlan : classes.modalWrapper}
    >
      {step === 'submitting' ? (
        <Grid alignItems="center" className={classes.submitting} container direction="column" justify="center">
          <CircularProgress size={16} />
          <Typography style={{ textAlign: 'center' }} variant="h2">
            Claiming & Connecting Your New Domain...
          </Typography>
        </Grid>
      ) : (
        <>
          <ModalHeader
            backClick={step === 'success' || step === 'updatePlan' ? undefined : handleBackClick}
            handleClose={handleClose}
            title={step === 'success' ? 'Success' : step === 'updatePlan' ? 'Update Plan' : 'Get a Domain'}
          />
          <DialogContent className={classes.modalContent} style={step === 'updatePlan' ? { padding: 40 } : {}}>
            <div id="custom-domain-modal-top"></div>
            {!!errorMessage && (
              <Banner className={classes.banner} variant="error">
                <Typography style={{ color: 'inherit' }} variant="body2">
                  {errorMessage}
                </Typography>
              </Banner>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {(formikProps: FormikProps<any>) => (
                <AdminForm style={{ height: '100%' }}>
                  {step === 'search' && <Search />}
                  {step === 'updatePlan' && <UpdatePlan />}
                  {step === 'registration' && <Registration />}
                  {step === 'review' && <Review />}
                  {step === 'success' && <Success />}
                </AdminForm>
              )}
            </Formik>
          </DialogContent>
          <ModalActions>
            {(step === 'search' ||
              (step === 'updatePlan' && !isMobile) ||
              step === 'registration' ||
              step === 'review') && (
              <ButtonV2 onClick={handleClose} variant="outlined">
                Cancel
              </ButtonV2>
            )}
            <ButtonV2
              disabled={step === 'review' && !!errorMessage} // disable if there is an error registering the domain
              onClick={handleNextClick}
              fullWidth={step === 'success' || (step === 'updatePlan' && isMobile)}
              variant="contained"
            >
              {nextButtonText[step]}
            </ButtonV2>
          </ModalActions>
        </>
      )}
    </ModalWrapper>
  );
};

export default CustomDomainModal;

import { ButtonV2, CouponOutlinedIcon, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { Button, Grid } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import NavItem from '../Layout/SideDrawer/Nav/NavItem/NavItem';
import { isUnconvertedTrial } from '../../lib/accountUtils';
import { Account } from '@castiron/domain';

type Props = {
  location: 'admin-menu' | 'home' | 'order-details' | 'plans-and-pricing' | 'side-nav';
  onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 16,
    backgroundColor: theme.branding.v2.green[50],
    padding: '40px 24px',
    gap: 16,
    minWidth: 240,
    textAlign: 'center',
  },
  couponIcon: {
    color: theme.branding.v2.green[500],
    height: 18,
    width: 18,
  },
  menuOption: {
    '& span': {
      color: theme.branding.gray[800],
    },
  },
  subscribeButton: {
    backgroundColor: theme.branding.v2.green[500],
    color: theme.branding.v2.gray[0],
    maxWidth: 138,
    '&:hover': {
      backgroundColor: theme.branding.v2.green[600],
    },
  },
  subscribeButtonTextVariant: {
    color: theme.branding.v2.gray[900],
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

export const showFirstMonthPromoBlock = (account: Account): boolean => {
  return isUnconvertedTrial(account) && !!account?.config?.showFirstMonthPromo;
};

const PromoBlock: React.FC<Props> = (props: Props) => {
  const { location, onClick } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const appLocation = useLocation();

  const { account, shop, subscription, tier } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    subscription: state.shops.account.subscription,
    tier: state.shops.account.tier,
  }));

  const openChangePlanModal = () => {
    trackEvent('Shop View Upgrade Modal', {
      shopId: shop.id,
      location,
      tier: tier,
      currentPlan: subscription?.plan.name,
    });
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
          couponCode: 'dqPgpfQz',
          step: account?.isCastironPlus() ? 1 : 0,
          selectedPlan: {
            plan: subscription?.plan,
            price: subscription?.price,
          },
        },
      }),
    );
  };

  const handleClick = e => {
    onClick && onClick();
    trackEvent('Promo CTA Clicked', { location });
    openChangePlanModal();
  };

  return location === 'admin-menu' ? (
    <Button
      startIcon={<CouponOutlinedIcon className={classes.couponIcon} style={{ height: 20, width: 20 }} />}
      key="promo-block-admin-menu"
      onClick={handleClick}
      className={classes.menuOption}
    >
      <Typography variant="subtitle2">Cyber Monday: $1</Typography>
    </Button>
  ) : location === 'side-nav' ? (
    <NavItem
      neverActive
      label="Cyber Monday: $1"
      Icon={CouponOutlinedIcon}
      iconClass={classes.couponIcon}
      path={appLocation.pathname}
      onClick={handleClick}
    />
  ) : (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
      <CouponOutlinedIcon className={classes.couponIcon} style={{ height: 24, width: 24 }} />
      <Typography variant="h3">Cyber Monday Special: First Month for $1</Typography>
      <Typography variant="body1">
        Subscribe to any Nourysh plan and get your first month for just $1. Limited time only.
      </Typography>
      <ButtonV2 variant="contained" className={classes.subscribeButton} onClick={handleClick}>
        Subscribe
      </ButtonV2>
    </Grid>
  );
};

export default PromoBlock;

import React, { ReactNode, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import {
  CreditCardOutlined,
  ListRounded,
  LocalOfferOutlined,
  LocalShippingOutlined,
  NotificationsActiveOutlined,
  PaletteOutlined,
  Receipt,
  StyleOutlined,
} from "@material-ui/icons/";
import { FeatureFlag } from "@castiron/castiron-firebase";
import {
  DomainVerificationOutlinedIcon,
  ProFeatureChip,
  SeoIcon,
  Typography,
  useFeatures,
} from "@castiron/components";
import { FeatureName } from "@castiron/domain";
import { useAppSelector } from "../../hooks";
import FeatureTooltip from "../FeatureTooltip";
import { LayoutPageProps } from "../Layout";
import AvatarMenu from "../Menus/AvatarMenu";
import ViewShopButton from "../Layout/Header/ViewShopButton";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: "16px 16px 18px 16px",
    border: `1px solid ${theme.branding.gray[400]}`,
    "&:hover": {
      backgroundColor: theme.branding.gray[100],
      border: `1px solid ${theme.branding.v2.plum[400]}`,
    },
    justifyContent: "flex-start",
    height: "100%",
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "12px",
    },
  },
  buttonContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  buttonText: {
    marginLeft: "16px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  icon: {
    height: "30px",
    width: "30px",
  },
  iconContainer: {
    color: theme.branding.v2.plum[500],
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    height: "70px",
    width: "92px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export interface SettingLink {
  name: string;
  subtitle: string;
  icon: ReactNode;
  path: string;
  feature?: FeatureName;
  proFeature?: boolean;
  flag?: string;
}

const SettingsDashboard: React.FC<LayoutPageProps> = (
  props: LayoutPageProps
) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const features = useFeatures();
  const showSubscriptions = features.includes("admin.subscriptions");

  const { account } = useAppSelector((state) => ({
    account: state.shops.account,
  }));

  const links: SettingLink[] = [
    {
      name: "Appearance",
      subtitle: "Manage the look and feel of your shop.",
      icon: <PaletteOutlined className={classes.icon} />,
      path: "/store/appearance",
    },
    {
      name: "Pages",
      subtitle: "Manage which pages are included on your website.",
      icon: <StyleOutlined className={classes.icon} />,
      path: "/store/pages",
      feature: "shop.subpages",
    },
    {
      name: "Business Details",
      subtitle: "Manage dietary info, links, and more.",
      icon: <ListRounded className={classes.icon} />,
      path: "/store/business-details",
    },
    {
      name: "Shipping",
      subtitle: "Manage how your customers retrieve their items.",
      icon: <LocalShippingOutlined className={classes.icon} />,
      path: "/store/fulfillment",
    },
    {
      name: "Payments",
      subtitle: "Manage how you get paid and your fees.",
      icon: <CreditCardOutlined className={classes.icon} />,
      path: "/store/payments",
    },
    {
      name: "Coupons",
      subtitle: "Manage your coupons and associated discounts.",
      icon: <LocalOfferOutlined className={classes.icon} />,
      path: "/store/coupons",
      feature: "admin.coupons",
    },
    {
      name: "Domains",
      subtitle: "View and manage your shop's domains.",
      icon: <DomainVerificationOutlinedIcon className={classes.icon} />,
      path: "/store/domains",
      feature: "shop.customDomain",
    },
    {
      name: "SEO",
      subtitle: "Manage your visibility and how you show up on search engines.",
      icon: <SeoIcon className={classes.icon} />,
      path: "/store/seo",
    },
    {
      name: "My Subscription",
      subtitle: "Manage your Nourysh Subscription.",
      icon: <Receipt className={classes.icon} />,
      path: "/store/plans",
    },
    {
      name: "Notifications",
      subtitle: "Manage what notifications you receive.",
      icon: <NotificationsActiveOutlined className={classes.icon} />,
      path: "/store/notifications",
      proFeature: false,
    },
  ] as SettingLink[];

  useEffect(() => {
    setPageTitle("Website Settings");
    setFooterCTAs([]);

    return () => {
      setPageTitle("");
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<ViewShopButton />, <AvatarMenu />]);
    } else {
      setHeaderCTAs([<ViewShopButton />]);
    }
  }, [isMobile]);

  const isQualified = useCallback(
    (feature: FeatureName) => !feature || features.includes(feature),
    [features]
  );
  const displayProFeatureChip = useCallback(
    (link: SettingLink) => {
      return (
        link.proFeature &&
        !account.isCastironPlus() &&
        (!features.includes(link.feature) || account.isInTrial())
      );
    },
    [account, features]
  );

  return (
    <Grid container className={classes.buttonsWrapper}>
      <Helmet>
        <title>Settings | Nourysh</title>
      </Helmet>
      {links.map((link, index) =>
        displayProFeatureChip(link) ||
        !isQualified(link.feature) ? undefined : (
          <FeatureFlag name={link.flag}>
            <Grid
              xs={12}
              sm={6}
              lg={4}
              item
              key={index}
              className={classes.buttonContainer}
              justify={!isMobile ? "flex-start" : "center"}
            >
              <FeatureTooltip feature={link.feature} featureName={link.name}>
                <Button
                  component={Link}
                  to={isQualified(link.feature) ? link.path : undefined}
                  variant="outlined"
                  className={classes.button}
                  fullWidth
                >
                  <Grid className={classes.buttonContent}>
                    <Grid className={classes.iconContainer}>{link.icon}</Grid>
                    <Grid
                      container
                      direction="column"
                      className={classes.buttonText}
                    >
                      <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Typography variant="subtitle1">
                              {link.name}
                            </Typography>
                          </Grid>
                          {displayProFeatureChip(link) && (
                            <Grid item>
                              <ProFeatureChip />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="body4">{link.subtitle}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Button>
              </FeatureTooltip>
            </Grid>
          </FeatureFlag>
        )
      )}
    </Grid>
  );
};

export default SettingsDashboard;

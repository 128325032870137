import React, { ReactElement, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { Box, DialogContent, Grid } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";
import { ButtonV2 as Button, SelectInput } from "@castiron/components";
import {
  FulfillmentType,
  Order,
  Transaction,
  calculateTotals,
  fulfillmentTypeDisplayName,
  phoneRegExp,
} from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { transactionRepository } from "../../../domain";
import { getService } from "../../../firebase";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCustomersAction } from "../../../store/reducers/customers";
import { closeModal } from "../../../store/reducers/modalConductor";
import ModalWrapper from "../../RootModal/ModalWrapper";
import ModalActions from "../../RootModal/ModalActions";
import ModalHeader from "../../RootModal/ModalHeader";
import Customer from "./Customer";
import LineItems from "./LineItems";

const createCustomer = getService("customers", "createorupdatecustomer");

export interface Props {
  show: boolean;
  onAdd: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
  },
}));

export interface LineItem {
  title: string;
  price: number;
  quantity: number;
}
export interface FormValues {
  lineItems: LineItem[];
  customerName?: string;
  customerEmail?: string;
  customerPhoneNumber?: string;
  fulfillmentType: FulfillmentType | "";
}
const initialState: FormValues = {
  lineItems: [
    {
      title: "",
      price: 0,
      quantity: 0,
    },
  ],
  customerName: "",
  customerEmail: "",
  customerPhoneNumber: "",
  fulfillmentType: "",
};

const AddOrderModal: React.FC<Props> = (props: Props) => {
  const { show, onAdd } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const [showCustomerForm, setShowCustomerForm] = useState(false);

  const { account, shop, customers } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
    customers: state.customers.customers,
  }));

  const customerSchema = yup.object({
    customerName: yup.string().required("Name is required for customer"),
    customerEmail: yup
      .string()
      .email("Please input a valid email")
      .required("Email is required for customer"),
    customerPhoneNumber: yup
      .string()
      .matches(phoneRegExp, "Must be a valid 10-digit phone number format"),
  });

  const schema = yup.object({
    lineItems: yup
      .array()
      .of(
        yup.object({
          title: yup.string().required("Please include a title").trim(),
          price: yup
            .number()
            .required("Please include a price")
            .moreThan(0, "Price must be more than zero"),
          quantity: yup
            .number()
            .required("Please include a quantity")
            .moreThan(0, "Quantity must be more than zero"),
        })
      )
      .min(1, "At least one item must be added"),
    fulfillmentType: yup.string().oneOf(["pickup", "delivery", "shipping"]),
  });

  const schemaWithCustomer = schema.concat(customerSchema);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const submit = async (values: FormValues) => {
    try {
      let customer;
      const suppliedCustomer = !!values.customerEmail;
      const existingCustomer =
        suppliedCustomer &&
        customers.find((cust) => cust.email === values.customerEmail);
      if (existingCustomer) {
        customer = existingCustomer;
      } else if (suppliedCustomer) {
        const customerNameParts = values.customerName.split(" ");
        const customerToSave = {
          shopId: shop.id,
          email: values.customerEmail,
          mobileNumber: values.customerPhoneNumber,
          firstName: customerNameParts[0],
          lastName: customerNameParts[1],
        };
        customer = await createCustomer(customerToSave);
        dispatch(getCustomersAction(shop.id));
      }

      const includeFulfillment = !!values.fulfillmentType;

      const order: Order = {
        type: "standard",
        stage: "order",
        initiator: "owner",
        items: values.lineItems.map((item) => ({
          ...item,
          id: nanoid(),
          description: "",
          subtotal: item.price * item.quantity,
          total: item.price * item.quantity,
          type: "standard",
        })),
        fulfillmentOption: includeFulfillment
          ? {
              status: "active",
              /* initial values allows empty, but schema won't allow submission of empty so cast */
              type: values.fulfillmentType as FulfillmentType,
              displayName: fulfillmentTypeDisplayName(
                values.fulfillmentType as FulfillmentType
              ),
              description: fulfillmentTypeDisplayName(
                values.fulfillmentType as FulfillmentType
              ),
            }
          : null,
        orderTotal: values.lineItems
          .map((item) => item.price * item.quantity)
          .reduce((acc, val) => acc + val, 0),
      };

      let transaction: Transaction = {
        type: "transaction",
        shopId: shop.id,
        customer: customer?.id,
        customerObj: customer,
        order,
        transactionStatus: "succeeded",
        status: "open",
        tier: account.tier,
        totals: calculateTotals({
          order,
          paymentSettings: {
            castironTakeRate: 0,
            customerRate: 0,
            isCustomerPayingStripeFee: false,
          },
        }),
      };
      if (!suppliedCustomer) {
        /* remove it to make the create call play nice */
        transaction = _.omit(
          transaction,
          "customer",
          "customerObj"
        ) as Transaction;
      }
      if (!includeFulfillment) {
        /* remove it to make the create call play nice */
        transaction = _.omit(transaction, "fulfillmentOption");
      }
      const createdTransaction = await transactionRepository.create(
        transaction
      );
      if (createdTransaction.transactionStatus == "succeeded") {
        trackEvent("Manual Order Created", {
          transactionId: createdTransaction.id,
        });
      }
    } catch (error) {
      console.error(error);
    }

    onAdd && onAdd();

    handleClose();
  };

  const fulfillmentTypeToSelectOption = (ffType: FulfillmentType) => ({
    display: fulfillmentTypeDisplayName(ffType),
    value: ffType,
  });

  return (
    <ModalWrapper size={"lg"} show={show}>
      <Formik
        onSubmit={submit}
        validationSchema={showCustomerForm ? schemaWithCustomer : schema}
        initialValues={initialState}
      >
        {({ errors, touched, isSubmitting }): ReactElement => (
          <Form>
            <ModalHeader title="Add Order" handleClose={handleClose} />
            <DialogContent>
              <Box className={classes.contentContainer}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <LineItems />
                  </Grid>
                  <Grid item>
                    <SelectInput
                      label="Fulfillment"
                      name="fulfillmentType"
                      options={["pickup", "delivery", "shipping"].map(
                        fulfillmentTypeToSelectOption
                      )}
                      error={touched.fulfillmentType && errors.fulfillmentType}
                    />
                  </Grid>
                  <Grid item>
                    <Customer
                      showCustomerForm={showCustomerForm}
                      setShowCustomerForm={setShowCustomerForm}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <ModalActions>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </ModalActions>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddOrderModal;

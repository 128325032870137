import React, { useState } from "react";
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import { ButtonV2 as Button } from "@castiron/components";
import { getService } from '../../../../firebase';
import { useAppDispatch } from '../../../../hooks';
import { closeModal } from '../../../../store/reducers/modalConductor';
import ModalWrapper from "../../../RootModal/ModalWrapper";
import { useHistory } from "react-router-dom";
import * as zendesk from '../../../../lib/zendesk';

export interface Props {
  show: boolean;
  domain: string;
  type: 'delete' | 'deleteFreeCustomDomain' | 'error';
};

const useStyles = makeStyles((theme: Theme) => ({
  actionSeparator: {
    borderTop: `1px solid ${theme.branding.gray[300]}`,
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  image: {
    marginBottom: '16px',
  },
  progress: {
    color: theme.branding.gray[0],
  },
  text: {
    marginTop: '8px',
    textAlign: 'center',
  },
}));

const deleteDomainService = getService('shops', 'deletecustomdomain', { version: 2 });

const DomainManagementModal: React.FC<Props> = (props: Props) => {
  const { show, domain, type } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();

  const [deleting, setDeleting] = useState(false);

  const handleClose = (): void => {
    if (type === 'error') {
      localStorage.setItem('seenDomainManagementErrorModal', 'true');
    }
    dispatch(closeModal());
  };

  const goToDomains = (): void => {
    history.push('/store/domains');
    handleClose();
  };

  const deleteDomain = async () => {
    setDeleting(true);
    localStorage.setItem('seenDomainManagementErrorModal', 'false');
    /* strip www, as backend service will remove both */
    const domainName = domain.startsWith('www.') ? domain.substring(4) : domain;
    await deleteDomainService({ domainName });
    handleClose();
  };

  const contactSupport = () => {
    zendesk.popChat();
    handleClose();
  };

  let icon;
  let title;
  let subtext;
  let actions;
  switch(type) {
    case 'delete':
      icon = <img src="/assets/img/domains/trash-can.png" className={classes.image} />;
      title = 'Are you sure you want to delete this domain?';
      subtext = 'By deleting this domain, your Nourysh shop will no longer be associated with it.';
      actions = (
        <>
          <Button variant='outlined' onClick={handleClose} disabled={deleting}>
            No, Cancel
          </Button>
          <Button style={{background: theme.branding.v2.red[500]}} variant='contained' onClick={deleteDomain}>
            {!deleting && 'Yes, Delete'}
            {deleting && <CircularProgress size={24} color='inherit' className={classes.progress} />}
          </Button>
        </>
      );
      break;
    case 'deleteFreeCustomDomain':
      icon = <img src="/assets/img/domains/heart-letter.png" className={classes.image} />;
      title = 'We can help with that!';
      subtext = 'Please contact customer support to disable or delete your custom domain purchased through Nourysh.';
      actions = (
        <>
          <Button variant='outlined' onClick={handleClose} disabled={deleting}>
            Cancel
          </Button>
          <Button variant='contained' onClick={contactSupport}>
            Contact Support
          </Button>
        </>
      );
      break;
    case 'error':
      icon = <img src="/assets/img/domains/no-entry.png" className={classes.image} />;
      title = 'We’re Having Issues Verifying Your Domain';
      subtext = 'Please check the information for your domain is correct, or try deleting and adding it again.';
      actions = (
        <>
          <Button variant='outlined' onClick={handleClose}>
            Close
          </Button>
          <Button variant='contained' onClick={goToDomains}>
            View My Domains
          </Button>
        </>
      );
      break;
  }

  return (
    <ModalWrapper size="sm" show={show}>
      <DialogTitle>
        <Grid container direction='row-reverse'>
          <Close onClick={handleClose} className={classes.closeIcon} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box style={{ padding: '24px' }}>
          <Grid container direction='column' alignItems='center' >
            <Grid item>
              {icon}
            </Grid>
            <Grid item>
              <Typography variant='h2' className={classes.text}>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' className={classes.text}>
                {subtext}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actionSeparator}>
        {actions}
      </DialogActions>
    </ModalWrapper>
  );
};

export default DomainManagementModal;
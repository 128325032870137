/* eslint-disable react/display-name */
import { ButtonV2, Dropdown, Pill } from "@castiron/components";
import { Message, MessageHistory, ScheduledMessage } from "@castiron/domain";
import {
  Divider,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  GridCellParams,
  GridColumns,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import { TabContext, TabPanel } from "@material-ui/lab";
import _ from "lodash";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { scheduledMessagesRepository } from "../../../domain";
import { getService } from "../../../firebase";
import { useAppSelector } from "../../../hooks";
import ActionsMenu from "../../ActionsMenu";
import DataGrid from "../../DataGrid/DataGrid";
import { tabProps } from "../../Layout/Header/HeaderTabs";
import SortHeader from "../../SortHeader";
import Spinner from "../../Spinner";

const emailEventsSearchService = getService("messaging", "emaileventssearch");

export type MarketingStatsMessage = (MessageHistory | Message<any>) & {
  delivered: number;
  opened?: number;
  clicked?: number;
  audience: number;
};

type Props = {
  context: "email" | "sms";
};

export type SortOption =
  | "alphabetical"
  | "lastEdited"
  | "dateCreated"
  | "newestFirst";

const useStyles = makeStyles((theme: Theme) => ({
  activeSortOption: {
    color: theme.branding.v2.plum[500],
  },
  messageCard: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    gap: "12px",
    padding: 16,
  },
  tabPanel: {
    padding: 0,
    width: "100%",
  },
  tabsContainer: {
    "& div > div.MuiTabs-flexContainer": {
      borderBottom: "none !important",
    },
  },
  titleCutoff: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    wordBreak: "break-all",
  },
}));

const MarketingStats: React.FC<Props> = (props: Props) => {
  const { context } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { account } = useAppSelector((state) => ({
    account: state.shops.account,
  }));

  const [tabValue, setTabValue] = useState<string>("all");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalSentMessagesCount, setTotalSentMessagesCount] =
    useState<number>(null);
  const [sentMessages, setSentMessages] = useState<MessageHistory[]>([]);
  const [totalScheduledMessagesCount, setTotalScheduledMessagesCount] =
    useState<number>(null);
  const [scheduledMessages, setScheduledMessages] = useState<
    ScheduledMessage<any>[]
  >([]);
  const [combinedMessages, setCombinedMessages] = useState<
    MarketingStatsMessage[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<SortOption>("dateCreated");

  const messagesDisplayed =
    tabValue === "all"
      ? combinedMessages
      : tabValue === "sent"
      ? combinedMessages?.filter((m) => (m as MessageHistory).wasSent)
      : combinedMessages.filter((m) => !(m as MessageHistory).wasSent);
  const messagesDisplayedTotalCount =
    tabValue === "all"
      ? totalSentMessagesCount + totalScheduledMessagesCount
      : tabValue === "sent"
      ? totalSentMessagesCount
      : totalScheduledMessagesCount;

  const batchSize = 10;
  const sentMessagesSortMap = {
    alphabetical: context === "email" ? "subject" : "body",
    lastEdited: "createdAt",
    dateCreated: "createdAt",
    newestFirst: "sendAt",
  };
  const scheduledMessagesSortMap = {
    alphabetical:
      context === "email"
        ? "message.content.subjectLine"
        : "message.content.text",
    lastEdited: "createdAt",
    dateCreated: "createdAt",
    newestFirst: "sendAt",
  };

  useEffect(() => {
    const getTotalMessagesCount = async () => {
      if (account) {
        const sentCount = await account.getMessageHistoryCount(context);
        const scheduledCount =
          await scheduledMessagesRepository.findScheduledMessagesForShopCount(
            account.id,
            context === "email"
              ? [
                  "email_announcement",
                  "presale_announcement",
                  "product_announcement",
                ]
              : ["sms_announcement"]
          );
        setTotalSentMessagesCount(sentCount);
        setTotalScheduledMessagesCount(scheduledCount);
      }
    };
    getTotalMessagesCount();
  }, [account]);

  const getMessages = async (
    newPage: number,
    updateSort?: SortOption
  ): Promise<void> => {
    const orderBy = updateSort ? updateSort : sortBy;
    if (
      account &&
      totalSentMessagesCount !== null &&
      totalScheduledMessagesCount !== null
    ) {
      setIsLoading(true);
      // don't need to get items again if we're going back or have already retrieved those items
      if (
        updateSort ||
        (newPage >= page &&
          sentMessages?.length + scheduledMessages?.length <=
            (page + 1) * (batchSize * 2))
      ) {
        let newSentMessages = [];
        let newScheduledMessages = [];
        if (updateSort || sentMessages?.length < totalSentMessagesCount) {
          const sentStartAfter =
            newPage === 0
              ? ""
              : sentMessages?.length > 0
              ? sentMessages[sentMessages.length - 1]?.id
              : "";
          const messageHistory = await account.getMessageHistory(
            context,
            batchSize,
            sentMessagesSortMap[orderBy] as "subject" | "createdAt" | "sendAt",
            sentStartAfter
          );
          setSentMessages([
            ...(updateSort ? [] : sentMessages),
            ...messageHistory,
          ]);
          const sentMessagesMapped = await Promise.all(
            messageHistory.map(async (message) => {
              if (context === "email") {
                const deliveredResponse = await emailEventsSearchService({
                  messageId: message.messageId,
                  event: "delivered",
                  countOnly: true,
                });
                const openedResponse = await emailEventsSearchService({
                  messageId: message.messageId,
                  event: "open",
                  countOnly: true,
                });
                const clickedResponse = await emailEventsSearchService({
                  messageId: message.messageId,
                  event: "click",
                  countOnly: true,
                });
                return {
                  ...message,
                  updatedAt: message.createdAt,
                  delivered: deliveredResponse?.total || 0,
                  opened: openedResponse?.total || 0,
                  clicked: clickedResponse?.total || 0,
                  audience: message?.to?.customerIds?.length || 0,
                };
              } else {
                const bodyTextSplit = message.body.split("\n");
                const body =
                  bodyTextSplit?.length >= 2 ? bodyTextSplit[1] : message.body;
                return {
                  ...message,
                  subject: body,
                  updatedAt: message.createdAt,
                  delivered: message?.to?.customerIds?.length || 0,
                  audience: message?.to?.customerIds?.length || 0,
                };
              }
            })
          );
          newSentMessages = sentMessagesMapped;
        }
        if (
          updateSort ||
          scheduledMessages?.length < totalScheduledMessagesCount
        ) {
          const scheduledStartAfter =
            newPage === 0
              ? null
              : scheduledMessages?.length > 0
              ? scheduledMessages[scheduledMessages.length - 1]
              : null;
          const scheduledMessageHistory =
            await scheduledMessagesRepository.findScheduledMessagesForShop(
              account.id,
              context === "email"
                ? [
                    "email_announcement",
                    "presale_announcement",
                    "product_announcement",
                  ]
                : ["sms_announcement"],
              batchSize,
              scheduledMessagesSortMap[orderBy] as
                | "message.content.subjectLine"
                | "message.content.text"
                | "updatedAt"
                | "createdAt"
                | "sendAt",
              scheduledStartAfter
            );
          setScheduledMessages([
            ...(updateSort ? [] : scheduledMessages),
            ...scheduledMessageHistory,
          ]);
          const scheduledMessagesMapped = scheduledMessageHistory.map(
            (scheduledMessage) => {
              if (context === "email") {
                return {
                  ...scheduledMessage.message,
                  id: scheduledMessage.id,
                  createdAt: scheduledMessage.createdAt,
                  delivered: 0,
                  opened: 0,
                  clicked: 0,
                  audience:
                    scheduledMessage.message.to?.customerIds?.length || 0,
                };
              } else {
                return {
                  ...scheduledMessage.message,
                  id: scheduledMessage.id,
                  createdAt: scheduledMessage.createdAt,
                  subject: scheduledMessage.message?.content?.text,
                  delivered: 0,
                  audience:
                    scheduledMessage.message?.to?.customerIds?.length || 0,
                };
              }
            }
          );
          newScheduledMessages = scheduledMessagesMapped;
        }
        let newCombinedMessages = [
          ...(updateSort ? [] : combinedMessages),
          ...newSentMessages,
          ...newScheduledMessages,
        ];
        newCombinedMessages.sort((m1, m2) => {
          if (orderBy === "alphabetical") {
            return m1.subject.localeCompare(m2.subject);
          } else if (orderBy === "dateCreated") {
            return m2.createdAt - m1.createdAt;
          } else if (orderBy === "lastEdited") {
            return m2.updatedAt - m1.updatedAt;
          } else if (orderBy === "newestFirst") {
            return m2.sendAt - m1.sendAt;
          }
        });
        setCombinedMessages(newCombinedMessages);
      }
      setPage(newPage);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMessages(0);
  }, [totalSentMessagesCount, totalScheduledMessagesCount]);

  const columns: GridColumns = [
    {
      field: "subject",
      headerName: context === "email" ? "Email Title" : "SMS Title",
      flex: 2,
      renderCell: (params: GridCellParams): ReactElement => (
        <Grid container item direction="column" wrap="nowrap">
          <Typography variant="subtitle1">{params.row.subject}</Typography>
          <Typography
            variant="caption"
            style={{ color: theme.branding.v2.gray[400] }}
          >
            {params.row.wasSent ? "Sent" : "Scheduled for"}{" "}
            {moment.unix(params.row.sendAt).format("MMMM D, YYYY [at] h:mmA")}
          </Typography>
        </Grid>
      ),
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "wasSent",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params: GridCellParams): ReactElement => {
        return (
          <Pill
            content={params.row.wasSent ? "SENT" : "SCHEDULED"}
            variant={params.row.wasSent ? "green" : "gray"}
          />
        );
      },
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "delivered",
      flex: 0.5,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.row.delivered || "--",
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    ...(context === "email"
      ? [
          {
            field: "opened",
            flex: 0.5,
            valueFormatter: (params: GridValueFormatterParams) =>
              params.row.opened || "--",
            renderHeader: (params: GridCellParams): ReactElement => (
              <SortHeader params={params} />
            ),
          },
          {
            field: "clicked",
            flex: 0.5,
            valueFormatter: (params: GridValueFormatterParams) =>
              params.row.clicked || "--",
            renderHeader: (params: GridCellParams): ReactElement => (
              <SortHeader params={params} />
            ),
          },
        ]
      : []),
    {
      field: "audience",
      flex: 0.5,
      valueFormatter: (params: GridValueFormatterParams) => params.row.audience,
      renderHeader: (params: GridCellParams): ReactElement => (
        <SortHeader params={params} />
      ),
    },
    {
      field: "actionMenu",
      renderHeader: (): ReactElement => <div style={{ display: "none" }} />,
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridCellParams): ReactElement => (
        <ActionsMenu
          message={params.row as MarketingStatsMessage}
          status={params.row.wasSent ? "sent" : "scheduled"}
          type="marketingStats"
        />
      ),
    },
  ];

  const sortByDropdown = (
    <Dropdown
      fullWidth={isXsMobile}
      title="Sort by"
      options={[
        {
          label: (
            <Typography
              variant="subtitle2"
              className={
                sortBy === "alphabetical" ? classes.activeSortOption : ""
              }
            >
              Alphabetical
            </Typography>
          ),
          onClick: () => {
            setSortBy("alphabetical");
            getMessages(0, "alphabetical");
          },
        },
        {
          label: (
            <Typography
              variant="subtitle2"
              className={
                sortBy === "lastEdited" ? classes.activeSortOption : ""
              }
            >
              Last Edited
            </Typography>
          ),
          onClick: () => {
            setSortBy("lastEdited");
            getMessages(0, "lastEdited");
          },
        },
        {
          label: (
            <Typography
              variant="subtitle2"
              className={
                sortBy === "dateCreated" ? classes.activeSortOption : ""
              }
            >
              Date Created
            </Typography>
          ),
          onClick: () => {
            setSortBy("dateCreated");
            getMessages(0, "dateCreated");
          },
        },
        {
          label: (
            <Typography
              variant="subtitle2"
              className={
                sortBy === "newestFirst" ? classes.activeSortOption : ""
              }
            >
              Newest First
            </Typography>
          ),
          onClick: () => {
            setSortBy("newestFirst");
            getMessages(0, "newestFirst");
          },
        },
      ]}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    />
  );

  const noMessagesText = `No ${
    context === "email" ? "emails" : "messages"
  } to show yet. Create a new ${
    context === "email" ? "email" : "SMS"
  } and see how it does.`;

  const messageCard = (message: MarketingStatsMessage) => (
    <Grid
      container
      item
      direction="column"
      wrap="nowrap"
      className={classes.messageCard}
      key={`message-${message.id}`}
    >
      <Grid container item wrap="nowrap" alignItems="flex-end">
        <Pill
          content={(message as MessageHistory)?.wasSent ? "SENT" : "SCHEDULED"}
          variant={(message as MessageHistory)?.wasSent ? "green" : "gray"}
        />
        <ActionsMenu
          message={message}
          status={(message as MessageHistory)?.wasSent ? "sent" : "scheduled"}
          type="marketingStats"
          location="marketingStatsMobile"
        />
      </Grid>
      <Typography variant="h3" className={classes.titleCutoff}>
        {message.subject}
      </Typography>
      <Typography
        variant="caption"
        style={{ color: theme.branding.v2.gray[400] }}
      >
        {(message as MessageHistory)?.wasSent ? "Sent" : "Scheduled for"}{" "}
        {moment
          .unix((message as MessageHistory)?.sendAt)
          .format("MMMM D, YYYY [at] h:mmA")}
      </Typography>
      <Divider />
      <Grid
        container
        item
        wrap="nowrap"
        justify={context === "email" ? "space-between" : "flex-start"}
        style={{ gap: "16px" }}
      >
        {(context === "email"
          ? ["delivered", "opened", "clicked", "audience"]
          : ["delivered", "audience"]
        ).map((stat, index) => (
          <Grid
            key={`stat-${index}`}
            container
            item
            direction="column"
            alignContent="center"
            style={{ textAlign: "center" }}
          >
            <Typography variant="body1">{message[stat] || "--"}</Typography>
            <Typography variant="caption">{_.capitalize(stat)}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const content = isXsMobile ? (
    <Grid container direction="column" style={{ gap: "16px" }}>
      {!isLoading && messagesDisplayedTotalCount === 0 ? (
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          className={classes.messageCard}
        >
          <Typography>{noMessagesText}</Typography>
        </Grid>
      ) : (
        sortByDropdown
      )}
      {messagesDisplayed.map((message) => messageCard(message))}
      {messagesDisplayed?.length < messagesDisplayedTotalCount && (
        <ButtonV2
          variant="outlined"
          onClick={() => getMessages(page + 1)}
          disabled={isLoading}
          loading={isLoading}
        >
          Load more
        </ButtonV2>
      )}
    </Grid>
  ) : (
    <>
      <Spinner size="fullscreen" show={isLoading} />
      <DataGrid
        columns={columns}
        rows={messagesDisplayed}
        pageSize={batchSize}
        onPageChange={getMessages}
        rowCount={messagesDisplayedTotalCount}
        page={page}
        noRowsLabel={noMessagesText}
      />
    </>
  );

  const tabs: tabProps[] = [
    {
      label: (
        <span>
          All ({totalSentMessagesCount + totalScheduledMessagesCount})
        </span>
      ),
      value: "all",
      content,
    },
    {
      label: <span>Sent ({totalSentMessagesCount})</span>,
      value: "sent",
      content,
    },
    {
      label: <span>Scheduled ({totalScheduledMessagesCount})</span>,
      value: "scheduled",
      content,
    },
  ];

  const onTabChange = (event, selectedTabValue: string): void => {
    setTabValue(selectedTabValue);
  };

  return (
    <Grid
      container
      item
      direction="column"
      wrap="nowrap"
      style={{ gap: "16px", minHeight: 780 }}
    >
      <Typography variant="subtitle1">
        Recent {context === "email" ? "Email" : "SMS"} Marketing
      </Typography>
      <TabContext value={tabValue}>
        <Tabs
          variant="standard"
          indicatorColor="primary"
          value={tabValue}
          onChange={onTabChange}
          aria-label="marketing-tabs"
          className={classes.tabsContainer}
          centered={isXsMobile}
        >
          {tabs.map((tab, index) => (
            <Tab
              role="button"
              tabIndex={index}
              id={`${tab.value}-tab`}
              aria-pressed={false}
              value={tab.value}
              label={tab.label || tab.value}
              key={`tab-${tab.value}-${index}`}
            />
          ))}
          {!isXsMobile && (
            <div style={{ marginLeft: "auto" }}>{sortByDropdown}</div>
          )}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel
            value={tab.value}
            key={`tab-panel-marketing-${tab.value}-${index}`}
            className={classes.tabPanel}
          >
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Grid>
  );
};

export default MarketingStats;

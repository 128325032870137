import React, { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Badge,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  FeatureGate,
  NewChip,
  ProFeatureChip,
  Typography,
  useFeatures,
  Pill,
} from "@castiron/components";
import { FeatureName } from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { useAppSelector } from "../../../../../hooks";

type Props = {
  label: string;
  path: string;
  betaChip?: boolean;
  className?: string;
  external?: boolean;
  feature?: FeatureName;
  Icon?: any;
  iconClass?: string;
  itemCount?: number;
  neverActive?: boolean;
  newChip?: boolean;
  onClick?: any;
  previewPath?: string;
  proFeature?: boolean;
};

type NavGateProps = {
  children: ReactNode;
  feature?: FeatureName;
  disabled?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.branding.v2.plum[50],
    color: theme.branding.v2.plum[500],
    ".MuiCollapse-container &": {
      background: "none",
    },
    "& span": {
      color: theme.branding.v2.plum[500],
    },
  },
  badge: {
    right: "-16px",
    "& .MuiBadge-badge": {
      backgroundColor: theme.branding.v2.deepOrange[500],
      color: theme.branding.gray[100],
      fontSize: 9,
      height: 16,
      lineHeight: 2,
    },
  },
  icon: {
    width: 20,
    height: 20,
    fontSize: 20,
  },
  standardIconColor: {
    color: theme.branding.v2.gray[800],
    "& g > path": {
      fill: theme.branding.v2.gray[800],
    },
  },
  iconActive: {
    "& svg": {
      color: theme.branding.v2.plum[500],
      "& g > path": {
        fill: theme.branding.v2.plum[500],
      },
    },
    "&:hover": {
      "& svg": {
        color: theme.branding.v2.plum[500],
        "& g > path": {
          fill: theme.branding.v2.plum[500],
        },
      },
    },
  },
  newChip: {
    transform: "scale(1) translate(50%, 0%)",
    transformOrigin: "100% 0%",
  },
  listItem: {
    padding: 8,
    borderRadius: "12px",
    marginBottom: 2,
    "&.MuiLink-root": {
      color: "inherit",
    },
    "&:last-child": {
      marginBottom: 0,
    },
    "& .MuiListItemText-root": {
      margin: 0,
    },
    ".MuiCollapse-container &": {
      fontSize: 13,
      width: "auto",
      marginLeft: theme.spacing(4),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      "&:hover": {
        background: "none",
      },
    },
  },
  wrapperActive: {
    "&:hover": {
      backgroundColor: theme.branding.v2.plum[50],
      borderRadius: "12px",
    },
    " & .MuiListItem-button:hover": {
      backgroundColor: theme.branding.v2.plum[50],
    },
  },
}));

const NavGate: React.FC<NavGateProps> = (props) => {
  const { children, feature, disabled } = props;
  if (feature) {
    return (
      <FeatureGate name={feature} disabled={disabled}>
        {children}
      </FeatureGate>
    );
  } else {
    return <>{children}</>;
  }
};

const NavItem: React.FC<Props> = (props: Props) => {
  const {
    betaChip,
    className,
    external,
    feature,
    Icon,
    iconClass,
    itemCount,
    label,
    neverActive,
    newChip,
    onClick,
    proFeature,
    path,
    previewPath,
  } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const location = useLocation();
  const features = useFeatures();

  const { account } = useAppSelector((state) => ({
    account: state.shops.account,
  }));

  let isActive;
  if (path === "/" && !neverActive) {
    isActive = path === location.pathname;
  } else if (!neverActive) {
    isActive = new RegExp(`^${path}$`).test(location.pathname);
  } else isActive = false;

  const usePreview = previewPath && !features.includes(feature);

  const NavComponent = !external ? NavLink : Link;

  const handleClick = (e) => {
    onClick && onClick(e);
    trackEvent("Admin Nav Item Clicked", {
      label: label,
      path: usePreview ? previewPath : path,
    });
  };

  return (
    <NavGate feature={usePreview ? null : feature}>
      <li className={`${isActive && classes.wrapperActive} ${className}`}>
        <ListItem
          button
          component={NavComponent}
          data-href={path}
          to={!external ? (usePreview ? previewPath : path) : null}
          href={external ? path : null}
          target={external ? "_blank" : "_self"}
          className={`${classes.listItem}${
            isActive ? ` ${classes.active}` : ""
          }`}
          onClick={handleClick}
        >
          {Icon && (
            <ListItemIcon className={isActive ? classes.iconActive : ""}>
              <Icon
                className={`${classes.icon} ${
                  iconClass ? iconClass : classes.standardIconColor
                }`}
              />
            </ListItemIcon>
          )}
          <ListItemText
            disableTypography
            primary={<Typography variant="button">{label}</Typography>}
          />
          {newChip && <NewChip className={classes.newChip} />}
          {betaChip && <Pill content="BETA" variant="yellow" />}
          {itemCount > 0 && (
            <ListItemIcon>
              <Badge badgeContent={itemCount} className={classes.badge} />
            </ListItemIcon>
          )}
          {proFeature &&
            !account.isCastironPlus() &&
            (!features.includes(feature) || account.isInTrial()) && (
              <ListItemIcon>
                <ProFeatureChip selected={isActive} />
              </ListItemIcon>
            )}
        </ListItem>
      </li>
    </NavGate>
  );
};

export default NavItem;

import React, { ReactElement, useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TabContext, TabPanel } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import Spinner from "../../Spinner";
import { CustomSwitch, SaveButton, DiscardButton } from "@castiron/components";
import { updateShopAction } from "../../../store/reducers/shops";
import Tooltip from "../../Tooltip";
import { useTracking } from "@castiron/utils";
import { useHistory, useLocation } from "react-router-dom";
import UnsavedChangesPrompt from "../../UnsavedChangesPrompt.tsx";
import FeeStructure from "./FeeStructure";
import { openModal } from "../../../store/reducers/modalConductor";
import AdminForm from "../../AdminForm";
import { FeatureFlag } from "@castiron/castiron-firebase";
import { LayoutPageProps } from "../../Layout";
import TaxRate from "./TaxRate";
import Payouts from "./Payouts";

import obModalDesktopScreen1 from "../../../assets/img/onboardingModals/paymentsModal/PaymentDesktopScreen1.png";
import obModalMobileScreen1 from "../../../assets/img/onboardingModals/paymentsModal/PaymentMobileScreen1.png";

const useStyles = makeStyles((theme: Theme) => ({
  bodyContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 17,
      paddingRight: 17,
    },
  },
  paymentModalMediaAdjustments: {
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  paymentModalContentAdjustments: {
    maxWidth: "100% !important",
  },
  tabNav: {
    padding: "0px 24px",
    "& .MuiTabs-flexContainer": {
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
    },
  },
  tippingContainer: {
    height: 64,
    padding: "0 24px",
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 16,
    margin: "24px 0",
  },
}));

export interface PaymentsValues {
  taxRate: number;
  state: string;
  chosenFeeStructure: string;
  isCustomerPayingStripeFee: boolean;
}

const Payments: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromChecklist = urlParams.get("fromChecklist");
  const history = useHistory();
  const { trackEvent } = useTracking();
  const { account, shop, isShopLoading, stripeStatus } = useAppSelector(
    (state) => ({
      account: state.shops.account,
      shop: state.shops.shop,
      isShopLoading: state.shops.loading,
      stripeStatus: state.shops.stripe.status,
    })
  );
  const formikRef = useRef<FormikProps<PaymentsValues>>();
  const location = useLocation<{ redirectUrl?: string }>();
  const redirectUrl = `${process.env.REACT_APP_ADMIN_URL}store/payments?fromStripe=true`;
  const [submitting, setSubmitting] = useState(false);
  const [tab, setTab] = useState<string>(
    stripeStatus !== "READY" ? "payouts" : "settings"
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onTabChange = (event, selectedTab: string): void => {
    setTab(selectedTab);
  };

  const submit = async (values, formikProps) => {
    const { state, taxRate, tipping, isCustomerPayingStripeFee } = values;

    setSubmitting(true);

    const paymentSettings = {
      ...shop.paymentSettings,
      taxRate,
      isCustomerPayingStripeFee,
    };

    if (account?.hasCastironTakeRate()) {
      paymentSettings.customerRate = isCustomerPayingStripeFee
        ? shop?.paymentSettings?.castironTakeRate
        : 0;
    }

    //since the toggle sets up isCustomerPayingStripeFee don't do switch to override
    if (!account.isCastironPlus()) {
      switch (values.chosenFeeStructure) {
        case "artisan":
          paymentSettings.customerRate = 0;
          paymentSettings.isCustomerPayingStripeFee = false;
          break;
        case "customer":
          paymentSettings.customerRate =
            shop?.paymentSettings?.castironTakeRate;
          paymentSettings.isCustomerPayingStripeFee = true;
          break;
        case "split":
          paymentSettings.customerRate =
            0.5 * shop?.paymentSettings?.castironTakeRate;
          paymentSettings.isCustomerPayingStripeFee = true;
          break;
      }
    }

    const newShop = {
      ...shop,
      state,
      paymentSettings,
      config: {
        ...shop?.config,
        tipping,
      },
    };

    if (typeof newShop.paymentSettings?.taxRate === "string") {
      newShop.paymentSettings.taxRate = Number(
        newShop.paymentSettings?.taxRate
      );
    }
    await dispatch(updateShopAction({ shop: newShop }));

    dispatch(
      openModal({
        modalType: "SIMPLE_ALERT",
        modalProps: {
          show: true,
          celebrate: true,
          content: <>Payment settings were updated</>,
        },
      })
    );

    if (
      shop.paymentSettings.customerRate != newShop.paymentSettings.customerRate
    ) {
      trackEvent("Payment Settings Updated", {
        paymentSettings: newShop.paymentSettings,
      });
    }

    setSubmitting(false);
    formikProps.resetForm();
    if (fromChecklist) history.push("/");
  };

  const paymentSchema = yup.object().shape({
    castironTakeRate: yup.number(),
    customerRate: yup.number(),
    state: yup.string(),
    taxRate: yup.number(),
  });

  useEffect(() => {
    setPageTitle("Payments");
    setBackLocation(true);
    setHeaderCTAs([]);

    return () => {
      setPageTitle("");
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton
        isSubmitting={submitting}
        backLocation="/store/dashboard"
      />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} />,
    ]);
  }, [submitting]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentModalContent = [
    {
      header: "Connect to Stripe to Get Paid!",
      body: isMobile
        ? "Nourysh partners with Stripe to securely process your customers' payments and automatically deposit your sales directly into your bank account."
        : "Nourysh partners with Stripe to securely process your customers' payments and automatically deposit your sales directly into your bank account. Once connected, you'll be able to accept all major debit cards, credit cards, and wallets such as Apple and Google Pay.",
      media: isMobile ? (
        <img src={obModalMobileScreen1} alt="Welcome Payment Screen 1" />
      ) : (
        <img src={obModalDesktopScreen1} alt="Welcome Payment Screen 1" />
      ),
      mediaClassName: classes.paymentModalMediaAdjustments,
      contentClassName: classes.paymentModalContentAdjustments,
    },
  ];

  const openWelcomePaymentModal = () => {
    dispatch(
      openModal({
        modalType: "ONBOARDING_MODAL",
        modalProps: {
          show: true,
          stepContent: paymentModalContent,
          onboardingModalType: "paymentModal",
        },
      })
    );
  };

  // Comment this out for now may comeback and delete
  // useEffect(() => {
  //   if (shop?.status === 'prelaunch' && account) {
  //     if (!account.onboardingModals || (account.onboardingModals && !account.onboardingModals?.paymentModalShown)) {
  //       openWelcomePaymentModal();
  //     }
  //   }
  // }, [account]);

  return (
    <Grid container>
      <Helmet>
        <title>Payments | Nourysh</title>
      </Helmet>
      {isLoading || isShopLoading ? (
        <Spinner show={isLoading || isShopLoading} />
      ) : (
        <Formik
          initialValues={{
            taxRate: shop?.paymentSettings?.taxRate || 0,
            state: shop?.physicalAddress?.region || "",
            chosenFeeStructure: "customer",
            tipping: !!shop?.config?.tipping,
            isCustomerPayingStripeFee: true,
          }}
          validationSchema={paymentSchema}
          onSubmit={submit}
          innerRef={formikRef}
        >
          {({ values, dirty, setFieldValue, isSubmitting }): ReactElement => (
            <AdminForm>
              {!isSubmitting && <UnsavedChangesPrompt when={dirty} />}
              <TabContext value={tab}>
                <Tabs
                  variant="standard"
                  indicatorColor="primary"
                  value={tab}
                  onChange={onTabChange}
                  aria-label="view-payment-settings"
                  className={classes.tabNav}
                >
                  <Tab
                    role="button"
                    tabIndex={0}
                    id="payouts-tab"
                    aria-pressed={false}
                    value="payouts"
                    label="Payouts"
                  />
                  <Tab
                    role="button"
                    tabIndex={0}
                    id="settings-tab"
                    aria-pressed={false}
                    value="settings"
                    label="Settings"
                  />
                </Tabs>
                <TabPanel value="payouts">
                  <Payouts redirectUrl={redirectUrl} />
                </TabPanel>
                <TabPanel value="settings">
                  <Grid item xs={12}>
                    <Grid className={classes.bodyContainer} item xs={12}>
                      <FeatureFlag name="feature_tipping">
                        <Grid
                          container
                          xs={12}
                          className={classes.tippingContainer}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid>
                            <Typography variant="subtitle1">
                              Tipping
                              <Tooltip title="Allow customers to add a tip to their order when they check out. The Nourysh fee never applies to tips received." />
                            </Typography>
                          </Grid>
                          <CustomSwitch
                            checked={values.tipping}
                            onChange={(e, checked) =>
                              setFieldValue("tipping", checked)
                            }
                            name="tipping"
                          />
                        </Grid>
                      </FeatureFlag>
                      <FeeStructure />
                      <TaxRate />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </AdminForm>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default Payments;

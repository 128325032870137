import React, { useMemo } from 'react';
import { Grid, makeStyles, Theme, Typography, Button as MUIButton, Link } from '@material-ui/core';
import ButtonGroup from '../../ButtonGroup';
import { Field, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { Input, Button, Chip } from '@castiron/components';
import { FormValues } from '.';
import { defaultMsg } from '../EditUpdateEmail';
import { Coupon } from '@castiron/domain';
import Dinero from 'dinero.js';
import AdminForm from '../../AdminForm';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: '16px',
  },
  chip: {
    marginLeft: '24px',
  },
  cancelButton: {
    marginRight: '16px',
  },
  sendContainer: {
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: '#F8F8F8',
    marginTop: '40px',
  },
  leftColumn: {
    marginRight: '71px',
  },
  send: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  sendLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  label: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  decorator: {
    color: '#FC6E26',
  },
  defaultMsg: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.branding.gray[800],
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  editCoupon: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: '#005FFF',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  sublabel: {
    color: theme.branding.gray[600],
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    marginBottom: '12px',
  },
}));

type Props = {
  formikProps: FormikProps<FormValues>;
  coupon: Coupon;
  marketingSendId?: string;
};

const DesktopForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { formikProps, coupon, marketingSendId } = props;

  const { values, setFieldValue, errors, touched, isSubmitting } = formikProps;

  const isValid = useMemo(() => !errors.status || !errors.artisanMessage, [errors]);

  return (
    <AdminForm>
      <Grid wrap="nowrap" container alignItems="center" justify="space-between" className={classes.header}>
        <Grid xs={8} wrap="nowrap" container alignItems="center">
          <Typography variant="h3">New subscriber coupon</Typography>
          <Chip colorScheme={values.status === 'active' ? 'success' : 'error'} uppercase bold className={classes.chip}>
            {values.status === 'active' ? 'Active' : 'Inactive'}
          </Chip>
        </Grid>
        <Grid xs={4} container justify="flex-end">
          <Button
            className={classes.cancelButton}
            onClick={() => history.push('/marketing/automations')}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button disabled={!isValid || isSubmitting} type="submit" variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
      {marketingSendId && (
        <Button
          onClick={() => {
            dispatch(
              openModal({
                modalType: 'AUTOMATION_TEST_MODAL',
                modalProps: {
                  marketingSendId: marketingSendId,
                },
              }),
            );
          }}
          variant="outlined"
          color="inherit"
        >
          Send a test email
        </Button>
      )}
      <Grid container wrap="nowrap">
        <Grid xs={8} className={classes.leftColumn}>
          <Grid container wrap="nowrap">
            <Grid xs={4} className={classes.sendContainer} style={{ marginRight: '16px' }}>
              <Typography className={classes.sendLabel}>Next send:</Typography>
              <Typography className={classes.send}>Ongoing</Typography>
            </Grid>
            <Grid xs={8} className={classes.sendContainer}>
              <Grid container justify="space-between">
                <Typography className={classes.sendLabel}>Subscriber coupon details:</Typography>
                {coupon && (
                  <Link className={classes.editCoupon} onClick={() => history.push(`/coupons/edit/${coupon.id}`)}>
                    Edit coupon amount
                  </Link>
                )}
              </Grid>
              {coupon ? (
                <Grid container justify="space-between">
                  <Typography className={classes.send}>{coupon.code}</Typography>
                  <Typography className={classes.send} style={{ margin: '0px 16px' }}>
                    {coupon.discount.type === 'amount'
                      ? `${Dinero({ amount: coupon.discount.value }).toFormat('$0.00')} off`
                      : `${coupon.discount.value}% off`}
                  </Typography>
                  {coupon.maximumPerCustomer && (
                    <Typography className={classes.send}>{coupon.maximumPerCustomer} per customer</Typography>
                  )}
                </Grid>
              ) : (
                <Grid container justify="space-between">
                  <Typography className={classes.send}>THANKYOUSAVE10</Typography>
                  <Typography className={classes.send} style={{ margin: '0px 16px' }}>
                    10% off
                  </Typography>
                  <Typography className={classes.send}>1 per customer</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Typography
            className={classes.label}
            style={{
              marginTop: '48px',
              marginBottom: '-8px',
            }}
          >
            Personal sign off<span className={classes.decorator}> *</span>
          </Typography>
          <Input
            error={touched.artisanMessage && errors.artisanMessage}
            name="artisanMessage"
            multiline
            rows={8}
            placeholder={defaultMsg}
          />
          <Grid container justify="flex-end">
            <Link className={classes.defaultMsg} onClick={() => setFieldValue('artisanMessage', defaultMsg)}>
              Reset to default mesage
            </Link>
          </Grid>
        </Grid>
        <Grid xs={5}>
          <Typography
            className={classes.label}
            style={{
              marginBottom: '-14px',
            }}
          >
            Send status<span className={classes.decorator}> *</span>
          </Typography>
          <Field
            required
            as={ButtonGroup}
            error={touched.status && errors.status}
            name="status"
            value={values.status}
            onChange={(value): void => {
              switch (value) {
                case 'active':
                  setFieldValue('status', 'active');
                  break;
                case 'inactive':
                  setFieldValue('status', 'inactive');
                  break;
              }
            }}
            buttons={['active', 'inactive']}
          />
          <Typography className={classes.sublabel}>
            Setting your send status to inactive will turn off the monthly shop update autosend.
          </Typography>
        </Grid>
      </Grid>
    </AdminForm>
  );
};

export default DesktopForm;

import {
  ButtonV2,
  DiscardButton,
  Dropdown,
  SaveButton,
  Typography,
} from "@castiron/components";
import { useTracking } from "@castiron/utils";
import { Grid, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import Dinero from "dinero.js";
import { Field, Formik, FormikProps } from "formik";
import moment from "moment-timezone";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { getNewsletterStats } from ".";
import { shopRepository } from "../../../domain";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { openModal } from "../../../store/reducers/modalConductor";
import ButtonGroup from "../../ButtonGroup";
import { LayoutPageProps } from "../../Layout";
import Spinner from "../../Spinner";
import IconWithLines from "../EditWelcomeSeries/IconWithLines";

export type FormValues = {
  status: "active" | "inactive";
};

const useStyles = makeStyles((theme: Theme) => ({
  containerGrey: {
    borderRadius: 12,
    background: `${theme.branding.v2.gray[50]}`,
    padding: 16,
  },
  containerWhite: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    background: `${theme.branding.v2.gray[0]}`,
    padding: 16,
  },
  menuContainer: {
    color: theme.branding.v2.gray[500],
    "&:hover": {
      color: theme.branding.v2.gray[800],
    },
    position: "absolute",
    top: "8px",
    right: "8px",
    height: "40px",
    width: "40px",
  },
  section: {
    padding: "12px 0px",
  },
}));

const EditNewsletterSeries: React.FC<LayoutPageProps> = (
  props: LayoutPageProps
) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pageRefresh, setPageRefresh] = useState<boolean>(false);
  const [newsletterStats, setNewsletterStats] = useState<{
    totalSends: number;
    totalRevenue: number;
    lastSent: number;
  }>(null);
  const formikRef = useRef<FormikProps<any>>();

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const mobileMenu = () => (
    <Paper className={classes.menuContainer}>
      <Grid
        container
        style={{ height: "100%" }}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Dropdown
            variant="ellipsis"
            options={[
              {
                label: "Send Myself Test Emails",
                onClick: () => {
                  dispatch(
                    openModal({
                      modalType: "AUTOMATION_TEST_MODAL",
                      modalProps: {
                        marketingSendId: "newsletter-series",
                      },
                    })
                  );
                },
              },
            ]}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  useEffect(() => {
    setPageTitle("Newsletter");
    setBackLocation("/marketing/automations");
    setHeaderCTAs(
      isMobile
        ? [mobileMenu()]
        : [
            <ButtonV2
              variant="outlined"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "AUTOMATION_TEST_MODAL",
                    modalProps: {
                      marketingSendId: "newsletter-series",
                    },
                  })
                )
              }
            >
              Send Test Emails
            </ButtonV2>,
          ]
    );
    setFooterCTAs([
      <DiscardButton
        isSubmitting={isSubmitting}
        onClick={() => history.push("/marketing/automations")}
      />,
      <SaveButton
        handleSubmit={() => {
          window.scrollTo(0, 0);
          formikRef?.current?.handleSubmit();
        }}
        isSubmitting={isSubmitting}
      />,
    ]);

    return () => {
      setPageTitle("");
      setBackLocation(false);
    };
  }, [isMobile]);

  useEffect(() => {
    const getNewsletterSend = async () => {
      const newsletterStats = await getNewsletterStats(shop.id);
      setNewsletterStats({
        totalSends: newsletterStats?.totalSends,
        totalRevenue: newsletterStats?.totalRevenue,
        lastSent: newsletterStats?.lastSent,
      });
      setIsLoading(false);
    };

    if (shop?.id) {
      getNewsletterSend();
    }
  }, [shop]);

  useEffect(() => {
    if (pageRefresh) {
      window.location.reload();
    }
  }, [pageRefresh]);

  const onSubmit = async (values: FormValues) => {
    setIsSubmitting(true);
    await shopRepository.updateProps(shop.id, {
      "config.marketing.newsletterSeries.enabled": values.status === "active",
    });
    trackEvent(
      `Newsletter Turned ${values.status === "active" ? "On" : "Off"}`
    );
    setIsSubmitting(false);
    dispatch(
      openModal({
        modalType: "SIMPLE_ALERT_V2",
        modalProps: {
          show: true,
          title: "Success!",
          content: "The Automation has been Updated.",
          buttonText: "Done",
          handleAction: () => setPageRefresh(true),
        },
      })
    );
  };

  const formSchema = yup.object().shape({
    status: yup.string().required("Active value required"),
  });

  const sendDaySection = (sendDay: "Tuesday" | "Saturday") => (
    <Grid
      container
      xs={12}
      justify="flex-start"
      alignItems="center"
      style={{ margin: "16px 0px" }}
    >
      <Grid item style={{ marginRight: 5 }}>
        <IconWithLines
          showTopLine={sendDay === "Saturday"}
          icon={
            <WatchLaterOutlinedIcon
              style={{ color: theme.branding.v2.gray[400] }}
            />
          }
          lineHeight="20px"
          offset="-16px"
        />
      </Grid>
      <Grid item>
        <Typography variant="body2">{sendDay}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      {isLoading || isSubmitting ? (
        <Grid justify="center" container>
          <Spinner show={true} size={"relative"} />
        </Grid>
      ) : (
        <Formik
          validateOnMount
          onSubmit={onSubmit}
          validationSchema={formSchema}
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            status:
              shop?.config?.marketing?.newsletterSeries?.enabled !== false
                ? "active"
                : "inactive",
          }}
        >
          {({ errors, setFieldValue, touched, values }): ReactElement => (
            <Grid container xs={12} style={{ padding: isMobile ? 16 : 0 }}>
              <Grid
                container
                item
                sm={12}
                md={7}
                style={{ padding: isMobile ? 0 : "0px 16px" }}
              >
                <Typography variant="subtitle1">Email Series</Typography>
                <Grid container className={classes.containerGrey}>
                  {sendDaySection("Tuesday")}
                  <Grid
                    container
                    className={classes.containerWhite}
                    xs={12}
                    justify="center"
                    alignItems="flex-start"
                    direction="column"
                    wrap="nowrap"
                  >
                    <Typography variant="caption">EMAIL 1/2</Typography>
                    <Typography variant="body1">
                      Relevant features for the week including:
                    </Typography>
                    <ul>
                      <li>
                        <Typography variant="body1">New products</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Relevant holidays and occasions
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                  {sendDaySection("Saturday")}
                  <Grid
                    container
                    className={classes.containerWhite}
                    xs={12}
                    justify="center"
                    alignItems="flex-start"
                    direction="column"
                    wrap="nowrap"
                  >
                    <Typography variant="caption">EMAIL 2/2</Typography>
                    <Typography variant="body1">
                      Week in review including:
                    </Typography>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          Top selling products
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                md={5}
                spacing={2}
                style={{ padding: "0px 16px" }}
              >
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Typography variant="body1">
                    Share timely updates with subscribers.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Audience</Typography>
                  <Typography variant="body1">
                    All email subscribers.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Timing</Typography>
                  <Typography variant="body1">Twice a week.</Typography>
                  {newsletterStats?.lastSent && (
                    <Typography
                      variant="body2"
                      style={{ color: theme.branding.v2.gray[500] }}
                    >
                      {`Last Sent ${moment
                        .unix(newsletterStats?.lastSent)
                        .format("MMMM D, YYYY")}`}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Status</Typography>
                  <Field
                    required
                    as={ButtonGroup}
                    error={touched.status && errors.status}
                    name="status"
                    value={values.status}
                    onChange={(value): void => {
                      switch (value) {
                        case "active":
                          setFieldValue("status", "active");
                          break;
                        case "inactive":
                          setFieldValue("status", "inactive");
                          break;
                      }
                    }}
                    buttons={["active", "inactive"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Activity</Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body2">Sent</Typography>
                      <Typography variant="body1">
                        {newsletterStats?.totalSends || 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Revenue</Typography>
                      <Typography variant="body1">
                        {typeof newsletterStats?.totalRevenue === "number"
                          ? Dinero({
                              amount: newsletterStats?.totalRevenue,
                            }).toFormat("$0,0.00")
                          : newsletterStats?.totalRevenue || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      )}
    </>
  );
};

export default EditNewsletterSeries;

import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useFormikContext } from "formik";
import clsx from "clsx";
import { FulfillmentOption, TimePeriod } from "@castiron/domain";
import { defaultTimeZone } from "@castiron/utils";
import { useAppSelector } from "../../../../../hooks";

type Props = {
  date: TimePeriod;
  index: number;
  setShowError?: (showError: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dateContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  dateTimeContainer: {
    [theme.breakpoints.down("xs")]: {
      border: `1px solid ${theme.branding.gray[400]}`,
      borderRadius: 12,
      flexDirection: "column",
      marginTop: 6,
      marginBottom: 6,
      padding: 16,
    },
  },
  datePicker: {
    width: "100%",

    "& input:hover": {
      cursor: "pointer",
    },
    "& input::placeholder": {
      color: theme.branding.v2.plum[500],
      opacity: 1,
    },
  },
  error: {
    textAlign: "center",
  },
  expired: {
    backgroundColor: theme.branding.gray[300],
    borderRadius: 12,
  },
  hyphen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 4px",
  },
  timePicker: {
    "& input:hover": {
      cursor: "pointer",
    },
    "& .MuiTextField-root": {
      "& input:hover": {
        borderBottom: "none",
      },
      "& input::placeholder": {
        color: theme.branding.v2.plum[500],
        opacity: 1,
      },
      "& input:disabled::placeholder": {
        color: theme.branding.gray[600],
      },
    },

    "& .MuiInput-underline::before": {
      borderBottom: "none",
    },
  },
}));

const DateTimePickers: React.FC<Props> = (props: Props) => {
  const { date, index, setShowError } = props;
  const formik = useFormikContext<FulfillmentOption>();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;

  const [dateTimeError, setDateTimeError] = useState("");
  const [day, setDay] = useState<moment.Moment | null>(null);

  const isExpired = day && day.isBefore(moment());

  useEffect(() => {
    if (formik.values.schedule.type === "fixed" && dateTimeError.length > 0) {
      setTimeout(() => {
        setDateTimeError("");
      }, 5000);
    }
  }, [dateTimeError]);

  useEffect(() => {
    date?.startTime ? setDay(moment.unix(date.startTime).tz(timeZone)) : null;
  }, [date?.startTime]);

  const handleDayChange = (day: moment.Moment | null) => {
    setShowError(false);
    if (day && day.isValid()) {
      setDay(day);
      let newStartTime;
      let newEndTime;

      // if a startTime exists, we're editing an existing date
      // merge the date and time
      if (date?.startTime) {
        newStartTime = moment.unix(date.startTime);
        newStartTime.year(day.year());
        newStartTime.month(day.month());
        newStartTime.date(day.date());
      } else {
        // if no startTime exists, we're adding a new date at 12:00pm
        newStartTime = moment(day);
        newStartTime.hour(12);
        newStartTime.minute(0);
        newStartTime.second(0);
        newStartTime.millisecond(0);
      }

      if (date?.endTime) {
        newEndTime = moment.unix(date.endTime);
        newEndTime.year(day.year());
        newEndTime.month(day.month());
        newEndTime.date(day.date());
      } else {
        newEndTime = moment(day);
        newEndTime.hour(13);
        newEndTime.minute(0);
        newEndTime.second(0);
        newEndTime.millisecond(0);
      }

      const newTimePeriod = {
        id: date?.id,
        startTime: newStartTime.tz(timeZone, true).unix(),
        endTime: newEndTime.tz(timeZone, true).unix(),
      };

      const newDates = [...formik.values.schedule.dates];
      newDates[index] = newTimePeriod;
      formik.setFieldValue("schedule.dates", newDates);
    }
  };

  const handleTimeChange = (time: moment.Moment | null, field: string) => {
    setShowError(false);
    if (time && time.isValid()) {
      const newTimePeriod = {
        id: date.id,
        startTime: date.startTime,
        endTime: date.endTime,
      };

      if (field === "startTime") {
        const newStartTime = moment.unix(date.startTime);
        newStartTime.hour(time.hour());
        newStartTime.minute(time.minute());
        newStartTime.tz(timeZone, true);
        newTimePeriod.startTime = newStartTime.unix();
        day ? setDay(newStartTime) : null;

        /* apply same changes to end time */
        const startTime = moment.unix(date.startTime).tz(timeZone);
        const hourChange = time.hour() - startTime.hour();
        const minuteChange = time.minute() - startTime.minute();
        const totalMinuteChange = 60 * hourChange + minuteChange;
        const newEndTime = moment
          .unix(date.endTime)
          .tz(timeZone)
          .add(totalMinuteChange, "minutes");
        if (newEndTime.day() !== newStartTime.day()) {
          newEndTime.hour(0).minute(0).second(0).subtract(1, "minute");
        }
        newTimePeriod.endTime = newEndTime.unix();
      } else if (field === "endTime") {
        const newEndTime = moment.unix(date.endTime);
        newEndTime.hour(time.hour());
        newEndTime.minute(time.minute());
        newEndTime.tz(timeZone, true);
        newTimePeriod.endTime = newEndTime.unix();
        day ? setDay(newEndTime) : null;
      }

      if (newTimePeriod.startTime < newTimePeriod.endTime) {
        const newDates = [...formik.values.schedule.dates];
        newDates[index] = newTimePeriod;
        formik.setFieldValue("schedule.dates", newDates);
        setDateTimeError("");
      } else {
        setDateTimeError("Time not saved. Start time must be before end time.");
      }
    }
  };

  return (
    <>
      <Grid
        container
        item
        className={classes.dateTimeContainer}
        justify="center"
        wrap="nowrap"
        alignItems="center"
        spacing={1}
        key={date?.id}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid className={classes.dateContainer} item xs={12}>
            <DatePicker
              autoOk
              className={clsx([
                classes.datePicker,
                isExpired && classes.expired,
              ])}
              disablePast={!isExpired}
              disableToolbar
              variant="inline"
              format="MM/DD/yyyy"
              margin="normal"
              placeholder="Select a Date"
              id="date-picker-inline"
              inputVariant="outlined"
              value={day}
              onChange={(date) => handleDayChange(date)}
            />
          </Grid>
          <Grid container item xs={12} wrap="nowrap">
            <Grid item className={classes.timePicker} xs={6}>
              <TimePicker
                margin="normal"
                minutesStep={5}
                id="time-picker"
                inputVariant="outlined"
                placeholder="Start Time"
                value={
                  date?.startTime
                    ? moment.unix(date.startTime).tz(timeZone)
                    : null
                }
                onChange={(time) => handleTimeChange(time, "startTime")}
                className={(!date?.startTime || isExpired) && classes.expired}
                disabled={!date?.startTime}
              />
            </Grid>
            <Typography className={classes.hyphen}>-</Typography>
            <Grid item className={classes.timePicker} xs={6}>
              <TimePicker
                margin="normal"
                minutesStep={5}
                id="time-picker"
                inputVariant="outlined"
                placeholder="End Time"
                value={
                  date?.endTime ? moment.unix(date.endTime).tz(timeZone) : null
                }
                onChange={(time) => handleTimeChange(time, "endTime")}
                className={(!date?.endTime || isExpired) && classes.expired}
                disabled={!date?.endTime}
              />
            </Grid>
          </Grid>
          {dateTimeError && isMobile && (
            <Typography
              variant="caption"
              color="error"
              className={classes.error}
            >
              {dateTimeError}
            </Typography>
          )}
        </MuiPickersUtilsProvider>
      </Grid>
      {dateTimeError && !isMobile && (
        <Typography variant="caption" color="error" className={classes.error}>
          {dateTimeError}
        </Typography>
      )}
    </>
  );
};

export default DateTimePickers;

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Close, HelpOutline } from "@material-ui/icons";
import { Typography } from "@castiron/components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { closeModal } from "../../store/reducers/modalConductor";
import { getShopLink } from "../../lib/domainUtils";

export interface Props {
  show: boolean;
  subdirectory?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialog-paper": {
        borderRadius: "0px",
      },
    },
  },
  helpIcon: {
    color: theme.branding.gray[600],
    height: 14,
    width: 14,
    marginLeft: "8px",
  },
  launchDialogCloseButton: {
    height: 42,
    width: 42,
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: "13px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "456px",
    [theme.breakpoints.down("sm")]: {
      padding: "4px 4px 4px 16px",
      width: "100%",
    },
  },
  linksContent: {
    padding: "24px",
  },
  linksTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 12px 14px 24px",
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  shareShopLinkButton: {
    padding: "16px",
    minWidth: "80px",
    borderRadius: "12px",
    backgroundColor: theme.branding.v2.plum[500],
    color: theme.branding.gray[100],
    whiteSpace: "nowrap",
    [theme.breakpoints.only("sm")]: {
      padding: "12px 16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "12px 13px",
    },
  },
  shopLink: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tooltip: {
    padding: 16,
    borderRadius: 12,
  },
}));

const ShareLinksModal: React.FC<Props> = (props: Props) => {
  const { show, subdirectory } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isShopLinkCopied, setIsShopLinkCopied] = useState(false);
  const [isSignupLinkCopied, setIsSignupLinkCopied] = useState(false);
  const [isPageLinkCopied, setIsPageLinkCopied] = useState(false);

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const shopLink = getShopLink(shop);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCopyShopLink = async () => {
    navigator.clipboard.writeText(shopLink);
    setIsShopLinkCopied(true);
    setTimeout(() => {
      setIsShopLinkCopied(false);
    }, 2000);
  };

  const handleCopySignupLink = async () => {
    navigator.clipboard.writeText(`${shopLink}/signup`);
    setIsSignupLinkCopied(true);
    setTimeout(() => {
      setIsSignupLinkCopied(false);
    }, 2000);
  };

  const handleCopySubdirectoryLink = async () => {
    navigator.clipboard.writeText(`${shopLink}/${subdirectory}`);
    setIsPageLinkCopied(true);
    setTimeout(() => {
      setIsPageLinkCopied(false);
    }, 2000);
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={show}
      onClose={handleClose}
      className={classes.dialog}
    >
      <DialogContent style={{ padding: 0 }}>
        <Grid className={classes.linksTitle}>
          <Typography variant="h2">Shop Links</Typography>
          <Close
            className={classes.launchDialogCloseButton}
            onClick={handleClose}
          />
        </Grid>
        <Grid className={classes.linksContent}>
          {subdirectory && subdirectory !== "home" && (
            <>
              <Grid container justify="flex-start" alignItems="center">
                <Typography variant="subtitle2">This Page's Link</Typography>
                <Tooltip
                  PopperProps={{
                    style: {
                      marginTop: "-12px",
                    },
                  }}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    <Typography
                      variant="body2"
                      style={{ color: theme.branding.gray[100] }}
                    >
                      This link navigates customers to the page you're currently
                      editing.
                    </Typography>
                  }
                >
                  <HelpOutline className={classes.helpIcon} />
                </Tooltip>
              </Grid>
              <Grid className={classes.linkContainer}>
                <Typography variant="body2" className={classes.shopLink}>
                  {shopLink}/{subdirectory}
                </Typography>
                <Button
                  variant="contained"
                  className={classes.shareShopLinkButton}
                  onClick={handleCopySubdirectoryLink}
                >
                  {isPageLinkCopied ? "Link Copied!" : "Copy Link"}
                </Button>
              </Grid>
            </>
          )}
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            style={subdirectory ? { marginTop: 24 } : {}}
          >
            <Typography variant="subtitle2">My Shop Link </Typography>
            <Tooltip
              PopperProps={{
                style: {
                  marginTop: "-12px",
                },
              }}
              classes={{
                tooltip: classes.tooltip,
              }}
              title={
                <Typography
                  variant="body2"
                  style={{ color: theme.branding.gray[100] }}
                >
                  This link navigates directly to your main shop page.
                </Typography>
              }
            >
              <HelpOutline className={classes.helpIcon} />
            </Tooltip>
          </Grid>
          <Grid className={classes.linkContainer}>
            <Typography variant="body2" className={classes.shopLink}>
              {shopLink}
            </Typography>
            <Button
              variant="contained"
              className={classes.shareShopLinkButton}
              onClick={handleCopyShopLink}
            >
              {isShopLinkCopied ? "Link Copied!" : "Copy Link"}
            </Button>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            style={{ marginTop: 24 }}
          >
            <Typography variant="subtitle2">
              My Email/SMS Signup Link{" "}
            </Typography>
            <Tooltip
              PopperProps={{
                style: {
                  marginTop: "-12px",
                },
              }}
              classes={{
                tooltip: classes.tooltip,
              }}
              title={
                <Typography
                  variant="body2"
                  style={{ color: theme.branding.gray[100] }}
                >
                  This link navigates customers to a page where they can join
                  your email/sms list.
                </Typography>
              }
            >
              <HelpOutline className={classes.helpIcon} />
            </Tooltip>
          </Grid>
          <Grid className={classes.linkContainer}>
            <Typography variant="body2" className={classes.shopLink}>
              {shopLink}/signup
            </Typography>
            <Button
              variant="contained"
              className={classes.shareShopLinkButton}
              onClick={handleCopySignupLink}
            >
              {isSignupLinkCopied ? "Link Copied!" : "Copy Link"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShareLinksModal;

import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EmptyPresalesIcon } from '@castiron/components';
import EmptyPage, { TipsForYou } from '../EmptyPage';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 120,
    width: 120,
  },
  productModalMediaAdjustments: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  productModalContentAdjustments: {
    maxWidth: '100% !important',
  },
  resourceGuide: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 16px',
    },
  },
}));

const EmptyPresales: React.FC = props => {
  const classes = useStyles();
  const history = useHistory();

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'cookie',
      title: 'Add a Product',
      description: 'Create a product you want available for a presale.',
      ctaAction: () => history.push('/products'),
    },
    {
      icon: 'car',
      title: 'Add a Fulfillment',
      description: 'Create a fulfillment you want available for a presale.',
      ctaAction: () => history.push('/store/fulfillment'),
    },
    {
      icon: 'briefcase',
      title: 'Read our Presales Guide',
      description: 'Learn everything you need to know about setting up the perfect presale.',
      ctaAction: () =>
        window.open('https://castiron.helpscoutdocs.com/article/121-rock-your-pre-sales-on-castiron', '_blank').focus(),
    },
  ];

  return (
    <Grid container item xs={12} className={classes.resourceGuide}>
      <EmptyPage
        icon={<EmptyPresalesIcon className={classes.icon} />}
        title="No Presales to Display"
        description="Group your products and fulfillments for customers to shop from for a limited time."
        tipsForYou={tipsForYou}
        page="Presales"
      />
    </Grid>
  );
};

export default EmptyPresales;

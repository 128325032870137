import { Account } from '@castiron/domain';

//Plus, Pro, Pro v2, Basic, Business, Dollar
const paidTiers = [
  '1IB2ACPnCLFm4lPjEJgM',
  '2KHC6hiFiFjVUvdDH0lD',
  'UqcnzrPWHT2fgCuTyDbz',
  'SQDLROyx5tJHhfNqB21h',
  'gBio8AP9UCXjEITDNWh3',
  'uNnuPUOpRodYpJRJfurEU',
  'Uh5M1dq1XmdacFt5uzwm', // Nourysh
  '89FximAgITOfOMF4u9Cx', // Nourysh VIP
];

export const getSubscriptionStatus = (account: Account): string => {
  const tierId = account?.subscription?.plan?.tier?.id;
  if (paidTiers.includes(tierId) && account?.subscription?.status === 'active') {
    return 'Subscriber';
  } else if (account.hasLegacySubscription()) {
    return 'Legacy';
  } else {
    return 'Trial';
  }
};

export const isUnconvertedTrial = (account: Account): boolean => {
  return account?.isInTrial() && !account?.subscription?.paymentMethod;
};

import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React from "react";
import { Banner, ButtonV2, ProcessingIcon } from "@castiron/components";
import ModalWrapper from "../RootModal/ModalWrapper";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { closeModal, openModal } from "../../store/reducers/modalConductor";
import { shopRepository } from "../../domain";
import { CheckCircleOutline } from "@material-ui/icons";
import { ChecklistValues } from "@castiron/domain";
import { useHistory } from "react-router-dom";
import firebase from "firebase/compat/app";
import ProductAdded from "../../assets/img/prelaunchModals/product-added.png";
import Brand from "../../assets/img/prelaunchModals/brand.png";
import Customize from "../../assets/img/prelaunchModals/customize.png";
import FulfillmentAdded from "../../assets/img/prelaunchModals/fulfillment-added.png";
import StripeConnection from "../../assets/img/prelaunchModals/stripe-connection.png";
import GoLive from "../../assets/img/prelaunchModals/go-live.png";

const { REACT_APP_SHOP_URL } = process.env;

export type Props = {
  show: boolean;
  stepCompleted: ChecklistValues;
};

export const requiredValues: ChecklistValues[] = [
  ChecklistValues.ProductAdded,
  ChecklistValues.Brand,
  ChecklistValues.Customize,
  ChecklistValues.FulfillmentAdded,
  ChecklistValues.StripeConnection,
];

export const requiredValuesV2: ChecklistValues[] = [
  ChecklistValues.Brand,
  ChecklistValues.Pages,
  ChecklistValues.ProductAdded,
  ChecklistValues.FulfillmentAdded,
  ChecklistValues.StripeConnection,
  ChecklistValues.AddCreditCard,
];

export const prelaunchStepContent = {
  [ChecklistValues.ProductAdded]: {
    text: "Add a Menu Item",
    url: "/products",
    modal: {
      modalType: "ADD_PRODUCT_MODAL",
      modalProps: {
        show: true,
        fromChecklist: true,
      },
    },
    image: ProductAdded,
  },
  [ChecklistValues.Brand]: {
    text: "Brand Your Shop",
    url: "/store/appearance",
    image: Brand,
  },
  [ChecklistValues.Customize]: {
    text: "Describe Your Shop",
    url: "/store/business-details",
    image: Customize,
  },
  [ChecklistValues.FulfillmentAdded]: {
    text: "Add a Fulfillment Option",
    url: "/store/fulfillment",
    modal: {
      modalType: "ADD_FULFILLMENT_MODAL",
      modalProps: {
        open: true,
      },
    },
    image: FulfillmentAdded,
  },
  [ChecklistValues.StripeConnection]: {
    text: "Connect to Get Paid",
    url: "/store/payments",
    modal: {
      modalType: "PAYMENT_MODAL",
    },
    image: StripeConnection,
  },
};

export const prelaunchStepContentV2 = {
  [ChecklistValues.Brand]: {
    text: "Customize website fonts & colors.",
    bannerText: "Customize fonts & colors.",
    url: "/store/appearance",
    image: Brand,
  },
  [ChecklistValues.Pages]: {
    text: "Add website pages.",
    bannerText: "Add website pages.",
    url: "/store/pages",
    image: Brand,
  },
  [ChecklistValues.ProductAdded]: {
    text: "Build your first instant checkout product or order form.",
    bannerText: "Build product or order form.",
    url: "/products",
    modal: {
      modalType: "ADD_PRODUCT_MODAL",
      modalProps: {
        show: true,
        fromChecklist: true,
      },
    },
    image: ProductAdded,
  },
  [ChecklistValues.FulfillmentAdded]: {
    text: "Add a local pickup, local delivery, or shipping option for your instant checkout products.",
    bannerText: "Add pickup, delivery, or shipping option.",
    url: "/store/fulfillment",
    modal: {
      modalType: "ADD_FULFILLMENT_MODAL",
      modalProps: {
        open: true,
      },
    },
    image: FulfillmentAdded,
  },
  [ChecklistValues.StripeConnection]: {
    text: "Connect to Stripe to securely accept credit & debit card payments through your website.",
    bannerText: "Enable online credit & debit card payments.",
    url: "/store/payments",
    modal: {
      modalType: "PAYMENT_MODAL",
    },
    image: StripeConnection,
  },
  [ChecklistValues.AddCreditCard]: {
    text: "You're ready to sell! Keep up the momentum by subscribing today!",
    bannerText: "Subscribe Today!",
    url: "/store/plans",
    modal: {
      modalType: "CHANGE_PLANS_MODAL",
      modalProps: {
        open: true,
      },
      image: Customize,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  addBottomMargin: {
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "auto",
    },
  },
  banner: {
    marginTop: 8,
  },
  button: {
    marginTop: 24,
  },
  icon: {
    color: theme.branding.v2.plum[500],
    height: 56,
    width: 56,
  },
  closeIcon: {
    cursor: "pointer",
    color: theme.branding.gray[800],
    fontSize: 32,
  },
  container: {
    height: "100%",
    padding: 24,
  },
  ctaText: {
    color: theme.branding.v2.plum[500],
    marginBottom: 8,
  },
  img: {
    width: "100%",
    maxWidth: 456,
  },
  paperClass: {
    borderRadius: 12,
    maxWidth: 504,
  },
  title: {
    margin: "16px 0px 8px 0px",
    textAlign: "center",
  },
  midBox: {
    marginBottom: "8px",
  },
}));

const PrelaunchModals: React.FC<Props> = (props: Props) => {
  const { show, stepCompleted } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { account, hasLoaded, isReady, shop, stripeStatus } = useAppSelector(
    (state) => ({
      account: state.shops.account,
      hasLoaded: state.shops.hasLoaded,
      isReady: state.shops.account?.isReady,
      shop: state.shops.shop,
      stripeStatus: state.shops.stripe?.status,
    })
  );

  const handleClose = (): void => {
    if (isReady && stepCompleted === ChecklistValues.GoLive) {
      localStorage.setItem("seenPrelaunchModal", "true");
    }
    dispatch(closeModal());
  };

  const handleCta = async () => {
    if (!nextStep && isReady) {
      await shopRepository?.updateProps(shop.id, {
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(
          ChecklistValues.GoLive
        ),
        status: "active",
      });
      dispatch(
        openModal({
          modalType: "SHOP_LIVE_MODAL",
          modalProps: {
            show: true,
          },
        })
      );
    } else if (!pendingStripeConnection) {
      ctaAction();
      handleClose();
    } else {
      handleClose();
    }
  };

  const nextStep = requiredValues.find(
    (item) => !shop?.checklistCompletions?.includes(item)
  );
  const pendingStripeConnection =
    nextStep === ChecklistValues.StripeConnection &&
    account?.lastStripeConnectionAttempt &&
    !isReady;

  let title: string;
  let subtext: string;
  let img = (
    <img
      src={prelaunchStepContent[nextStep]?.image}
      alt={prelaunchStepContent[nextStep]?.text}
      className={classes.img}
    />
  );
  let ctaText = prelaunchStepContent[nextStep]?.text;
  let ctaAction = () => {
    history.push(prelaunchStepContent[nextStep]?.url);
  };

  switch (stepCompleted) {
    case ChecklistValues.ProductAdded:
      title = "You Added a Menu Item!";
      subtext =
        "You’re off to the races! Add as many products or order forms as you need.";
      break;
    case ChecklistValues.Brand:
      title = "You Branded Your Shop!";
      subtext = "It’s really starting to come together!";
      break;
    case ChecklistValues.Customize:
      title = "You Added a Description!";
      subtext =
        "Now your customers can see what makes you and your business unique!";
      break;
    case ChecklistValues.FulfillmentAdded:
      title = "You Added a Fulfillment Option!";
      subtext =
        "Your customers are now able to select a fulfillment method at checkout.";
      break;
    case ChecklistValues.StripeConnection:
      if (stripeStatus === "READY") {
        title = "You’re Connected to Stripe!";
        subtext =
          "You can officially accept payment! Stripe will automatically deposit your funds in your bank account. Let the funds roll in!";
      } else {
        title = "Your Stripe Account is Being Processed";
        subtext =
          "This process typically takes a few minutes. Check your Payments tab for status updates.";
      }
      break;
  }

  return (
    <ModalWrapper
      paperClass={!isMobile && classes.paperClass}
      fullScreen={isMobile}
      show={show && hasLoaded}
      onClose={handleClose}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid container justify="flex-end">
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        {!nextStep && isReady ? (
          <>
            <img
              src={GoLive}
              alt="Shop Published"
              className={classes.img}
              style={{ marginTop: 24 }}
            />
            <Typography
              className={classes.title}
              style={{ alignSelf: "flex-start" }}
              variant="h2"
            >
              Your Shop is Ready to Publish!
            </Typography>
            <Typography
              className={classes.addBottomMargin}
              style={{ alignSelf: "flex-start" }}
              variant="body2"
            >
              You’ve completed all five recommended steps. Click the button
              below to publish your shop and begin taking orders from your
              customers.
            </Typography>
          </>
        ) : (
          <>
            {pendingStripeConnection ? (
              <ProcessingIcon className={classes.icon} />
            ) : (
              <CheckCircleOutline className={classes.icon} />
            )}
            <Typography className={classes.title} variant="h2">
              {title}
            </Typography>
            <Typography
              className={classes.addBottomMargin}
              style={{ textAlign: "center" }}
              variant="body2"
            >
              {subtext}
            </Typography>
            {!pendingStripeConnection && (
              <Typography className={classes.ctaText} variant="subtitle2">
                Next Step: {ctaText}
              </Typography>
            )}
            <Grid item>
              {pendingStripeConnection ? (
                <img
                  src={GoLive}
                  alt="Shop Published"
                  className={classes.img}
                />
              ) : (
                img
              )}
            </Grid>
            {pendingStripeConnection && (
              <Banner variant="info-white" className={classes.banner}>
                <Typography variant="body2">
                  You’ve completed all five recommended steps! We’ll let you
                  know when your shop is ready to publish once Stripe confirms
                  your account.
                </Typography>
              </Banner>
            )}
          </>
        )}
        <ButtonV2
          className={classes.button}
          onClick={handleCta}
          variant={pendingStripeConnection ? "outlined" : "contained"}
        >
          {isReady
            ? "Publish Your Shop"
            : pendingStripeConnection
            ? "Close"
            : ctaText}
        </ButtonV2>
      </Grid>
    </ModalWrapper>
  );
};

export default PrelaunchModals;

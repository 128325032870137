import React, { ReactNode, useEffect, useState } from 'react';
import { useTracking } from '@castiron/utils';
import { useAppSelector } from '../../hooks';
import {useLocation} from "react-router";

interface Props {
  isUnauthed: boolean;
  children: ReactNode;
}


const SegmentPageTracking: React.FC<Props> = (props: Props) => {
  const [prevPath, setPrevPath] = useState('');
  const location = useLocation();


  const { trackPage } = useTracking();

  const { shop, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
  }));


  useEffect(() => {
    if ((props.isUnauthed || (shop && me)) && prevPath !== location.pathname) {
      trackPage();
    }
    setPrevPath(location.pathname);
  }, [shop, me, location]);

  return <>{props.children}</>;
};

export default SegmentPageTracking;

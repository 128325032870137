import React, { useEffect, useState } from 'react';
import { Box, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OrdersTable from './OrdersTable';
import CustomerDetails from './CustomerDetails';
import CustomerStats from './CustomerStats';
import CustomerViewHeader from './CustomerViewHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { listTransactionsAction } from '../../../store/reducers/transactions';
import Spinner from '../../Spinner';
import { useParams } from 'react-router';
import { Customer } from '@castiron/domain';
import { customerRepository } from '../../../domain';
import { LayoutPageProps } from '../../Layout';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '17px 17px 0 17px',
    },
  },
}));

const CustomerView: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const [customer, setCustomer] = useState<Customer>();
  const { id } = useParams<{ id: string }>();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    const getCustomer = async id => {
      const customer = await customerRepository.get(id);
      setCustomer(customer);
    };

    getCustomer(id);
    dispatch(listTransactionsAction(shop.id));
    setPageTitle('Manage Contact');
    setBackLocation(true);
    setHeaderCTAs([]);
    setFooterCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  return (
    <Box className={classes.container}>
      {!customer ? (
        <Grid justify="center" container>
          <Spinner show={true} size={'fullscreen'} />
        </Grid>
      ) : (
        <>
          <CustomerViewHeader isMobile={isMobile} data={customer} />
          <Grid container direction={isMobile ? 'column-reverse' : 'row'} spacing={isMobile ? 2 : 6}>
            <Grid item xs={12} md={8}>
              <CustomerStats data={customer} />
              <OrdersTable data={customer} />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomerDetails setFooterCTAs={setFooterCTAs} setCustomer={setCustomer} context='customerView' customer={customer} isEditable />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CustomerView;

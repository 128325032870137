import {getAuth, OAuthProvider, signInWithRedirect} from "@firebase/auth";

export const loginWithGSHQ = async () => {
  const provider = new OAuthProvider('oidc.com.goodsoil');
  provider.addScope("profile");
  provider.addScope("user_info");
  provider.addScope("openid");
  provider.addScope("email");

  const auth = getAuth();
  await signInWithRedirect(auth, provider).catch(err => {
    console.error('Error logging in with GSHQ.', err);
    throw err;
  });
}
import { ButtonV2, DropDownOption, Dropdown, Pill, Typography, useFeatures } from '@castiron/components';
import { MessageType } from '@castiron/domain';
import {
  Coupon,
  SubscriberCouponMarketingSend,
} from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { CircularProgress, Divider, Grid, Paper, Theme, makeStyles, useTheme } from '@material-ui/core';
import Dinero from 'dinero.js';
import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AbandonedCartImage from '../../../assets/img/marketingAutomation/abandoned-cart.png';
import AbandonedOrderFormImage from '../../../assets/img/marketingAutomation/abandoned-order-form.png';
import NewSubscriberImage from '../../../assets/img/marketingAutomation/new-subscriber.png';
import ShopUpdateImage from '../../../assets/img/marketingAutomation/shop-update.png';
import ThankYouImage from '../../../assets/img/marketingAutomation/thank-you.png';
import {
  couponRepository,
  marketingSendRepository,
  shopRepository,
  transactionRepository,
  sendLogRepository,
} from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCouponAction } from '../../../store/reducers/coupons';
import { openModal } from '../../../store/reducers/modalConductor';
import { getShopAction } from '../../../store/reducers/shops';
import Spinner from '../../Spinner';

interface Props {}

export type Stats = {
  totalSends: number;
  totalRevenue: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  automationContent: {
    padding: '24px 16px',
    gap: '16px',
    height: '100%',
  },
  emailContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    textAlign: 'left',
    overflow: 'hidden',
  },
  sectionHeader: {
    marginBottom: 16,
  },
  emailsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gap: '16px',
    },
  },
  menuContainer: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '8px',
    right: '8px',
    height: '40px',
    width: '40px',
  },
  statsSection: {
    textAlign: 'center',
    width: '100%',
  },
  statusDecorator: {
    marginRight: 4,
    fontSize: 20,
  },
  statusPill: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
}));

export const sendFrequencyLabel = frequency =>
  frequency === 'weekly' ? 'weekly' : frequency === 'biweekly' ? 'biweekly' : 'monthly';

const getCouponValue = (coupon: Coupon): string => {
  return `${
    coupon.discount.type === 'amount'
      ? `${Dinero({ amount: coupon.discount.value }).toFormat('$0.00')} Off`
      : `${coupon.discount.value}% Off`
  }`;
};

export const getTotalSends = async (sendType: MessageType, shopId: string) => {
  const sends = await Promise.resolve(sendLogRepository.findSendsByType(shopId, sendType));
  const total = sendType === 'welcome_series_nourysh' ? _.uniqBy(sends, 'customerId')?.length : sends.length;
  return total;
};

export const getTotalSendsAndLastSent = async (sendType: MessageType, shopId: string) => {
  const sends = await Promise.resolve(sendLogRepository.findSendsByType(shopId, sendType));
  const total = sendType === 'welcome_series_nourysh' ? _.uniqBy(sends, 'customerId')?.length : sends.length;
  return {
    total,
    lastSent: sends.length > 0 ? sends[0]?.createdAt : null,
  };
};

export const getTotalRevenueBySend = async (utmSource: string, findBy: 'source' | 'campaign', shopId: string) => {
  const transactions = await Promise.resolve(transactionRepository.findTransactionsByUtm(shopId, utmSource, findBy));

  const totals = transactions.map(t => t.totals.totalWithTax);
  const revenue = !_.isEmpty(totals) ? totals.reduce((acc, curr) => acc + curr, 0) : 0;

  return revenue;
};

export const getNewsletterSeriesStats = async (
  shopId: string,
): Promise<{ totalSends: number; totalRevenue: number; lastSent: number }> => {
  const newsletterSeriesTotalP = getTotalSendsAndLastSent('newsletter_series_nourysh', shopId);
  const newsletterSeries1TotalRevenueP = getTotalRevenueBySend('newsletter_series_1', 'campaign', shopId);
  const newsletterSeries2TotalRevenueP = getTotalRevenueBySend('newsletter_series_2', 'campaign', shopId);
  const originalNewsletterTotalSendsP = getTotalSends('newsletter', shopId);
  const originalNewsletterTotalRevenueP = getTotalRevenueBySend('shop-newsletter', 'source', shopId);

  const [
    newsletterSeriesTotal,
    newsletterSeries1TotalRevenue,
    newsletterSeries2TotalRevenue,
    originalNewsletterTotalSends,
    originalNewsletterTotalRevenue,
  ] = await Promise.all([
    newsletterSeriesTotalP,
    newsletterSeries1TotalRevenueP,
    newsletterSeries2TotalRevenueP,
    originalNewsletterTotalSendsP,
    originalNewsletterTotalRevenueP,
  ]);
  const newsletterSeriesTotalSends = newsletterSeriesTotal.total;
  const totalSends = newsletterSeriesTotalSends + originalNewsletterTotalSends;
  const totalRevenue = newsletterSeries1TotalRevenue + newsletterSeries2TotalRevenue + originalNewsletterTotalRevenue;
  return {
    totalSends: totalSends || 0,
    totalRevenue: totalRevenue || 0,
    lastSent: newsletterSeriesTotal.lastSent,
  };
};

export const getWelcomeSeriesStats = async (shopId: string): Promise<{ totalSends: number; totalRevenue: number }> => {
  const welcomeSeriesTotalSendsP = getTotalSends('welcome_series_nourysh', shopId);
  const welocmeSeriesTotalRevenueSubscribersP = getTotalRevenueBySend(
    'welcome_series_new_subscriber',
    'campaign',
    shopId,
  );
  const welocmeSeriesTotalRevenueSocialP = getTotalRevenueBySend('welcome_series_social', 'campaign', shopId);
  const welocmeSeriesTotalRevenueProductsP = getTotalRevenueBySend('welcome_series_products', 'campaign', shopId);
  const SubscriberTotalSendsP = getTotalSends('subscriber_coupon', shopId);
  const SubscriberTotalRevenueP = getTotalRevenueBySend('subscriber-coupon', 'source', shopId);

  const [
    welcomeSeriesTotalSends,
    welocmeSeriesTotalRevenueSubscribers,
    welocmeSeriesTotalRevenueSocial,
    welocmeSeriesTotalRevenueProducts,
    SubscriberTotalSends,
    SubscriberTotalRevenue,
  ] = await Promise.all([
    welcomeSeriesTotalSendsP,
    welocmeSeriesTotalRevenueSubscribersP,
    welocmeSeriesTotalRevenueSocialP,
    welocmeSeriesTotalRevenueProductsP,
    SubscriberTotalSendsP,
    SubscriberTotalRevenueP,
  ]);
  const totalSends = welcomeSeriesTotalSends + SubscriberTotalSends;
  const totalRevenue =
    welocmeSeriesTotalRevenueSubscribers +
    welocmeSeriesTotalRevenueSocial +
    welocmeSeriesTotalRevenueProducts +
    SubscriberTotalRevenue;
  return {
    totalSends: totalSends || 0,
    totalRevenue: totalRevenue || 0,
  };
};

const Automations: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const features = useFeatures();
  const isQuotesEnabled = features.includes('admin.quotes');

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [thankYouCouponLoading, setThankYouCouponLoading] = useState<boolean>(true);
  const [thankYouCoupon, setThankYouCoupon] = useState<Coupon | null>(null);
  const [thankYouLoading, setThankYouLoading] = useState<boolean>(true);
  const [thankYouStats, setThankYouStats] = useState<Stats>(null);
  const [newsletterLoading, setNewsletterLoading] = useState<boolean>(true);
  const [newsletterStats, setNewsletterStats] = useState<Stats>(null);
  const [welcomeCoupon, setWelcomeCoupon] = useState<Coupon | null>(null);
  const [welcomeLoading, setWelcomeLoading] = useState<boolean>(true);
  const [welcomeStats, setWelcomeStats] = useState<Stats>(null);

  const isThankYouEnabled = shop?.config?.marketing?.thankYouCoupon?.enabled !== false;
  const isThankYouOfferActive = isThankYouEnabled && thankYouCoupon?.status === 'active';
  const thankYouCouponId = shop?.config?.marketing?.thankYouCoupon?.couponId;
  const isNewsletterEnabled = shop?.config?.marketing?.newsletterSeries?.enabled !== false;

  const getThankYouCoupon = async () => {
    setThankYouCouponLoading(true);
    if (thankYouCouponId) {
      const getCouponResponse = await couponRepository.get(thankYouCouponId);
      setThankYouCoupon(getCouponResponse);
    }
    setThankYouCouponLoading(false);
  };

  const getThankYouStats = async () => {
    setThankYouLoading(true);
    const totalSends = await getTotalSends('thank_you_coupon_nourysh', shop.id);
    const totalRevenue = await getTotalRevenueBySend('thank_you_coupon', 'campaign', shop.id);

    setThankYouStats({
      totalSends,
      totalRevenue,
    });
    setThankYouLoading(false);
  };

  const getNewsletterStats = async () => {
    setNewsletterLoading(true);
    const newsLetterStats = await getNewsletterSeriesStats(shop.id);
      setNewsletterStats({
        totalSends: newsLetterStats?.totalSends,
        totalRevenue: newsLetterStats?.totalRevenue,
      });

    setNewsletterLoading(false);
  };

  const getWelcomeCoupon = async () => {
    const couponId = shop?.config?.marketing?.welcomeSeriesCoupon?.couponId;
    if (couponId) {
      const getCouponResponse = await couponRepository.get(couponId);
      setWelcomeCoupon(getCouponResponse);
    } else {
      const subscriberMarketingSendResponse = await marketingSendRepository.findActiveOfTypeForShop(shop.id, 'subscriber_coupon');
      if (!!subscriberMarketingSendResponse.length) {
        const marketingSend = subscriberMarketingSendResponse[0] as SubscriberCouponMarketingSend;
        const getCouponResponse = await couponRepository.get(marketingSend.couponId);
        setWelcomeCoupon(getCouponResponse);
      }
    }
  };

  const getWelcomeStats = async () => {
    setWelcomeLoading(true);
    const welcomeSeriesStats = await getWelcomeSeriesStats(shop.id);
    setWelcomeStats({
      totalSends: welcomeSeriesStats?.totalSends,
      totalRevenue: welcomeSeriesStats?.totalRevenue,
    });
    setWelcomeLoading(false);
  };

  useEffect(() => {
    if (shop?.id) {
      getThankYouCoupon();
      getThankYouStats();
      getNewsletterStats();
      getWelcomeCoupon();
      getWelcomeStats();
    }

    return function cleanup() {
      getThankYouCoupon();
      getThankYouStats();
      getNewsletterStats();
      getWelcomeCoupon();
      getWelcomeStats();
    };
  }, [shop]);

  const generateEmailContainer = ({
    menuOptions,
    imageSrc,
    status,
    name,
    subheader,
    cardClick,
    handleSetUpClick,
    loading = false,
    index,
    pillText,
    totalSends,
    totalRevenue,
  }: {
    menuOptions?: DropDownOption[];
    imageSrc: string;
    status: string;
    name: string;
    subheader: string;
    cardClick?: () => void;
    handleSetUpClick?: () => void;
    loading?: boolean;
    index: number;
    pillText?: string;
    totalSends?: string | number;
    totalRevenue?: string | number;
  }): ReactNode => {
    return (
      <Grid
        key={`automation-${index}`}
        container
        item
        direction="column"
        justify="flex-start"
        wrap="nowrap"
        className={classes.emailContainer}
        style={{ cursor: cardClick ? "pointer" : null }}
      >
        <Grid container item style={{ position: "relative" }}>
          <img
            src={imageSrc}
            alt={name}
            style={{ width: "100%", height: "100%" }}
            onClick={cardClick ? cardClick : null}
          />
          <Pill
            className={classes.statusPill}
            variant="white"
            content={
              <>
                <span
                  onClick={cardClick ? cardClick : null}
                  className={classes.statusDecorator}
                  style={{
                    color:
                      status === "active"
                        ? theme.branding.v2.green[500]
                        : status === "inactive"
                        ? theme.branding.v2.red[500]
                        : theme.branding.v2.yellow[500],
                  }}
                >
                  •
                </span>
                {_.capitalize(status)}
              </>
            }
          />
          {menuOptions && (
            <Paper className={classes.menuContainer}>
              <Grid
                container
                style={{ height: "100%" }}
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Dropdown
                    variant="ellipsis"
                    options={menuOptions}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          justify="space-between"
          className={classes.automationContent}
          onClick={cardClick ? cardClick : null}
        >
          <Grid
            container
            item
            direction="column"
            wrap="nowrap"
            justify={status === "inactive" ? "space-between" : "flex-start"}
            style={{ gap: "8px" }}
            xs
          >
            <Grid container item direction="column" wrap="nowrap">
              <Typography variant="subtitle1">{name}</Typography>
              <Typography variant="body2">{subheader}</Typography>
            </Grid>
            {status === "inactive" ? (
              <Grid container item>
                <ButtonV2
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSetUpClick}
                  style={{ marginTop: "16px" }}
                >
                  Activate
                </ButtonV2>
              </Grid>
            ) : (
              pillText && <Pill content={pillText} variant="gray" />
            )}
          </Grid>
          <Grid container item direction="column" style={{ gap: "16px" }}>
            <Divider />
            <Grid
              container
              item
              direction="row"
              wrap="nowrap"
              alignItems="center"
              style={{ gap: "16px" }}
            >
              <Grid
                container
                item
                direction="column"
                wrap="nowrap"
                justify="center"
                alignItems="center"
                className={classes.statsSection}
              >
                <Typography variant="subtitle1">
                  {loading ? <CircularProgress size={16} /> : totalSends}
                </Typography>
                <Typography variant="body2">Sent</Typography>
              </Grid>
              <Grid
                container
                item
                direction="column"
                wrap="nowrap"
                justify="center"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  {loading ? (
                    <CircularProgress size={16} />
                  ) : typeof totalRevenue === "number" ? (
                    Dinero({ amount: totalRevenue }).toFormat("$0,0.00")
                  ) : (
                    totalRevenue
                  )}
                </Typography>
                <Typography variant="body2">Revenue</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const manageNewsletterMarketingSend = isNewsletterEnabled
    ? () => {
        history.push('/marketing/edit-newsletter-series')
      }
    : async () => {
      trackEvent('Automation Turned On', { type: 'Newsletter Series' });
      if (!shop?.config?.marketing?.newsletterSeries?.enabled) {
        await shopRepository.updateProps(shop.id, {
          'config.marketing.newsletterSeries.enabled': true,
        });
      }
      await dispatch(getShopAction(shop.id));
      };

  const manageThankYouCouponSend = isThankYouOfferActive
    ? () => {
        dispatch(
          openModal({
            modalType: 'MANAGE_COUPON_MODAL',
            modalProps: {
              couponId: thankYouCouponId || '',
              variant: 'thank-you',
            },
          }),
        );
      }
    : async () => {
        trackEvent('Automation Turned On', { type: 'Thank You' });
        if (!isThankYouEnabled) {
          await shopRepository.updateProps(shop.id, {
            'config.marketing.thankYouCoupon.enabled': true,
          });
        }
        const thankYouCoupon = thankYouCouponId
          ? await couponRepository.get(thankYouCouponId)
          : null;
        if (thankYouCoupon && thankYouCoupon?.status !== 'active') {
          await couponRepository.updateProps(thankYouCoupon.id, {
            status: 'active',
          });
        }
        await dispatch(getShopAction(shop.id));
        await dispatch(getCouponAction(shop.id));
      };

  const automations = [
    {
      menuOptions: [
        {
          label: `${
            isNewsletterEnabled ? "Manage" : "Activate"
          } Automation`,
          onClick: manageNewsletterMarketingSend,
        },
        {
          label: "Send Myself Test Emails",
          onClick: () => {
            dispatch(
              openModal({
                modalType: "AUTOMATION_TEST_MODAL",
                modalProps: {
                  marketingSendId: "newsletter-series",
                },
              })
            );
          },
        },
      ],
      cardClick: () => history.push("/marketing/edit-newsletter-series"),
      imageSrc: ShopUpdateImage,
      status: isNewsletterEnabled ? "active" : "inactive",
      name: "Newsletter",
      subheader:
        "Automatically send a twice weekly newsletter with relevant content & products.",
      handleSetUpClick: manageNewsletterMarketingSend,
      loading: newsletterLoading,
      pillText: "Twice Weekly",
      totalSends: newsletterStats?.totalSends || 0,
      totalRevenue: newsletterStats?.totalRevenue || 0,
    },
    {
      menuOptions: [
        {
          label: "Manage Automation",
          onClick: () => history.push("/marketing/edit-welcome-series-emails"),
        },
        {
          label: "Send Myself Test Emails",
          onClick: () => {
            dispatch(
              openModal({
                modalType: "AUTOMATION_TEST_MODAL",
                modalProps: {
                  marketingSendId: "welcome-series",
                },
              })
            );
          },
        },
      ],
      cardClick: () => history.push("/marketing/edit-welcome-series-emails"),
      imageSrc: NewSubscriberImage,
      status: "active",
      name: "Welcome Series",
      subheader: "Automatically send 3 sequential emails to introduce a new customer to your shop.",
      handleSetUpClick: () => {},
      loading: welcomeLoading,
      pillText: welcomeCoupon
      ? getCouponValue(welcomeCoupon)
      : "",
      totalSends: welcomeStats?.totalSends || 0,
      totalRevenue: welcomeStats?.totalRevenue || 0,
    },
    {
      menuOptions: [
        {
          label: `${isThankYouOfferActive ? "Manage" : "Activate"} Automation`,
          onClick: manageThankYouCouponSend,
        },
        {
          label: "Send Myself a Test Email",
          onClick: () => {
            dispatch(
              openModal({
                modalType: "AUTOMATION_TEST_MODAL",
                modalProps: {
                  marketingSendId: "thank-you",
                },
              })
            );
          },
        },
      ],
      cardClick: () =>
        dispatch(
          openModal({
            modalType: "MANAGE_COUPON_MODAL",
            modalProps: {
              couponId: thankYouCouponId || "",
              variant: "thank-you",
            },
          })
        ),
      imageSrc: ThankYouImage,
      status: isThankYouOfferActive ? "active" : "inactive",
      name: "Thank You Offer",
      subheader:
        "Automatically send a promotional offer after a customer receives their order.",
      handleSetUpClick: manageThankYouCouponSend,
      loading: thankYouLoading,
      pillText: thankYouCoupon
        ? getCouponValue(thankYouCoupon)
        : "",
      totalSends: thankYouStats?.totalSends || 0,
      totalRevenue: thankYouStats?.totalRevenue || 0,
    },
    {
      imageSrc: AbandonedCartImage,
      status: "Coming Soon!",
      name: "Abandoned Cart",
      subheader:
        "Automatically send a reminder to shoppers who left products in their cart.",
      totalSends: "-",
      totalRevenue: "-",
    },
    ...(isQuotesEnabled
      ? [
          {
            imageSrc: AbandonedOrderFormImage,
            status: "Coming Soon!",
            name: "Abandoned Custom Order Form",
            subheader:
              "Automatically send a reminder to shoppers who didn’t complete their custom order request.",
            totalSends: "-",
            totalRevenue: "-",
          },
        ]
      : []),
  ];

  return (
    <Grid container style={{ gap: '40px' }}>
      {/* we only need to wait for thank you coupon to load, because that will determine if the Thank You send is active or inactive */}
      <Spinner show={thankYouCouponLoading} />
      <Grid container direction="column">
        {/* <Typography variant="h3" className={classes.sectionHeader}>
          Marketing
        </Typography> */}
        <Grid container className={classes.emailsContainer}>
          {automations.map((automation, index) => generateEmailContainer({ ...automation, index }))}
        </Grid>
      </Grid>
      {/* <Grid container direction="column">
        <Typography variant="h3" className={classes.sectionHeader}>
          Transactional
        </Typography>
        <Grid container className={classes.emailsContainer}>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default Automations;

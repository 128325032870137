import React, { useEffect, useState, useCallback } from 'react';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  IconButton,
  makeStyles,
  Typography,
  Grid,
  Tabs,
  Tab,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { TabContext, TabPanel } from '@material-ui/lab';
import Close from '@material-ui/icons/Close';
import { ImageUploader, ImageRadioOrCheckboxInput, ButtonV2, Banner } from '@castiron/components';
import { Asset, Shop, GalleryPhoto } from '@castiron/domain';
import { assets, upload } from '@castiron/castiron-firebase';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { useAppDispatch } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import { MAX_UPLOAD_FILE_SIZE } from '../../../constants';
import { assetRepository } from '../../../domain';

export type Props = {
  show: boolean;
  backgroundImageObj: Asset;
  setBackgroundImageObj: React.SetStateAction<any>;
  shop: Shop;
  galleryAssetsProp?: GalleryPhoto[];
};

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    marginTop: 24,
    padding: '12px 24px',
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  closeIcon: {
    cursor: 'pointer',
    height: 24,
    width: 24,
  },
  content: {
    padding: '22px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0px 0px',
    },
  },
  errorMessage: {
    marginBottom: 16,
  },
  imageBox: {
    display: 'inline-block',
    position: 'relative',
  },
  imagePreview: {
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: 12,
    width: 148,
    height: 148,
  },
  tabErrorBanner: {
    padding: '16px 32px 0px',
  },
  trashButton: {
    zIndex: 25,
    backgroundColor: theme.branding.v2.gray[0],
    right: 0,
    margin: 8,
    borderRadius: 12,
    width: 48,
    height: 48,
    position: 'absolute',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    '&:hover': {
      cursor: 'pointer',
      '& svg': {
        color: theme.branding.v2.red[800],
      },
    },
    '& svg': {
      color: theme.branding.v2.red[500],
    },
  },
  modalTitle: {
    padding: '26px 24px',
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1px solid ${theme.branding.v2.gray[200]}`,
    },
  },
  radioOption: {
    maxWidth: 148,
  },
  wrapper: {
    '& .MuiDialog-paperWidthLg': {
      width: '100%',
      maxWidth: 1032,
      [theme.breakpoints.up('sm')]: {
        height: 680,
      },
    },
  },
}));

const BackgroundPhotoModal: React.FC<Props> = (props: Props) => {
  const { show, backgroundImageObj, setBackgroundImageObj, shop, galleryAssetsProp } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [tab, setTab] = useState('upload');
  const [uploadedImg, setUploadedImg] = useState({ tempURL: '', fileName: '', file: undefined });
  const [errorMessage, setErrorMessage] = useState('');
  const [formattedGallery, setFormattedGallery] = useState([]);
  const [formattedCastironPhotos, setFormattedCastironPhotos] = useState([]);
  const [selectedValue, setSelectedValue] = useState(backgroundImageObj?.id || '');
  const [submitting, setSubmitting] = useState(false);
  const [tabError, setTabError] = useState(false);

  const getCastironAssets = async () => {
    assetRepository
      .findHomepageBackgrounds()
      .then(res => {
        const formattedAssets =
          !_.isEmpty(res) &&
          res.map(img => ({
            background: img.downloadUrl,
            value: img.id,
            context: img,
          }));
        setFormattedCastironPhotos(formattedAssets);
      })
      .catch(err => console.error('Error retrieving castiron assets: ', err));
  };

  const getGallery = () => {
    if (galleryAssetsProp) {
      const formattedAssets = galleryAssetsProp.map(img => ({
        background: img.photo.downloadUrl,
        value: img.id,
        context: img.photo,
      }));
      setFormattedGallery(formattedAssets);
    } else {
      shop
        .getGalleryPhotos()
        .then(res => {
          const formattedAssets =
            !_.isEmpty(res) &&
            res.map(img => ({
              url: img.photo.downloadUrl,
              value: img.id,
              context: img.photo,
            }));
          setFormattedGallery(formattedAssets);
        })
        .catch(err => console.error('Error retrieving gallery assets: ', err));
    }
  };

  useEffect(() => {
    getCastironAssets();
    getGallery();
  }, []);

  useEffect(() => {
    tabError && setTabError(false);
  }, [selectedValue]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const setBackground = useCallback(
    imageObj => {
      setBackgroundImageObj(imageObj);

      handleClose();
    },
    [backgroundImageObj],
  );

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    const newImageObj = {
      id: metadata.id,
      shopId: shop.id,
      downloadUrl,
      metadata: metadata,
      options,
    };

    setBackground(newImageObj);
  };

  const handleFileUploadFailure = (error, callbacks, metadata) => {
    console.error('Error Uploading Image', error);
    setErrorMessage(`An error occured uploading your photo: ${metadata.originalFilename}.`);
  };

  const handleFile = async () => {
    const id = nanoid();
    if (uploadedImg.file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: uploadedImg.fileName,
        id,
        assetType: 'background',
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        success: handleFileUploadSuccess,
        fail: handleFileUploadFailure,
      };
      const context = {
        shop: shop,
      };

      await upload(uploadedImg.file, metadata, options, callbacks, context);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const isCastironPhoto = formattedCastironPhotos.find(photo => photo.value === selectedValue);
    const isGalleryPhoto = formattedGallery.find(photo => photo.value === selectedValue);

    if (tab === 'upload') {
      handleFile();
    } else if (tab === 'castiron-photos' && isCastironPhoto) {
      const asset = await assets.get(selectedValue);
      const newImageObj = {
        id: asset.id,
        shopId: shop.id,
        downloadUrl: asset.downloadUrl,
        metadata: asset.metadata,
        options: asset.options,
      };

      setBackground(newImageObj);
    } else if (tab === 'gallery' && isGalleryPhoto) {
      const asset = await formattedGallery.find(a => a.value === selectedValue);
      const newImageObj = {
        id: asset.value,
        shopId: shop.id,
        downloadUrl: asset.url,
        metadata: asset.context.metadata,
        options: asset.context.options,
      };

      setBackground(newImageObj);
    } else {
      setTabError(true);
      setSubmitting(false);
    }

    setSubmitting(false);
  };

  return (
    <ModalWrapper size="lg" show={show} fullScreen={isMobile} className={classes.wrapper}>
      <DialogTitle style={{ padding: 0 }}>
        <Grid container direction="row" justify="space-between" className={classes.modalTitle}>
          <Typography variant="h3">Select a Photo</Typography>
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {tabError && (
          <Grid className={classes.tabErrorBanner}>
            <Banner variant="error">Please select an image.</Banner>
          </Grid>
        )}
        <TabContext value={tab}>
          <Grid container direction="column" className={classes.content}>
            <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
              <Tab label="Upload a Photo" value="upload" />
              {!_.isEmpty(formattedGallery) && <Tab label="My Gallery" value="gallery" />}
              <Tab label="Nourysh Photos" value="castiron-photos" />
            </Tabs>
            <Grid item>
              <TabPanel value="upload" tabIndex={0}>
                {uploadedImg.tempURL && !errorMessage ? (
                  <Grid className={classes.imageBox}>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className={classes.trashButton}
                      onClick={() => setUploadedImg({ tempURL: '', fileName: '', file: undefined })}
                    >
                      <DeleteOutlinedIcon />
                    </Grid>
                    <img className={classes.imagePreview} src={uploadedImg.tempURL} />
                  </Grid>
                ) : (
                  <Grid>
                    {errorMessage && (
                      <Banner variant="error" className={classes.errorMessage}>
                        {errorMessage}
                      </Banner>
                    )}
                    <ImageUploader
                      onFileDrop={(files): void => {
                        setErrorMessage('');
                        if (files[0].size > MAX_UPLOAD_FILE_SIZE) {
                          setErrorMessage('Your file size must be less than 15MB. Please upload a different image.');
                        } else {
                          const newURL = URL.createObjectURL(files[0]);
                          setUploadedImg({ tempURL: newURL, fileName: files[0].path, file: files[0] });
                        }
                      }}
                      maxSize={'100%'}
                    />
                  </Grid>
                )}
              </TabPanel>
              {!_.isEmpty(formattedGallery) && (
                <TabPanel value="gallery" tabIndex={1}>
                  <ImageRadioOrCheckboxInput
                    options={formattedGallery}
                    optionClassName={classes.radioOption}
                    optionSizeObj={{ md: 2, lg: 2 }}
                    onChange={value => setSelectedValue(value)}
                    value={selectedValue}
                  />
                </TabPanel>
              )}
              <TabPanel value="castiron-photos" tabIndex={2}>
                <ImageRadioOrCheckboxInput
                  options={formattedCastironPhotos}
                  optionClassName={classes.radioOption}
                  optionSizeObj={{ md: 2, lg: 2 }}
                  onChange={value => setSelectedValue(value)}
                  value={selectedValue}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </TabContext>
      </DialogContent>
      <DialogActions style={{ padding: 0 }}>
        <Grid container item justify="flex-end" className={classes.actionsContainer}>
          <ButtonV2 variant="outlined" onClick={handleClose} style={{ marginRight: 8 }} disabled={submitting}>
            Cancel
          </ButtonV2>
          <ButtonV2 variant="contained" onClick={handleSubmit} disabled={submitting}>
            Save
          </ButtonV2>
        </Grid>
      </DialogActions>
    </ModalWrapper>
  );
};

export default BackgroundPhotoModal;

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import NouryshSpinner from "../../assets/img/nouryshSpinner.gif";
import { Typography } from "@castiron/components";
import { useMediaQuery } from "@material-ui/core";

type Props = {
  show: boolean;
  size?: "fullscreen" | "content" | "relative" | "transparent";
  label?: string;
};

const useStyles = makeStyles(() => ({
  image: {
    height: 90,
    width: 90,
  },
  containerAbsolute: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    zIndex: 10000,
  },
  containerRelative: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "100%",
    width: "100%",
  },
  containerContent: {
    position: "absolute",
    left: 272,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    zIndex: 10000,
  },
  transparent: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    zIndex: 10000,
  },
}));

const Spinner: React.FC<Props> = (props: Props) => {
  // const { spacing } = props;
  // const outputValue = spacing ? '0px' : spacing + 'px';
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const size = props.size || 'lg';

  const getClass = () => {
    if (isMobile) return classes.containerAbsolute;

    switch (props.size) {
      case "fullscreen":
        return classes.containerAbsolute;
      case "relative":
        return classes.containerRelative;
      case "content":
        return classes.containerContent;
      case "transparent":
        return classes.transparent;
      default:
        return classes.containerContent;
    }
  };

  return (
    <>
      {props.show && (
        <div className={getClass()}>
          <img src={NouryshSpinner} className={classes.image} />
          {props.label && (
            <>
              <Typography
                style={{ margin: "16px 6px 0px 6px", textAlign: "center" }}
                variant="h2"
              >
                {props.label}
              </Typography>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Spinner;

import { Coupon, couponUtils } from '@castiron/domain';
import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import { couponRepository } from '../../../domain';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import moment from 'moment';
import { getCouponAction } from '../../../store/reducers/coupons';
import { useHistory } from 'react-router-dom';
import { useTracking } from '@castiron/utils';

type Props = {
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  coupon: Coupon;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 8,
    height: '32px',
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  delete: {
    color: theme.palette.error.main,
  },
}));

const CouponsActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, coupon } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const onEndClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    const timeStamp = moment().unix();

    const updatedCoupon = {
      ...coupon,
      duration: { startDate: coupon.duration.startDate, endDate: timeStamp },
      status: 'expired',
    };

    await couponRepository.update(updatedCoupon);
    trackEvent('Coupon Ended', { coupon: { id: coupon.id } });
    dispatch(getCouponAction(shop.id));
    onClose(event);
  }, []);

  const onRestartClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    const timeStamp = moment().unix();

    const restartedCoupon = {
      ...coupon,
      duration: { startDate: timeStamp },
      status: 'active',
    };

    await couponRepository.update(restartedCoupon);
    trackEvent('Coupon Restarted', { coupon: { id: restartedCoupon.id } });
    dispatch(getCouponAction(shop.id));
    onClose(event);
  }, []);

  const onDeleteClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    await couponRepository.delete(coupon.id);
    trackEvent('Coupon Deleted', { coupon: { id: coupon.id } });
    dispatch(getCouponAction(shop.id));
    onClose(event);
  }, []);

  const onEditClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    history.push(`/coupons/edit/${coupon.id}`);
    onClose(event);
  }, []);

  return (
    <>
      <ButtonBase onClick={onEditClick} focusRipple className={classes.buttonBase}>
        <Typography className={classes.menuLabel}>Edit</Typography>
      </ButtonBase>
      {coupon.status === 'expired' ? (
        <ButtonBase focusRipple className={classes.buttonBase} onClick={onRestartClick}>
          <Typography className={classes.menuLabel}>Restart coupon</Typography>
        </ButtonBase>
      ) : (
        <ButtonBase focusRipple className={classes.buttonBase} onClick={onEndClick}>
          <Typography className={classes.menuLabel}>End coupon early</Typography>
        </ButtonBase>
      )}
      <ButtonBase focusRipple onClick={onDeleteClick} className={classes.buttonBase}>
        <Typography className={`${classes.menuLabel} ${classes.delete}`}>Delete</Typography>
      </ButtonBase>
    </>
  );
};

export default CouponsActionsMenu;

import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Link,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Theme, useTheme } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import {
  Typography,
  Input,
  Button,
  InputLabel,
  SaveButton,
  DiscardButton,
} from "@castiron/components";
import { openModal } from "../../store/reducers/modalConductor";
import { getCurrentUser } from "@castiron/castiron-firebase";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getMyselfAction } from "../../store/reducers/users";
import { updateShopAction } from "../../store/reducers/shops";
import Select from "../Select";
import momentTimezone from "moment-timezone";
import {
  defaultTimeZone,
  timeFormat,
  timeZoneOptions,
  useTracking,
} from "@castiron/utils";
import { LayoutPageProps } from "../Layout";
import AvatarMenu from "../Menus/AvatarMenu";
import AdminForm from "../AdminForm";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: 17,
    },
  },
  box: {
    [theme.breakpoints.up("sm")]: {
      border: `1px solid ${theme.branding.gray[400]}`,
      borderRadius: "8px",
      padding: "32px 24px 40px 24px",
    },
    display: "flex",
    flexDirection: "column",
    margin: "28px 0 32px 0",
  },
  buttonsContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      width: "100%",
    },
    marginTop: "32px",
  },
  buttonOffset: {
    marginRight: "16px",
  },
  profileImg: {
    backgroundColor: theme.branding.v2.plum[500],
    border: `1px solid ${theme.branding.gray[400]}`,
    fontSize: 56,
    width: 120,
    height: 120,
    marginBottom: 32,
  },
  link: {
    color: theme.branding.v2.plum[500],
    maxWidth: 200,
  },
  paragraph: {
    marginTop: 12,
    marginBottom: 24,
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "8px",
    },
  },
  oIdentityButton: {
    borderRadius: 20,
    height: 40,
    width: 120,
    border: "1px solid #BE5F4F",
    color: "#BE5F4F",
    marginTop: 9,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    alignItems: "start",
    marginBottom: 32,
    "& div": {
      width: "100%",
    },
  },
  selectContainer: {
    marginBottom: 32,
  },
  selectInput: {
    width: "100%",
  },
}));

const AccountSettings: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const { me, shop } = useAppSelector((state) => ({
    me: state.users.me,
    shop: state.shops.shop,
  }));

  const [currentTime, setCurrentTime] = useState(momentTimezone(new Date()));
  const [submitting, setSubmitting] = useState(false);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const formRef = useRef() as any;
  const { trackEvent } = useTracking();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(momentTimezone(new Date()));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required("Please enter a name."),
    timeZone: yup.string(),
  });

  useEffect(() => {
    setFooterCTAs([
      //@ts-ignore
      <DiscardButton
        isSubmitting={submitting}
        onClick={formRef.current.handleReset}
      />,
      //@ts-ignore
      <SaveButton isSubmitting={submitting} formikState={formRef.current} />,
    ]);
  }, [submitting]);

  const onSubmit = async (
    values: any,
    { setFieldError, resetForm }: any
  ): Promise<any> => {
    setSubmitting(true);
    try {
      const nameParts = values.name?.split(" ") || [];
      const firstName = nameParts[1] ? nameParts[0] : values.name;
      const lastName = nameParts[1] ? nameParts[1] : "";

      const newShop = {
        ...shop,
        config: {
          ...shop?.config,
          timeZone: values.timeZone,
        },
        owner: {
          firstName,
          lastName,
        },
      };

      const user = await getCurrentUser();
      await user.updateProfile({
        displayName: values.name,
      });

      await dispatch(updateShopAction({ shop: newShop }));
      await dispatch(getMyselfAction(user)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            openModal({
              modalType: "SIMPLE_ALERT",
              modalProps: {
                show: true,
                celebrate: true,
                content: "Changes have been saved!",
              },
            })
          );
        }
      });

      const shopOldTimeZone = formRef.current.initialValues.timeZone;
      const shopNewTimeZone = values.timeZone;
      if (shopOldTimeZone !== shopNewTimeZone) {
        trackEvent("Shop Time Zone Edited", {
          shop: newShop.id,
          shopNewTimeZone,
          shopOldTimeZone,
        });
      }

      resetForm({ values });
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      console.debug("error: ", err);
    }
  };

  const handleClick = () => {
    dispatch(
      openModal({
        modalType: "RESET_PASSWORD_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const nameParts = me.displayName?.split(" ") || [];
  const initials =
    nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0];

  useEffect(() => {
    setPageTitle("Account Settings");

    return () => {
      setPageTitle("");
    };
  }, []);

  useEffect(() => {
    if (isSmall) {
      setHeaderCTAs([
        //@ts-ignore
        <AvatarMenu />,
      ]);
    } else {
      setHeaderCTAs([]);
    }
  }, [isSmall]);

  return (
    <>
      <Helmet>
        <title>Account Settings | Nourysh</title>
      </Helmet>
      <Formik
        initialValues={{
          name: me.displayName,
          timeZone: shop?.config?.timeZone || defaultTimeZone,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {({ errors, touched, setFieldValue, dirty, values }): ReactNode => (
          <AdminForm>
            <Grid container>
              <Grid item xs={12} lg={8} className={classes.container}>
                <Box className={classes.box}>
                  <Avatar
                    className={classes.profileImg}
                    src={me.profileImg || undefined}
                  >
                    {!me.profileImg && initials.toUpperCase()}
                  </Avatar>
                  <Form>
                    <Grid className={classes.inputContainer}>
                      <Input
                        label="Full Name"
                        type="text"
                        name="name"
                        error={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid className={classes.selectContainer}>
                      <InputLabel
                        primaryLabel="Time Zone"
                        secondaryLabel={
                          <Typography variant="caption">
                            Current Time:{" "}
                            {currentTime.tz(values.timeZone).format(timeFormat)}
                          </Typography>
                        }
                      />
                      <Select
                        value={values.timeZone}
                        onChange={(e) => {
                          setFieldValue("timeZone", e.target.value);
                        }}
                        options={timeZoneOptions}
                        selectClass={classes.selectInput}
                      />
                    </Grid>
                    <Link
                      onClick={handleClick}
                      role="button"
                      tabIndex={0}
                      id="reset-password"
                    >
                      <Typography variant="button" className={classes.link}>
                        Reset Password
                      </Typography>
                    </Link>
                  </Form>

                  {/* {connectedOpenIdentity ? (
              <div>
                <Typography className={classes.label}>Connected open identity</Typography>
                <Button variant="outlined" className={classes.oIdentityButton}>
                  Google
                </Button>
              </div>
            ) : (
              <Link onClick={handleClick} role="button" tabIndex={0} className={classes.link} id="reset-password">
                Reset password
              </Link>
            )} */}
                </Box>
                {/* <Typography variant="subtitle4">Deactivate account</Typography>
          <Typography paragraph variant='body4' className={classes.paragraph}>Deactivating your Nourysh account means your online store will not longer be accessible and you will no longer be able to review your customer or order history. </Typography>
          <Button className={classes.button}>Deactivate account</Button> */}
              </Grid>
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </>
  );
};

export default AccountSettings;

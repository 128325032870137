import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Typography } from '@castiron/components';
import { BaseProduct, getImageUrl } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import ShareItem from '../Messaging/Social/ShareItem';
import { Close } from '@material-ui/icons';
import { AutoAwesome } from '@castiron/components';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import { getService } from '../../firebase';
import Spinner from '../Spinner';

export type Props = {
  product: BaseProduct;
  show: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    height: '24px',
    width: '24px',
  },
}));

const generateSocialPostService = getService('products', 'generatesocialpost');

const ProductSocialShareModal: React.FC<Props> = (props: Props) => {
  const { product, show } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showSpinner, setShowSpinner] = useState<boolean>();
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const [shopHashtag, setShopHashtag] = useState<string>();
  const [post, setPost] = useState<string>('');
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const productImageUrl = getImageUrl(product, 'mediumVersion');

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    generateSocialPost();
  }, []);

  useEffect(() => {
    if (shop) setShopHashtag(shop.websiteUrl.replaceAll('-', ''));
  }, [shop]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const openAboutModal = () => {
    dispatch(
      openModal({
        modalType: 'ABOUT_CASTIRON_AI_MODAL',
        modalProps: {
          setIsModalPopped: (is: boolean) => {
            return;
          },
          type: 'social-post',
        },
      }),
    );
  };

  const productShareUrl = `${process.env.REACT_APP_SHOP_URL}${shop.websiteUrl}/product/${product.id}`;

  const generateSocialPost = async () => {
    setHasCopied(false);
    setShowSpinner(true);
    const response = await generateSocialPostService({
      productId: product.id,
      title: product.title,
      category: product.category?.name,
      allergens: product.allergen?.join(', '),
      dietary: product.dietary?.join(', '),
    });
    trackEvent('AI Generated Social Post', {
      product: product,
      post: response.socialPost,
    });

    setHasGenerated(true);
    setPost(response.socialPost);
    setShowSpinner(false);
  };

  return (
    <ModalWrapper fullScreen={isMobile} size="sm" show={show}>
      <Spinner
        show={showSpinner}
        label="Generating Social Post. This may take up to 1 minute. Please do not refresh your page."
        size="fullscreen"
      />
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3">Share your products</Typography>
          </Grid>
          <Grid item>
            <IconButton>
              <Close className={classes.closeIcon} onClick={handleClose} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container item direction="row" xs={12}>
          <Grid item container xs={12} style={{ width: '100%' }} justify="center" alignItems="center">
            <ShareItem
              key={product.id}
              img={productImageUrl}
              pinterestImg={productImageUrl ? productImageUrl : ''}
              shareUrl={productShareUrl}
              text={
                post ||
                `Just added to my store: ${product?.title}. #${shopHashtag} #shoplocal #supportlocal #shopcastiron`
              }
              itemType="product"
              item={product}
              copyOnly={true}
              hasCopied={hasCopied}
              setHasCopied={setHasCopied}
            />
          </Grid>
          <Grid item container xs={12} style={{ width: '100%' }} justify="center" alignItems="center">
            <Button variant="outlined" onClick={generateSocialPost} style={{ width: 'inherit' }}>
              <AutoAwesome style={{ marginRight: '8px' }} /> {hasGenerated ? 'Try Writing Again' : 'Write It For Me!'}
            </Button>
          </Grid>
          <Grid item container xs={12} style={{ width: '100%' }} justify="center" alignItems="center">
            <Typography variant="caption4">
              Use our AI-powered generator to quickly create a high-quality social post.{' '}
              <a href="#" onClick={openAboutModal}>
                Learn More
              </a>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalWrapper>
  );
};

export default ProductSocialShareModal;

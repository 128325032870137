import React, { useState } from "react";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import _ from "lodash";
import { Button, Chip, Typography } from "@castiron/components";
import { closeModal, openModal } from "../../store/reducers/modalConductor";
import ModalWrapper from "../RootModal/ModalWrapper";
import {
  listAllTransactionsAction,
  listTransactionsAction,
} from "../../store/reducers/transactions";
import { accountRepository, transactionRepository } from "../../domain";
import { useTracking, createTrackingNumberUrl } from "@castiron/utils";
import { useHistory } from "react-router-dom";
import { Transaction, CarrierOption } from "@castiron/domain";
import { useAppDispatch, useAppSelector } from "../../hooks";
import AdminForm from "../AdminForm";
import PartyPopper from "../../assets/img/party-popper.png";
import moment from "moment-timezone";

export type Props = {
  show: boolean;
  transaction: Transaction;
  updateList?: boolean;
  multipleTransactions?: Transaction[];
};

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    display: "flex",
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: "12px 24px",
  },
  addNoteContainer: {
    marginTop: 8,
    [theme.breakpoints.up("md")]: {
      marginLeft: 44,
      maxWidth: 495,
    },
  },
  button: {
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
    },
  },
  chip: {
    color: theme.branding.v2.plum[500],
    cursor: "pointer",
    fontWeight: 600,
    fontSize: 12,
    height: 25,
    margin: 8,
    padding: "0 8px",
  },
  closeIcon: {
    position: "absolute",
    top: 16,
    right: 9,
    cursor: "pointer",
    zIndex: 10,
    fontSize: 32,
    padding: 7,
  },
  content: {
    padding: "14px 12px 32px",
    [theme.breakpoints.up("md")]: {
      minWidth: "575px",
      minHeight: "332px",
    },
  },
  decorator: {
    color: theme.branding.red.primary,
  },
  fieldTitle: {
    fontWeight: 600,
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: "22px 24px",
    "& h6": {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "28px",
    },
  },
  input: {
    width: "100%",
    padding: "12px 0",
    marginTop: -13,
  },
  inputInner: {
    width: "100%",
  },
  inputPadding: {
    padding: "0px 12px",
  },
}));

interface FormValues {
  note: string;
  trackingNumber: string;
  shippingCarrier: CarrierOption;
  sendCustomerEmail: boolean;
  sendArtisanEmail: boolean;
}

const fulfillOrderSchema = yup.object().shape({
  note: yup.string(),
  trackingNumber: yup.string(),
  shippingCarrier: yup.string().when("trackingNumber", {
    is: (trackingNumber) => !!trackingNumber?.length,
    then: yup.string().required("Shipping carrier is required"),
  }),
  sendCustomerEmail: yup.boolean(),
  sendArtisanEmail: yup.boolean(),
});

const carrierOptions: { label: string; value: CarrierOption }[] = [
  { label: "USPS", value: "usps" },
  { label: "UPS", value: "ups" },
  { label: "FedEx", value: "fedex" },
  { label: "DHL Express", value: "dhlexpress" },
  { label: "Other", value: "other" },
];

const FulfillOrderModal: React.FC<Props> = (props: Props) => {
  const { show, transaction, updateList, multipleTransactions } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { trackEvent } = useTracking();
  const isMultipleOrders =
    !_.isEmpty(multipleTransactions) && multipleTransactions.length > 1;
  const placeholderText = `Add a note for your customer${
    isMultipleOrders ? "s" : ""
  }`;

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const [sendCustomerEmail, setSendCustomerEmail] = useState(
    account.config?.commsDefaults?.sendCustomerFulfilledConfirmation || false
  );
  const [sendArtisanEmail, setSendArtisanEmail] = useState(
    account.config?.commsDefaults?.sendMeFulfilledConfirmation || false
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);

    const sendCustomerDefault =
      account.config?.commsDefaults?.sendCustomerFulfilledConfirmation || false;
    const sendArtisanDefault =
      account.config?.commsDefaults?.sendMeFulfilledConfirmation || false;

    const sendCustomerEmailChanged =
      sendCustomerDefault !== values.sendCustomerEmail;
    const sendArtisanEmailChanged =
      values.sendCustomerEmail &&
      sendArtisanDefault !== values.sendArtisanEmail;

    if (sendCustomerEmailChanged || sendArtisanEmailChanged) {
      const newAccount = {
        config: {
          ...account.config,
          commsDefaults: {
            ...account.config?.commsDefaults,
            sendCustomerFulfilledConfirmation: values.sendCustomerEmail,
            sendMeFulfilledConfirmation: sendArtisanEmailChanged
              ? values.sendArtisanEmail
              : sendArtisanDefault,
          },
        },
      };

      await accountRepository.updateProps(account.id, newAccount);
    }

    const updateTx = async (transaction: Transaction) => {
      trackEvent("Order Marked Fulfilled", {
        order_id: transaction.id,
        orderType: transaction.order?.type,
        source: "fulfill-order-modal",
        wasCustomerEmailSent: values.sendCustomerEmail,
        wasArtisanEmailSent: values.sendArtisanEmail,
      });

      await transactionRepository.updateProps(transaction.id, {
        status: "fulfilled",
        fulfillOrderInfo: {
          orderFulfilledAt: moment().unix(),
          note: values.note,
          sendArtisanEmail: values.sendArtisanEmail,
          sendCustomerEmail: values.sendCustomerEmail,
          ...(transaction.order.fulfillmentOption?.type === "shipping"
            ? {
                trackingNumber: values.trackingNumber,
                shippingCarrier: values.shippingCarrier,
                shippingCarrierLabel:
                  carrierOptions.find(
                    (cOptions) => cOptions.value === values.shippingCarrier
                  )?.label || "",
                trackingNumberUrl:
                  values.trackingNumber && values.shippingCarrier
                    ? createTrackingNumberUrl(
                        values.trackingNumber,
                        values.shippingCarrier
                      )
                    : "",
              }
            : {}),
        },
      });
    };

    if (transaction) {
      await updateTx(transaction);
    } else if (!_.isEmpty(multipleTransactions)) {
      if (multipleTransactions.length > 1) {
        trackEvent("Bulk Order Fulfilled", {
          order_ids: multipleTransactions.map((tx) => tx.id),
          orderTypse: multipleTransactions.map((tx) => tx.order?.type),
          source: "bulk-fulfill-order-modal",
          wasCustomerEmailSent: values.sendCustomerEmail,
          wasArtisanEmailSent: values.sendArtisanEmail,
        });
      }

      await Promise.all(multipleTransactions.map((tx) => updateTx(tx)));
      dispatch(listAllTransactionsAction(shop.id));
      dispatch(
        openModal({
          modalType: "SIMPLE_ALERT",
          modalProps: {
            show: true,
            celebrate: true,
            content: <>Orders marked fulfilled!</>,
          },
        })
      );
    }

    if (transaction && _.isEmpty(multipleTransactions)) {
      dispatch(
        openModal({
          modalType: "SIMPLE_ALERT",
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <Grid container style={{ padding: 24 }}>
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="center"
                  style={{ height: 76 }}
                >
                  <img src={PartyPopper} height={52} width={52} />
                </Grid>
                <Grid container item justify="center" style={{ marginTop: 16 }}>
                  <Typography variant="body1">
                    Order{" "}
                    <span style={{ fontWeight: 700 }}>
                      #{transaction.order.orderNumber}
                    </span>{" "}
                    fulfilled!
                  </Typography>
                </Grid>
              </Grid>
            ),
          },
        })
      );
    }
    updateList && dispatch(listTransactionsAction(transaction.shopId));
    history.push("/orders");
  };

  return (
    <ModalWrapper fullScreen={isMobile} size="lg" fullWidth show={show}>
      <Grid>
        <DialogTitle className={classes.header}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            {!_.isEmpty(multipleTransactions) ? (
              <>
                <Typography variant="h3">Mark as Fulfilled</Typography>
                <Chip
                  title={`${multipleTransactions.length} orders`}
                  className={classes.chip}
                  colorScheme="lightBlue"
                >
                  {multipleTransactions.length}
                </Chip>
              </>
            ) : (
              <Typography variant="h3">
                Mark Order #{transaction?.order.orderNumber} as Fulfilled
              </Typography>
            )}
          </Grid>
          <IconButton onClick={handleClose} className={classes.closeIcon}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <Formik
            validationSchema={fulfillOrderSchema}
            onSubmit={onSubmit}
            initialValues={{
              note: "",
              trackingNumber: "",
              shippingCarrier: "",
              sendCustomerEmail: sendCustomerEmail || false,
              sendArtisanEmail: sendArtisanEmail || false,
            }}
          >
            {({ values, setFieldValue }: FormikProps<FormValues>) => (
              <AdminForm>
                <Grid className={classes.content} container direction="column">
                  {transaction?.order.fulfillmentOption?.type ===
                    "shipping" && (
                    <>
                      <Typography
                        variant="subtitle1"
                        style={{ padding: "12px 0px 7px 12px" }}
                      >
                        Shipping Info
                      </Typography>
                      <Grid className={classes.inputPadding}>
                        <Typography
                          variant="subtitle2"
                          className={classes.fieldTitle}
                        >
                          Tracking number{" "}
                          <span style={{ color: theme.branding.gray[500] }}>
                            (optional)
                          </span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          className={classes.input}
                          InputProps={{
                            classes: { input: classes.inputInner },
                          }}
                          size="small"
                          name="trackingNumber"
                          value={values.trackingNumber}
                          placeholder="Tracking number"
                          onChange={(event) =>
                            setFieldValue("trackingNumber", event.target.value)
                          }
                        />
                      </Grid>
                      {!!values?.trackingNumber?.length && (
                        <Grid className={classes.inputPadding}>
                          <Typography
                            variant="subtitle2"
                            className={classes.fieldTitle}
                            style={{ marginTop: 10 }}
                          >
                            Shipping carrier
                            <span className={classes.decorator}>*</span>
                          </Typography>
                          <Select
                            onChange={(event) => {
                              setFieldValue(
                                "shippingCarrier",
                                event.target.value
                              );
                            }}
                            value={values.shippingCarrier}
                            variant="outlined"
                            fullWidth={true}
                            inputProps={{
                              classes: { input: classes.inputInner },
                            }}
                            style={{ marginBottom: 26 }}
                          >
                            {carrierOptions.map((carrierObj) => (
                              <MenuItem
                                key={carrierObj.value}
                                value={carrierObj.value}
                              >
                                {carrierObj.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      )}
                    </>
                  )}
                  <Typography
                    variant="subtitle1"
                    style={{ padding: "0px 0px 10px 12px" }}
                  >
                    Notifications
                  </Typography>
                  <Grid container item wrap="nowrap" alignItems="center">
                    <Checkbox
                      color="primary"
                      checked={values.sendCustomerEmail}
                      onChange={(e, checked) => {
                        setFieldValue("sendCustomerEmail", checked);
                        setSendCustomerEmail(!sendCustomerEmail);
                      }}
                    />
                    <Typography variant="body2" style={{ marginLeft: 4 }}>
                      {isMultipleOrders
                        ? "Send my customers fulfillment confirmation emails"
                        : "Send my customer a fulfillment confirmation email"}
                    </Typography>
                  </Grid>
                  {sendCustomerEmail && (
                    <>
                      <Grid container item className={classes.addNoteContainer}>
                        <Typography
                          variant="subtitle2"
                          className={classes.fieldTitle}
                        >
                          Add a note for your customer
                          {isMultipleOrders ? "s" : ""}{" "}
                          <span style={{ color: theme.branding.gray[500] }}>
                            (optional)
                          </span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          className={classes.input}
                          multiline
                          InputProps={{
                            classes: { input: classes.inputInner },
                          }}
                          size="small"
                          rows={4}
                          name="note"
                          value={values.note}
                          placeholder="Add a note for your customer"
                          onChange={(event) =>
                            setFieldValue("note", event.target.value)
                          }
                          rowsMax={8}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        wrap="nowrap"
                        alignItems="center"
                        style={{ marginTop: 4 }}
                      >
                        <Checkbox
                          color="primary"
                          checked={values.sendArtisanEmail}
                          onChange={(e, checked) => {
                            setFieldValue("sendArtisanEmail", checked);
                            setSendArtisanEmail(!sendArtisanEmail);
                          }}
                        />
                        <Typography variant="body2" style={{ marginLeft: 4 }}>
                          Send me a copy of the email
                          {isMultipleOrders ? "s" : ""}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <DialogActions className={classes.actionsContainer}>
                  <Button
                    fullWidth={isMobile}
                    className={classes.button}
                    onClick={handleClose}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth={isMobile}
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </AdminForm>
            )}
          </Formik>
        </DialogContent>
      </Grid>
    </ModalWrapper>
  );
};

export default FulfillOrderModal;

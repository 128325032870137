import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Tab, Tabs, Theme, useMediaQuery, useTheme, } from '@material-ui/core';
import { useTracking } from '@castiron/utils';
import { TabContext, TabPanel } from '@material-ui/lab';
import AddSingleCustomer from './AddSingleCustomer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createCustomerAction, getCustomersAction } from '../../../store/reducers/customers';
import AddCustomerBulk from './AddCustomerBulk';
import { ChecklistValues } from '@castiron/domain';
import { updateChecklistAction } from '../../../store/reducers/shops';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import HubSpotAmexHack from "../../Hacks/HubSpotAmexHack";
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  subheader: {
    color: theme.branding.gray[700],
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 14,
    [theme.breakpoints.down('sm')]: {
      marginTop: 22,
    },
  },
  bodyText: {
    color: theme.palette.text.secondary,
    marginBottom: 24,
  },
  inactiveTab: {
    color: theme.branding.gray[800],
    opacity: 1,
  },
  tabPanel: {
    padding: 0,
  },
  gridContainer: {
    width: '100%',
  },
}));

const AddCustomers: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromChecklist = urlParams.get('fromChecklist');

  const [tab, setTab] = useState('bulk');

  const onTabChange = (event, selectedTab: 'single' | 'bulk'): void => {
    setTab(selectedTab);
  };

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const { trackEvent } = useTracking();

  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;
  const customerSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup
      .string()
      .email('Invalid email')
      .required("Please enter your contact's email"),
    mobileNumber: yup.string().matches(phoneRegExp, 'Please enter a valid 10-digit phone number'),
    notes: yup.string(),
    subscribed: yup.boolean(),
  });

  const submit = async values => {

    trackEvent('Single Customer Upload Attempted', {
      newCustomer: {
        shopId: shop.id,
        subscriberOrigination: values.subscribed ? 'admin-individual-upload' : '',
        ...values
      },
    });
    const newCustomer = {
      shopId: shop.id,
      subscriberOrigination: values.subscribed ? 'admin-individual-upload' : '',
      ...values,
    };
    if (!shop.checklistCompletions?.includes(ChecklistValues.CustomerList)) {
      dispatch(updateChecklistAction({ shop, items: [ChecklistValues.CustomerList] }));
    }
    await dispatch(createCustomerAction(newCustomer));
    await dispatch(getCustomersAction(shop.id));


    if (fromChecklist) {
      setTimeout(() => history.push('/'), 10);
    } else {
      setTimeout(() => history.push('/contacts/overview'), 10);
    }

  };


  const onCancelClick = (): void => {
    history.push('/contacts/overview');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle('Add Contacts');
    setBackLocation(true);
    setHeaderCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    if (fromChecklist) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fromChecklist]);

  useEffect(() => {
    if (shop?.id) dispatch(getCustomersAction(shop.id));
  }, [shop]);

  return (
    <div>
      <Helmet>
        <title>Add Contacts | Contacts | Nourysh</title>
      </Helmet>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          notes: '',
          subscribed: false,
        }}
        validationSchema={customerSchema}
        onSubmit={submit}
        validateOnMount
      >
        {({ values, setFieldValue, isValid, dirty, isSubmitting }): ReactNode => (
          <AdminForm>
            <HubSpotAmexHack />
            {isSubmitting && <UnsavedChangesPrompt when={dirty} />}
            <Grid direction="row" className={classes.container} container>
              <Grid className={classes.gridContainer} item lg={8}>
                <Box>
                  <TabContext value={tab}>
                    <Tabs
                      variant={isMobile ? 'fullWidth' : 'standard'}
                      indicatorColor="primary"
                      onChange={onTabChange}
                      value={tab}
                      aria-label="add-customer-mode"
                    >
                      <Tab
                        fullWidth={isMobile}
                        role="button"
                        tabIndex={0}
                        id="bulk-customer-upload-tab"
                        aria-pressed={false}
                        className={`${tab !== 'bulk' && classes.inactiveTab}`}
                        value="bulk"
                        label="Add in bulk"
                      />
                      <Tab
                        fullWidth={isMobile}
                        role="button"
                        tabIndex={0}
                        id="single-customer-upload-tab"
                        aria-pressed={false}
                        className={`${tab !== 'single' && classes.inactiveTab}`}
                        value="single"
                        label="Add an individual"
                      />
                    </Tabs>
                    <TabPanel className={classes.tabPanel} value="bulk">
                      <AddCustomerBulk setFooterCTAs={setFooterCTAs} />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value="single">
                      <AddSingleCustomer
                        smallView={isMobile}
                        setFooterCTAs={setFooterCTAs}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </div>
  );
};

export default AddCustomers;

import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Typography } from "@castiron/components";
import { useTracking } from "@castiron/utils";
import checklistInstructionImage from "../../assets/img/checklist-instruction-image.png";

interface Props {
  id: string;
  link: string;
  title: string;
  text: string;
  videoLength?: string;
  trackEventTitle?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  plumText: {
    color: theme.branding.v2.plum[500],
  },
  instructionContainer: {
    height: 184,
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 16,
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      height: 128,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px",
    },
  },
  instructionImage: {
    backgroundImage: `url(${checklistInstructionImage})`,
    maxWidth: 273,
    backgroundSize: "cover",
    height: "100%",
    backgroundRepeat: "no-repeat",
    borderRadius: "0px 15px 15px 0px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: 126,
      width: 126,
    },
  },
  instructionImageLink: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  instructionImageText: {
    color: theme.branding.gray[100],
    background: theme.branding.gray[800],
    padding: 6,
    margin: 6,
    borderRadius: 12,
    textAlign: "center",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  instructionTextContainer: {
    padding: "0px 5px 0px 20px",
  },
  instructionBodyText: {
    whiteSpace: "pre-wrap",
    color: theme.branding.gray[600],
  },
  fullContainerLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  playIcon: {
    color: theme.branding.v2.plum[500],
    fontSize: 53,
    position: "absolute",
    left: "50%",
    top: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  playIconBackground: {
    backgroundColor: theme.branding.gray[100],
    height: 25,
    width: 25,
    position: "absolute",
    left: "50%",
    top: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}));

const ResourceGuide: React.FC<Props> = (props: Props) => {
  const { id, link, title, text, videoLength, trackEventTitle } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const trackClick = (ctaName) => {
    if (trackEventTitle) {
      trackEvent(trackEventTitle, { description: ctaName });
    }
  };

  return (
    <>
      <a
        href={link}
        target="_blank"
        onClick={() => {
          trackClick("Video Guide");
        }}
        className={classes.fullContainerLink}
      >
        <Grid
          container
          justify="space-between"
          className={classes.instructionContainer}
        >
          <Grid
            item
            xs={7}
            container
            alignItems="center"
            className={classes.instructionTextContainer}
          >
            <Grid>
              <div>
                <Typography variant="caption" className={classes.plumText}>
                  GETTING STARTED
                </Typography>
              </div>
              <div>
                <Typography variant={isMobile ? "h4" : "h2"}>
                  {title}
                </Typography>
              </div>
              {!isMobile && (
                <div>
                  <Typography
                    variant="body1"
                    className={classes.instructionBodyText}
                  >
                    {text}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.instructionImage}>
            <Grid id={id} className={classes.instructionImageLink}>
              {videoLength && (
                <Typography
                  variant="caption"
                  className={classes.instructionImageText}
                >
                  {videoLength}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </a>
    </>
  );
};

export default ResourceGuide;

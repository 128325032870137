import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { HeaderText, Text } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { transactionRepository } from '../../../domain';
import { useTracking } from '@castiron/utils';
import { listAllTransactionsAction } from '../../../store/reducers/transactions';
import AdminForm from '../../AdminForm';
import { prepareQuoteSegmentData } from '../../Quotes/QuoteUtils';
import { backendStateToFrontendState, Transaction } from '@castiron/domain';
import { useHistory } from 'react-router-dom';

export type Props = {
  show: boolean;
  transaction: Transaction;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  container: {
    padding: '22px 32px',
  },
  actionsContainer: {
    marginTop: 16,
    display: 'flex',
    gap: 8,
  },
  title: {
    fontWeight: 700,
    fontSize: 22,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
    },
  },
  input: {
    width: '100%',
    padding: '12px 0',
  },
  inputInner: {
    width: '100%',
  },
}));

const CustomOrderRejectModal: React.FC<Props> = (props: Props) => {
  const { show, transaction } = props;
  const transactionId = transaction.id;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvent } = useTracking();
  const history = useHistory();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const rejectionSchema = yup.object().shape({
    note: yup.string(),
  });

  const onSubmit = async values => {
    await transactionRepository.updateProps(transactionId, {
      status: 'canceled',
      rejectionNote: values.rejectionNote,
    });
    dispatch(listAllTransactionsAction(shop.id));
    trackEvent('Custom Order Rejected', { transaction: { id: transactionId } });
    trackEvent('Quote Status Changed', {
      url: window.location.href,
      previousStatus: backendStateToFrontendState(transaction, 'quote'),
      newStatus: 'canceled',
      ...prepareQuoteSegmentData(transaction),
    });
    dispatch(
      openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          celebrate: false,
          content: <>Custom order was rejected.</>,
        },
      }),
    );
    history.push('/quotes');
  };

  return (
    <ModalWrapper size="xs" show={show}>
      <IconButton onClick={handleClose} className={classes.closeIcon}>
        <Close />
      </IconButton>
      <Grid style={{ padding: '21px' }}>
        <DialogTitle>
          <HeaderText presetFont="heading--s" fontSize={22}>
            Reject custom request?
          </HeaderText>
        </DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={rejectionSchema}
            onSubmit={onSubmit}
            initialValues={{
              rejectionNote: '',
            }}
          >
            {({ values, setFieldValue }) => (
              <AdminForm>
                <Text presetFont="txt--m">
                  This will notify your customer that their custom order is unable to be completed at this time. You’ll
                  receive a copy of the email your customer receives for reference.
                </Text>
                <Text presetFont="txt--xs" bold style={{ marginBottom: -16, marginTop: 16 }}>
                  Note to customer (optional)
                </Text>
                <TextField
                  variant="outlined"
                  className={classes.input}
                  multiline
                  InputProps={{ classes: { input: classes.inputInner } }}
                  size="small"
                  rows={3}
                  name="rejectionNote"
                  value={values.rejectionNote}
                  placeholder="Add a note for your customer"
                  onChange={event => setFieldValue('rejectionNote', event.target.value)}
                  rowsMax={6}
                />
                <DialogActions className={classes.actionsContainer}>
                  <Button fullWidth={isMobile} className={classes.button} onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button fullWidth={isMobile} className={classes.deleteButton} type="submit" variant="contained">
                    Yes, reject
                  </Button>
                </DialogActions>
              </AdminForm>
            )}
          </Formik>
        </DialogContent>
      </Grid>
    </ModalWrapper>
  );
};

export default CustomOrderRejectModal;

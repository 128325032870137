import React, { useEffect, useState, ReactNode } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, ButtonV2 } from '@castiron/components';
import { useAppDispatch } from '../../../../hooks';
import { closeModal } from '../../../../store/reducers/modalConductor';
import ModalWrapper from '../../../RootModal/ModalWrapper';
import Hourglass from '../../../../assets/img/hourglass.png';

export type Props = {
  show?: boolean;
};


const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: '16px 16px 50px',
    }
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    padding: 5
  },
  content: {
    padding: '24px 24px 24px 40px'
  },
  hourglass: {
    margin: 'auto'
  },
  modalHeader: {
    padding: '22px 24px 48px',
  },
  modalWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 504
    }
  },
  textAlign: {
    width: '100%',
    textAlign: 'center',
    marginTop: 8
  }
}));

const DomainProcessingModal: React.FC<Props> = (props: Props) => {
  const { show } = props;

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper fullScreen={isMobile} show={show} onClose={handleClose} paperClass={classes.modalWrapper}>
      <DialogTitle className={classes.modalHeader}>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container alignItems='center'>
          <img src={Hourglass} className={classes.hourglass} />
          <Grid item style={{ marginTop: 16 }}>
            <Typography variant='h2' className={classes.textAlign}>Your Domain is Processing</Typography>
            <Typography variant='body1' className={classes.textAlign}>Please allow 24-48 hours for your new domain to appear in your shop. <strong>Please note:</strong> Your default Nourysh domain will begin redirecting to your new domain once it is live.</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ButtonV2
          variant='contained'
          onClick={handleClose}
          fullWidth={isMobile}
        >
          Got It!
        </ButtonV2>
      </DialogActions>
    </ModalWrapper>
  );
};

export default DomainProcessingModal;
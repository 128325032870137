import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import * as yup from "yup";
import * as Sentry from "@sentry/react";
import momentTimezone from "moment-timezone";
import firebase from "firebase/compat/app";
import { Form, Formik, FormikProps } from "formik";
import {
  Grid,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import {
  loginWithApple,
  loginWithGoogle,
} from "@castiron/castiron-firebase";
import {
  Typography,
  Button,
  EmailInput,
  TextInput,
  CheckboxInput,
  PasswordInput,
  Link,
  Banner,
  GoogleGIcon,
  NouryshLogoBlack,
} from "@castiron/components";
import { Customer } from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { shopRepository } from "../../domain";
import { useAppDispatch } from "../../hooks";
import { trackHubSpotContactPage } from "../../lib/trackHubSpotContactEvent";
import { createCustomerAction } from "../../store/reducers/customers";
import {
  createShopAction,
  getShopAction,
  setIsOnboarding,
} from "../../store/reducers/shops";
import setDomainAuth from "../Auth/domainAuth";
import domainAuth from "../Auth/domainAuth";
import AppleIcon from "@material-ui/icons/Apple";

const { REACT_APP_ADMIN_URL } = process.env;

export interface SignupInfo {
  email: string;
  fullName: string;
  password: string;
  termsOfService: boolean;
}

const signupSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter an email address.")
    .required("Please enter an email address."),
  fullName: yup.string().required("Please enter your full name."),
  password: yup
    .string()
    .required("Please enter a password.")
    .min(8, "Password must be at least 8 characters"),
  termsOfService: yup
    .boolean()
    .oneOf(
      [true],
      "Please agree to our Terms of Service and Privacy Policy to continue."
    )
    .required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  appleIcon: {
    height: 24,
    width: 24,
    color: theme.branding.v2.gray[900],
  },
  button: {
    minHeight: 56,
    padding: "16px 24px",
  },
  caption: {
    marginTop: 4,
  },
  checkbox: {
    padding: "0px 8px 0px 0px",
  },
  container: {
    padding: "40px 104px 40px 80px",
    [theme.breakpoints.down("lg")]: {
      padding: 40,
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px 168px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "40px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px",
    },
  },
  desktopImage: {
    objectFit: "cover",
    top: 0,
    width: "100%",
  },
  desktopImageContainer: {
    backgroundColor: theme.branding.gray[0],
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      background: `radial-gradient(69.65% 74.88% at 80.17% -21.82%, #53E4D7 22.7%, rgba(252, 221, 216, 0.21) 100%), radial-gradient(181.21% 76.27% at 19.74% -8.07%, rgba(86, 141, 246, 0.9) 18.75%, rgba(255, 150, 207, 0.027) 75.17%, rgba(255, 255, 255, 0) 99%), radial-gradient(69.47% 69.47% at 82.78% 113.97%, #FAE5BA 23.34%, rgba(255, 215, 209, 0.06) 100%), linear-gradient(7.48deg, rgba(255, 225, 220, 0.672) 12.58%, rgba(255, 150, 207, 0.376) 69.86%), radial-gradient(83.53% 154.65% at 0% 42.88%, rgba(244, 132, 192, 0.7) 19.5%, rgba(255, 225, 220, 0.203) 100%)`,
    },
  },
  desktopImageLink: {
    backgroundColor: theme.branding.gray[100],
    borderRadius: 12,
    cursor: "pointer",
    height: 64,
    margin: 24,
    opacity: "72%",
    padding: "8px 24px",
    position: "absolute",
    width: 193,
  },
  divider: {
    flex: 1,
  },
  dividerContainer: {
    margin: "16px 0px",
  },
  dividerText: {
    margin: "0px 16px",
  },
  imageBannerContainer: {
    position: "absolute",
    top: 24,
    padding: "0px 24px",
  },
  infoBox: {
    borderRadius: 12,
    background: theme.branding.v2.gray[0],
    textAlign: "center",
    gap: 16,
    padding: "32px 48px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      gap: 8,
    },
  },
  infoContainer: {
    gap: 16,
    [theme.breakpoints.up("md")]: {
      gap: 32,
      padding: 32,
    },
  },
  infoList: {
    margin: 0,
    padding: "0px 8px",
    [theme.breakpoints.up("md")]: {
      padding: "0px 16px",
    },
  },
  inputContainer: {
    marginBottom: 16,
  },
  link: {
    color: theme.branding.v2.plum[500],
    "&:hover": {
      textDecoration: "none",
    },
  },
  loadingIcon: {
    color: theme.branding.gray[100],
  },
  logo: {
    width: 130,
    height: "fit-content",
    marginBottom: 40,
    alignSelf: "center",
  },
  shopNowButton: {
    padding: "0px 16px",
    minWidth: 100,
  },
  tosErrorBanner: {
    color: theme.branding.red.primary,
    margin: "16px 0",
  },
  transactionFeeBanner: {
    background: theme.branding.v2.plum[500],
    padding: 16,
    [theme.breakpoints.up("lg")]: {
      borderRadius: "16px",
    },
  },
}));

const Signup: React.FC = () => {
  const classes = useStyles();
  const { trackEvent, trackUser } = useTracking();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromTapToPayApp = queryParams.get("src") == "ttpapp";
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const showDesktopImage = useMediaQuery(theme.breakpoints.up("lg"));
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initialValues = {
    email: "",
    fullName: "",
    password: "",
    termsOfService: true,
  };

  useEffect(() => {
    if (fromTapToPayApp) {
      setIsSubmitting(true);
      firebase
        .auth()
        .signOut()
        .then(() => setIsSubmitting(false));
    }
  }, [fromTapToPayApp]);

  const loginWithProvider = async (values, user) => {
    // check for shop
    const shopExists = await shopRepository.get(user.uid);

    if (shopExists) {
      dispatch(getShopAction(user.uid));
    } else {
      handleSubmit(values, user);
    }
  };

  const googleSignUp = async (values) => {
    const result = await loginWithGoogle(undefined);
    await setDomainAuth();
    await loginWithProvider(values, result);
  };

  const appleSignUp = async (values) => {
    const result = await loginWithApple(undefined);
    await setDomainAuth();
    await loginWithProvider(values, result);
  };

  const createShop = async (email, fullName, firstName, lastName, user?) => {
    trackEvent("Shop Login Created", {
      user: {
        id: user?.uid,
        email,
        name: fullName,
      },
    });

    const shopResponse = await dispatch(
      createShopAction({
        businessName: `${firstName}'s Shop`,
        email: email,
        firstName,
        lastName,
        onboardingQuestions: {
          onboardingStep: {
            version: 1,
            step: 1,
          },
        },
        tags: fromTapToPayApp
          ? ["Day1Onboarding", "TapToPayOnboarding"]
          : ["Day1Onboarding"],
        timeZone: momentTimezone.tz.guess() || "America/Chicago",
        overrideTierId: "jPAnKCplJ58JtJMLScQ5",
      })
    );

    return shopResponse.payload;
  };

  const handleSubmit = async (values, socialUser) => {
    const isSocialUser = !!socialUser?.uid;
    const email = isSocialUser ? socialUser?.email : values.email;
    const fullName = isSocialUser ? socialUser?.displayName : values.fullName;
    const password = values.password;

    const nameParts = fullName.split(" ");
    const firstName = nameParts.shift();
    const lastName = nameParts.length > 0 ? nameParts.join(" ") : "";
    const createTx = Sentry.startTransaction({
      op: "submit",
      name: "Create Shop Request",
      tags: {
        transactionType: "business",
      },
    });
    Sentry.getCurrentHub().configureScope((scope) => {
      scope.setSpan(createTx);
      scope.setUser({
        email,
      });
    });
    setIsSubmitting(true);
    try {
      let user;
      if (!isSocialUser) {
        const span = createTx.startChild({
          op: "auth-create",
          description: "Create Auth Account",
        });
        console.debug("Creating Auth Account");
        const result = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        await domainAuth();
        user = result.user;
        // Commenting out google email verification for now will bring back later when improve template
        // await user.sendEmailVerification();
        await user.updateProfile({
          displayName: fullName,
        });
        span.finish();
      } else {
        user = socialUser;
      }

      const shop = await shopRepository.get(user.uid);
      if (!shop) {
        const span = createTx.startChild({
          op: "shop-create",
          description: "Create Shop",
        });

        trackUser(user.uid);

        const { shop } = await createShop(
          email,
          fullName,
          firstName,
          lastName,
          user
        );

        dispatch(getShopAction(shop?.id));

        const initialCustomers: Customer[] = [
          {
            shopId: shop?.id,
            firstName,
            lastName,
            email,
          },
        ];

        initialCustomers.forEach(async (c) => {
          await dispatch(
            createCustomerAction({
              ...c,
              subscribed: true,
              subscriberOrigination: "shop-creation",
            })
          );
        });

        trackHubSpotContactPage(
          {
            email: email,
            migration: false,
            firstName,
            lastName,
          },
          "/signup/create"
        );

        await dispatch(setIsOnboarding(true));
        trackEvent("Lead Started Onboarding");
        trackEvent("Agreed To Terms Of Service");

        span.finish();
        createTx.finish();
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        await dispatch(setIsOnboarding(false));
        history.push("/");
      }
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error Setting up Email User", err);
      if (err.code === "auth/email-already-in-use") {
        history.push("/signup/account/existing", {
          existingAccountEmail: email,
        });
      }
    }
  };

  const transactionFeeBanner = (
    <Grid container item className={classes.transactionFeeBanner}>
      <Typography
        variant="body1"
        style={{
          color: theme.branding.v2.gray[0],
          textAlign: isXsMobile ? "left" : "center",
          width: "100%",
        }}
      >
        Grow your business, sell on Nourysh.
      </Typography>
    </Grid>
  );

  const infoTiles = [
    {
      icon: "🏆",
      text: "All the tools you need, in one place so you can work less and sell more.",
      mobileOrder: 0,
    },
    {
      icon: "💸",
      text: "Low-priced and high-value to keep more money in your pocket.",
      mobileOrder: 1,
    },
    {
      icon: "🍰",
      text: "Do what you love; let Nourysh worry about the rest.",
      mobileOrder: 2,
    },
    {
      icon: "🫶",
      text: "Join a community of sellers to learn, find support and more.",
      mobileOrder: 3,
    },
  ];

  return (
    <Grid container wrap="nowrap" style={{ minHeight: "100vh" }}>
      <Grid container item direction="column" xs={showDesktopImage ? 6 : 12}>
        {!showDesktopImage && transactionFeeBanner}
        <Grid container item direction="column" className={classes.container}>
          <NouryshLogoBlack className={classes.logo} />
          <Typography style={{ marginBottom: 16 }} variant="h1">
            Join the Nourysh community and marketplace where conscious consumers
            can discover and purchase directly from entrepreneurs like you.
          </Typography>
          {!showDesktopImage && (
            <Grid
              container
              item
              justify="center"
              style={{ gap: "16px", marginBottom: 16, textAlign: "center" }}
            >
              {infoTiles
                .sort((a, b) => a.mobileOrder - b.mobileOrder)
                .map((tile, index) => (
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="center"
                    justify="flex-start"
                    key={`tile-${index}`}
                    style={{ maxWidth: isXsMobile ? "150px" : "fit-content" }}
                  >
                    <Typography variant="body1">{tile.icon}</Typography>
                    <Typography
                      variant="body1"
                      style={{ wordBreak: "break-word" }}
                    >
                      {tile.text}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          )}
          <Formik
            onSubmit={handleSubmit}
            validationSchema={signupSchema}
            initialValues={initialValues}
          >
            {({ errors, touched, values }: FormikProps<SignupInfo>) => (
              <Form data-cello>
                <Button
                  className={classes.button}
                  fullWidth
                  onClick={() => googleSignUp(values)}
                  variant="outlined"
                  style={{ marginBottom: 16 }}
                >
                  <Grid container item>
                    <Grid item xs={1} style={{ justifySelf: "flex-start" }}>
                      <GoogleGIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="button">
                        Sign up with Google
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
                <Button
                  className={classes.button}
                  fullWidth
                  onClick={() => appleSignUp(values)}
                  variant="outlined"
                >
                  <Grid container item>
                    <Grid item xs={1} style={{ justifySelf: "flex-start" }}>
                      <AppleIcon className={classes.appleIcon} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="button">
                        Sign up with Apple
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
                <Grid
                  container
                  item
                  className={classes.dividerContainer}
                  alignItems="center"
                >
                  <Divider className={classes.divider} />
                  <Typography className={classes.dividerText} variant="body2">
                    or
                  </Typography>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <EmailInput
                    error={touched.email && errors.email}
                    label="Email Address"
                    name="email"
                    placeholder="email@domain.com"
                    required
                  />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <TextInput
                    error={touched.fullName && errors.fullName}
                    label="Full Name"
                    name="fullName"
                    placeholder="Jackie Blue"
                    required
                  />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <PasswordInput
                    error={touched.password && errors.password}
                    label="Password"
                    name="password"
                    placeholder="••••••••••••••"
                    required
                  />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <CheckboxInput
                    className={classes.checkbox}
                    label={
                      <Typography variant="caption">
                        I have read and agree to Nourysh's&nbsp;
                        <Link
                          className={classes.link}
                          target="_blank"
                          href="https://nourysh.com/terms-and-conditions"
                        >
                          Terms of Service
                        </Link>
                        &nbsp; and&nbsp;
                        <Link
                          className={classes.link}
                          target="_blank"
                          href="https://nourysh.com/privacy-policy"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </Typography>
                    }
                    name="termsOfService"
                    variant="label-right"
                  />
                  {errors.termsOfService && (
                    <Banner className={classes.tosErrorBanner} variant="error">
                      <Typography variant="body2">
                        {errors.termsOfService}
                      </Typography>
                    </Banner>
                  )}
                </Grid>
                <Button
                  className={classes.button}
                  fullWidth
                  loading={isSubmitting}
                  loadingClassName={classes.loadingIcon}
                  type="submit"
                  variant="contained"
                  style={{ background: theme.branding.v2.gray[900] }}
                >
                  {isSubmitting ? "" : "Get Started"}
                </Button>
              </Form>
            )}
          </Formik>
          <Typography
            variant="body1"
            style={{ margin: "16px 0px", alignSelf: "center" }}
          >
            Already have an account?&nbsp;
            <Link className={classes.link} href={REACT_APP_ADMIN_URL}>
              Log in
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {showDesktopImage && (
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          justify="center"
          className={classes.desktopImageContainer}
        >
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.container}
            style={{ gap: "16px", textAlign: "center", maxWidth: "650px" }}
          >
            <Grid
              container
              item
              justify="space-between"
              wrap="nowrap"
              style={{ gap: "8px" }}
            >
              {infoTiles.slice(0, 2).map((tile, index) => (
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  justify="flex-start"
                  key={`tile-top-${index}`}
                >
                  <Typography variant="body1">{tile.icon}</Typography>
                  <Typography
                    variant="body1"
                    style={{ wordBreak: "break-word" }}
                  >
                    {tile.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {transactionFeeBanner}
            <Grid
              container
              item
              justify="space-between"
              wrap="nowrap"
              style={{ gap: "8px" }}
            >
              {infoTiles.slice(2).map((tile, index) => (
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  justify="flex-start"
                  key={`tile-bottom-${index}`}
                >
                  <Typography variant="body1">{tile.icon}</Typography>
                  <Typography
                    variant="body1"
                    style={{ wordBreak: "break-word" }}
                  >
                    {tile.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Signup;

import React, { MouseEvent, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { Grid, Paper, TextField, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  AddCircleOutline,
  CheckCircleOutline,
  Close,
  ListAlt,
  MailOutline,
} from "@material-ui/icons";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import { Autocomplete } from "@material-ui/lab";
import {
  ButtonV2 as Button,
  Checkbox,
  ChevronDownIcon,
  Chip,
  Dropdown,
  DropDownOption,
  FaqsIcon,
  FileUploadIcon,
  ProFeatureChip,
  SearchInput,
  SvgIcon,
  Typography,
  useFeatures,
} from "@castiron/components";
import { FeatureName, Transaction } from "@castiron/domain";
import { useVariation } from "@castiron/components";
import { defaultTimeZone, useTracking } from "@castiron/utils";
import { useConfig } from "@castiron/castiron-firebase";
import { GridSortModel } from "@material-ui/data-grid";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCustomersAction } from "../../store/reducers/customers";
import { openModal } from "../../store/reducers/modalConductor";
import { LayoutPageProps } from "../Layout";
import Spinner from "../Spinner";
import EllipsisMenu from "../Menus/EllipsisMenu";
import AvatarMenu from "../Menus/AvatarMenu";
import SubscriptionBanner from "../SubscriptionBanner";
import _ from "lodash";
import { getService } from "../../firebase";
import { EmptyOrdersIcon } from "@castiron/components";
import { TipsForYou } from "../EmptyPage";
import { SEEN_SMS_OPTIN } from "../SMSNotificationModal";
import { transactionRepository } from "../../domain";
import OrdersList from "./OrdersList";
import MobileList from "./MobileList";
import EmptyPage from "../EmptyPage";

const printOrderService = getService("orders", "printorder");
const orderSearchService = getService("orders", "search");

interface BulkActionOption {
  label: string;
  icon: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  feature?: FeatureName;
}

const useStyles = makeStyles((theme: Theme) => ({
  bannerText: {
    padding: "0px 4px",
    textAlign: "center",
  },
  checkCircleOutlineIcon: {
    height: 20,
    width: 20,
    paddingBottom: 2,
  },
  chevronDownIcon: {
    height: 40,
    width: 40,
    color: theme.branding.gray[800],
  },
  clearFilterText: {
    cursor: "pointer",
    paddingLeft: 8,
  },
  closeIcon: {
    height: 20,
    width: 20,
    color: theme.branding.gray[600],
    marginLeft: 8,
  },
  dropdownBanner: {
    width: "100%",
    borderRadius: 12,
    padding: "24px 16px",
    backgroundColor: theme.branding.yellow.light,
    maxWidth: 198,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  faqsIcon: {
    "& svg path": {
      fill: theme.branding.gray[800],
    },
  },
  filterPill: {
    cursor: "pointer",
    padding: "4px 12px",
  },
  icon: {
    height: 104,
    width: 190,
  },
  learnMore: {
    fontSize: 16,
    color: theme.branding.gray[100],
    marginLeft: 8,
  },
  upgradeButton: {
    padding: "16px 24px",
    marginTop: 8,
  },
  resourceGuide: {
    [theme.breakpoints.down("sm")]: {
      margin: "0px 17px",
    },
  },
  rocket: {
    color: "#F57F17",
    height: 14,
    width: 14,
  },
  statusApplyFilterButton: {
    padding: 16,
  },
  statusCTAsContainer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: 8,
  },
  statusEndAdornment: {
    top: 6,
  },
  filtersContainer: {
    [theme.breakpoints.down("sm")]: {
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
      width: "100%",
      padding: "16px",
    },
  },
  statusInput: {
    cursor: "pointer",
    color: theme.branding.gray[100],
  },
  statusInputRoot: {
    height: 56,
    minWidth: 125,
    borderRadius: 12,
    [theme.breakpoints.down("sm")]: {
      minWidth: 100,
    },
  },
  statusListbox: {
    padding: 0,
  },
  statusOption: {
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
    "&:last-child": {
      border: "none",
    },
    // Selected
    '&[aria-selected="true"]': {
      background: "none",
    },
  },
  statusPaperClass: {
    marginTop: 8,
    width: 225,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
    },
  },
  statusRoot: {
    "& .MuiFormControl-marginDense": {
      margin: 0,
    },
  },
  statusTag: {
    display: "none",
  },
  subscriptionBanner: {
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      margin: "16px 17px 0px 17px",
    },
  },
  tooltipText: {
    color: theme.branding.gray[100],
  },
  upgradeTooltipLink: {
    color: theme.branding.gray[100],
    textDecoration: "underline",
    "&:hover": {
      color: theme.branding.gray[100],
      fontWeight: 700,
    },
  },
  upgradeToPro: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const Orders: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;

  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const ffconfig = useConfig();
  const history = useHistory();
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const filterOptions = ["open", "completed", "fulfilled", "canceled"];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { shop, account, customers, modal } = useAppSelector((state) => ({
    shop: state.shops.shop,
    account: state.shops.account,
    customers: state.customers.customers,
    modal: state.modal,
  }));
  const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
  const [orderSearched, setOrderSearched] = useState(false);
  const [ranFixRaceCondition, setRanFixRaceCondition] = useState(false);
  const [isStripeSetup, setIsStripeSetup] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>(undefined);
  const [validTransactions, setValidTransactions] = useState<Transaction[]>([]);
  const [orders, setOrders] = useState<Transaction[]>([]);
  const [ordersFromES, setOrdersFromES] = useState<Transaction[]>([]);
  const [moreOrdersFromES, setMoreOrdersFromES] = useState(true);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [newFilters, setNewFilters] = useState([]);
  const [isStatusFilterApplied, setIsStatusFilterApplied] =
    useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<Transaction[]>([]);
  const [printing, setPrinting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);
  const [initialSearchValue, setInitialSearchValue] =
    useState<string>(undefined);

  const subscriptionBannerExperiment = useVariation(
    "Subscription Upgrade Banner Test"
  );
  const isVariant = subscriptionBannerExperiment?.name === "Order Page";

  const remoteConfig = useConfig();

  const features = useFeatures() || [];
  const isSubscriptionPreviewEnabled =
    !account.isCastironPlus() &&
    ffconfig?.featureFlag("feature_subscriptions_preview", shop);

  useEffect(() => {
    if (account && account.isReady) {
      setIsStripeSetup(true);
    }
  }, [account]);

  const getTransactions = async (shopId) => {
    setIsLoading(true);
    const transactions = await transactionRepository.getAllTransactions(
      shopId,
      100
    );
    setTransactions(transactions);
    setIsLoading(false);
  };

  const updateTransactionStatus = async (transaction) => {
    await transactionRepository.updateProps(transaction.id, {
      transactionStatus: transaction.transactionStatus,
    });
  };

  //Not ideal but this does a check for status mismatch and updates so it shows up
  const fixRaceCondition = async () => {
    try {
      if (!ranFixRaceCondition) {
        const pendingEsTransactions = await orderSearchService({
          start: 0,
          size: 20,
          transactionStatus: "pending",
        });
        const orderIds = pendingEsTransactions.orders.map((o) => o.id);
        const transactions = await transactionRepository.getAll(orderIds);
        pendingEsTransactions.orders.map((et) => {
          const transaction = transactions.find((t) => t.id == et.id);
          if (transaction.transactionStatus != "pending") {
            console.debug(`transaction mistmatch update ${transaction}`);
            updateTransactionStatus(transaction);
          }
        });
        setRanFixRaceCondition(true);
      }
    } catch {
      setRanFixRaceCondition(false);
    }
  };

  const searchForOrders = async (reset?: boolean, searchText?: string) => {
    const searchResponse = await orderSearchService({
      start: reset ? 0 : ordersFromES.length,
      size: 20,
      frontendStatuses: currentFilters,
      transactionStatus: "succeeded",
      search: {
        text: searchText,
      },
    });
    const newFoundOrders = searchResponse.orders;
    /* hack to avoid rebuilding everything off of order search results right now */
    const orderIds = newFoundOrders.map((o) => o.id);
    const transactions = await transactionRepository.getAll(orderIds);
    /* transactions aren't sorted, resort via orderIds */
    const newTransactions = orderIds.map((oid) =>
      transactions.find((tx) => tx.id === oid)
    );
    const newOrdersList = reset
      ? newTransactions
      : ordersFromES.concat(newTransactions);
    setOrdersFromES(newOrdersList);
    if (newOrdersList.length >= searchResponse.total) {
      setMoreOrdersFromES(false);
    } else if (reset) {
      setMoreOrdersFromES(true);
    }
  };

  const searchForOrdersDesktop = async (page: number, searchText?: string) => {
    const ordersState = localStorage.getItem("ordersState");
    const params = new URLSearchParams(ordersState);
    const savedPage = parseInt(params.get("page") || "0", 10);
    const searchValue = searchText == undefined && search ? search : searchText;
    const reset = searchValue != search;
    setSearch(searchValue);
    setPage(ordersFromES.length == 0 ? savedPage : page);
    const searchResponse = await orderSearchService({
      start: ordersFromES.length == 0 ? 0 : page * 20,
      size: ordersFromES.length == 0 ? savedPage * 20 + 20 : 20,
      frontendStatuses: currentFilters,
      transactionStatus: "succeeded",
      search: {
        text: searchValue,
      },
    });
    const newFoundOrders = searchResponse.orders;
    setTotalTransactions(searchResponse.total);
    /* hack to avoid rebuilding everything off of order search results right now */
    const orderIds = newFoundOrders.map((o) => o.id);
    const transactions = await transactionRepository.getAll(orderIds);
    /* transactions aren't sorted, resort via orderIds */
    const newTransactions = orderIds.map((oid) =>
      transactions.find((tx) => tx.id === oid)
    );
    const newOrdersList =
      page == 0 ? newTransactions : ordersFromES.concat(newTransactions);
    setOrdersFromES(newOrdersList);
    if (newOrdersList.length >= searchResponse.total) {
      setMoreOrdersFromES(false);
    } else if (page == 0 || reset) {
      setMoreOrdersFromES(true);
    }
    setOrderSearched(true);
  };

  const exportOrdersDisplay = "Export";
  const openExportOrdersMenu = () => {
    dispatch(
      openModal({
        modalType: "EXPORT_ORDERS",
        modalProps: {
          show: true,
          // Export all orders in the current view if none are selected
          selectedOrders: selectedOrders?.length > 0 ? selectedOrders : orders,
          trackEventProps: {
            exportAllOrders:
              selectedOrders?.length === 0 ||
              selectedOrders?.length === orders?.length,
            filters: currentFilters,
            selectedOrderCount:
              selectedOrders?.length > 0
                ? selectedOrders.length
                : orders?.length,
          },
        },
      })
    );
  };

  const openAddOrderModal = () => {
    dispatch(
      openModal({
        modalType: "ADD_ORDER_MODAL",
        modalProps: {
          show: true,
          onAdd: () => {
            searchForOrders(true);
            window.scrollTo(0, 0);
          },
        },
      })
    );
  };

  useEffect(() => {
    setPageTitle("Orders");
    return () => {
      setPageTitle("");
      setHeaderCTAs([]);
      setFooterCTAs([]);
    };
  }, []);

  useEffect(() => {
    const headerCTAs = isMobile
      ? [
          <EllipsisMenu
            options={[
              {
                display: "Export",
                icon: <FileUploadIcon />,
                action: openExportOrdersMenu,
              },
              {
                display: "Create Order",
                icon: <AddCircleOutline />,
                action: openAddOrderModal,
              },
            ]}
          />,
          <AvatarMenu />,
        ]
      : [
          <Button variant="outlined" onClick={openExportOrdersMenu}>
            {exportOrdersDisplay}
          </Button>,
          <Button variant="contained" onClick={openAddOrderModal}>
            Create Order
          </Button>,
        ];
    setHeaderCTAs(transactions?.length == 0 ? [] : headerCTAs);
    setFooterCTAs(
      isMobile
        ? [
            <Button variant="contained" onClick={openAddOrderModal} fullWidth>
              Create Order
            </Button>,
          ]
        : []
    );
  }, [isMobile, selectedOrders, validTransactions]);

  useEffect(() => {
    if (shop && shop.id) {
      getTransactions(shop.id);
      dispatch(getCustomersAction(shop.id));
    }
  }, [dispatch, shop]);

  useEffect(() => {
    if (transactions) {
      const successfulTransactions = transactions.filter(
        (d) => d.transactionStatus === "succeeded"
      );
      setOrders(successfulTransactions);
      setValidTransactions(successfulTransactions);
      fixRaceCondition();
    }
  }, [shop, transactions]);

  useEffect(() => {
    const filterString = urlParams.get("filter");
    if (filterString) {
      const filterChoices = filterString.split(",");
      const toFilter = filterChoices.filter((choice) =>
        filterOptions.includes(choice)
      );

      setIsStatusFilterApplied(true);
      applyFilterChange(toFilter);
      setNewFilters(toFilter);
    }
  }, [validTransactions]);

  useEffect(() => {
    if (account && !account.config?.messagingPreferences?.sms) {
      const seemOptInModal = localStorage.getItem(SEEN_SMS_OPTIN) === "true";
      if (!seemOptInModal) {
        dispatch(
          openModal({
            modalType: "SMS_NOTIFICATION_OPTIN_MODAL",
            modalProps: {
              show: true,
            },
          })
        );
      }
    }
  }, [shop, account, remoteConfig]);

  useEffect(() => {
    if (localStorageLoaded) {
      setPage(0);
      isMobile ? searchForOrders(true) : searchForOrdersDesktop(0);
    }
  }, [currentFilters, localStorageLoaded]);

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ): void => {
    setNewFilters(value);
  };

  const applyFilterChange = (overrideFilters?: string[]): void => {
    const filters = overrideFilters ? overrideFilters : newFilters;
    if (filters.length === 0) {
      setOrders(validTransactions);
    } else {
      setOrders(validTransactions.filter((o) => filters.includes(o.status)));
    }
    setCurrentFilters(filters);
    /* clear selections always when filters change */
    setSelectedOrders([]);
  };

  useEffect(() => {
    if (localStorageLoaded) {
      const filtersString = JSON.stringify(currentFilters);
      const sortModelString = JSON.stringify(sortModel);
      localStorage.setItem(
        "ordersState",
        `filters=${filtersString}&page=${page}&sortModel=${sortModelString}&search=${search}`
      );
    }
  }, [currentFilters, page, sortModel, localStorageLoaded, search]);

  useEffect(() => {
    const ordersState = localStorage.getItem("ordersState");
    if (ordersState) {
      const params = new URLSearchParams(ordersState);
      const savedFilters = params.get("filters");
      const savedSortModel = params.get("sortModel");
      const savedPage = parseInt(params.get("page") || "0", 10);
      const savedSearch = params.get("search");

      if (savedFilters) {
        applyFilterChange(JSON.parse(savedFilters));
        setNewFilters(JSON.parse(savedFilters));
        setIsStatusFilterApplied(true);
      }
      setPage(savedPage);
      setSortModel(JSON.parse(savedSortModel));
      if (savedSearch !== "undefined") {
        setSearch(savedSearch);
        setInitialSearchValue(savedSearch);
      }
    }
    setLocalStorageLoaded(true);
    setIsLoading(false);
  }, []);

  const handleFilterPillChange = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    const filterClicked = event.currentTarget.id;
    const newFilters = currentFilters.filter(
      (filter) => filter !== filterClicked
    );
    handleFilterChange(event, newFilters);
    applyFilterChange(newFilters);
  };

  const clearFilters = (event: React.MouseEvent<HTMLElement>): void => {
    handleFilterChange(event, []);
    applyFilterChange([]);
  };

  const renderStatusPaperComponent = ({ children, ...other }) => (
    <Paper {...other} className={classes.statusPaperClass}>
      {children}
      <Grid
        container
        wrap="nowrap"
        justify="space-between"
        className={classes.statusCTAsContainer}
      >
        <Grid container item wrap="nowrap" alignItems="center">
          {newFilters.length > 0 && (
            <>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{
                    color: theme.branding.v2.plum[500],
                    paddingLeft: 16,
                  }}
                >
                  {newFilters.length} Selected
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.clearFilterText}
                  onMouseDown={clearFilters}
                  variant="body2"
                >
                  Clear
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item>
          <Button
            className={classes.statusApplyFilterButton}
            onMouseDown={() => applyFilterChange()}
            variant="contained"
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  const renderStatusInput = (params) => (
    <TextField
      {...params}
      InputProps={{ ...params.InputProps }}
      InputLabelProps={{ shrink: false }}
      label={
        <Typography variant="body1" style={{ paddingTop: 4 }}>
          {isMobile ? (
            <CheckCircleOutline className={classes.checkCircleOutlineIcon} />
          ) : (
            "Status"
          )}
          {currentFilters.length > 0 && isStatusFilterApplied && (
            <span style={{ color: theme.branding.v2.plum[500], marginLeft: 8 }}>
              {currentFilters.length}
            </span>
          )}
        </Typography>
      }
      variant="outlined"
    />
  );

  const renderStatusOption = (option, { selected }) => (
    <Checkbox
      checked={selected}
      label={<Typography variant="body1">{_.capitalize(option)}</Typography>}
    />
  );

  const chevronDownIcon = (
    <SvgIcon className={classes.chevronDownIcon}>
      <ChevronDownIcon />
    </SvgIcon>
  );

  const statusFilter = (
    <Autocomplete
      classes={{
        endAdornment: classes.statusEndAdornment,
        input: classes.statusInput,
        inputRoot: classes.statusInputRoot,
        listbox: classes.statusListbox,
        option: classes.statusOption,
        root: classes.statusRoot,
        tag: classes.statusTag,
      }}
      closeIcon={chevronDownIcon}
      disableClearable
      disableCloseOnSelect
      getOptionLabel={(option: string) => _.capitalize(option)}
      inputValue="" // disables typing
      multiple
      options={filterOptions}
      onChange={handleFilterChange}
      onClose={() => {
        setIsStatusFilterApplied(true);
        setNewFilters(currentFilters);
      }}
      onOpen={() => {
        setIsStatusFilterApplied(false);
      }}
      PaperComponent={renderStatusPaperComponent}
      popupIcon={chevronDownIcon}
      renderInput={renderStatusInput}
      renderOption={renderStatusOption}
      value={newFilters}
      style={{ width: currentFilters.length > 0 ? "80%" : "100%" }}
    />
  );

  const printOrders = async () => {
    try {
      setPrinting(true);

      const printFrame = document.createElement("iframe");
      printFrame.style.display = "none";
      document.title = "Orders";

      const ordersHtml = [];

      for (let order of selectedOrders) {
        // get generated html for the order
        let response = await printOrderService({
          transactionId: order.id,
          timeZone: shop?.config?.timeZone || defaultTimeZone,
          title: "Orders",
        });
        // append to ordersHtml
        ordersHtml.push(response.html);
      }

      printFrame.srcdoc = ordersHtml.join("");

      document.body.appendChild(printFrame);

      printFrame.onload = () => {
        setTimeout(() => {
          printFrame.contentWindow.focus();
          printFrame.contentWindow.print();

          setPrinting(false);
        });
      };

      trackEvent("Print Order", { ordersPrinted: selectedOrders?.length });
    } catch (err) {
      console.debug(err);
      setPrinting(false);
    }
  };

  const uniqueSelectedCustomers = () => {
    const selectedCustomers = selectedOrders
      .map((ord) => {
        const cust = customers.find((c) => c.id == ord.customerObj?.id);
        return (
          cust && {
            email: cust.email,
            id: cust.id,
            name: `${cust.firstName} ${cust.lastName ? cust.lastName : ""}`,
          }
        );
      })
      .filter((sc) => !!sc);
    return _.uniqBy(selectedCustomers, "id");
  };

  const handleStripeSetup = () => {
    dispatch(
      openModal({
        modalType: "PROMPT_STRIPE_SETUP_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const onSendClick = async () => {
    await dispatch(
      openModal({
        modalType: "BULK_CONTACT_MODAL",
        modalProps: {
          customerList: uniqueSelectedCustomers(),
          show: true,
        },
      })
    );
    setSelectedOrders([]);
  };

  const openOrderFulfilledModal = () => {
    dispatch(
      openModal({
        modalType: "FULFILL_ORDER",
        modalProps: {
          multipleTransactions: selectedOrders,
          show: true,
          updateList: true,
        },
      })
    );
    setSelectedOrders([]);
    clearFilters(null);
  };

  const formatGatedText = (text: string) => (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="button" style={{ display: "flex" }}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <ProFeatureChip feature="all bulk actions" />
      </Grid>
    </Grid>
  );

  const upgradeTooltip = (
    <Typography variant="caption" className={classes.tooltipText}>
      <Link to="/store/plans" className={classes.upgradeTooltipLink}>
        Upgrade to Pro
      </Link>{" "}
      to unlock all bulk actions and many other features.
    </Typography>
  );

  const bulkActionOptions: BulkActionOption[] = [
    {
      label: "Send Message",
      icon: <MailOutline />,
      onClick: isStripeSetup ? onSendClick : handleStripeSetup,
    },
    {
      label: "Export Report",
      icon: <ListAlt />,
      onClick: openExportOrdersMenu,
    },
    ...(features.includes("admin.orders.actions.print")
      ? [
          {
            label: "Print",
            icon: <FaqsIcon className={classes.faqsIcon} />,
            onClick: printOrders,
          },
        ]
      : []),
    ...(features.includes("admin.orders.actions.multipleFulfillOrders")
      ? [
          {
            label: "Mark as Fulfilled",
            icon: <DriveEtaOutlinedIcon />,
            onClick: openOrderFulfilledModal,
          },
        ]
      : []),
  ];

  const bulkActionOptionToDropDownOption = (
    bulkActionOption: BulkActionOption
  ): DropDownOption => {
    const { label, icon, onClick, feature } = bulkActionOption;
    const hasFeatureAccess = features.includes(feature);
    if (
      !feature ||
      account.isCastironPlus() ||
      (hasFeatureAccess && !account.isInTrial())
    ) {
      return { label, icon, onClick };
    } else if (isSubscriptionPreviewEnabled) {
      return {
        label: formatGatedText(label),
        icon,
        onClick: hasFeatureAccess ? onClick : () => {},
        tooltipText: hasFeatureAccess ? null : upgradeTooltip,
        interactiveTooltip: true,
      };
    }
    return null;
  };

  const bulkActions: DropDownOption[] = [
    {
      label: (
        <Typography
          variant="body2"
          style={{ color: theme.branding.v2.plum[500] }}
        >
          {selectedOrders.length} Order{selectedOrders.length === 1 ? "" : "s"}{" "}
          Selected
        </Typography>
      ),
      onClick: () => {
        /* do nothing */
      },
    },
    ...bulkActionOptions.map(bulkActionOptionToDropDownOption),
  ];

  const filterPills = currentFilters.map((filter) => (
    <Grid item key={`filterPill-${filter}`}>
      <Chip
        className={classes.filterPill}
        colorScheme="fulfilled"
        id={filter}
        onClick={handleFilterPillChange}
      >
        <Typography variant="caption">{_.capitalize(filter)}</Typography>
        <Close className={classes.closeIcon} />
      </Chip>
    </Grid>
  ));

  const handleSearchTextChange = async (event) => {
    if (localStorageLoaded) {
      setPage(0);
      isMobile
        ? await searchForOrders(true, event.target.value)
        : searchForOrdersDesktop(0, event.target.value);
    }
  };

  const openAddProductModal = () => {
    dispatch(
      openModal({
        modalType: "ADD_PRODUCT_MODAL",
        modalProps: {
          show: true,
          context: "products",
        },
      })
    );
  };

  const tipsForYou: TipsForYou[] = [
    {
      icon: "cookie",
      title: "Add a Product",
      description: "Enable your customers to place an order online.",
      ctaAction: () => openAddProductModal(),
    },
    {
      icon: "megaphone",
      title: "Announce a Product",
      description: "Once a product is added, tell your customers about it!",
      ctaAction: () =>
        window
          .open("/marketing/single-send-email?type=new-product", "_self")
          .focus(),
    },
  ];

  const emptyOrders = () => (
    <Grid className={classes.resourceGuide}>
      <EmptyPage
        icon={<EmptyOrdersIcon className={classes.icon} />}
        title="No Orders to Display"
        description="Here are a few suggestions to help you get your first order
      with Nourysh in no time:"
        tipsForYou={tipsForYou}
        page="Orders"
      />
    </Grid>
  );

  return (
    <div>
      <Spinner label="Exporting your PDF" show={printing} size="fullscreen" />
      <Helmet>
        <title>{`Orders | ${shop ? shop.businessName : ""}`}</title>
      </Helmet>
      <Grid justify="center" container>
        {modal.modalType !== "CHANGE_PLANS_MODAL" && (
          <Spinner show={isLoading} />
        )}
        {isVariant && (
          <Grid item xs={12} className={classes.subscriptionBanner}>
            <SubscriptionBanner />
          </Grid>
        )}
        <>
          {transactions.length == 0 ? (
            emptyOrders()
          ) : (
            <Grid container direction="column">
              <Grid item style={{ marginBottom: 16 }}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="flex-start"
                  className={classes.filtersContainer}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify={isMobile ? "flex-start" : "center"}
                      wrap={isXsMobile ? "wrap" : "nowrap"}
                    >
                      <Grid item xs={12} sm={6} md={7}>
                        <SearchInput
                          initialValue={initialSearchValue}
                          onChange={handleSearchTextChange}
                          placeholder="Search orders..."
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={5}
                        style={
                          isXsMobile ? { marginTop: 4 } : { marginLeft: 4 }
                        }
                      >
                        {statusFilter}
                        {currentFilters.length > 0 && (
                          <Typography
                            variant="body1"
                            onClick={clearFilters}
                            className={classes.clearFilterText}
                          >
                            Clear
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {currentFilters.length > 0 && (
                      <Grid
                        container
                        item
                        spacing={1}
                        style={{ marginTop: 10 }}
                      >
                        {filterPills}
                      </Grid>
                    )}
                  </Grid>
                  {selectedOrders.length > 0 && (
                    <Grid item>
                      <Dropdown
                        title="Bulk Actions"
                        options={bulkActions}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {isMobile ? (
                  <MobileList
                    transactions={ordersFromES}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    retrieveMoreOrders={searchForOrders}
                    moreOrdersLeft={moreOrdersFromES}
                  />
                ) : (
                  <OrdersList
                    transactions={ordersFromES}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    total={totalTransactions}
                    retrieveOrders={searchForOrdersDesktop}
                    page={page}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </div>
  );
};

export default Orders;

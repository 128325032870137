import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, FormikProps, getIn } from "formik";
import Fuse from "fuse.js";
import levenshtein from "js-levenshtein";
import _ from "lodash";
import moment from "moment-timezone";
import * as yup from "yup";
import {
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {
  Banner,
  Button,
  CollapsableCard,
  DateInput,
  DeleteButton,
  ImageRadioOrCheckboxInput,
  SaveButton,
  SearchInput,
  TextInput,
  TimeInput,
  Typography,
} from "@castiron/components";
import {
  BaseProduct,
  ChecklistValues,
  Customer,
  Presale,
  ScheduledMessage,
  TicketedEvent,
  getImageUrl,
  getPresaleImageUrl,
} from "@castiron/domain";
import { useTracking, defaultTimeZone } from "@castiron/utils";
import {
  customerRepository,
  productRepository,
  scheduledMessagesRepository,
} from "../../../domain";
import { getService } from "../../../firebase";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { closeModal, openModal } from "../../../store/reducers/modalConductor";
import EmailPreview from "../../../assets/img/email-preview.png";
import AdminForm from "../../AdminForm";
import { DropDownOption } from "../../Dropdown";
import { LayoutPageProps } from "../../Layout";
import EllipsisMenu, { EllipsisMenuOption } from "../../Menus/EllipsisMenu";
import RichTextInput from "../../RichTextEditor";
import GeneralModal from "../../RootModal/GeneralModal";
import { ModalType } from "../../RootModal/RootModal";
import Spinner from "../../Spinner";
import UnsavedChangesPrompt from "../../UnsavedChangesPrompt.tsx";
import { Template, TemplateType, templates } from "./SingleSendEmailContent";
import RequireStripe from "../../RequireStripe";

type PresaleOrProduct = (Presale | BaseProduct) & {
  emailType: "product";
};

const sendProductAnnouncementService = getService(
  "products",
  "sendproductannouncementv2"
);
const sendProductAnnouncementTestService = getService(
  "products",
  "sendproductannouncementtestv2"
);

const sendEmailAnnouncementService = getService(
  "messaging",
  "sendemailannouncement"
);

const generateEmailService = getService("messaging", "generateemail");

const useStyles = makeStyles((theme: Theme) => ({
  addContacts: {
    color: "inherit",
    borderColor: theme.branding.v2.red[800],
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
  },
  banner: {
    maxWidth: "416px",
  },
  bodyTextContainer: {
    marginBottom: "45px !important",
    "& label": {
      fontFamily: "Nunito Sans,sans-serif",
      marginTop: 24,
    },
    "& .ql-editor": {
      border: `1px solid ${theme.branding.v2.gray[400]}`,
      borderBottom: "none",
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    "& .ql-toolbar": {
      border: `1px solid ${theme.branding.v2.gray[400]}`,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  bodyTextInput: {
    border: "none",
    "& div": {
      border: "none",
    },
  },
  cardContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "calc(100% - 32px)",
      minWidth: 0,
      paddingTop: "16px",
    },
    width: "100%",
  },
  emptySearch: {
    width: "100%",
    padding: "40px 0px 150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorBox: {
    marginBottom: 24,
    padding: "16px 18px",
    backgroundColor: theme.branding.v2.red[50],
    color: theme.branding.v2.red[500],
    display: "flex",
    flexDirection: "row",
    borderRadius: 12,
  },
  errorText: {
    color: "inherit",
    margin: "1px 0px 0px 10px",
  },
  infoIcon: {
    color: theme.branding.v2.gray[500],
    marginLeft: 6,
    height: 16,
    width: 16,
    display: "flex",
    alignContent: "center",
  },
  infoSend: {
    fontWeight: 700,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      color: theme.branding.v2.plum[900],
    },
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  modalPaperClass: {
    [theme.breakpoints.up("sm")]: {
      height: "fit-content",
    },
  },
  noArrow: {
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
  noContactsBanner: {
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      margin: "24px 16px 0px",
    },
  },
  previewImage: {
    minWidth: 0,
    maxWidth: "fit-content",
    minHeight: 0,
    maxHeight: "fit-content",
    marginBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  previewModalContainer: {
    background: "#F4F4F5", // match preview image background
  },
  previewModalHeader: {
    borderBottom: `1px solid ${theme.branding.v2.gray[300]}`,
  },
  radioLabel: {
    margin: 0,
    gap: "8px",
    "& .MuiRadio-root": {
      padding: 0,
    },
    "& .Mui-checked": {
      color: theme.branding.v2.plum[500],
    },
  },
  searchIcon: {
    height: 36,
    width: 36,
    marginBottom: 16,
    color: theme.branding.v2.gray[600],
  },
  sendText: {
    color: theme.branding.v2.plum[500],
    cursor: "pointer",
  },
  sendTestEmailText: {
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    padding: "12px 16px",
    color: theme.branding.v2.plum[500],
    backgroundColor: theme.branding.v2.gray[100],
    borderRadius: 12,
    position: "fixed",
    bottom: 24,
    [theme.breakpoints.down("xs")]: {
      bottom: 124,
      border: "none",
      boxShadow: `8px 8px 24px ${theme.branding.v2.gray[500]}`,
      color: theme.branding.v2.gray[100],
      backgroundColor: theme.branding.v2.gray[800],
    },
  },
  titleCutoff: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    wordBreak: "break-all",
  },
  select: {
    width: "100%",
    borderRadius: 12,
    marginTop: 4,
    cursor: "not-allowed",
  },
  sendOptionsContainer: {
    gap: "24px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%",
  },
}));

const SingleSendEmail: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const {
    setPageTitle,
    setBackLocation,
    setLongTitle,
    setOptFooterFormat,
    setHeaderCTAs,
    setFooterCTAs,
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const formikRef = useRef<FormikProps<any>>();
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { id: scheduledMessageId } = useParams<{ id: string }>();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const itemId = urlParams.get("itemId");
  const templateType: TemplateType = urlParams.get("type") as TemplateType;
  const emailTemplate: Template = templateType && templates[templateType];
  const showTicketedEventsOnly = !emailTemplate?.showStandardProducts && emailTemplate?.showTicketedEvents;

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop?.config?.timeZone || defaultTimeZone;

  const fuseOptions = {
    keys: ["title"],
    threshold: 0.5,
    useExtendedSearch: true,
  };

  // matches empty body text <p><br></p> or <p><br></p><p><br></p><p><br></p>... etc if multiple empty lines
  const emptyBodyTextRegex = /^(<p>(<br>){0,}<\/p>)+$/;
  const emptyTextRegex = /^\s*$/;

  const [scheduledMessage, setScheduledMessage] =
    useState<ScheduledMessage<any>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [sendingTest, setSendingTest] = useState(false);
  const [testEmailSent, setTestEmailSent] = useState(false);

  const [itemExpanded, setItemExpanded] = useState(
    templateType !== "sms-signup"
  );
  const [contentExpanded, setContentExpanded] = useState(
    templateType === "sms-signup"
  );
  const [sendDetailsExpanded, setSendDetailsExpanded] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showPreSendModal, setShowPreSendModal] = useState(false);

  const [items, setItems] = useState<PresaleOrProduct[]>([]);
  const [formattedItems, setFormattedItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [chosenItem, setChosenItem] = useState<PresaleOrProduct>();
  const [itemError, setItemError] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [contentError, setContentError] = useState(false);
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [sendScheduleType, setSendScheduleType] = useState(
    scheduledMessageId ? "schedule" : "now"
  );
  const [timeError, setTimeError] = useState(false);

  const [isGeneratingSubjectLine, setIsGeneratingSubjectLine] =
    useState<boolean>(false);
  const [hasGeneratedSubjectLine, setHasGeneratedSubjectLine] =
    useState<boolean>(false);
  const [isGeneratingBodyText, setIsGeneratingBodyText] =
    useState<boolean>(false);
  const [hasGeneratedBodyText, setHasGeneratedBodyText] =
    useState<boolean>(false);

  const getScheduledMesssage = async () => {
    if (scheduledMessageId) {
      const scheduledMessage = await scheduledMessagesRepository.get(
        scheduledMessageId
      );
      setScheduledMessage(scheduledMessage);
    }
  };

  const formatItems = (items: PresaleOrProduct[]) => {
    const radioOptions = items.map((item) => ({
      label: item.title,
      background:
        item.emailType === "product"
          ? getImageUrl(item, "mediumVersion")
          : getPresaleImageUrl(item as Presale, "mediumVersion"),
      value: item,
    }));
    setFormattedItems(radioOptions);
  };

  const getItems = async () => {
    if (templateType === "sms-signup") {
      setIsLoading(false);
      return;
    }

    let products = [];
    const getProducts = emailTemplate?.showStandardProducts || emailTemplate?.showTicketedEvents;
    if (shop && shop.id && getProducts) {
      let productsToShow =
        (await productRepository.findActive(shop.id, shop?.config?.timeZone)) ||
        [];
      if (emailTemplate?.eventTagFilter) {
        productsToShow = productsToShow?.filter((product) =>
          product?.eventTags?.includes(emailTemplate?.eventTagFilter)
        );
      }
      if (!emailTemplate?.showStandardProducts) {
        productsToShow = productsToShow?.filter(product => product.type !== 'standard');
      }
      if (!emailTemplate?.showTicketedEvents) {
        productsToShow = productsToShow?.filter(product => product.type !== 'event');
      }
      const orderedProducts = productsToShow
        .map((product) => ({
          ...product,
          lastEdited: product.updatedAt ? product.updatedAt : product.createdAt,
          emailType: "product",
        }))
        .sort((a, b) => {
          return b.lastEdited - a.lastEdited;
        });
      products = orderedProducts;
    }
    setItems(products);
    setIsLoading(false);
  };

  const getCustomers = async () => {
    if (shop && shop.id) {
      let subscribers = await customerRepository.findSubscribed(shop.id);
      if (templateType === "sms-signup") {
        subscribers = subscribers.filter(
          (sub) => !sub?.messagingPreferences?.sms?.enabled
        );
      }
      setCustomers(subscribers);
      setCustomersLoaded(true);
    }
  };

  const getSingleItem = (id: string) => {
    const chosenItem = items.find((item) => id === item.id);
    setChosenItem(chosenItem);
    return chosenItem;
  };

  useEffect(() => {
    getScheduledMesssage();
    getItems();
    getCustomers();
  }, [shop]);

  useEffect(() => {
    formatItems(items);
    if (itemId && templateType !== "sms-signup") {
      getSingleItem(itemId);
    } else if (scheduledMessage) {
      getSingleItem(
        scheduledMessage?.message?.content?.product || ""
      );
    }
  }, [items, scheduledMessage]);

  const testLabels = () => {
    if (sendingTest) {
      return <CircularProgress style={{ height: 24, width: 24 }} />;
    } else if (testEmailSent) {
      return "Test Email Sent!";
    } else if (!sendingTest && !testEmailSent) {
      return "Send a Test Email";
    }
  };

  const mobileDropdown = () => {
    const options: DropDownOption[] = [
      {
        label: "Preview Email",
        icon: <VisibilityOutlinedIcon />,
        onClick: previewModal,
      },
      {
        label: "Send a Test Email",
        icon: <SendOutlinedIcon />,
        onClick: sendTest,
      },
    ].map((a) => a as DropDownOption);
    const ellipsisOptions: EllipsisMenuOption[] = options.map((option) => ({
      display: option.label,
      color: option.color,
      icon: option.icon,
      action: option.onClick,
    }));

    return <EllipsisMenu options={ellipsisOptions} />;
  };

  useEffect(() => {
    setBackLocation("/marketing/email");
    setPageTitle(
      (isMobile && emailTemplate?.pageTitleMobile) ||
        emailTemplate?.pageTitle ||
        (scheduledMessage && "Edit Email") ||
        "New Email"
    );
    setLongTitle(true);

    isMobile
      ? setHeaderCTAs([
          mobileDropdown(),
          <ButtonBase onClick={previewModal}>
            <VisibilityOutlinedIcon />
          </ButtonBase>,
        ])
      : setHeaderCTAs([
          <Button
            variant="outlined"
            onClick={sendTest}
            style={{ minWidth: 190 }}
          >
            {testLabels()}
          </Button>,
        ]);

    return () => {
      setLongTitle(false);
      setPageTitle("");
      setBackLocation(false);
      setHeaderCTAs([]);
    };
  }, [isMobile, sendingTest, testEmailSent, scheduledMessage]);

  useEffect(() => {
    setOptFooterFormat("two-space-between");
    setFooterCTAs([
      scheduledMessageId ? (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(
              openModal({
                modalType: "DELETE_MARKETING_MODAL",
                modalProps: {
                  id: scheduledMessageId,
                  status: "scheduled",
                  context: "email",
                },
              })
            );
          }}
        >
          Delete
        </Button>
      ) : (
        <DeleteButton
          backLocation="/marketing/email"
          isSubmitting={submitting}
        />
      ),
      <RequireStripe>
        <SaveButton
          isSubmitting={submitting}
          handleSubmit={() => {
            setShowPreSendModal(true);
          }}
          customName={
            sendScheduleType === "schedule" ? "Schedule Email" : "Send Email"
          }
        />
      </RequireStripe>,
    ]);
    return () => {
      setOptFooterFormat("");
      setFooterCTAs([]);
    };
  }, [submitting, isXsMobile, customers, sendScheduleType]);

  const sendTest = async () => {
    if (!formikRef.current.values.itemId) {
      setItemError(true);
      !itemExpanded && setItemExpanded(true);
      closePreviewModal();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (
      formikRef.current.errors.subjectLine ||
      formikRef.current.errors.bodyText ||
      formikRef.current.errors.buttonText
    ) {
      setContentError(true);
      if (contentExpanded) {
        scrollToTop("content");
      } else {
        setContentExpanded(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      setSendingTest(true);
      setTestEmailSent(true);

      try {
        const currentValues = formikRef.current.values;
        if (currentValues.emailType === "product") {
          await sendProductAnnouncementTestService({
            product: currentValues.itemId,
            subjectLine: currentValues.subjectLine,
            bodyText: currentValues.bodyText,
            buttonText: currentValues.buttonText,
            isTest: true,
            utmCampaign:
              emailTemplate?.utmCampaign ||
              "",
          });
          trackEvent("Test Marketing Email Sent", {
            emailType: emailTemplate?.pageTitle || "Custom",
            product: currentValues.itemId,
          });
        } else if (currentValues.emailType === "sms-signup") {
          await sendEmailAnnouncementService({
            customerIds: [],
            subjectLine: currentValues.subjectLine,
            bodyText: currentValues.bodyText,
            buttonText: currentValues.buttonText,
            isTest: true,
            utmCampaign: "sms-signup",
          });
          trackEvent("Test Marketing Email Sent", {
            emailType: emailTemplate?.pageTitle || "Custom",
          });
        }
      } catch (err) {
        console.error("Error in Send Test Email: ", err);
      }
      setSendingTest(false);
      setTimeout(() => {
        setTestEmailSent(false);
      }, 2000);
    }
  };

  const handleSearchChange = (event: any) => {
    setEmptySearch(false);
    const fuse = new Fuse(items, fuseOptions);

    if (event.target.value === "") {
      formatItems(items);
    } else {
      const result = fuse.search(event.target.value);

      if (_.isEmpty(result)) {
        setEmptySearch(true);
        setSearchValue(event.target.value);
      } else {
        //get rid of extra formatting so we can reuse function
        const formattedSearch = [];
        result.forEach((o) => formattedSearch.push(o.item));
        formatItems(formattedSearch);
      }
    }
  };

  const scrollToTop = (containerId: string) => {
    const scrollTo = document.getElementById(`${containerId}Error`);

    const yOffset = -120;
    const y =
      scrollTo.getBoundingClientRect().top + window.pageYOffset + yOffset;

    // scrolls to the error within the scroll container, then scrolls to the element on the page
    scrollTo.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const onSubmit = async (values) => {
    setSubmitting(true);

    if (!values.itemId) {
      setItemError(true);
      !itemExpanded && setItemExpanded(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (
      formikRef.current.errors.subjectLine ||
      formikRef.current.errors.bodyText ||
      formikRef.current.errors.buttonText
    ) {
      setContentError(true);
      if (contentExpanded) {
        scrollToTop("content");
      } else {
        setContentExpanded(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else if (
      sendScheduleType === "schedule" &&
      values.sendTime <= moment().unix()
    ) {
      !sendDetailsExpanded &&
        document.getElementById("send").scrollIntoView({ behavior: "smooth" });
      setSendDetailsExpanded(true);
      setTimeError(true);
    } else {
      try {
        const isScheduledSend =
          sendScheduleType === "schedule" &&
          values?.sendTime &&
          values.sendTime >= moment().tz(timeZone).unix();
        if (scheduledMessage && !isScheduledSend) {
          await scheduledMessagesRepository.updateProps(scheduledMessageId, {
            status: "sent",
          });
        }
        const subscribersArr = customers.map((s) => s.id);
        const messageContent = {
          subjectLine: values.subjectLine,
          bodyText: values.bodyText,
          buttonText: values.buttonText,
          utmCampaign:
            values.emailType === "sms-signup"
              ? "sms-signup"
              : emailTemplate?.utmCampaign || "",
          ...(isScheduledSend && { sendAt: values.sendTime }),
          ...(isScheduledSend && { scheduledMessageId }),
          templateType,
        };

        if (values.emailType === "product") {
          await sendProductAnnouncementService({
            customerIds: subscribersArr,
            product: values.itemId,
            isTest: false,
            ...messageContent,
          });

          trackEvent("Marketing Email Sent", {
            emailType: emailTemplate?.pageTitle || "Custom",
            product: values.itemId,
            numberOfSubscribers: subscribersArr.length,
            sendTime: {
              type: isScheduledSend ? "scheduled" : "instant",
              time: isScheduledSend ? values.sendTime : moment().unix(),
            },
          });
        } else if (values.emailType === "sms-signup") {
          await sendEmailAnnouncementService({
            customerIds: subscribersArr,
            isTest: false,
            ...messageContent,
          });

          trackEvent("Marketing Email Sent", {
            emailType: emailTemplate?.pageTitle || "Custom",
            numberOfSubscribers: subscribersArr.length,
            sendTime: {
              type: isScheduledSend ? "scheduled" : "instant",
              time: isScheduledSend ? values.sendTime : moment().unix(),
            },
          });
        }
        if (values.generationIdSubjectLine) {
          let editDistance;
          try {
            editDistance = levenshtein(
              values.rawGeneratedSubjectLine,
              values.subjectLine
            );
          } catch (err) {
            console.error("Error AI Subject Line Calc: ", err);
          }
          trackEvent("AI Generated Content Result", {
            generationId: values.generationIdSubjectLine,
            editDistance,
            type: `email-${values.emailType}-subjectLine`,
          });
        }
        if (values.generationIdBodyText) {
          let editDistance;
          try {
            editDistance = levenshtein(
              values.rawGeneratedgenerationIdBodyText,
              values.bodyText
            );
          } catch (err) {
            console.error("Error AI Body Text Calc: ", err);
          }
          trackEvent("AI Generated Content Result", {
            generationId: values.generationIdBodyText,
            editDistance,
            type: `email-${values.emailType}-bodyText`,
          });
        }
        openPostSendModal(isScheduledSend, values.sendTime);
        if (
          !shop?.checklistCompletions?.includes(
            ChecklistValues.EmailMarketingSingleSend
          )
        ) {
          shop?.addToChecklist(ChecklistValues.EmailMarketingSingleSend);
        }
        formikRef.current.resetForm();
      } catch (err) {
        console.error("Error in Submit Email Send: ", err);
      }
    }
    setSubmitting(false);
  };

  const formSchema = yup.object().shape({
    itemId: yup.string().required("Please select an item."),
    emailType: yup.string().oneOf(["product", "sms-signup"]),
    subjectLine: yup
      .string()
      .required()
      .test((value) => !emptyTextRegex.test(value)),
    bodyText: yup
      .string()
      .required()
      .test((value) => !emptyBodyTextRegex.test(value)),
    buttonText: yup
      .string()
      .required()
      .test((value) => !emptyTextRegex.test(value)),
    generationIdSubjectLine: yup.string().nullable(),
    rawGeneratedSubjectLine: yup.string().nullable(),
    generationIdBodyText: yup.string().nullable(),
    rawGeneratedBodyText: yup.string().nullable(),
    sendTime: yup
      .number()
      .nullable()
      .moreThan(
        moment().tz(timeZone).subtract(5, "minutes").unix(),
        "Please select a time in the future."
      ),
  });

  const initialValues = {
    itemId: scheduledMessage
      ? scheduledMessage?.message?.content?.product ||
        ""
      : templateType === "sms-signup"
      ? "sms-signup"
      : chosenItem?.id || "",
    emailType: scheduledMessage?.message?.content?.emailType
      ? scheduledMessage?.message?.content?.emailType
      : templateType === "sms-signup"
      ? "sms-signup"
      : chosenItem?.emailType || "",
    subjectLine: scheduledMessage?.message?.content?.subjectLine
      ? scheduledMessage?.message?.content?.subjectLine
      : emailTemplate?.subjectLine || "",
    bodyText: scheduledMessage?.message?.content?.bodyText
      ? scheduledMessage?.message?.content?.bodyText
      : emailTemplate?.bodyText || "",
    buttonText: scheduledMessage?.message?.content?.buttonText
      ? scheduledMessage?.message?.content?.buttonText
      : emailTemplate?.buttonText || "",
    generationIdSubjectLine: "",
    rawGeneratedSubjectLine: "",
    generationIdBodyText: "",
    rawGeneratedBodyText: "",
    sendTime: scheduledMessage
      ? scheduledMessage.sendAt
      : moment().tz(timeZone).unix(),
  };

  const previewModal = () => {
    setOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const emailPreview = (
    <>
      <img src={EmailPreview} className={classes.previewImage} />
      <Banner variant="info-white" className={classes.banner}>
        <Typography variant="body2" style={{ color: "inherit" }}>
          This preview is for your reference only. Curious what customers see?
          {isMobile ? <br /> : " "}
          <span onClick={sendTest} className={classes.infoSend}>
            Send yourself a test email
          </span>
        </Typography>
      </Banner>
    </>
  );

  const audienceOptions = [
    {
      label: `${
        templateType === "sms-signup"
          ? "Not An SMS Subscriber"
          : "All Subscribers"
      } (${customers.length})`,
      value: "all",
    },
  ];

  const renderPreSendModal = (values) => {
    const isScheduledSend =
      sendScheduleType === "schedule" &&
      values?.sendTime &&
      values.sendTime >= moment().tz(timeZone).unix();

    return (
      <GeneralModal
        paperClass={classes.modalPaperClass}
        icon={templateType === "sms-signup" ? "💬" : "💌"}
        title={
          isScheduledSend
            ? `Your email is scheduled to be delivered on ${moment
                .unix(values.sendTime)
                .tz(timeZone)
                .format("l [at] h:mm A")}`
            : `Ready to send this email to ${customers.length} subscriber${
                customers.length !== 1 ? "s" : ""
              }?`
        }
        content={
          <>
            <Typography variant="body1">
              Prefer to preview your email before sending it to your subscriber
              {customers.length !== 1 ? "s" : ""}?{isXsMobile ? <br /> : " "}
              <span onClick={sendTest} className={classes.sendText}>
                Send a Test Email
              </span>
            </Typography>
            {testEmailSent && (
              <Typography
                variant="button"
                component="p"
                className={classes.sendTestEmailText}
              >
                {testLabels()}
              </Typography>
            )}
          </>
        }
        actions={[
          <Button
            variant="outlined"
            onClick={() => {
              setShowPreSendModal(false);
            }}
          >
            Continue Editing
          </Button>,
          <RequireStripe>
            <Button
              variant="contained"
              onClick={() => {
                setShowPreSendModal(false);
                onSubmit(formikRef.current.values);
              }}
            >
              {isScheduledSend ? "Schedule Email" : "Send Email"}
            </Button>
          </RequireStripe>,
        ]}
        onClose={() => {
          setShowPreSendModal(false);
        }}
        show={showPreSendModal}
      />
    );
  };

  const getPostSendModalContent = (
    isScheduledSend: boolean,
    sendTime: number
  ) => {
    if (emailTemplate?.eventName) {
      return (
        <>
          You've successfully {isScheduledSend ? "scheduled" : "shared"} your{" "}
          {emailTemplate?.eventName} offerings with{" "}
          <b>
            {customers.length} subscriber{customers.length !== 1 ? "s" : ""}
          </b>
          .
        </>
      );
    } else {
      return (
        <>
          <b>
            {customers.length} subscriber{customers.length !== 1 ? "s" : ""}
          </b>{" "}
          {isScheduledSend
            ? "will soon be"
            : customers.length !== 1
            ? "are now"
            : "is now"}{" "}
          aware of your{" "}
          {templateType === "new-product"
            ? "latest product"
            : templateType === "sms-signup"
            ? "shop sign up page"
            : showTicketedEventsOnly
            ? "event"
            : "item"}
          .
        </>
      );
    }
  };

  const openPostSendModal = (isScheduledSend: boolean, sendTime: number) =>
    dispatch(
      openModal({
        modalType: "GENERAL_MODAL" as ModalType,
        modalProps: {
          paperClass: classes.modalPaperClass,
          icon: "🎉",
          title: `${emailTemplate?.postSendModalTitlePrefix || "Email"} ${
            isScheduledSend ? "Scheduled!" : "Sent!"
          }`,
          content: (
            <Grid
              container
              item
              direction="column"
              wrap="nowrap"
              justify="flex-start"
            >
              <Typography variant="body1">
                {getPostSendModalContent(isScheduledSend, sendTime)}
              </Typography>
            </Grid>
          ),
          actions: [
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(closeModal());
                window.scrollTo(0, 0);
                setItemExpanded(true);
                setContentExpanded(false);
                setSendDetailsExpanded(false);
              }}
            >
              Create Another Email
            </Button>,
            <Button
              variant="contained"
              onClick={() => {
                dispatch(closeModal());
                history.push("/marketing/email");
              }}
            >
              Close
            </Button>,
          ],
          show: true,
        },
      })
    );

  const getEmptyItemsBanner = () => {
    let content;
    if (emailTemplate?.eventTagFilter) {
      content = (
        <>
          Please tag an active{" "}
          <span
            onClick={() => history.push("/products")}
            className={classes.infoSend}
          >
            product
          </span>{" "}
          with your event to continue.
        </>
      );
    } else if (templateType === "new-product") {
      content = (
        <>
          Please create an active{" "}
          <span
            onClick={() => history.push("/products")}
            className={classes.infoSend}
          >
            product
          </span>{" "}
          to continue.
        </>
      );
    } else if (showTicketedEventsOnly) {
      content = (
        <>
          Please create an active{" "}
          <span
            onClick={() => history.push("/events")}
            className={classes.infoSend}
          >
            event
          </span>{" "}
          to continue.
        </>
      );
    } else {
      content = <>No items available.</>;
    }
    return (
      <Banner variant="info-plum">
        <Typography variant="body2" style={{ color: "inherit" }}>
          {content}
        </Typography>
      </Banner>
    );
  };

  const generateEmailContent = async (
    contentType: "subjectLine" | "bodyText"
  ) => {
    if (formikRef?.current?.values?.emailType) {
      if (contentType === "subjectLine") {
        setIsGeneratingSubjectLine(true);
      } else {
        setIsGeneratingBodyText(true);
      }

      const response = await generateEmailService({
        contentType,
        itemType: formikRef?.current?.values?.emailType,
        itemId: formikRef?.current?.values?.itemId,
        eventName: emailTemplate?.eventName,
      });
      if (contentType === "subjectLine") {
        setHasGeneratedSubjectLine(true);
        await formikRef?.current?.setFieldValue(
          "generationIdSubjectLine",
          response.generationId
        );
        await formikRef?.current?.setFieldValue(
          "rawGeneratedSubjectLine",
          response.emailContent
        );
      } else {
        setHasGeneratedBodyText(true);
        await formikRef?.current?.setFieldValue(
          "generationIdBodyText",
          response.generationId
        );
        await formikRef?.current?.setFieldValue(
          "rawGeneratedBodyText",
          response.emailContent
        );
      }
      await formikRef?.current?.setFieldValue(
        contentType,
        response.emailContent
      );

      if (contentType === "subjectLine") {
        setIsGeneratingSubjectLine(false);
      } else {
        setIsGeneratingBodyText(false);
      }
    } else {
      dispatch(
        openModal({
          modalType: "SIMPLE_ALERT",
          modalProps: {
            show: true,
            buttonText: "Close",
            content: (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ margin: "15px 0px" }}
              >
                <Typography variant="body1">
                  Select a Product to Write with AI.
                </Typography>
              </Grid>
            ),
            center: true,
          },
        })
      );
    }
  };

  const writeItForMe = (contentType: "subjectLine" | "bodyText") => (
    <Button
      loading={
        (contentType === "subjectLine" && isGeneratingSubjectLine) ||
        (contentType === "bodyText" && isGeneratingBodyText)
      }
      disabled={
        (contentType === "subjectLine" && isGeneratingSubjectLine) ||
        (contentType === "bodyText" && isGeneratingBodyText)
      }
      variant="text"
      onClick={() => generateEmailContent(contentType)}
      style={{ padding: 0 }}
    >
      ✨{" "}
      {contentType === "subjectLine" && hasGeneratedSubjectLine
        ? "Try Writing Again"
        : contentType === "bodyText" && hasGeneratedBodyText
        ? "Try Writing Again"
        : "Write with AI"}
    </Button>
  );

  const handleDayChange = async (
    day: moment.Moment | null,
    values,
    setFieldValue
  ) => {
    if (day && day.isValid()) {
      const newTime = moment.unix(values?.sendTime);
      newTime.set({
        year: day.get("year"),
        month: day.get("month"),
        date: day.get("date"),
      });

      await setFieldValue("sendTime", newTime.tz(timeZone, true).unix());
    }
  };

  const handleTimeChange = async (
    time: moment.Moment | null,
    values,
    setFieldValue
  ) => {
    if (time && time.isValid()) {
      setTimeError(false);
      const newTime = moment.unix(values?.sendTime);
      newTime.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: 0,
        millisecond: 0,
      });

      await setFieldValue("sendTime", newTime.tz(timeZone, true).unix());
    }
  };

  return isLoading ? (
    <Spinner size="fullscreen" show={true} />
  ) : (
    <>
      <Formik
        onSubmit={onSubmit}
        validationSchema={formSchema}
        initialValues={initialValues}
        innerRef={formikRef}
      >
        {({ dirty, values, setFieldValue, errors }): ReactElement => {
          return (
            <AdminForm>
              <UnsavedChangesPrompt when={dirty} />
              <Grid
                container
                spacing={!isMobile && 3}
                className={classes.innerContainer}
              >
                <Grid container item xs={12} md={7} justify="center">
                  {customersLoaded && customers.length === 0 && (
                    <Banner
                      variant="error"
                      className={classes.noContactsBanner}
                      children={
                        <Grid
                          container
                          item
                          justify="space-between"
                          direction="row"
                          alignItems="flex-start"
                        >
                          <Grid container item xs={8}>
                            <Typography
                              variant="body2"
                              style={{ color: "inherit" }}
                            >
                              Currently, no one will receive this email. To
                              continue, add contacts who’ve opted in to receive
                              marketing offers.
                            </Typography>
                          </Grid>
                          <Grid container item xs={4} justify="flex-end">
                            <Button
                              variant="outlined"
                              className={classes.addContacts}
                              onClick={() => history.push("/contacts/add")}
                              color="inherit"
                            >
                              <Typography
                                variant="button"
                                style={{ color: "inherit" }}
                              >
                                Add Contacts
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    />
                  )}
                  <Grid container className={classes.cardContainer}>
                    {templateType !== "sms-signup" && (
                      <CollapsableCard
                        cta={
                          <Button
                            onClick={() => {
                              if (_.isEmpty(values.itemId)) {
                                scrollToTop("item");
                                setItemError(true);
                              } else {
                                setItemExpanded(false);
                                setContentExpanded(true);
                              }
                            }}
                            variant="contained"
                            fullWidth
                            disabled={_.isEmpty(items)}
                          >
                            Next Step: Content
                          </Button>
                        }
                        expanded={itemExpanded}
                        handleExpand={() => {
                          setItemExpanded(!itemExpanded);
                        }}
                        id="item"
                        supertitle="STEP 1/3"
                        title={
                          <Grid className={classes.title} item xs={12}>
                            <Typography
                              variant="subtitle1"
                              className={classes.titleCutoff}
                            >
                              {!_.isEmpty(values.itemId)
                                ? `${
                                    emailTemplate?.step1TitleSelectedPrefix ||
                                    "Announcing"
                                  }: ${chosenItem?.title}`
                                : emailTemplate?.step1TitleUnselected ||
                                  "Featured Item"}
                            </Typography>
                            <Tooltip
                              title={
                                <Typography
                                  variant="body2"
                                  style={{ color: theme.branding.v2.gray[100] }}
                                >
                                  We'll include the item link and its photo in
                                  the email.
                                </Typography>
                              }
                            >
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </Tooltip>
                          </Grid>
                        }
                      >
                        <div id="itemError">
                          {itemError && (
                            <Grid className={classes.errorBox}>
                              <ErrorOutlineIcon />
                              <Typography
                                variant="body2"
                                className={classes.errorText}
                              >
                                Please select an item to continue.
                              </Typography>
                            </Grid>
                          )}
                        </div>
                        {_.isEmpty(items) ? (
                          getEmptyItemsBanner()
                        ) : (
                          <>
                            <Grid style={{ marginBottom: 8 }}>
                              <SearchInput onChange={handleSearchChange} />
                            </Grid>
                            {emptySearch ? (
                              <Grid container className={classes.emptySearch}>
                                <SearchOutlinedIcon
                                  className={classes.searchIcon}
                                />
                                <Typography variant="body3">
                                  No results for{" "}
                                  <span style={{ fontWeight: 700 }}>
                                    "{searchValue}"
                                  </span>
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid container item>
                                <ImageRadioOrCheckboxInput
                                  options={formattedItems}
                                  onChange={(value) => {
                                    setItemError(false);
                                    const item = getSingleItem(value);
                                    setFieldValue("itemId", value);
                                    setFieldValue("emailType", item?.emailType);
                                  }}
                                  value={getIn(values, "itemId")}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      </CollapsableCard>
                    )}

                    <CollapsableCard
                      cta={
                        <Button
                          onClick={() => {
                            if (
                              errors.subjectLine ||
                              errors.bodyText ||
                              errors.buttonText
                            ) {
                              scrollToTop("content");
                              setContentError(true);
                            } else {
                              setContentExpanded(false);
                              document.getElementById("send").scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                              setSendDetailsExpanded(true);
                            }
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Next Step: Send Details
                        </Button>
                      }
                      expanded={contentExpanded}
                      handleExpand={() => {
                        setContentExpanded(!contentExpanded);
                      }}
                      id="content"
                      noScroll={isMobile}
                      supertitle={
                        templateType === "sms-signup" ? "STEP 1/2" : "STEP 2/3"
                      }
                      title={
                        <Grid className={classes.title}>
                          <Typography variant="subtitle1">Content</Typography>
                          <Tooltip
                            title={
                              <Typography
                                variant="body2"
                                style={{ color: theme.branding.v2.gray[100] }}
                              >
                                Customize the content of your email.
                              </Typography>
                            }
                          >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                          </Tooltip>
                        </Grid>
                      }
                    >
                      <div id="contentError">
                        {contentError && (
                          <Grid className={classes.errorBox}>
                            <ErrorOutlineIcon />
                            <Typography
                              variant="body2"
                              className={classes.errorText}
                            >
                              Please enter the following to continue:{" "}
                              {[
                                errors.subjectLine && "Subject Line",
                                errors.bodyText && "Body Text",
                                errors.buttonText && "Button Text",
                              ]
                                .filter((error) => error)
                                .join(", ")}
                              .
                            </Typography>
                          </Grid>
                        )}
                      </div>
                      <Grid style={{ marginBottom: "3px" }}>
                        <TextInput
                          label={
                            <Grid container justify="space-between">
                              <Typography
                                variant="subtitle2"
                                style={{ width: "fit-content" }}
                              >
                                Subject Line
                              </Typography>
                              {writeItForMe("subjectLine")}
                            </Grid>
                          }
                          name="subjectLine"
                          onChange={(e) => {
                            setContentError(false);
                            setFieldValue("subjectLine", e.target.value);
                          }}
                        >
                          {values.subjectLine}
                        </TextInput>
                        <Typography variant="caption">
                          The main header of your email.
                        </Typography>
                      </Grid>
                      <Grid style={{ marginBottom: "24px" }}>
                        <RichTextInput
                          className={classes.bodyTextContainer}
                          editorClassName={classes.bodyTextInput}
                          height={103}
                          label={
                            <Grid container justify="space-between">
                              <Typography
                                variant="subtitle2"
                                style={{ width: "fit-content" }}
                              >
                                Body Text
                              </Typography>
                              {writeItForMe("bodyText")}
                            </Grid>
                          }
                          name="bodyText"
                          onChange={(value) => {
                            setContentError(false);
                            setFieldValue("bodyText", value);
                          }}
                          placeholder=""
                        />
                        <Typography variant="caption">
                          The main message of your email. We recommend keeping
                          this short and concise.
                        </Typography>
                      </Grid>
                      <Grid>
                        <TextInput
                          label="Button Text"
                          name="buttonText"
                          onChange={(e) => {
                            setContentError(false);
                            setFieldValue("buttonText", e.target.value);
                          }}
                        >
                          {values.buttonText}
                        </TextInput>
                        <Typography variant="caption">
                          This button links directly to{" "}
                          {templateType === "sms-signup"
                            ? "your shop signup page"
                            : "this item in your shop"}
                          .
                        </Typography>
                      </Grid>
                    </CollapsableCard>

                    <CollapsableCard
                      expanded={sendDetailsExpanded}
                      handleExpand={() => {
                        !sendDetailsExpanded &&
                          document
                            .getElementById("send")
                            .scrollIntoView({ behavior: "smooth" });
                        setSendDetailsExpanded(!sendDetailsExpanded);
                      }}
                      id="send"
                      noScroll
                      supertitle={
                        templateType === "sms-signup" ? "STEP 2/2" : "STEP 3/3"
                      }
                      title={
                        <Grid className={classes.title}>
                          <Typography variant="subtitle1">
                            Send Details
                          </Typography>
                          <Tooltip
                            title={
                              <Typography
                                variant="body2"
                                style={{ color: theme.branding.v2.gray[100] }}
                              >
                                Select when to send and who should receive this
                                email.
                              </Typography>
                            }
                          >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                          </Tooltip>
                        </Grid>
                      }
                    >
                      <div id="timeError">
                        {timeError && (
                          <Grid className={classes.errorBox}>
                            <ErrorOutlineIcon />
                            <Typography
                              variant="body2"
                              className={classes.errorText}
                            >
                              Please select a time in the future to schedule
                              your text, or select "Send Now".
                            </Typography>
                          </Grid>
                        )}
                      </div>
                      {templateType === "sms-signup" && (
                        <Banner variant="info-white">
                          <Typography variant="button">
                            This email will only send to email subscribers who
                            are not already an SMS subscriber.
                          </Typography>
                        </Banner>
                      )}
                      <Grid
                        xs={12}
                        container
                        item
                        className={classes.sendOptionsContainer}
                      >
                        <Grid container item direction="column">
                          <Typography variant="subtitle2">Audience</Typography>
                          <Select
                            className={`${classes.noArrow} ${classes.select}`}
                            disabled
                            variant="outlined"
                            defaultValue="all"
                          >
                            {audienceOptions.map((option, index) => (
                              <MenuItem value={option.value} key={index}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid
                          container
                          item
                          direction="column"
                          style={{ gap: "12px" }}
                        >
                          <Typography variant="subtitle2">Send Time</Typography>
                          <FormControl
                            component="fieldset"
                            style={{ margin: 0 }}
                          >
                            <RadioGroup
                              value={sendScheduleType}
                              onChange={(e) =>
                                setSendScheduleType(e.target.value)
                              }
                              style={{ gap: "24px" }}
                            >
                              <FormControlLabel
                                value="now"
                                control={<Radio />}
                                label={
                                  <Typography variant="body1">
                                    Send Now
                                  </Typography>
                                }
                                className={classes.radioLabel}
                              />
                              <FormControlLabel
                                value="schedule"
                                control={<Radio />}
                                label={
                                  <Typography variant="body1">
                                    Schedule Send
                                  </Typography>
                                }
                                className={classes.radioLabel}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {sendScheduleType === "schedule" && (
                          <Grid
                            container
                            direction={isMobile ? "column" : "row"}
                            wrap="nowrap"
                            style={{ gap: "16px" }}
                          >
                            <DateInput
                              disablePast
                              label="Delivery Date"
                              selectedDate={moment.unix(values?.sendTime)}
                              onChange={(date) =>
                                handleDayChange(date, values, setFieldValue)
                              }
                              required
                            />
                            <TimeInput
                              label="Delivery Time"
                              name="sendTime"
                              required
                              onChange={(time) =>
                                handleTimeChange(time, values, setFieldValue)
                              }
                              timeZone={timeZone}
                              addErrorStyling={!!errors.sendTime}
                              error={!!errors.sendTime && errors.sendTime}
                            />
                          </Grid>
                        )}
                        <Banner variant="info-plum">
                          <Typography
                            variant="body2"
                            style={{ color: "inherit" }}
                          >
                            Curious what customers see? 
                            <span
                              className={classes.infoSend}
                              onClick={sendTest}
                            >
                              Send yourself a test email.
                            </span>
                          </Typography>
                        </Banner>
                      </Grid>
                    </CollapsableCard>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid container item md={5}>
                    {emailPreview}
                  </Grid>
                )}
              </Grid>
              {renderPreSendModal(values)}
            </AdminForm>
          );
        }}
      </Formik>
      <Dialog
        fullScreen={true}
        open={openPreviewModal}
        onClose={closePreviewModal}
      >
        <DialogTitle className={classes.previewModalHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h2">Email Preview</Typography>
            <IconButton onClick={closePreviewModal}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.previewModalContainer}>
          <Grid container direction="column" alignItems="center">
            {emailPreview}
            {testEmailSent && (
              <Typography
                variant="button"
                component="p"
                className={classes.sendTestEmailText}
              >
                {testLabels()}
              </Typography>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SingleSendEmail;

import { Banner } from "@castiron/components";
import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  dueToday: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    padding: 16,
  },
  label: {
    marginBottom: 4,
  },
}));

const Review: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { values }: any = useFormikContext();

  return (
    <Grid container>
      <Banner noIcon variant="info-plum">
        <Typography style={{ color: "inherit" }} variant="body2">
          <b>NOTE</b>: Review your information carefully before confirming!
        </Typography>
      </Banner>
      <Grid
        container
        direction="column"
        item
        style={{ gap: 16, marginTop: 24 }}
      >
        <Grid item>
          <Typography className={classes.label} variant="subtitle2">
            Domain
          </Typography>
          <Typography style={{ wordBreak: "break-word" }} variant="body1">
            {values?.domain}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.label} variant="subtitle2">
            Full Name
          </Typography>
          <Typography variant="body1">{values?.fullName}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.label} variant="subtitle2">
            Address
          </Typography>
          <Typography variant="body1">
            {values?.address?.fullAddress}
          </Typography>
          {values?.address?.addressLine2 && (
            <Typography variant="body1">
              {values?.address?.addressLine2}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Typography className={classes.label} variant="subtitle2">
            Auto-Renew
          </Typography>
          <Typography variant="body1">
            {values?.autoRenew ? "On" : "Off"}
          </Typography>
        </Grid>
        <Grid className={classes.dueToday} container direction="column" item>
          <Typography variant="subtitle2">Due Today</Typography>
          <Typography style={{ margin: "4px 0px 8px 0px" }} variant="body1">
            $0
          </Typography>
          <Typography
            style={{ color: theme.branding.v2.plum[500] }}
            variant="body1"
          >
            Grow your business with a custom domain <b>FREE</b> for the first
            year.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Review;

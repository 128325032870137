import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ReactElement } from "react";

export type ModalChoiceIconColor = "blue" | "green";
interface Props {
  icon: ReactElement;
  color: ModalChoiceIconColor;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    height: "40px",
    width: "40px",
    borderRadius: "12px",
    backgroundColor: (props: Props) => {
      switch (props.color) {
        case "blue":
          return theme.branding.v2.blue[50];
        case "green":
          return theme.branding.green.light;
        default:
          return theme.branding.gray[100];
      }
    },
  },
  icon: {
    height: "20px",
    width: "20px",
    position: "relative",
    /* no idea why 10 pushes this down off center, but 6 works :shrug: */
    top: "6px",
    left: "10px",
    color: (props: Props) => {
      switch (props.color) {
        case "blue":
          return theme.branding.v2.plum[500];
        case "green":
          return theme.branding.green.primary;
        default:
          return theme.branding.gray[800];
      }
    },
  },
}));

const ModalChoiceIcon: React.FC<Props> = (props: Props) => {
  const { icon } = props;
  const classes = useStyles(props);

  const styledIcon = React.cloneElement(icon, { className: classes.icon });

  return <Box className={classes.container}>{styledIcon}</Box>;
};

export default ModalChoiceIcon;

import React, { useEffect, useRef } from "react";

import { useFormikContext } from "formik";
import {
  Button,
  fade,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import {
  SvgIcon,
  Text,
  Typography,
  HouseBadge,
  WomanBadge,
  FoodEstablishmentBadge,
  SinkBadge,
  ShieldBadge,
  SilverwareBadge,
  GlutenBadge,
  PlantBadge,
  FriendlyBadge,
  HeartBadge,
  AwardBadge,
} from "@castiron/components";
import { FeatureFlag } from "@castiron/castiron-firebase";

const useStyles = makeStyles((theme: Theme) => ({
  awardContainer: {
    // TODO: Replace with new theme color
    backgroundColor: "#F5F9FF",
    padding: 16,
    borderRadius: 16,
  },
  outterContainer: {
    marginBottom: "16px",
  },
  container: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[700]}3D`,
    padding: 24,
  },
  label: {
    marginBottom: 8,
  },
  badgesContainer: {
    gap: 8,
  },
  badge: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    border: `1px solid ${theme.branding.gray[700]}3D`,
    borderRadius: "28px",
  },
  selected: {
    border: `1px solid ${theme.branding.v2.plum[500]}`,
    backgroundColor: fade(theme.branding.v2.plum[500], 0.1),
  },
  sectionContainer: {
    marginBottom: 24,
  },
  icon: {
    marginRight: 4,
  },
}));

interface Props {
  focus?: boolean;
  currentBadges?: {
    certifications?: string[];
    made?: string;
    more?: string[];
    specialDiets?: string[];
    award?: string;
  };
}

const Badges = (props: Props) => {
  const { focus, currentBadges } = props;
  const { setFieldValue, values }: any = useFormikContext();

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (focus) ref.current?.scrollIntoView();
  }, [focus]);

  const classes = useStyles();
  const theme = useTheme();

  const badgeSections = [
    {
      type: "made",
      label: "Where are your products made?",
      badges: [
        {
          value: "home",
          svg: <HouseBadge />,
          label: "Made in a Home Kitchen",
          onClick: () =>
            setFieldValue(
              "madeBadge",
              values.madeBadge === "home" ? "" : "home"
            ),
          selected: values.madeBadge === "home",
        },
        {
          value: "commercial",
          svg: <SinkBadge />,
          label: "Made in a Commercial Kitchen",
          onClick: () =>
            setFieldValue(
              "madeBadge",
              values.madeBadge === "commercial" ? "" : "commercial"
            ),
          selected: values.madeBadge === "commercial",
        },
      ],
    },
    {
      type: "certifications",
      label: "Do you have any certifications or licenses?",
      badges: [
        {
          value: "licensed-cotttage",
          svg: <ShieldBadge />,
          label: "Licensed Cottage Food Operator",
          onClick: () =>
            setFieldValue(
              "certificationsBadges",
              values.certificationsBadges.includes("licensed-cotttage")
                ? values.certificationsBadges.filter(
                    (b) => b !== "licensed-cotttage"
                  )
                : [...values.certificationsBadges, "licensed-cotttage"]
            ),
          selected: values.certificationsBadges.includes("licensed-cotttage"),
        },
        {
          value: "licensed-establishment",
          svg: <FoodEstablishmentBadge />,
          label: "Licensed Food Establishment Operator",
          onClick: () =>
            setFieldValue(
              "certificationsBadges",
              values.certificationsBadges.includes("licensed-establishment")
                ? values.certificationsBadges.filter(
                    (b) => b !== "licensed-establishment"
                  )
                : [...values.certificationsBadges, "licensed-establishment"]
            ),
          selected: values.certificationsBadges.includes(
            "licensed-establishment"
          ),
        },
        {
          value: "certified-food-handler",
          svg: <SilverwareBadge />,
          label: "Certified Food Handler",
          onClick: () =>
            setFieldValue(
              "certificationsBadges",
              values.certificationsBadges.includes("certified-food-handler")
                ? values.certificationsBadges.filter(
                    (b) => b !== "certified-food-handler"
                  )
                : [...values.certificationsBadges, "certified-food-handler"]
            ),
          selected: values.certificationsBadges.includes(
            "certified-food-handler"
          ),
        },
      ],
    },
    {
      type: "special diets",
      label: "Do you cater to special diets?",
      badges: [
        {
          value: "gluten-free",
          svg: <GlutenBadge />,
          label: "Gluten-Free Kitchen",
          onClick: () =>
            setFieldValue(
              "specialDietsBadges",
              values.specialDietsBadges.includes("gluten-free")
                ? values.specialDietsBadges.filter((b) => b !== "gluten-free")
                : [...values.specialDietsBadges, "gluten-free"]
            ),
          selected: values.specialDietsBadges.includes("gluten-free"),
        },
        {
          value: "allergen-friendly",
          svg: <FriendlyBadge />,
          label: "Allergen-Friendly Kitchen",
          onClick: () =>
            setFieldValue(
              "specialDietsBadges",
              values.specialDietsBadges.includes("allergen-friendly")
                ? values.specialDietsBadges.filter(
                    (b) => b !== "allergen-friendly"
                  )
                : [...values.specialDietsBadges, "allergen-friendly"]
            ),
          selected: values.specialDietsBadges.includes("allergen-friendly"),
        },
        {
          value: "plant-based",
          svg: <PlantBadge />,
          label: "Plant-Based Kitchen",
          onClick: () =>
            setFieldValue(
              "specialDietsBadges",
              values.specialDietsBadges.includes("plant-based")
                ? values.specialDietsBadges.filter((b) => b !== "plant-based")
                : [...values.specialDietsBadges, "plant-based"]
            ),
          selected: values.specialDietsBadges.includes("plant-based"),
        },
      ],
    },
    {
      type: "more",
      label: "Share more about your business",
      badges: [
        {
          value: "minority-owned",
          svg: <HeartBadge />,
          label: "Minority-Owned Business",
          onClick: () =>
            setFieldValue(
              "moreBadges",
              values.moreBadges.includes("minority-owned")
                ? values.moreBadges.filter((b) => b !== "minority-owned")
                : [...values.moreBadges, "minority-owned"]
            ),
          selected: values.moreBadges.includes("minority-owned"),
        },
        {
          value: "woman-owned",
          svg: <WomanBadge />,
          label: "Woman-Owned Business",
          onClick: () =>
            setFieldValue(
              "moreBadges",
              values.moreBadges.includes("woman-owned")
                ? values.moreBadges.filter((b) => b !== "woman-owned")
                : [...values.moreBadges, "woman-owned"]
            ),
          selected: values.moreBadges.includes("woman-owned"),
        },
      ],
    },
    {
      type: "awards",
      label: "🏆 Congrats on being a 2022 Food Entrepreneur Award winner!",
      badges: [
        {
          value: "2022-food-entrepreneur",
          svg: <AwardBadge />,
          label: "2022 Food Entrepreneur Award Winner",
          onClick: () =>
            setFieldValue(
              "awardBadge",
              values.awardBadge === "2022-food-entrepreneur"
                ? ""
                : "2022-food-entrepreneur"
            ),
          selected: values.awardBadge === "2022-food-entrepreneur",
        },
      ],
    },
  ];

  const awardFeatureFlagWrapper = (children) => {
    return (
      <FeatureFlag name="feature_2022_food_entrepreneur">
        {children}
      </FeatureFlag>
    );
  };

  return (
    <Grid item container direction="column" className={classes.outterContainer}>
      <Typography variant="subtitle1">
        <span ref={ref}>Shop Badges</span>
      </Typography>
      <Typography variant="body4">
        Let your customers know more about your business. All badges selected
        are visible in your storefront.
      </Typography>
      <Grid className={classes.container}>
        {badgeSections.map((badgeSection, index) => {
          const isAward = badgeSection.type === "awards";
          const badge = (
            <Grid
              key={`${badgeSection.type}-${index}`}
              id={`${badgeSection.type}`}
              className={
                isAward ? classes.awardContainer : classes.sectionContainer
              }
            >
              <Typography variant="subtitle2" className={classes.label}>
                {badgeSection.label}
              </Typography>
              <Grid container className={classes.badgesContainer}>
                {badgeSection.badges.map((badge) => (
                  <Button
                    onClick={badge.onClick}
                    className={`${classes.badge} ${
                      badge.selected ? classes.selected : ""
                    }`}
                    key={badge.label}
                  >
                    <SvgIcon className={classes.icon}>{badge.svg}</SvgIcon>
                    <Typography variant="body2">{badge.label}</Typography>
                  </Button>
                ))}
              </Grid>
            </Grid>
          );
          return isAward ? awardFeatureFlagWrapper(badge) : badge;
        })}
      </Grid>
    </Grid>
  );
};

export default Badges;

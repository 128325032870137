import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { CheckCircleOutline, InfoOutlined, Language } from "@material-ui/icons";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  AllDayPanel,
  Appointments,
  DateNavigator,
  DayView,
  MonthView,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";
import {
  Button,
  CollapsableCard,
  SettingsIcon,
  Typography,
  useFeatures,
  useRequireFeature,
} from "@castiron/components";
import { AvailabilitySubtype } from "@castiron/domain";
import { defaultTimeZone, formatTimeZone, useTracking } from "@castiron/utils";
import { getService } from "../../firebase";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { openModal } from "../../store/reducers/modalConductor";
import {
  listCustomTransactionsAction,
  listTransactionsAction,
} from "../../store/reducers/transactions";
import { LayoutPageProps } from "../Layout";
import EllipsisMenu from "../Menus/EllipsisMenu";
import AvatarMenu from "../Menus/AvatarMenu";
import { curateTransactions } from "./calendarContentUtils";
import Listing from "./Listing";

export type UpdatedTimeframe = {
  updatedStartDate: number;
  updatedEndDate: number;
};

// Faking data from update modal while we wait for async ES call
export type UpdatedAvailability = {
  start: number;
  end: number;
  subtype: AvailabilitySubtype;
};

export type CalendarViewType = "Day" | "Week" | "Month";

const calendarSearch = getService("shops", "calendareventsearch");

const useStyles = makeStyles((theme: Theme) => ({
  availabilityButton: {
    padding: 16,
  },
  calendarContainer: {
    "& .availabilityListing": {
      fontSize: 14,

      "& div:first-of-type": {
        width: 24,
        height: 24,
      },
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
    },
  },
  checkCircleOutlineIcon: {
    color: theme.branding.gray[800],
  },
  infoOutlinedIcon: {
    color: theme.branding.gray[500],
    marginRight: 8,
  },
  missingDatesBannerContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  missingDatesText: {
    color: theme.branding.v2.plum[500],
    cursor: "pointer",
  },
  settingsIcon: {
    fontSize: 14,
    color: theme.branding.gray[800],
  },
  settingsIconContainer: {
    backgroundColor: theme.branding.gray[200],
    borderRadius: 4,
    width: 32,
    height: 32,
    marginLeft: 8,
  },
  timeZoneSetting: {
    padding: 16,
  },
  monthView: {
    "& .availabilityListing": {
      position: "relative",
      bottom: "28px",
      paddingLeft: "4px",
    },
  },
  weekView: {
    "& .availabilityListing": {
      textAlign: "center",
      height: 35, // provides better spacing
      marginTop: -15,
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        background: theme.branding.gray[300],
        borderRadius: 12,
        textDecoration: "none",
      },
    },
  },
  dayView: {
    "& .availabilityListing": {
      justifyContent: "center",
      height: "35px", // provides better spacing
      marginTop: -15,
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        background: theme.branding.gray[300],
        borderRadius: 12,
        textDecoration: "none",
      },
    },
  },
}));

const DATE_FORMAT = "YYYY-MM-DD";
const CALENDAR_START_HOUR = 9;

const Calendar: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setPageIsProFeature, setHeaderCTAs, setFooterCTAs } =
    props;

  useRequireFeature("admin.calendar", "/calendar/preview");

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const features = useFeatures() || [];
  const isAvailabilityEnabled = features.includes(
    "admin.calendar.availability"
  );

  const { shop, transactions } = useAppSelector((state) => ({
    shop: state.shops.shop,
    transactions: state.transactions?.transactions,
  }));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlDate = urlParams.get("date");
  const urlView = urlParams.get("view") as CalendarViewType;

  const [currentViewName, setCurrentViewName] =
    useState<CalendarViewType>("Month");
  const [isMissingDatesBannerExpanded, setIsMissingDatesBannerExpanded] =
    useState<boolean>(false);
  const currentTimeZone = formatTimeZone(
    shop?.config?.timeZone || defaultTimeZone
  );
  const [availability, setAvailability] = useState<
    Record<string, AvailabilitySubtype>
  >({});
  const [shopTimeZone, setShopTimeZone] = useState<string>(
    shop?.config?.timeZone || defaultTimeZone
  );
  const [currDate, setCurrDate] = useState(
    urlDate
      ? urlDate
      : moment()
          .tz(shopTimeZone || defaultTimeZone)
          .format(DATE_FORMAT)
  );

  const isExtraSmall = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.only("md"));
  const isMobile = isExtraSmall || isSmall;

  let displayCount: number;
  switch (currentViewName) {
    case "Month":
      displayCount = 3;
      break;
    case "Day":
    case "Week":
    default:
      displayCount = 16;
      break;
  }

  const timeZoneSetting = (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      className={isMobile && classes.timeZoneSetting}
    >
      <Typography variant="button">{currentTimeZone}</Typography>
      <IconButton
        onClick={() => {
          history.push("/account-settings");
        }}
        className={classes.settingsIconContainer}
      >
        <SettingsIcon className={classes.settingsIcon} />
      </IconButton>
    </Grid>
  );

  const toAvailabilityRange = (
    type: AvailabilitySubtype,
    start: Moment,
    end: Moment
  ): Record<string, AvailabilitySubtype> => {
    return _.range(0, end.diff(start, "days") + 1)
      .map((day) => {
        return start.clone().add(day, "days");
      })
      .reduce((acc, date) => {
        const dateStr = date.format(DATE_FORMAT);
        /* add new available event if we don't yet have an event there */
        return { ...acc, [dateStr]: type };
      }, {});
  };

  const getCalendarEvents = async (updatedView?: string, newDate?: Moment) => {
    // get the visible date range based on the current view
    const view = updatedView || currentViewName;
    const currentDate =
      newDate?.tz(shopTimeZone) ||
      (urlDate
        ? moment(urlDate, "YYYY-MM-DD").tz(shopTimeZone)
        : moment().tz(shopTimeZone));
    const startDate = currentDate
      .clone()
      .startOf(view.toLowerCase() as moment.unitOfTime.StartOf);
    const endDate = currentDate
      .clone()
      .endOf(view.toLowerCase() as moment.unitOfTime.StartOf);

    if (view === "Month") {
      startDate.subtract(2, "week");
      endDate.add(2, "week");
    } else if (view === "Week") {
      startDate.startOf("week");
      endDate.endOf("week");
    } else if (view === "Day") {
      startDate.startOf("day");
      endDate.endOf("day");
    }

    const unixStartDate = startDate.unix();
    const unixEndDate = endDate.unix();

    const availabilityResp = await calendarSearch({
      startTime: unixStartDate,
      endTime: unixEndDate,
      type: "availability",
      shopId: shop?.id,
    });

    const availabilityEventsByDay: Record<string, AvailabilitySubtype> =
      availabilityResp.events.reduce((acc, ae) => {
        let encompassingDates: string[] = [];
        const start = moment.unix(ae.startTime).tz(shopTimeZone);
        const end = moment.unix(ae.endTime).tz(shopTimeZone);
        const endDateStr = end.format(DATE_FORMAT);
        while (start.isBefore(end)) {
          const dateStr = start.format(DATE_FORMAT);
          encompassingDates = [...encompassingDates, dateStr];
          start.add(1, "day");
        }
        /* end date is inclusive, so might need to add it depending on how the loop lines up */
        if (!encompassingDates.includes(endDateStr)) {
          encompassingDates = [...encompassingDates, endDateStr];
        }

        const newValues = _.fromPairs(
          encompassingDates.map((date) => [
            date,
            ae.subtype as AvailabilitySubtype,
          ])
        );
        return {
          ...acc,
          ...newValues,
        };
      }, {});

    const updatedAvailability = {
      ...availability,
      ...availabilityEventsByDay,
    };

    const availableFiller = toAvailabilityRange(
      "available",
      startDate,
      endDate
    );

    /* available filler first to be overwritten by any legitimate events */
    const withAvailableFiller = {
      ...availableFiller,
      ...updatedAvailability,
    };

    setAvailability(withAvailableFiller);

    return withAvailableFiller;
  };

  useEffect(() => {
    if (isAvailabilityEnabled) {
      getCalendarEvents();
    }
    setShopTimeZone(shop?.config?.timeZone || defaultTimeZone);
  }, [shop]);

  useEffect(() => {
    setPageTitle("Calendar");
    setPageIsProFeature(true);
    return () => {
      setPageTitle("");
      setPageIsProFeature(false);
    };
  }, []);

  const openAvailabilityModal = () => {
    dispatch(
      openModal({
        modalType: "AVAILABILITY_MODAL",
        modalProps: {
          show: true,
          onSubmit: (
            type: AvailabilitySubtype,
            updatedTimeframes: UpdatedTimeframe[]
          ) => {
            const newAvailabilities = updatedTimeframes.reduce(
              (availabilities, timeframe) => {
                const newAvailabilty = toAvailabilityRange(
                  type,
                  moment.unix(timeframe.updatedStartDate).tz(shopTimeZone),
                  moment.unix(timeframe.updatedEndDate).tz(shopTimeZone)
                );
                return { ...availabilities, ...newAvailabilty };
              },
              { ...availability }
            );
            setAvailability(newAvailabilities);
          },
        },
      })
    );
  };

  const calendarEllipsisMenuMobile = (
    <EllipsisMenu
      options={[
        {
          display: "Update Availability",
          icon: (
            <CheckCircleOutline className={classes.checkCircleOutlineIcon} />
          ),
          action: openAvailabilityModal,
        },
        {
          display: "Change Time Zone",
          icon: <Language />,
          action: () => {
            history.push("/account-settings");
          },
        },
      ]}
    />
  );

  const calendarEllipsisMenuDesktop = (
    <EllipsisMenu
      addBorder={true}
      options={[
        {
          display: "Change Time Zone",
          icon: <Language />,
          action: () => {
            history.push("/account-settings");
          },
        },
      ]}
    />
  );

  useEffect(() => {
    const availabilityHeaderCTAs = isMobile
      ? [calendarEllipsisMenuMobile, <AvatarMenu />]
      : [
          calendarEllipsisMenuDesktop,
          <Button
            variant="outlined"
            className={classes.availabilityButton}
            onClick={openAvailabilityModal}
          >
            Update Availability
          </Button>,
        ];

    const headerCTAs = isMobile ? [<AvatarMenu />] : [timeZoneSetting];
    setHeaderCTAs(isAvailabilityEnabled ? availabilityHeaderCTAs : headerCTAs);
    /* availability here to refresh the open modal function with the appropriate underlying state */
  }, [isMobile, availability]);

  /* this allows a page refresh to work correctly */
  useEffect(() => {
    if (shop && shop.id) {
      dispatch(listTransactionsAction(shop.id));
      dispatch(listCustomTransactionsAction(shop.id));
    }

    setFooterCTAs([]);
  }, [shop]);

  useEffect(() => {
    if (urlView) {
      setCurrentViewName(urlView);
    } else if (isExtraSmall) {
      setCurrentViewName("Day");
    } else if (isSmall) {
      setCurrentViewName("Day");
    } else if (isMedium) {
      setCurrentViewName("Day");
    } else {
      setCurrentViewName("Month");
    }
  }, [isExtraSmall, isSmall, isMedium]);

  const updateView = (newView) => {
    trackEvent("Calendar View Changed", {
      previousView: currentViewName,
      newView,
    });
    setCurrentViewName(newView);
    history.replace(`/calendar?view=${newView}&date=${currDate}`);
    if (isAvailabilityEnabled) {
      getCalendarEvents(newView);
    }
  };

  const { calendarTransactionEvents, missingQuotes, missingOrders } =
    curateTransactions(transactions, displayCount, CALENDAR_START_HOUR);
  const transactionEventsWithType = calendarTransactionEvents.map((event) => ({
    ...event,
    viewType: currentViewName,
  }));

  const goToMissingEvents = (e: React.MouseEvent, type: string) => {
    e.stopPropagation();

    if (type === "order") {
      trackEvent("Calendar Missing Events Link Clicked", {
        eventType: "order",
      });
      history.push("/orders?filter=open,completed,fulfilled");
    } else {
      trackEvent("Calendar Missing Events Link Clicked", {
        eventType: "quote",
      });
      history.push("/quotes?filter=New,Draft,Pending");
    }
  };

  const findMissingDatesText = (type: string) => {
    const missingType = type === "Quote" ? missingQuotes : missingOrders;

    return (
      <span
        className={classes.missingDatesText}
        onClick={(e) => goToMissingEvents(e, type.toLowerCase())}
      >
        {missingType.length} {type.toLowerCase()}
        {missingType.length === 1 ? "" : "s"}
      </span>
    );
  };

  const formattedMissingDatesText = () => {
    let missingDatesText;
    if (missingQuotes.length > 0 && missingOrders.length > 0) {
      missingDatesText = (
        <span>
          {findMissingDatesText("Quote")} and {findMissingDatesText("Order")}
        </span>
      );
    } else if (!_.isEmpty(missingQuotes)) {
      missingDatesText = findMissingDatesText("Quote");
    } else if (!_.isEmpty(missingOrders)) {
      missingDatesText = findMissingDatesText("Order");
    }

    if (!areMultipleMissing) {
      return (
        <Typography variant="body1">
          Add a fulfillment date to {missingDatesText} without a date to see it
          on your calendar.
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1">
          Add fulfillment dates to {missingDatesText} without dates to see them
          on your calendar.
        </Typography>
      );
    }
  };

  const areMultipleMissing =
    (!_.isEmpty(missingQuotes) && !_.isEmpty(missingOrders)) ||
    missingQuotes.length > 1 ||
    missingOrders.length > 1;

  const missingDatesBanner = (
    <Grid container className={classes.missingDatesBannerContainer}>
      <CollapsableCard
        expanded={isMissingDatesBannerExpanded}
        handleExpand={() => {
          trackEvent("Calendar Missing Events Banner Clicked", {
            action: isMissingDatesBannerExpanded ? "collapse" : "expand",
          });
          setIsMissingDatesBannerExpanded(!isMissingDatesBannerExpanded);
        }}
        noScroll
        title={
          <Grid container wrap="nowrap">
            <InfoOutlined className={classes.infoOutlinedIcon} />
            <Typography variant="subtitle2">
              Missing item{areMultipleMissing ? "s" : ""} on calendar
            </Typography>
          </Grid>
        }
      >
        {formattedMissingDatesText()}
      </CollapsableCard>
    </Grid>
  );

  // for each availability entry, split it into multiple all day events for each day in the range
  const availabilityEvents = _.keys(availability).map((dateStr) => {
    const availabilityType = availability[dateStr];
    /* calendar doesn't play nice with moment timezones, so just use vanilla dates */
    return {
      title: availabilityType,
      startDate: Date.parse(`${dateStr}T00:00:00.000`),
      endDate: Date.parse(`${dateStr}T23:59:59.000`),
      allDay: true,
      availabilityType,
      viewType: currentViewName,
      onSetAvailability: (
        type: AvailabilitySubtype,
        start: number,
        end: number
      ) => {
        setAvailability({
          ...availability,
          [moment.unix(start).format(DATE_FORMAT)]: type,
        });
      },
    };
  });

  const orderAndAvailabilityCalendarData = isAvailabilityEnabled
    ? [...availabilityEvents, ...transactionEventsWithType]
    : transactionEventsWithType;

  const changeCurrentDate = (dir: string) => {
    let newDate;

    if (currentViewName === "Month") {
      if (dir === "forward") {
        newDate = moment(currDate).add(1, "month").format(DATE_FORMAT);
      } else {
        newDate = moment(currDate).subtract(1, "month").format(DATE_FORMAT);
      }
    } else if (currentViewName === "Week") {
      if (dir === "forward") {
        newDate = moment(currDate).add(1, "week").format(DATE_FORMAT);
      } else {
        newDate = moment(currDate).subtract(1, "week").format(DATE_FORMAT);
      }
    } else if (currentViewName === "Day") {
      if (dir === "forward") {
        newDate = moment(currDate).add(1, "day").format(DATE_FORMAT);
      } else {
        newDate = moment(currDate).subtract(1, "day").format(DATE_FORMAT);
      }
    }

    setCurrDate(newDate);
    history.replace(`/calendar?view=${currentViewName}&date=${newDate}`);
    if (isAvailabilityEnabled) {
      getCalendarEvents(currentViewName, moment(newDate));
    }
  };

  const handleViewChange = (view: CalendarViewType) => {
    // overriding the built in view switcher so we can get the new date range for availability
    setCurrentViewName(view);
    history.replace(`/calendar?view=${view}&date=${currDate}`);
    if (isAvailabilityEnabled) {
      getCalendarEvents(view, moment(currDate).tz(shopTimeZone));
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Calendar | ${shop ? shop.businessName : ""}`}</title>
      </Helmet>
      <Grid container direction="column">
        {!isAvailabilityEnabled && isMobile && timeZoneSetting}
        {(!_.isEmpty(missingQuotes) || !_.isEmpty(missingOrders)) &&
          missingDatesBanner}
        <Grid
          item
          className={`${classes.calendarContainer} ${
            classes[currentViewName.toLowerCase() + "View"]
          }`}
        >
          <Scheduler data={orderAndAvailabilityCalendarData}>
            <ViewState
              defaultCurrentDate={currDate}
              currentViewName={currentViewName}
              onCurrentViewNameChange={updateView}
              onCurrentDateChange={(date: Date) =>
                setCurrDate(moment(date).format(DATE_FORMAT))
              }
              currentDate={currDate}
            />
            <DayView
              startDayHour={CALENDAR_START_HOUR}
              endDayHour={17}
              timeScaleLabelComponent={() => <></>}
            />
            {!isExtraSmall && (
              <WeekView
                startDayHour={CALENDAR_START_HOUR}
                endDayHour={17}
                timeScaleLabelComponent={() => <></>}
              />
            )}
            {!isExtraSmall && !isSmall && <MonthView />}
            <Toolbar />
            <DateNavigator
              rootComponent={
                //dumb plugin
                ({ onNavigate, ...restProps }) => {
                  return (
                    <DateNavigator.Root
                      {...restProps}
                      onNavigate={changeCurrentDate}
                    />
                  );
                }
              }
            />
            <TodayButton
              buttonComponent={({ setCurrentDate, ...restProps }) => {
                return (
                  <TodayButton.Button
                    {...restProps}
                    setCurrentDate={(newDate) => {
                      const newDateString = moment(newDate)
                        .tz(shopTimeZone)
                        .format("YYYY-MM-DD");
                      setCurrDate(newDateString);
                      history.replace(
                        `/calendar?view=${currentViewName}&date=${newDateString}`
                      );
                      if (isAvailabilityEnabled) {
                        getCalendarEvents(currentViewName, moment(newDate));
                      }
                    }}
                  />
                );
              }}
            />
            {!isExtraSmall && (
              <ViewSwitcher
                switcherComponent={
                  //dumb plugin
                  ({ onChange, ...restProps }) => {
                    return (
                      <ViewSwitcher.Switcher
                        {...restProps}
                        onChange={handleViewChange}
                      />
                    );
                  }
                }
              />
            )}
            <Appointments appointmentComponent={Listing} />
            <AllDayPanel titleCellComponent={() => <></>} />
          </Scheduler>
        </Grid>
      </Grid>
    </>
  );
};

export default Calendar;

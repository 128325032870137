import React, { useContext } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { appTheme, ExtendedTypographyOptions } from "@castiron/components";
import { findFontFamily } from "@castiron/utils";

type Props = {
  children: any;
};

const Theme: React.FC<Props> = (props: Props) => {
  const { children } = props;

  //exporting to enable customization of global theme within client-admin or client-external
  const nouryshTheme = createMuiTheme({
    ...appTheme,
    palette: {
      ...appTheme.palette,
      primary: {
        main: appTheme.branding.v2.plum[500],
      },
      secondary: {
        main: appTheme.branding.v2.plum[800],
      },
      success: {
        main: appTheme.branding.v2.plum[500],
        light: "#CDE5C8",
        dark: "#266617",
      },
    },
    overrides: {
      ...appTheme.overrides,
      MuiFormControlLabel: {
        root: {
          "& .MuiCheckbox-root": {
            color: "#dadae9",
          },
          "& .MuiCheckbox-colorPrimary.Mui-checked": {
            color: appTheme.branding.v2.plum[500],
          },
        },
        label: {
          fontSize: 14,
          color: appTheme.branding.gray[800],
          fontWeight: 600,
          paddingRight: 30,
        },
      },
      MuiTab: {
        root: {
          opacity: 1,
          fontSize: 16,
          fontWeight: 700,
          color: "#0D0C21",
          "&.Mui-selected": {
            color: appTheme.branding.v2.plum[500],
          },
          "&.MuiTab-textColorInherit": {
            opacity: 1,
          },
        },
      },
    },
  });

  return <ThemeProvider theme={nouryshTheme}>{children}</ThemeProvider>;
};

export default Theme;

import {
  Box,
  Button,
  ButtonGroup as MUIButtonGroup,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import InputLabel from "../InputLabel";

type Props = {
  value: string | boolean;
  onChange: (status: any) => void;
  buttons?: string[];
  label?: ReactNode;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: 0,
    height: 48,
    marginBottom: 15,
    fontWeight: 600,
    fontSize: 16,
    borderColor: `${theme.branding.gray[700]}3D`,
  },
  buttonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonDisabledActive: {
    backgroundColor: theme.branding.v2.plum[100],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.gray[700],
  },
}));

const ButtonGroup: React.FC<Props> = (props: Props) => {
  const { value, onChange, buttons, label, disabled = false } = props;
  const classes = useStyles();

  return (
    <Box>
      <InputLabel primaryLabel={label} />
      <MUIButtonGroup fullWidth>
        {buttons.map((button) => (
          <Button
            key={button}
            onClick={(): void => onChange(button)}
            disabled={disabled}
            className={`${classes.button} ${
              value === button && disabled
                ? classes.buttonDisabledActive
                : value === button && classes.buttonActive
            }`}
          >
            {button.charAt(0).toUpperCase() + button.slice(1)}
          </Button>
        ))}
      </MUIButtonGroup>
    </Box>
  );
};

export default ButtonGroup;

import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { Customer } from '@castiron/domain';
import { Button, HeaderText, SubscriberStatusPill, SvgIcon, Typography } from '@castiron/components';
import TrashIcon from '../../assets/img/trash.svg';
import RequireStripe from '../RequireStripe';

type Props = {
  customer: Customer;
  deleteCustomer?: () => void;
  enableDelete?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
  },
  smallHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
  },
  textFontweight: {
    fontWeight: 600,
  },
  text: {
    color: theme.branding.gray[700],
    marginBottom: 5,
  },
  borderContainer: {
    position: 'relative',
    border: '1px solid #7777773D',
    borderRadius: 8,
    marginBottom: 20,
  },
  sendButtonContainer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
  },
  lowerItems: {
    padding: '20px 32px 32px',
  },
  detailsBox: {
    marginBottom: '32px',
    wordBreak: 'break-word',
  },
  subscribedHeader: {
    marginBottom: '8px',
  },
  title: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const CustomerDetailsBox: React.FC<Props> = (props: Props) => {
  const { customer, deleteCustomer, enableDelete } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const openContactModal = useCallback((): void => {
    customer &&
      dispatch(
        openModal({
          modalType: 'CONTACT_MODAL',
          modalProps: {
            show: true,
            email: customer.email,
            customerId: customer.id,
          },
        }),
      );
  }, [customer]);

  return (
    <Box className={classes.borderContainer}>
      <div className={classes.smallHeader}>
        <HeaderText className={classes.title} presetFont="heading--xs">
          Contact Details
        </HeaderText>
        {enableDelete && (
          <SvgIcon className={classes.deleteIcon} onClick={deleteCustomer}>
            <TrashIcon />
          </SvgIcon>
        )}
      </div>
      <Box className={classes.lowerItems}>
        {customer ? (
          <>
            <Box className={classes.detailsBox}>
              <Typography variant="body1" className={classes.textFontweight}>
                {`${customer?.firstName || ''} ${customer?.lastName || ''}`}
              </Typography>
              <Typography className={classes.text}>{customer?.email || ''}</Typography>
              <Typography className={classes.text}>{customer?.mobileNumber || ''}</Typography>
            </Box>
            <Box className={classes.detailsBox}>
              <Typography variant="body1" className={classes.textFontweight}>
                Subscriber
              </Typography>
              <SubscriberStatusPill customer={customer} />
            </Box>
            {customer?.notes && (
              <Box className={classes.detailsBox}>
                <Typography className={classes.textFontweight}>Notes</Typography>
                <Typography className={classes.text}>{customer?.notes || ''}</Typography>
              </Box>
            )}
            <Box className={classes.sendButtonContainer}>
              <RequireStripe>
                <Button onClick={openContactModal} variant="outlined" fullWidth>
                  Send Message
                </Button>
              </RequireStripe>
            </Box>
          </>
        ) : (
          'None'
        )}
      </Box>
    </Box>
  );
};

export default CustomerDetailsBox;

import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useAppSelector } from '../../../hooks';
import { InstagramIcon, FacebookIcon, TwitterIcon } from '@castiron/components';
import { Button } from '@castiron/components';
import { useTracking } from '@castiron/utils';

type Props = {
  img: string;
  pinterestImg: string;
  shareUrl: string;
  text: string;
  itemType: 'product' | 'coupon';
  item: any;
  copyOnly?: boolean;
  hasCopied?: boolean;
  setHasCopied?: (boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    marginTop: '24px',
    '& >button': {
      marginRight: 15,
    },
  },
  cancelButton: {
    marginRight: '15px',
  },
  container: {
    padding: '40px',
  },
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  icon: {
    backgroundColor: theme.branding.gray[800],
    borderRadius: 4,
  },
  iconLink: {
    marginRight: '.5em',
  },
  imageStyle: {
    [theme.breakpoints.up('md')]: {
      width: '215px',
      maxWidth: '215px',
      maxHeight: '215px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      maxWidth: '150px',
      maxHeight: '150px',
    },
    marginBottom: 20,
  },
  shareText: {
    'word-wrap': 'break-word',
    'white-space': 'pre-wrap',
    'word-break': 'break-word',
  },
  socialIcons: {
    marginTop: '2em',
  },
  wrapper: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '8px',
    marginBottom: '20px',
    maxWidth: '16em',
    minWidth: '16em',
    padding: '20px',
  },
}));

const ShareItem: React.FC<Props> = (props: Props) => {
  const { copyOnly = false, hasCopied, setHasCopied, img, pinterestImg, shareUrl, text, item, itemType } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fbCopy = useRef();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [fbOpen, setFBOpen] = useState<boolean>(false);
  const [igOpen, setIGOpen] = useState<boolean>(false);
  const [unsupportedOpen, setUnsupportedOpen] = useState<boolean>(false);
  const [filesArray, setFilesArray] = useState([]);

  const encodedUrl = encodeURIComponent(shareUrl);
  const encodedPinterestImgUrl = encodeURIComponent(pinterestImg);
  const encodedText = encodeURIComponent(text);

  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`;
  const pnUrl = `https://www.pinterest.com/pin-builder/?url=${encodedUrl}&media=${encodedPinterestImgUrl}&method=button`;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getImages = async () => {
    try {
      const response = await fetch(pinterestImg);
      const blob = await response.blob();

      const filesArr = [
        new File([blob], 'image', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        }),
      ];

      setFilesArray(filesArr);
    } catch (err) {
      console.warn(`Error fetching image [${pinterestImg}]`);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  const handleClick = (service: string) => {
    trackEvent('Social Share Click', {
      service,
      shareType: itemType,
      item,
    });

    switch (service) {
      case 'fb':
        return window.open(fbUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      case 'tw':
        return window.open(twUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      case 'pn':
        return window.open(pnUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      default:
        break;
    }
  };

  const handleClipboardCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => setHasCopied(true))
      .catch(error => console.error('Error copying text:', error));
  };

  const handleNativeClick = async () => {
    const shareData = {
      file: filesArray,
      title: shop.businessName,
      text: text,
      url: shareUrl,
    };

    try {
      navigator.share(shareData);
      trackEvent('Social Share Click', {
        service: 'native',
        shareType: itemType,
        item,
      });
    } catch (error) {
      setUnsupportedOpen(true);
    }
  };

  const handleClose = () => {
    setFBOpen(false);
    setIGOpen(false);
    setUnsupportedOpen(false);
  };

  const handleCopyContent = () => {
    const copiedText = fbCopy.current;

    if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(copiedText);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
    document.execCommand('copy');
  };

  return (
    <Grid container className={classes.wrapper} style={copyOnly ? {} : { marginRight: '20px' }}>
      <Grid container direction="column" justify="space-between" alignItems="center">
        <Grid item container justify="center">
          {(img || shop?.logoImageObj?.downloadUrl) && (
            <img className={classes.imageStyle} src={img || shop?.logoImageObj?.downloadUrl} alt={shop?.businessName} />
          )}
          <div>
            <Typography className={classes.shareText} ref={fbCopy}>
              {text}
            </Typography>
          </div>
        </Grid>
        {copyOnly ? (
          <Grid container justify="center" style={{ marginTop: 10 }}>
            <Button variant="contained" onClick={() => handleClipboardCopy()}>
              {hasCopied ? 'Copied!' : 'Copy'}
            </Button>
          </Grid>
        ) : !isMobile ? (
          <Grid alignItems="flex-start" className={classes.socialIcons} container>
            <Typography>Post to:</Typography>
            <Grid container direction="row">
              <a
                className={classes.iconLink}
                onClick={e => {
                  e.preventDefault();
                  setFBOpen(true);
                  handleCopyContent();
                  trackEvent('Social Share Click', {
                    service: 'fb',
                    shareType: itemType,
                    item,
                  });
                }}
                href="#"
              >
                <FacebookIcon className={classes.icon}></FacebookIcon>
              </a>
              {/* <a
                className={classes.iconLink}
                onClick={e => {
                  e.preventDefault();
                  setIGOpen(true);
                }}
                href="#"
              >
                <InstagramIcon className={classes.icon}></InstagramIcon>
              </a> */}
              <a
                className={classes.iconLink}
                onClick={e => {
                  e.preventDefault();
                  handleClick('tw');
                }}
                href="#"
              >
                <TwitterIcon className={classes.icon}></TwitterIcon>
              </a>
              {/* <a className={classes.iconLink}  onClick={(e) => {e.preventDefault(); handleClick('pn')}} href='#'><PinterestIcon className={classes.icon}></PinterestIcon></a> */}
            </Grid>
            <Dialog open={fbOpen} onClose={handleClose}>
              <Grid container direction="column" className={classes.container}>
                <Typography>
                  <strong>Your post content is copied to your clipboard!</strong>
                  <br /> You can paste it and make any edits you'd like once you get to Facebook.
                </Typography>
                <Grid container justify={isMobile ? 'center' : 'flex-end'} className={classes.buttons}>
                  <Button
                    onClick={() => setFBOpen(false)}
                    variant="outlined"
                    color="primary"
                    style={{ marginBottom: isMobile ? 10 : 0 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleClick('fb');
                      setFBOpen(false);
                    }}
                    variant="contained"
                    style={{ marginBottom: isMobile ? 10 : 0 }}
                  >
                    Go to Facebook
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
            <Dialog open={igOpen} onClose={handleClose}>
              <Grid container direction="column" className={classes.container}>
                <Typography>
                  Due to Instagram's limitations, you'll need to post to Instagram from a mobile device.
                </Typography>
                <Grid container justify="space-evenly" className={classes.buttons}>
                  <Button onClick={() => setIGOpen(false)} variant="outlined" color="primary">
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
            <Dialog open={unsupportedOpen} onClose={handleClose}>
              <Grid container direction="column" className={classes.container}>
                <Typography>
                  Sorry, mobile sharing unsupported on this mobile device. Please try from a newer mobile device or a
                  computer.
                </Typography>
                <Grid container justify="space-evenly" className={classes.buttons}>
                  <Button onClick={() => setUnsupportedOpen(false)} variant="outlined" color="primary">
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        ) : (
          <Grid container>
            <Button variant="contained" onClick={() => handleNativeClick()}>
              SHARE
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ShareItem;

import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ButtonV2, GiftOutlinedIcon, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { FeatureFlag } from '@castiron/castiron-firebase';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

type Props = {
  location:
    | 'availability-modal'
    | 'custom-order-availability-modal'
    | 'home'
    | 'order-details'
    | 'plans-and-pricing'
    | 'product-announcement-modal'
    | 'presale-announcement-modal';
  onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 16,
    backgroundColor: theme.branding.v2.green[50],
    padding: '40px 24px',
    gap: 16,
    minWidth: 240,
  },
  giftIcon: {
    color: theme.branding.v2.green[500],
  },
  referButton: {
    backgroundColor: theme.branding.v2.green[500],
    color: theme.branding.v2.gray[0],
    maxWidth: 138,
    '&:hover': {
      backgroundColor: theme.branding.v2.green[600],
    },
  },
  referButtonTextVariant: {
    color: theme.branding.v2.gray[900],
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

const ReferralBlock: React.FC<Props> = (props: Props) => {
  const { location, onClick } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();

  const isInModal =
    location === 'availability-modal' ||
    location === 'custom-order-availability-modal' ||
    location === 'product-announcement-modal' ||
    location === 'presale-announcement-modal';

  const handleClick = e => {
    dispatch(closeModal());
    onClick && onClick();
    trackEvent('Referral CTA Clicked', { location });
  };

  const getText = () => {
    switch (location) {
      case 'availability-modal':
        return "Ready to help your friends stress less with Nourysh's availability calendar?";
      case 'custom-order-availability-modal':
        return "Ready to help your friends drive more orders with Nourysh's availability emails?";
      case 'product-announcement-modal':
        return "Ready to help your friends drive more orders with Nourysh's marketing emails?";
      case 'presale-announcement-modal':
        return "Ready to help your friends drive more orders with Nourysh's marketing emails?";
      default:
        return 'Refer a friend and get 1 month free when they subscribe to Nourysh.';
    }
  };

  return (
    <FeatureFlag name="feature_cello_referrals">
      <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
        <GiftOutlinedIcon className={classes.giftIcon} stroke={theme.branding.v2.green[500]} />
        {!isInModal && <Typography variant="h3">Get 1 Month Free</Typography>}
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          {getText()}
        </Typography>
        {isInModal ? (
          <ButtonV2 variant="text" className={`celloLauncher ${classes.referButtonTextVariant}`} onClick={handleClick}>
            Refer a Friend <ArrowForwardIcon style={{ color: theme.branding.v2.green[500], marginLeft: 8 }} />
          </ButtonV2>
        ) : (
          <ButtonV2 variant="contained" className={`celloLauncher ${classes.referButton}`} onClick={handleClick}>
            Refer a Friend
          </ButtonV2>
        )}
      </Grid>
    </FeatureFlag>
  );
};

export default ReferralBlock;

import React, { ReactNode, useState, useEffect } from 'react';
import { Grid, Link, TextField, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { ChecklistValues, Customer } from '@castiron/domain';
import { Button, Checkbox, SaveButton, DiscardButton } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { updateChecklistAction } from '../../../store/reducers/shops';
import { batchCustomerAction, getCustomersAction } from '../../../store/reducers/customers';
import Spinner from '../../Spinner';
import CustomerBulkAdded from './CustomerBulkAdded';
import RequireStripe from '../../RequireStripe';

const breakpointSmall = 749;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 20,
  },
  bulkLoadHeader: {
    fontSize: '14px',
    fontWeight: 700,
  },
  bulkLoadInstructions: {
    fontSize: '14px',
  },
  bulkLoadSubscriberDescription: {
    fontSize: '14px',
    marginLeft: '30px',
    color: theme.branding.gray[600],
  },
  addContactsButtonContainer: {
    [theme.breakpoints.down(breakpointSmall)]: {
      width: '100%',
    },
  },
  errorMessage: {
    fontSize: '14px',
    color: theme.branding.orange.primary,
  },
}));

/* do we have a global validations library somewher already? I'm not seeing one, so stole this from the interwebs */
const validateEmail = (email: string): boolean => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

type Props = {
  setFooterCTAs?: (ctas: ReactNode[]) => void;
}

const AddCustomerBulk: React.FC<Props> = (props: Props) => {
  const { setFooterCTAs } = props;

  const classes = useStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [contactList, setContactList] = useState('');
  const [areSubscribed, setAreSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [uploaded, setUploaded] = useState(false);
  const [emails, setEmails] = useState({
    newCustomers: [],
    invalidCustomers: [],
    existingCustomers: [],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpointSmall));

  const { shop, customers } = useAppSelector(state => ({
    shop: state.shops.shop,
    customers: state.customers.customers,
  }));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  useEffect(() => {
    !uploaded ? setFooterCTAs([
      <DiscardButton isSubmitting={isUploading} backLocation='/contacts/overview' />,
      <RequireStripe>
        <SaveButton
          handleSubmit={uploadContacts}
          isSubmitting={isUploading}
          customName='Add Contacts'
        />
      </RequireStripe>
    ]) : setFooterCTAs([])
  }, [isUploading, uploaded, contactList]);

  const baseTracking = {
    shop: {
      id: shop.id,
    },
  };

  const onEmailsFocus = () => {
    trackEvent('Bulk Customer Upload Emails Focused', {
      ...baseTracking
    });
  };

  const uploadContacts = async () => {
    setIsUploading(true);

    const contactListTokens = contactList.split(/[,;\s]+/);

    trackEvent('Bulk Customer Upload Attempted', {
      newCustomers:
        contactListTokens.map(email =>
        ({
          email: email,
          subscribed: areSubscribed,
          ...baseTracking
        })
        )
    });

    const [validEmails, invalidEmails] = _.partition(contactListTokens, validateEmail);
    const hasValidEmails = validEmails.length > 0;

    if (hasValidEmails) {
      const [unique, repeats] = validEmails.reduce((acc: string[][], email: string) => {
        return acc[0].includes(email) ? [acc[0], [...acc[1], email]] : [[...acc[0], email], acc[1]];
      }, [[], []]);
      const [existingCustomers, newCustomers] = _.partition(
        unique,
        (email) => customers && customers.find((c) => c.email?.toLowerCase() === email.toLowerCase())
      );

      if (newCustomers.length > 0) {
        const customersToUpload = newCustomers.map((email) => {
          /* type script was not playing nice with the subscriber origination,
           * so doing this in two steps
           */
          const cust: Customer = {
            email,
            shopId: shop.id,
            subscribed: areSubscribed,
            subscriberOrigination: 'admin-bulk-upload',
          };
          return cust;
        });
        await dispatch(batchCustomerAction(customersToUpload));
        if (!shop.checklistCompletions?.includes(ChecklistValues.CustomerList)) {
          await dispatch(updateChecklistAction({ shop, items: [ChecklistValues.CustomerList] }));
        }
        await dispatch(getCustomersAction(shop.id));
      }
      setEmails({
        newCustomers,
        invalidCustomers: invalidEmails,
        existingCustomers: [...repeats, ...existingCustomers],
      });
    } else {
      setErrorMessage('Please enter at least 1 valid email address');
    }
    setIsUploading(false);
    setUploaded(hasValidEmails);
  };

  const reset = () => {
    setContactList('');
    setAreSubscribed(false);
    setEmails({
      newCustomers: [],
      existingCustomers: [],
      invalidCustomers: [],
    });
    setUploaded(false);
  };

  return uploaded ? (
    <CustomerBulkAdded
      newCustomerEmails={emails.newCustomers}
      invalidCustomerEmails={emails.invalidCustomers}
      duplicateCustomerEmails={emails.existingCustomers}
      subscribedNewCustomers={areSubscribed}
      reset={reset}
    />
  ) : (
    <Grid container spacing={1} className={classes.container}>
      <Spinner show={isUploading} size='content' label='Please wait. This may take some time to process' />
      <Grid item xs={12}>
        <Typography className={classes.bulkLoadHeader}>Emails</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.bulkLoadInstructions}>
          Copy and paste your list of emails separated by a comma or a linebreak.
          Check out our guides to learn how to export contacts from{' '}
          <Link href={'https://www.sellercommunity.com/t5/Questions-How-To/How-do-I-download-the-acquired-Email-Addresses-as-a-CVS-File/m-p/39545#M19746'} target={'_blank'}>Square</Link>{', '}
          <Link href={'https://www.jotform.com/help/374-how-to-export-all-of-your-data-at-once/'} target={'_blank'}>JotForm</Link>{', '}
          <Link href={'https://help.shopify.com/en/manual/customers/import-export-customers#export-existing-customer-profiles-to-a-csv-file'} target={'_blank'}>Shopify</Link>{', '}
          and{' '}<Link href={'https://mailchimp.com/help/view-export-contacts/'} target={'_blank'}>Mailchimp</Link>.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={6}
          variant='outlined'
          fullWidth
          placeholder='Add emails...'
          value={contactList}
          onChange={(e) => setContactList(e.target.value)}
          onFocus={onEmailsFocus}
        />
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          label='These people gave me permission to email them about marketing offers'
          checked={areSubscribed}
          onChange={(e) => setAreSubscribed(e.target.checked)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.bulkLoadSubscriberDescription}>
          You can message this type of contact through Nourysh about pre-sales, events, special offers and more
        </Typography>
      </Grid>
      {
        errorMessage &&
        <Grid item xs={12}>
          <Typography className={classes.errorMessage}>{errorMessage}</Typography>
        </Grid>
      }
    </Grid >
  );
};

export default AddCustomerBulk;

import firebase from 'firebase/compat/app';
import { HttpsCallable } from '@firebase/functions-types';
import axios from 'axios';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AttributionContext, determineAttribution, AttributionData } from '@castiron/utils';
import { FirebaseApp } from '@castiron/castiron-firebase';
import * as crypto from 'crypto';
import { Attribution } from '@castiron/domain';
import { useContext } from 'react';

const REACT_APP_IS_LOCAL_FIREBASE = process.env.REACT_APP_IS_LOCAL_FIREBASE;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const MARKETPLACE_ID = process.env.REACT_APP_MARKETPLACE_ID;

type BoundedContextName =
  | 'accounts'
  | 'asana'
  | 'auth'
  | 'customers'
  | 'firestore'
  | 'images'
  | 'locations'
  | 'marketing'
  | 'messaging'
  | 'orders'
  | 'packages'
  | 'presales'
  | 'products'
  | 'shops'
  | 'stripe'
  | 'subscriptions'
  | 'test'
  | 'transactions';

interface ServiceConfig {
  type?: 'call' | 'request';
  version?: number;
}

const DEFAULT_CONFIG: ServiceConfig = {
  type: 'call',
  version: 2,
};

const FUNCTIONS_STUB = {
  httpsCallable: (message: any): HttpsCallable => {
    console.warn('Using stub httpsCallable implementation');
    return data => Promise.resolve({ data: {} });
  },
};

var getSHA256ofJSON = function(input) {
  return crypto
    .createHash('sha256')
    .update(JSON.stringify(input))
    .digest('hex');
};

const useStub = FirebaseApp?.firebase?.apps.length === 0;
const functions = () => (useStub ? FUNCTIONS_STUB : FirebaseApp.functions());
const functionsV2 = useStub ? undefined : getFunctions();

const retrieveExperiments = () => {
  const storedExp = localStorage.getItem('experiments');
  return storedExp ? JSON.parse(storedExp) : undefined;
};

export const getServiceV1 = (
  boundedContext: BoundedContextName,
  serviceName: string,
  attribution?: Attribution,
  reqType: ServiceConfig['type'] = 'call',
) => {
  return async (request: Record<string, any>) => {
    console.debug(`Making request to [${serviceName}]`, request);
    const att = attribution || determineAttribution({ gaStreamId: GA_MEASUREMENT_ID });
    const call =
      reqType == 'request'
        ? getRequestService(boundedContext, serviceName)
        : functions().httpsCallable(`${boundedContext}-${serviceName}`);
    /* if payload is from the data layer, then call data() to remove non serializable fields */
    const requestPayload = request.data ? request.data() : request;
    const result = await call({
      ...requestPayload,
      attribution: att,
      experiments: retrieveExperiments(),
      marketplace: MARKETPLACE_ID,
    });
    console.debug(`Response from [${serviceName}]`, result);
    return result.data;
  };
};

export const getServiceV2 = (
  boundedContext: BoundedContextName,
  serviceName: string,
  attribution?: Attribution,
  config: ServiceConfig = DEFAULT_CONFIG,
) => {
  return async (request: Record<string, any>) => {
    const name = serviceName.toLowerCase();
    const att = attribution || determineAttribution({ gaStreamId: GA_MEASUREMENT_ID });
    const call = useStub
      ? FUNCTIONS_STUB.httpsCallable(`${boundedContext}-${name}`)
      : httpsCallable(functionsV2, `${boundedContext}-${name}`);
    console.debug(`Calling Service [${name}]`, request);
    const result = await call({
      ...request,
      attribution: att,
      marketplace: MARKETPLACE_ID,
    });
    console.debug(`Response from call to Service [${name}]`, result);
    return result.data;
  };
};

export const getRequestService = (boundedContext: BoundedContextName, serviceName: string) => {
  const serviceUrl = getServiceUrl(boundedContext, serviceName);

  return async (request: Record<string, any>): Promise<any> => {
    const attribution = determineAttribution({ gaStreamId: GA_MEASUREMENT_ID });
    const experiments = retrieveExperiments();

    const token = await firebase.auth().currentUser?.getIdToken();
    const resp = await axios.post(
      serviceUrl,
      {
        ...request,
        attribution,
        experiments,
        marketplace: MARKETPLACE_ID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    );
    return resp;
  };
};

export const getServiceUrl = (boundedContext: BoundedContextName, serviceName: string) => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
  const isLocal = process.env.REACT_APP_IS_LOCAL_FIREBASE || process.env.NEXT_PUBLIC_IS_LOCAL_FIREBASE;
  return isLocal === 'true'
    ? `http://localhost:5001/castiron-localdev/us-central1/${boundedContext}-${serviceName}`
    : `https://us-central1-${projectId}.cloudfunctions.net/${boundedContext}-${serviceName}`;
};

export const useService = (
  boundedContext: BoundedContextName,
  serviceName: string,
  config: ServiceConfig = DEFAULT_CONFIG,
) => {
  const {attribution} = useContext<AttributionData>(AttributionContext);
  return !REACT_APP_IS_LOCAL_FIREBASE && config?.version === 2
    ? getServiceV2(boundedContext, serviceName, attribution, config)
    : getServiceV1(boundedContext, serviceName, attribution, config?.type);
};

export const getService = (
  boundedContext: BoundedContextName,
  serviceName: string,
  config: ServiceConfig = DEFAULT_CONFIG,
) => {
  return !REACT_APP_IS_LOCAL_FIREBASE && config?.version === 2
    ? getServiceV2(boundedContext, serviceName, undefined, config)
    : getServiceV1(boundedContext, serviceName, undefined, config?.type);
};

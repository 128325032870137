import { ButtonV2, Forms, Input } from "@castiron/components";
import {
  ChecklistValues,
  Coupon,
  SubscriberCouponMarketingSend,
  WelcomeSeriesMarketingSend,
} from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  couponRepository,
  marketingSendRepository,
  shopRepository,
} from "../../../domain";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCouponAction } from "../../../store/reducers/coupons";
import { closeModal, openModal } from "../../../store/reducers/modalConductor";
import { updateChecklistAction } from "../../../store/reducers/shops";
import AdminForm from "../../AdminForm";
import GeneralModal from "../../RootModal/GeneralModal";
import Select from "../../Select";

interface ThankYouFormValues {
  code: string;
  discountType: "amount" | "percent";
  discountValue: number;
  orderMinType?: "orderValue" | "orderQuantity" | "none";
  orderMinValue?: number;
  sendToOrdersWithin60Days: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  addOrderMinButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.branding.v2.red[500],
    marginTop: "8px",
  },
  formControl: {
    marginBottom: "15px",
    width: "100%",
    "& .MuiFormControl-root": {
      marginTop: 8,
    },
  },
  formGroup: {
    justifyContent: "space-between",
  },
  label: {
    color: theme.branding.v2.gray[800],
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: 0,
    lineHeight: "24px",
  },
  modalPaperClass: {
    [theme.breakpoints.up("sm")]: {
      height: "fit-content",
    },
  },
  selects: {
    marginTop: 8,
    borderRadius: "0 12px 12px 0",
    flexBasis: "50%",
    minWidth: "initial",
  },
  specialInputs: {
    "& > div:first-child": {
      flexBasis: "50%",
    },
  },
  specialInputRadius: {
    borderRadius: "12px 0 0 12px",
  },
  turnOffButton: {
    color: theme.branding.v2.red[500],
    minWidth: "fit-content",
    "&:hover": {
      border: `1px solid ${theme.branding.v2.red[500]}`,
      backgroundColor: theme.branding.v2.gray[100],
    },
  },
}));

export interface Props {
  couponId: string;
  variant: "thank-you" | "new-subscriber" | "welcome-series";
  setPageRefresh?: (value: boolean) => void;
}

const ManageCouponModal: React.FC<Props> = (props: Props) => {
  const { couponId, variant, setPageRefresh } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const formikRef = useRef<FormikProps<ThankYouFormValues>>(null);

  const { coupons, me, shop } = useAppSelector((state) => ({
    coupons: state.coupons.coupons,
    me: state.users.me,
    shop: state.shops.shop,
  }));

  const [coupon, setCoupon] = useState<Coupon>(null);
  const [uniqueCodeError, setUniqueCodeError] = useState<{
    type: string;
    msg: string;
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showOrderMin, setShowOrderMin] = useState<boolean>(false);
  const [welcomeSeriesMarketingSend, setWelcomeSeriesMarketingSend] =
    useState<WelcomeSeriesMarketingSend | null>(null);

  useEffect(() => {
    const getCoupon = async () => {
      const coupon = await couponRepository.get(couponId);
      setCoupon(coupon);
      if (
        coupon?.minimum?.type === "orderValue" ||
        coupon?.minimum?.type === "orderQuantity"
      ) {
        setShowOrderMin(true);
      }
    };
    if (couponId) {
      getCoupon();
    }
  }, []);

  useEffect(() => {
    const getWelcomeSeriesMarketingSend = async () => {
      const welcomeSeriesMarketingSendResponse =
        await marketingSendRepository.listByType(shop.id, "welcome_series");
      if (!!welcomeSeriesMarketingSendResponse.length) {
        const marketingSend =
          welcomeSeriesMarketingSendResponse[0] as WelcomeSeriesMarketingSend;
        setWelcomeSeriesMarketingSend(marketingSend);
      }
    };
    if (shop?.id) {
      getWelcomeSeriesMarketingSend();
    }
  }, [shop]);

  const header =
    variant === "thank-you"
      ? "Thank You Offer"
      : variant === "new-subscriber"
      ? "New Subscriber Coupon"
      : "Add Coupon";

  const thankYouSchema = yup.object().shape({
    code: yup
      .string()
      .max(20, "Must be less than 20 characters")
      .required("Code is required"),
    discountType: yup.string().required("Discount type is required"),
    discountValue: yup
      .number()
      .required("Discount amount is required")
      .min(1, "Must be greater than 0"),
    orderMinType: yup.string(),
    orderMinValue: yup
      .number()
      .nullable()
      .when("orderMinType", {
        is: "orderValue",
        then: yup
          .number()
          .min(1, "Must be greater than 0")
          .required("Must enter order minimum value"),
      })
      .when("orderMinType", {
        is: "orderQuantity",
        then: yup
          .number()
          .min(1, "Must be greater than 0")
          .required("Must enter order quantity"),
      }),
    sendToOrdersWithin60Days: yup.boolean(),
  });

  const generateRandomCode = (): string => {
    const code =
      shop.websiteUrl.replaceAll("-", "").toUpperCase() +
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 6)
        .toUpperCase();
    return code;
  };

  const alreadyHas5PercentCode = coupons?.some((c) => c.code === "5PERCENTOFF");
  const alreadyHasWelcomeCode = coupons?.some((c) => c.code === "WELCOME");

  const thankYouInitialValues: ThankYouFormValues = {
    code:
      coupon?.code && variant !== "welcome-series"
        ? alreadyHas5PercentCode
          ? generateRandomCode()
          : "5PERCENTOFF"
        : alreadyHasWelcomeCode
        ? generateRandomCode()
        : "WELCOME",
    discountType:
      coupon?.discount?.type ||
      ("percent" as ThankYouFormValues["discountType"]),
    discountValue:
      coupon?.discount?.value && coupon?.discount?.type
        ? coupon?.discount?.type === "amount"
          ? coupon?.discount?.value / 100
          : coupon?.discount?.value
        : variant !== "welcome-series"
        ? 5
        : 10,
    orderMinType: coupon?.minimum?.type || "none",
    orderMinValue:
      coupon?.minimum?.value && coupon?.minimum?.type
        ? coupon?.minimum?.type === "orderValue"
          ? coupon?.minimum?.value / 100
          : coupon?.minimum?.value
        : null,
    sendToOrdersWithin60Days:
      variant !== "welcome-series"
        ? shop?.config?.marketing?.thankYouCoupon?.sendToOrdersWithin60Days !==
          false
        : false,
  };

  const onSubmit = async (
    values: ThankYouFormValues,
    formikProps: FormikProps<ThankYouFormValues>
  ) => {
    setIsSubmitting(true);
    const config =
      variant !== "welcome-series"
        ? "config.marketing.thankYouCoupon"
        : "config.marketing.welcomeSeriesCoupon";
    const newValues = {
      code: values.code,
      discount: {
        type: values.discountType,
        value:
          values.discountType === "amount"
            ? Math.round(values.discountValue * 100)
            : values.discountValue,
      },
      duration: {
        startDate: coupon?.duration?.startDate || moment().unix(),
      },
      metrics: {
        totalRevenue: coupon?.metrics?.totalRevenue || 0,
        totalUses: coupon?.metrics?.totalUses || 0,
      },
      maximumPerCustomer: 1,
      minimum:
        values.orderMinType !== "none" &&
        values.orderMinType &&
        values.orderMinValue
          ? {
              type: values.orderMinType,
              value:
                values.orderMinType === "orderValue"
                  ? Math.round(values.orderMinValue * 100)
                  : values.orderMinValue,
            }
          : null,
      shopId: shop.id,
      status: "active",
    };

    if (coupon) {
      const newCoupon: Coupon = {
        id: coupon?.id,
        ...newValues,
      };
      couponRepository.update(newCoupon).then((c) => {
        dispatch(getCouponAction(shop.id));
        trackEvent("Coupon Edited", { coupon });
      });
      await shopRepository.updateProps(shop.id, {
        [config]: {
          enabled: true,
          couponId: coupon.id,
          sendToOrdersWithin60Days: values.sendToOrdersWithin60Days,
        },
      });
    } else {
      const couponCreated = await couponRepository.create(newValues);
      console.debug(`Created new Coupon with ID [${couponCreated.id}]`);
      dispatch(getCouponAction(shop.id));
      trackEvent("Coupon Created", { coupon: couponCreated });
      if (!shop.checklistCompletions?.includes(ChecklistValues.CouponCreate)) {
        dispatch(
          updateChecklistAction({ shop, items: [ChecklistValues.CouponCreate] })
        );
      }
      await shopRepository.updateProps(shop.id, {
        [config]: {
          enabled: true,
          couponId: couponCreated.id,
          sendToOrdersWithin60Days: values.sendToOrdersWithin60Days,
        },
      });
    }
    setPageRefresh && setPageRefresh(true);
    setIsSubmitting(false);
    handleClose();
  };

  const checkCode = (code: string, coupons) => {
    setUniqueCodeError(null);
    coupons.some((coupon) => {
      if (coupon.code === code.toUpperCase()) {
        setUniqueCodeError({
          type: "error",
          msg: `Coupon code must be unique!`,
        });
      }
    });
  };

  function getAdornment(position: "end" | "start", symbol: string) {
    return (
      <InputAdornment position={position}>
        <Typography>{symbol}</Typography>
      </InputAdornment>
    );
  }

  const discountTypeOptions = [
    { label: "Percent", value: "percent" },
    { label: "Dollars", value: "amount" },
  ];

  const orderMinOptions = [
    { label: "None", value: "none" },
    { label: "Min. Value", value: "orderValue" },
    { label: "Min. Quantity", value: "orderQuantity" },
  ];

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleShowOrderMin = () => {
    if (showOrderMin) {
      formikRef.current.setFieldValue("orderMinType", "");
      formikRef.current.setFieldValue("orderMinValue", "");
      setShowOrderMin(false);
    } else {
      setShowOrderMin(true);
    }
  };

  const removeCoupon = async () => {
    await shopRepository.deleteFields(shop.id, [
      "config.marketing.welcomeSeriesCoupon.couponId",
    ]);
    await couponRepository.delete(couponId);

    const subscriberMarketingSends =
      (await marketingSendRepository.findActiveOfTypeForShop(
        shop.id,
        "subscriber_coupon"
      )) as SubscriberCouponMarketingSend[];
    await Promise.all(
      subscriberMarketingSends.map(async (send) => {
        await marketingSendRepository.updateProps(send.id, {
          status: "inactive",
        });
      })
    );

    setPageRefresh && setPageRefresh(true);

    handleClose();
  };

  const handleRemoveCoupon = async () => {
    dispatch(
      openModal({
        modalType: "SIMPLE_ACTION_ALERT",
        modalProps: {
          show: true,
          title: "Remove Coupon?",
          content:
            "Studies show 86% of customers are more likely to buy when the marketing includes a special offer.",
          buttonText: "Remove Coupon",
          color: "red",
          handleAction: removeCoupon,
        },
      })
    );
  };

  return (
    <GeneralModal
      paperClass={classes.modalPaperClass}
      show={true}
      header={header}
      content={
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          style={{ textAlign: "left" }}
        >
          {variant !== "welcome-series" && (
            <>
              <Typography variant="body1">
                Send an offer to a buyer after they’ve ordered from you.
                Existing customers are more likely to purchase from your shop
                again than new customers.
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "32px" }}>
                Discounts are automatically limited to one per customer.
              </Typography>
            </>
          )}
          <Formik
            initialValues={thankYouInitialValues}
            validationSchema={thankYouSchema}
            onSubmit={onSubmit}
            innerRef={formikRef}
            enableReinitialize
          >
            {(formikProps: FormikProps<ThankYouFormValues>) => (
              <AdminForm>
                {/* Discount Amount */}
                <FormControl className={classes.formControl} required>
                  <FormLabel required className={classes.label}>
                    Discount Amount
                  </FormLabel>
                  <Grid container wrap="nowrap" alignItems="center">
                    <Grid
                      container
                      item
                      xs={12}
                      wrap="nowrap"
                      className={classes.specialInputs}
                    >
                      <Input
                        endAdornment={
                          formikProps.values.discountType === "percent"
                            ? getAdornment("end", "%")
                            : null
                        }
                        error={
                          formikProps.touched.discountValue &&
                          formikProps.errors.discountValue
                        }
                        id="discountValue"
                        min={0}
                        max={
                          formikProps.values.discountType === "percent"
                            ? 100
                            : null
                        }
                        value={formikProps.values.discountValue}
                        required
                        type="text"
                        name="discountValue"
                        className={classes.specialInputRadius}
                        onChange={(event) => {
                          formikProps.values.discountType === "percent"
                            ? formikProps.setFieldValue(
                                "discountValue",
                                event.target.value.replace(/[^\d]/gi, "")
                              ) // only allow digits
                            : formikProps.setFieldValue(
                                "discountValue",
                                event.target.value.replace(/[^\d.]/gi, "")
                              ); // only allow digits and .
                          checkCode(event.target.value, coupons);
                        }}
                        startAdornment={
                          formikProps.values.discountType === "amount"
                            ? getAdornment("start", "$")
                            : null
                        }
                        placeholder="---"
                      />
                      <Select
                        value={formikProps.values.discountType}
                        onChange={(event) =>
                          formikProps.setFieldValue(
                            "discountType",
                            event.target.value
                          )
                        }
                        options={discountTypeOptions}
                        selectClass={classes.selects}
                      />
                    </Grid>
                  </Grid>
                </FormControl>

                {/* Order Minimum */}
                {showOrderMin && (
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <FormLabel className={classes.label}>
                      Order Minimum
                    </FormLabel>
                    <FormGroup row className={classes.formGroup}>
                      <Grid container wrap="nowrap" alignItems="center">
                        <Grid
                          container
                          item
                          xs={12}
                          wrap="nowrap"
                          className={classes.specialInputs}
                        >
                          <Input
                            startAdornment={
                              <InputAdornment position="start">
                                <Typography>
                                  {formikProps.values.orderMinType ===
                                  "orderValue"
                                    ? "$"
                                    : "#"}
                                </Typography>
                              </InputAdornment>
                            }
                            className={classes.specialInputRadius}
                            error={
                              formikProps.touched.orderMinValue &&
                              formikProps.errors.orderMinValue
                            }
                            id="orderMinValue"
                            name="orderMinValue"
                            type="text"
                            placeholder="---"
                            min={0}
                            onChange={(event) =>
                              formikProps.setFieldValue(
                                "orderMinValue",
                                event.target.value.replace(/[^\d]/gi, "")
                              )
                            } // only allow digits
                            step={
                              formikProps.values.orderMinType === "orderValue"
                                ? 0.01
                                : 1
                            }
                          />
                          <Select
                            onChange={(event) => {
                              formikProps.setFieldValue(
                                "orderMinType",
                                event.target.value
                              );
                              if (event.target.value === "none") {
                                formikProps.setFieldTouched(
                                  "orderMinValue",
                                  false
                                );
                                formikProps.setFieldValue("orderMinValue", "");
                              }

                              setTimeout(function () {
                                formikProps.validateForm();
                              }, 1000);
                            }}
                            value={formikProps.values.orderMinType}
                            options={orderMinOptions}
                            selectClass={classes.selects}
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </FormControl>
                )}
                <Typography
                  variant="body1"
                  className={classes.addOrderMinButton}
                  onClick={handleShowOrderMin}
                  style={{
                    color: showOrderMin
                      ? theme.branding.v2.red[500]
                      : theme.branding.v2.plum[500],
                  }}
                >
                  {showOrderMin
                    ? "Remove order minimum"
                    : "+ Add an order minimum"}
                </Typography>

                {/* Code */}
                <FormControl className={classes.formControl}>
                  <FormLabel required className={classes.label}>
                    Code
                  </FormLabel>
                  {uniqueCodeError && (
                    <Typography className={classes.error}>
                      {uniqueCodeError.msg}
                    </Typography>
                  )}
                  <Grid container wrap="nowrap" item xs={12}>
                    <Input
                      fullWidth
                      error={
                        formikProps.touched.code && formikProps.errors.code
                      }
                      id="code"
                      max={20}
                      name="code"
                      placeholder="Eg., CODE123"
                      required
                      value={formikProps.values.code}
                      onChange={(event) => {
                        formikProps.setFieldValue(
                          "code",
                          event.target.value
                            .toUpperCase()
                            .replace(/[\W_]+/g, "")
                        ); // removes non alphanumeric characters
                        checkCode(event.target.value, coupons);
                      }}
                    />
                  </Grid>
                </FormControl>

                {variant !== "welcome-series" && (
                  <Forms.Checkbox
                    name="sendToOrdersWithin60Days"
                    label={
                      <Typography variant="body1">
                        Include customers from the last 60 days.
                      </Typography>
                    }
                  />
                )}
              </AdminForm>
            )}
          </Formik>
        </Grid>
      }
      actions={[
        <Grid
          container
          item
          wrap="nowrap"
          justify="space-between"
          style={{ width: "100%" }}
        >
          {variant !== "welcome-series" && coupon && (
            <ButtonV2
              variant="outlined"
              className={classes.turnOffButton}
              onClick={() => {
                dispatch(
                  openModal({
                    modalType: "TURN_OFF_AUTOMATION_MODAL",
                    modalProps: {},
                  })
                );
              }}
            >
              Turn Off
            </ButtonV2>
          )}
          {variant === "welcome-series" && coupon && (
            <ButtonV2
              variant="outlined"
              className={classes.turnOffButton}
              onClick={handleRemoveCoupon}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Remove Coupon
            </ButtonV2>
          )}
          <Grid
            container
            item
            wrap="nowrap"
            justify="flex-end"
            style={{ gap: "8px" }}
          >
            <ButtonV2 variant="outlined" onClick={handleClose}>
              Cancel
            </ButtonV2>
            <ButtonV2
              variant="contained"
              onClick={() => {
                formikRef.current?.submitForm();
              }}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Save
            </ButtonV2>
          </Grid>
        </Grid>,
      ]}
    />
  );
};

export default ManageCouponModal;

import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddOutlined } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";

type Props = {
  maxFiles: number;
  handleFiles: (files) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  addIcon: {
    color: theme.branding.v2.plum[500],
  },
  bgColor: {
    /* hack to include alpha using theme color */
    backgroundColor: `${theme.branding.v2.plum[100]}7a`,
  },
  dragging: {
    backgroundColor: theme.branding.blue.light,
  },
  nonPrimaryBox: {
    cursor: "pointer",
    borderRadius: "12px",
    width: "105px",
    height: "105px",
    [theme.breakpoints.only("sm")]: {
      height: "117px",
      width: "117px",
    },
    [theme.breakpoints.only("md")]: {
      height: "62px",
      width: "62px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "92px",
      width: "92px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "152px",
      width: "152px",
    },
  },
}));

const AddImage: React.FC<Props> = (props: Props) => {
  const { maxFiles, handleFiles } = props;
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: handleFiles,
    maxFiles: maxFiles,
    multiple: maxFiles > 1,
  });

  return (
    <Box className={clsx([classes.nonPrimaryBox, classes.bgColor])}>
      <div
        {...getRootProps({
          className: `dropzone ${isDragActive ? classes.dragging : ""}`,
        })}
        style={{ height: "100%" }}
      >
        <input {...getInputProps()} />
        {
          <Grid
            container
            style={{ height: "100%" }}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <AddOutlined className={classes.addIcon} />
            </Grid>
          </Grid>
        }
      </div>
    </Box>
  );
};

export default AddImage;

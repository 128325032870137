import React, { useEffect, useState } from "react";
import { Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Banner, Button, Chip, Typography } from "@castiron/components";
import moment from "moment";
import { useTracking } from "@castiron/utils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getService } from "../../../firebase";
import Spinner from "../../Spinner";
import Tooltip from "../../Tooltip";
import { openModal } from "../../../store/reducers/modalConductor";

//Payment Images
import visa from "../../../assets/img/payments/visa.png";
import mastercard from "../../../assets/img/payments/mastercard.png";
import amex from "../../../assets/img/payments/amex.png";
import discover from "../../../assets/img/payments/discover.png";
import applePay from "../../../assets/img/payments/applePay.png";
import googlePay from "../../../assets/img/payments/googlePay.png";
import { getDaysSinceShopCreation } from "@castiron/domain";

const payments = [visa, mastercard, amex, discover, applePay, googlePay];

const stripeCreateAccountService = getService(
  "stripe",
  "stripecreateaccountv2",
  { version: 2 }
);

export const checkLastStripeConnection = (account) => {
  if (account && account.lastStripeConnectionAttempt) {
    const timestamp = account.lastStripeConnectionAttempt;
    const timeToWait = moment.unix(timestamp).add(15, "minutes");
    return moment().utc().isAfter(timeToWait);
  }

  // TODO: Not sure what the default should be or how it would affect other states.
  return true;
};

export const findNextPayoutDate = (account) => {
  const shopCreatedWithinTwoWeeks =
    getDaysSinceShopCreation(account?.createdAt) < 14;
  const now = new Date();
  const date = new Date(now);
  if (
    (["Marketplace", "Nourysh"].includes(account?.tier?.name) &&
      shopCreatedWithinTwoWeeks) ||
    account?.tier.payoutFrequency === "weekly" ||
    account?.subscription?.status === "trial"
  ) {
    date.setDate(now.getDate() + ((1 + 7 - now.getDay()) % 7));
  } else {
    date.setDate(now.getDate() + 1);
  }

  return date;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: 16,
    height: 45,
    minWidth: 235,
  },
  errorColor: {
    color: theme.branding.red.primary,
  },
  mobileButton: {
    width: "100%",
  },
  payoffCard: {
    height: 48,
    maxWidth: 72,
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    margin: "8px 8px 8px 0px",
  },
  payoffContainer: {
    width: "100%",
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 16,
    margin: "28px 0",
    padding: "0 24px",
  },
  payoffItem: {
    margin: "6px 0",
  },
  payoffGetStarted: {
    margin: "20px 0",
  },
  infoColor: {
    color: theme.branding.v2.plum[500],
  },
}));

interface Props {
  redirectUrl?: string;
}

const Payouts: React.FC<Props> = (props) => {
  const { redirectUrl } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { account, isShopLoading, stripeLoginLink, stripeStatus } =
    useAppSelector((state) => ({
      account: state.shops.account,
      isShopLoading: state.shops.loading,
      stripeLoginLink: state.shops.stripe.stripeLink,
      stripeStatus: state.shops.stripe.status,
    }));
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleStripeConnect = async () => {
    try {
      const body = {
        redirectUrl,
      };
      setIsLoading(true);
      if (!account.stripeAccountId) {
        stripeCreateAccountService(body).then((r) => {
          console.debug("stripe create account response", r);
          trackEvent("Stripe Setup Started");
          window.location.href = r.url;
        });
      } else {
        window.location.href = `${stripeLoginLink}?return_uri=https://www.admin.nourysh.com/stripe/payouts`;
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error in handleStripeConnect: ", err);
    }
  };

  const goToStripeLogin = () => {
    console.debug("go to stripeLoginLink: ");
    if (stripeLoginLink) {
      window.location.href = `${stripeLoginLink}?return_uri=https://www.admin.nourysh.com/stripe/payouts`;
    }
  };

  const getButtonLabel = (): string => {
    switch (stripeStatus) {
      case "NOT_CONNECTED":
        return "Start Here";
      case "INCOMPLETE":
        return "Connect with Stripe";
      case "READY":
        return "View Payout Details";
    }
  };

  const openPaymentModal = (): void => {
    dispatch(
      openModal({
        modalType: "PAYMENT_MODAL",
        modalProps: {
          redirectUrl,
          show: true,
        },
      })
    );
  };

  const getButtonClick = () => {
    switch (stripeStatus) {
      case "NOT_CONNECTED":
        return openPaymentModal();
      case "INCOMPLETE":
        return handleStripeConnect();
      case "READY":
        return goToStripeLogin();
    }
  };

  return (
    <Grid>
      {isLoading || isShopLoading ? (
        <Spinner show={isLoading || isShopLoading} />
      ) : (
        <>
          {stripeStatus === "INCOMPLETE" &&
            checkLastStripeConnection(account) && (
              <Banner variant="error">
                <Typography variant="body2" className={classes.errorColor}>
                  It appears you have not completed your Stripe integration.
                  Click the button below to continue your Stripe integration.
                </Typography>
              </Banner>
            )}
          {stripeStatus === "INCOMPLETE" &&
            !checkLastStripeConnection(account) && (
              <Banner variant="error">
                <Typography variant="body2" className={classes.errorColor}>
                  It appears you recently attempted to connect with Stripe.
                  Please check back shortly to see if additional information is
                  needed.
                </Typography>
              </Banner>
            )}
          {stripeStatus === "READY" && (
            <Banner variant="info-plum">
              <Typography variant="body2" className={classes.infoColor}>
                {(["Marketplace", "Nourysh"].includes(account?.tier?.name) &&
                  getDaysSinceShopCreation(account?.createdAt) < 14) ||
                account?.tier.payoutFrequency === "weekly" ||
                account?.subscription?.status === "trial" ? (
                  <>
                    Payouts occur every Monday. Your next payout is scheduled on{" "}
                    <b>{findNextPayoutDate(account).toLocaleDateString()}.</b>
                  </>
                ) : (
                  <>
                    Payouts occur every day. Your next payout is scheduled on{" "}
                    <b>{findNextPayoutDate(account).toLocaleDateString()}.</b>
                  </>
                )}
              </Typography>
            </Banner>
          )}
          <Grid container className={classes.payoffContainer}>
            {stripeStatus !== "READY" && (
              <Grid xs={12} className={classes.payoffItem}>
                <Chip
                  style={{
                    background: theme.branding.yellow.light,
                    marginTop: 10,
                  }}
                  uppercase
                  bold
                >
                  REQUIRED
                </Chip>
              </Grid>
            )}
            {stripeStatus === "READY" && (
              <Grid xs={12} className={classes.payoffItem}>
                <Chip
                  style={{
                    background: theme.branding.green.light,
                    marginTop: 10,
                  }}
                  uppercase
                  bold
                >
                  INTEGRATION IS LIVE
                </Chip>
              </Grid>
            )}
            <Grid xs={12} className={classes.payoffItem}>
              <Typography variant="subtitle1">
                {stripeStatus === "READY"
                  ? "Payments with Stripe"
                  : "Accept Credit Card Payments"}
                <Tooltip title="We partnered with Stripe because they’re one of the world’s most secure and trusted brands, processing payments for 3M+ businesses. While this is currently our primary way of processing payments, additional options will be available in the future." />
              </Typography>
            </Grid>
            <Grid xs={12} className={classes.payoffItem}>
              <Typography variant="body2">
                Nourysh partners with Stripe to securely process payments and
                deposit your sales directly to your bank account.{" "}
                {stripeStatus === "READY"
                  ? "Your store is able to"
                  : "Once connected, you’ll be able to"}{" "}
                accept all major debit and credit cards, as well as wallets,
                including:
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              container
              justify={isMobile ? "space-between" : "flex-start"}
              spacing={2}
            >
              {payments.map((image, index) => (
                <Grid
                  xs={4}
                  sm={2}
                  item
                  key={`payment-${index}`}
                  className={classes.payoffCard}
                  style={{ backgroundImage: `url(${image})` }}
                />
              ))}
            </Grid>
            <Grid xs={12} className={classes.payoffGetStarted}>
              {stripeStatus !== "READY" && (
                <Grid
                  container
                  xs={12}
                  justify={isMobile ? "center" : "flex-start"}
                >
                  <Typography variant="body2">Ready to get started?</Typography>
                </Grid>
              )}
              <Grid
                container
                xs={12}
                justify={isMobile ? "center" : "flex-start"}
                style={{ marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  className={isMobile ? classes.mobileButton : ""}
                  onClick={getButtonClick}
                >
                  {getButtonLabel()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Payouts;

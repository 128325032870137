export type TemplateType =
  // Basic Templates
  | "product-promo"
  | "event-promo"
  | "coupon-promo"
  | "ticketed-event-promo"
  // Seasonal Templates
  | 'graduation-announcement'
  | 'graduation-coupon'
  | 'graduation-last-call'
  | 'easter-announcement'
  | 'easter-coupon'
  | 'easter-reminder'
  | 'stpatricks-announcement'
  | 'stpatricks-last-call';

export interface SmsTemplate {
  pageTitle: string;
  pageTitleMobile: string;
  step1TitleUnselected: string;
  step1TitleSelectedPrefix: string;
  message: string;
  postSendModalTitlePrefix: string;
  isCoupon: boolean;
  showStandardProducts: boolean;
  showTicketedEvents: boolean;
  eventName: string; // Used for the AI generated email content
  eventTagFilter: string; // Use if you need to filter 
  utmCampaign: string;
}

export const smsTemplates = {
  // Basic Templates
  "product-promo": {
    pageTitle: "Product Promo Text",
    pageTitleMobile: "Product Promo",
    step1TitleUnselected: "Product to Promote",
    step1TitleSelectedPrefix: '',
    message:
      "Don’t miss out - these products are selling fast! Order yours today!",
    postSendModalTitlePrefix: "Product Promo Text",
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: 'product_announcement'
  },
  "coupon-promo": {
    pageTitle: "Coupon Promo Text",
    pageTitleMobile: "Coupon Promo",
    step1TitleUnselected: "",
    step1TitleSelectedPrefix: '',
    message: "Check out this exclusive offer just for you! 💕",
    postSendModalTitlePrefix: "Coupon Promo Text",
    isCoupon: true,
    showStandardProducts: false,
    showTicketedEvents: false,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: 'coupon_announcement',
  },
  "event-promo": {
    pageTitle: "Event Promo Text",
    pageTitleMobile: "Event Promo",
    step1TitleUnselected: "Product to Promote",
    step1TitleSelectedPrefix: '',
    message:
      "Will we see you there? Check out where we’ll be in person next and shop early:",
    postSendModalTitlePrefix: "Event Promo Text",
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: 'event_announcement',
  },
  "ticketed-event-promo": {
    pageTitle: "Ticketed Event Promo",
    pageTitleMobile: "Ticketed Event",
    step1TitleUnselected: "Event to Promote",
    step1TitleSelectedPrefix: '',
    message: "Join us for our upcoming event! Act now before tickets sell out!",
    postSendModalTitlePrefix: "Event Promo Text",
    isCoupon: false,
    showStandardProducts: false,
    showTicketedEvents: true,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: 'ticketed_event_promo',    
  },
  // Seasonal Templates
  'graduation-announcement': {
    pageTitle: 'Graduation Announcement',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Product to Promote',
    step1TitleSelectedPrefix: '',
    message: 'Add some pomp and circumstance to your grad’s special day with handmade gifts! 🎓',
    postSendModalTitlePrefix: 'Graduation Text',
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation_sms',
  },
  'graduation-coupon': {
    pageTitle: 'Graduation Coupon',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: '',
    step1TitleSelectedPrefix: '',
    message: 'Looking for something special for the grad in your life? Shop now and save!',
    postSendModalTitlePrefix: 'Graduation Text',
    isCoupon: true,
    showStandardProducts: false,
    showTicketedEvents: false,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation_sms',
  },
  'graduation-last-call': {
    pageTitle: 'Graduation Last-Call',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Product to Promote',
    step1TitleSelectedPrefix: '',
    message: 'Soon tassels will be flipped - grab your artisanal grad gifts before time runs out!',
    postSendModalTitlePrefix: 'Graduation Text',
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation_sms',
  },
  'easter-announcement': {
    pageTitle: 'Easter Announcement',
    pageTitleMobile: 'Easter',
    step1TitleUnselected: 'Product to Promote',
    step1TitleSelectedPrefix: '',
    message: 'Add a dose of Easter cheer to your home this spring with my goods! 💐',
    postSendModalTitlePrefix: 'Easter Text',
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: 'Easter',
    eventTagFilter: '',
    utmCampaign: 'easter_sms',
  },
  'easter-coupon': {
    pageTitle: 'Easter Coupon',
    pageTitleMobile: 'Easter',
    step1TitleUnselected: '',
    step1TitleSelectedPrefix: '',
    message: 'Handmade Easter items: How egg-cellent! 🐣Shop now and save!',
    postSendModalTitlePrefix: 'Easter Text',
    isCoupon: true,
    showStandardProducts: false,
    showTicketedEvents: false,
    eventName: 'Easter',
    eventTagFilter: '',
    utmCampaign: 'easter_sms',
  },
  'easter-reminder': {
    pageTitle: 'Easter Reminder',
    pageTitleMobile: 'Easter',
    step1TitleUnselected: 'Product to Promote',
    step1TitleSelectedPrefix: '',
    message: 'Hop to it! 🐇Easter is right around the corner - place your order today!',
    postSendModalTitlePrefix: 'Easter Text',
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: 'Easter',
    eventTagFilter: '',
    utmCampaign: 'easter_sms',
  },
  'stpatricks-announcement': {
    pageTitle: 'St. Patrick’s Day Announcement',
    pageTitleMobile: "St. Patrick's",
    step1TitleUnselected: 'Product to Promote',
    step1TitleSelectedPrefix: '',
    message: 'Add a touch of luck to your St. Patty’s Day celebrations with artisanal goods! Shop today! ☘️',
    postSendModalTitlePrefix: "St. Patrick's Day Text",
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: "St. Patrick's Day",
    eventTagFilter: '',
    utmCampaign: 'stpatricksday_sms',
  },
  'stpatricks-last-call': {
    pageTitle: 'Last-Call for St. Patrick’s Day Orders',
    pageTitleMobile: "St. Patrick's",
    step1TitleUnselected: 'Product to Promote',
    step1TitleSelectedPrefix: '',
    message: 'Like a 🌈 these St. Patrick’s Day items won’t last! Order today before time runs out!',
    postSendModalTitlePrefix: "St. Patrick's Day Text",
    isCoupon: false,
    showStandardProducts: true,
    showTicketedEvents: true,
    eventName: "St. Patrick's Day",
    eventTagFilter: '',
    utmCampaign: 'stpatricksday_sms',
  },
};

import React, { ReactNode } from 'react';
import {withRouter, Link, useHistory} from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Spinner from '../../Spinner';
import { Forms } from '@castiron/components';
import '../Auth.scss';
import { CardContent, CardActions, Button, Typography, Grid, useTheme } from '@material-ui/core';
import firebase from 'firebase/compat/app';

const { Input, SubmissionError } = Forms;

type Props = {
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});


const ForgotPassword: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const theme = useTheme();

  // eslint-disable-next-line
  const onSubmit = async (values: any, { setSubmitting, setFieldError }: any): Promise<any> => {
    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
      setSubmitting(false);
      history.push('/');
    } catch (e) {
      console.log('error with forgot password start', e);
      setSubmitting(false);
      setFieldError('general', e.message);
    }
  };

  return (
    <>
      <Formik initialValues={{ email: '' }} validationSchema={schema} onSubmit={onSubmit}>
        {({ isSubmitting, errors }): ReactNode => (
          <>
            <Spinner show={isSubmitting} />
            <>
              {<SubmissionError msg={errors.general} marketplace='nourysh' />}
              {errors?.general && <SubmissionError msg={errors.general} marketplace='nourysh' />}
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  Forgot Password?
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" align="center" gutterBottom>
                  {`Enter the email associated with this account and we'll send a password reset link.`}
                </Typography>
                <Form>
                  <Input type="email" name="email" error={errors && errors.email} label="Your email" />
                  <CardActions>
                    <Button
                      variant="contained"
                      style={{
                        margin: '0 auto',
                      }}
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                    >
                      Reset Password
                    </Button>
                  </CardActions>
                </Form>
              </CardContent>
            </>
          </>
        )}
      </Formik>
      <Grid container justify="center" className="auth-footer">
        <Grid item xs={12} container justify="center" style={{ margin: '5px 0' }}>
          <Typography align="center" variant="caption" color="textSecondary">
            <Link to="/" style={{ color: theme.branding.v2.plum[500] }}>Back to login</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;

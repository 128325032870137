import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NouryshLogoPurple } from "@castiron/components";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks";

type Props = {
  isAuth?: boolean;
  stickyFooterClass?: string;
};

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 32,
    paddingBottom: 32,
  },
  innerPadding: {
    paddingLeft: 56,
    paddingRight: 105,
  },
  linksContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 24,
    alignItems: "center",
  },
  link: {
    display: "flex",
    textDecoration: "none",
  },
}));

const FooterDesktop: React.FC<Props> = (props: Props) => {
  const { isAuth, stickyFooterClass } = props;
  const classes = useStyles();
  const { me, shop } = useAppSelector((state) => ({
    me: state.users.me,
    shop: state.shops.shop,
  }));

  return (
    <div
      className={`${classes.container} ${
        !isAuth && classes.innerPadding
      } ${stickyFooterClass}`}
    >
      <div className={classes.linksContainer}>
        <NouryshLogoPurple style={{ width: "5em" }} />
        <Typography variant="caption" color="textSecondary">
          &copy; {new Date().getFullYear()} Nourysh
        </Typography>
        <Link to={{ pathname: "/contact-us" }} className={classes.link}>
          {me && (
            <Typography variant="caption" color="textSecondary">
              Contact Us
            </Typography>
          )}
        </Link>
        <Link
          className={classes.link}
          target="_blank"
          to={{ pathname: "https://nourysh.com/privacy-policy" }}
        >
          <Typography variant="caption" color="textSecondary">
            Privacy Policy
          </Typography>
        </Link>
        <Link
          className={classes.link}
          target="_blank"
          to={{ pathname: "https://nourysh.com/terms-and-conditions" }}
        >
          <Typography variant="caption" color="textSecondary">
            Terms &amp; Conditions
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default FooterDesktop;

import React, { useMemo } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  Button as MUIButton,
  Checkbox,
  Select,
  MenuItem,
  Link,
} from '@material-ui/core';
import ButtonGroup from '../../ButtonGroup';
import { Field, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { Product, Category } from '@castiron/domain';
import { Input, ProductImage, Button, Chip } from '@castiron/components';
import { defaultMsg, FormValues } from '.';
import ValidationError from '../../ValidationError';
import moment from 'moment';
import { sendFrequencyLabel } from '../Emails';
import { BaseProduct } from '@castiron/domain';
import { productHasImages } from '../../../lib/imageUtils';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginBottom: '16px',
    width: 'fit-content',
  },
  cancelButton: {
    marginBottom: '16px',
    marginTop: '40px',
  },
  sendDatesContainer: {
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: '#F8F8F8',
    marginTop: '32px',
    marginBottom: '40px',
  },
  sendDates: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  sendDateLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  label: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  decorator: {
    color: '#FC6E26',
  },
  chooseProductSublabel: {
    color: theme.branding.gray[600],
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  productChooseContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '6px',
    padding: '24px 16px 0px 32px',
  },
  productChooseHeader: {
    marginBottom: '35px',
    paddingRight: '16px',
  },
  selectAll: {
    padding: '7px 16px 7px 7px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '4px',
    marginRight: '8px',
    cursor: 'pointer',
  },
  numberSelected: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.branding.gray[600],
    width: '100%',
    textAlign: 'right',
  },
  checkboxLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    marginLeft: '7px',
  },
  select: {
    width: '100%',
  },
  product: {
    width: '136px',
    marginRight: '16px',
    marginBottom: '24px',
    cursor: 'pointer',
    position: 'relative',
  },
  productImg: {
    height: '136px',
    width: '136px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '4px',
    marginBottom: '8px',
  },
  checkbox: {
    position: 'absolute',
    zIndex: 1,
    left: -3,
    top: 5,
  },
  image: {
    height: '100%',
  },
  noImgText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.branding.gray[600],
    textAlign: 'center',
    margin: '0px 16px',
  },
  productName: {
    fontSize: '14px',
    fontWeight: 400,
  },
  defaultMsg: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.branding.gray[800],
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  productError: {
    marginBottom: '16px',
  },
  noProductsContainer: {
    padding: '75px 0px 100px 0px',
  },
  noProductsFont: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
  },
}));

type Props = {
  formikProps: FormikProps<FormValues>;
  products: BaseProduct[];
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  category: string;
  filteredProducts: BaseProduct[];
  categories: Category[];
  lastSendDate: number;
  marketingSendId?: string;
};

const MobileForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    formikProps,
    products,
    category,
    setCategory,
    filteredProducts,
    categories,
    lastSendDate,
    marketingSendId,
  } = props;

  const { values, setFieldValue, errors, touched, isSubmitting } = formikProps;

  const isValid = useMemo(
    () => !errors.status || !errors.frequency || !errors.artisanMessage || !errors.includedProductIds,
    [errors],
  );

  const nextSendDate = useMemo(() => {
    if (lastSendDate) {
      if (values.frequency === 'weekly') {
        return moment
          .unix(lastSendDate)
          .add(1, 'weeks')
          .day(1);
      } else if (values.frequency === 'biweekly') {
        return moment
          .unix(lastSendDate)
          .add(2, 'weeks')
          .day(1);
      } else {
        return moment.unix(lastSendDate).add(1, 'months');
      }
    } else {
      return values.frequency === 'weekly' || 'biweekly'
        ? moment()
            .add(1, 'weeks')
            .day(1)
        : moment().add(1, 'months');
    }
  }, [values.frequency, lastSendDate]);

  return (
    <Form>
      <Grid container direction="column">
        <Chip colorScheme={values.status === 'active' ? 'success' : 'error'} uppercase bold className={classes.chip}>
          {values.status === 'active' ? 'Active' : 'Inactive'}
        </Chip>
        <Typography variant="h3">Shop update</Typography>
        {marketingSendId && (
          <Button
            onClick={() => {
              dispatch(
                openModal({
                  modalType: 'AUTOMATION_TEST_MODAL',
                  modalProps: {
                    marketingSendId: marketingSendId,
                  },
                }),
              );
            }}
            variant="outlined"
            color="inherit"
          >
            Send a test email
          </Button>
        )}
        <Grid container direction="column" className={classes.sendDatesContainer}>
          <Grid
            style={{
              marginBottom: lastSendDate && '24px',
            }}
          >
            <Typography className={classes.sendDateLabel}>Next send:</Typography>
            <Typography className={classes.sendDates}>
              {nextSendDate.format('dddd, MMMM D, YYYY')} 10:00 AM ET
            </Typography>
          </Grid>
          {lastSendDate && (
            <Grid>
              <Typography className={classes.sendDateLabel}>Last send:</Typography>
              <Typography className={classes.sendDates}>
                {moment.unix(lastSendDate).format('dddd, MMMM D, YYYY')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Typography
          className={classes.label}
          style={{
            marginBottom: '-14px',
          }}
        >
          Send status<span className={classes.decorator}> *</span>
        </Typography>
        <Field
          required
          as={ButtonGroup}
          error={touched.status && errors.status}
          name="status"
          value={values.status}
          onChange={(value): void => {
            switch (value) {
              case 'active':
                setFieldValue('status', 'active');
                break;
              case 'inactive':
                setFieldValue('status', 'inactive');
                break;
            }
          }}
          buttons={['active', 'inactive']}
        />
        <Typography className={classes.chooseProductSublabel}>
          {`Setting your send status to inactive will turn off the ${sendFrequencyLabel(
            values.frequency,
          )} shop update autosend.`}
        </Typography>
        <Typography
          className={classes.label}
          style={{
            marginTop: '24px',
          }}
        >
          Send frequency<span className={classes.decorator}> *</span>
        </Typography>
        <Select
          onChange={event => setFieldValue('frequency', event.target.value as string)}
          variant="outlined"
          className={classes.select}
          value={values.frequency}
        >
          <MenuItem value="weekly">Once a week</MenuItem>
          <MenuItem value="biweekly">Once every other week</MenuItem>
          <MenuItem value="monthly">Once a month</MenuItem>
        </Select>
        <Typography className={classes.label} style={{ marginTop: '56px' }}>
          Select included products<span className={classes.decorator}> *</span>
        </Typography>
        <Typography className={classes.chooseProductSublabel}>
          Choose at least two products to randomly be shown in your shop update emails. Only active products with
          inventory and an image at time of send can be included in the email.
        </Typography>
        {errors.includedProductIds && touched.includedProductIds && (
          <div className={classes.productError}>
            <ValidationError msg={errors.includedProductIds as string} />
          </div>
        )}
        <Grid className={classes.productChooseContainer}>
          <Grid container wrap="nowrap" className={classes.productChooseHeader}>
            <Grid
              onClick={() =>
                setFieldValue(
                  'includedProductIds',
                  products.map(product => product.id),
                )
              }
              xs={8}
              container
              alignItems="center"
              wrap="nowrap"
              className={classes.selectAll}
            >
              <Grid container>
                <Checkbox
                  color="primary"
                  checked={products.every(product => values.includedProductIds.includes(product.id))}
                />

                <Typography className={classes.checkboxLabel}>Select all</Typography>
              </Grid>
              <Typography
                className={classes.numberSelected}
              >{`${values.includedProductIds.length} of ${products.length} selected`}</Typography>
            </Grid>
            <Grid xs={4}>
              <Select
                onChange={event => setCategory(event.target.value as string)}
                variant="outlined"
                className={classes.select}
                value={category}
              >
                <MenuItem value="all">All</MenuItem>
                {categories?.map((category: Category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container>
            {!!filteredProducts.length ? (
              filteredProducts.map((product: Product) => (
                <Grid
                  className={classes.product}
                  key={product.id}
                  onClick={() =>
                    values.includedProductIds.includes(product.id)
                      ? setFieldValue(
                          'includedProductIds',
                          values.includedProductIds.filter(id => id !== product.id),
                        )
                      : setFieldValue('includedProductIds', [...values.includedProductIds, product.id])
                  }
                >
                  <Checkbox
                    color="primary"
                    checked={values.includedProductIds.includes(product.id)}
                    className={classes.checkbox}
                  />
                  <Grid container justify="center" alignItems="center" className={classes.productImg}>
                    {productHasImages(product) ? (
                      <ProductImage
                        alt={product?.title}
                        className={classes.image}
                        src={
                          (product?.images?.length > 0 &&
                            (product?.images[0]?.smallVersion?.downloadUrl ||
                              product?.images[0]?.mediumVersion?.downloadUrl ||
                              product?.images[0]?.downloadUrl)) ||
                          product.imageObj?.downloadUrl
                        }
                        backgroundColor="inherit"
                      />
                    ) : (
                      <Typography className={classes.noImgText}>Image not available for this product</Typography>
                    )}
                  </Grid>
                  <Typography className={classes.productName}>{product.title}</Typography>
                </Grid>
              ))
            ) : (
              <Grid container justify="center" alignItems="center" className={classes.noProductsContainer}>
                <Typography className={classes.noProductsFont}>No products available</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Typography
          className={classes.label}
          style={{
            marginTop: '57.5px',
            marginBottom: '-8px',
          }}
        >
          Personal sign off<span className={classes.decorator}> *</span>
        </Typography>
        <Input
          error={touched.artisanMessage && errors.artisanMessage}
          name="artisanMessage"
          multiline
          rows={8}
          placeholder={defaultMsg}
        />
        <Grid container justify="flex-end">
          <Link className={classes.defaultMsg} onClick={() => setFieldValue('artisanMessage', defaultMsg)}>
            Reset to default mesage
          </Link>
        </Grid>
        <Button
          className={classes.cancelButton}
          onClick={() => history.push('/marketing/automations')}
          variant="outlined"
          fullWidth
        >
          Cancel
        </Button>
        <Button disabled={!isValid || isSubmitting} fullWidth type="submit" variant="contained">
          Save
        </Button>
      </Grid>
    </Form>
  );
};

export default MobileForm;

import {
  ButtonColorOption,
  ButtonRoundnessOption,
  ShopFontOption,
  ThemeColorOption,
  ThemeNameOption,
} from "@castiron/domain";
import { useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const themeNames = [
  "Sweet Patisserie",
  "Lavender Latte",
  "Rose Bakery",
  "Sugar Cookie",
  "Strawberry Sorbet",
  "Pink Lemonade",
  "Tropical Macaron",
  "Paris Cafe",
  "Blueberry Crisp",
  "Classic Castiron",
];

export const findThemeConfig = (
  selectedTheme: ThemeNameOption,
  theme: Theme
): {
  name: ThemeNameOption;
  themeColors: ThemeColorOption;
  shopButtonColor: ButtonColorOption;
  shopButtonRoundness: ButtonRoundnessOption;
  shopFont: ShopFontOption;
} => {
  switch (selectedTheme) {
    case "classic-castiron":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.gray[0],
          background: theme.branding.v2.gray[0],
          accent: {
            primary: theme.branding.v2.gray[100],
            secondary: theme.branding.v2.gray[100],
          },
        },
        shopButtonColor: "blue",
        shopButtonRoundness: "12px",
        shopFont: {
          primary: "nunito-sans",
          secondary: "nunito-sans",
        },
      };
    case "pink-lemonade":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.lime[50],
          background: theme.branding.v2.lime[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.lime[100],
          },
        },
        shopButtonColor: "pink",
        shopButtonRoundness: "0px",
        shopFont: {
          primary: "dela-gothic-one",
          secondary: "roboto",
        },
      };
    case "tropical-macaron":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.indigo[50],
          background: theme.branding.v2.indigo[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.indigo[100],
          },
        },
        shopButtonColor: "teal",
        shopButtonRoundness: "100px",
        shopFont: {
          primary: "jost",
          secondary: "jost",
        },
      };
    case "sugar-cookie":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.cyan[50],
          background: theme.branding.v2.cyan[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.cyan[100],
          },
        },
        shopButtonColor: "cyan",
        shopButtonRoundness: "12px",
        shopFont: {
          primary: "shrikhand",
          secondary: "lora",
        },
      };
    case "strawberry-sorbet":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.pink[50],
          background: theme.branding.v2.pink[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.pink[100],
          },
        },
        shopButtonColor: "pink",
        shopButtonRoundness: "12px",
        shopFont: {
          primary: "playfair-display",
          secondary: "lato",
        },
      };
    case "sweet-patisserie":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.brown[50],
          background: theme.branding.v2.brown[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.brown[200],
          },
        },
        shopButtonColor: "pink",
        shopButtonRoundness: "100px",
        shopFont: {
          primary: "sail",
          secondary: "montserrat",
        },
      };
    case "lavender-latte":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.brown[50],
          background: theme.branding.v2.brown[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.brown[200],
          },
        },
        shopButtonColor: "deepPurple",
        shopButtonRoundness: "100px",
        shopFont: {
          primary: "ultra",
          secondary: "pt-serif",
        },
      };
    case "rose-bakery":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.brown[50],
          background: theme.branding.v2.brown[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.brown[200],
          },
        },
        shopButtonColor: "lightRed",
        shopButtonRoundness: "12px",
        shopFont: {
          primary: "nunito-sans",
          secondary: "nunito-sans",
        },
      };
    case "paris-cafe":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.gray[50],
          background: theme.branding.v2.gray[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.gray[200],
          },
        },
        shopButtonColor: "green",
        shopButtonRoundness: "12px",
        shopFont: {
          primary: "lemon",
          secondary: "pt-serif",
        },
      };
    case "blueberry-crisp":
      return {
        name: selectedTheme,
        themeColors: {
          header: theme.branding.v2.gray[50],
          background: theme.branding.v2.gray[50],
          accent: {
            primary: theme.branding.v2.gray[0],
            secondary: theme.branding.v2.gray[200],
          },
        },
        shopButtonColor: "indigo",
        shopButtonRoundness: "0px",
        shopFont: {
          primary: "philosopher",
          secondary: "mulish",
        },
      };
  }
};

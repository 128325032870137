import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { Input, Checkbox, DiscardButton, SaveButton } from '@castiron/components';
import { useFormikContext } from 'formik';

type Props = {
  smallView?: boolean;
  isOrder?: boolean;
  showAddressTwo?: boolean;
  setFooterCTAs?: (ctas: ReactNode[]) => void;
  setEditMode?: (editMode: boolean) => void;
  context?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  emailInputContainer: {
    flexGrow: 0.5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  checkboxesContainer: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxSubtitle: {
    color: theme.branding.gray[700],
    fontSize: 12,
    alignContent: 'center',
  },
  actionButtons: {
    display: 'flex',
    gap: 14,
    marginTop: 22,
  },
}));

const AddSingleCustomer: React.FC<Props> = (props: Props) => {
  const { smallView, setFooterCTAs, context } = props;
  const { errors, touched, values, setFieldValue, dirty, isSubmitting, handleSubmit }: any = useFormikContext();
  const classes = useStyles();


  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation='/contacts/overview' />,
      <SaveButton
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        customName={context === 'customerView' ? 'Save' : 'Add Contact'}
      />
    ])
  }, [isSubmitting]);

  return (
    <Box>
      <Box className={!smallView ? classes.row : ''}>
        <Box className={classes.emailInputContainer}>
          <Input
            error={touched.email && errors.email}
            fullWidth
            label="Email"
            name={'email'}
            placeholder="you@example.com"
            required
          />
        </Box>
        <Input
          type="tel"
          error={touched.mobileNumber && errors.mobileNumber}
          fullWidth={smallView}
          name={'mobileNumber'}
          label="Mobile phone"
          placeholder="(555) 555-5555"
        />
      </Box>
      <Box className={!smallView ? classes.row : ''}>
        <Input fullWidth name={'firstName'} label="First name" placeholder="First name" />
        <Input fullWidth name={'lastName'} label="Last name" placeholder="Last name" />
      </Box>
      <Input
        rows={4}
        fullWidth
        multiline
        name={'notes'}
        label="Contact notes"
        placeholder="Notes on preferences, allergies, dropoff information, etc"
      />
      <Box className={classes.checkboxesContainer}>
        <Field
          as={Checkbox}
          onChange={(e): void => setFieldValue('subscribed', e.target.checked)}
          name={'subscribed'}
          checked={values.subscribed}
          label="This contact has opted in to be emailed about news & offers from my shop and Nourysh Marketplace."
        />
      </Box>
    </Box>
  );
};

export default AddSingleCustomer;

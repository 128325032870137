import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import Close from "@material-ui/icons/Close";
import moment from "moment";
import { Typography, ReceiptIcon, Button, Banner } from "@castiron/components";
import { getService } from "../../../../firebase";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import ModalWrapper from "../../../RootModal/ModalWrapper";
import { closeModal } from "../../../../store/reducers/modalConductor";
import { openModal } from "../../../../store/reducers/modalConductor";
import { trackEvent } from "@castiron/utils";

export type Props = {
  open: boolean;
};

const cancelSubscriptionService = getService(
  "subscriptions",
  "cancelsubscription",
  { version: 2 }
);

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: "12px 16px",
  },
  bannerWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 14px)",
    },
  },
  cancelButton: {
    backgroundColor: theme.branding.red.primary,
    color: theme.branding.gray[100],
    padding: 16,
    "&:hover": {
      backgroundColor: theme.branding.red.dark,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 13,
    },
  },
  closeIcon: {
    position: "absolute",
    top: 16,
    right: 16,
    cursor: "pointer",
    zIndex: 10,
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
      right: 24,
      top: 20,
    },
  },
  content: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
      width: "100%",
    },
  },
  featureContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: 16,
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 14px)",
    },
  },
  featureIconContainer: {
    borderRadius: 12,
    backgroundColor: theme.branding.blue.light,
    padding: 16,
    marginRight: 16,
    color: theme.branding.v2.plum[500],
    width: 56,
    height: 56,
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: "22px 24px",
    "& h6": {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "28px",
    },
  },
  keepButton: {
    padding: 16,
  },
}));

const CancelPlanModal: React.FC<Props> = (props: Props) => {
  const { open } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { account } = useAppSelector((state) => ({
    account: state.shops.account,
  }));

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (account?.isInTrial()) {
      const trialBillingDate = moment
        .unix(account?.subscription?.trialEndDate)
        .format("LL");
      setEndDate(trialBillingDate);
    } else {
      const nextBillingDate = moment
        .unix(account?.subscription?.nextPaymentDate)
        .format("LL");
      setEndDate(nextBillingDate);
    }
  }, [account]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleCancelSubscription = async () => {
    setIsSubmitting(true);
    const cancelResponse = await cancelSubscriptionService({
      reason: "Plan Canceled",
      renewalDate: endDate,
    });

    if (cancelResponse?.success) {
      trackEvent("Owner Canceled Subscription");
      dispatch(
        openModal({
          modalType: "CANCEL_SUCCESS_MODAL",
          modalProps: {
            open: true,
          },
        })
      );
    } else {
      setShowError(true);
    }
  };

  const features = [
    {
      title: "Your Online Shop",
      description:
        "Your shop will no longer be viewable to customers and you will lose access to Nourysh admin.",
      icon: <ShoppingCartOutlinedIcon />,
    },
    {
      title: "Email Marketing",
      description:
        "You will no longer be able to easily send marketing emails.",
      icon: <EmailOutlinedIcon />,
    },
    {
      title: "Custom Quotes & Invoicing",
      description:
        "You will no longer be able to receive quote requests or send invoices.",
      icon: <ListAltOutlinedIcon />,
    },
    {
      title: "Your System of Record",
      description:
        "You will no longer be able to view transaction, contact, or inventory history in Nourysh.",
      icon: <ReceiptIcon />,
    },
  ];

  return (
    <ModalWrapper fullScreen={isMobile} size="sm" show={open}>
      <DialogTitle className={classes.header}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Typography variant="h3">Cancel Subscription</Typography>
        </Grid>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h3" style={{ marginBottom: 8 }}>
          Are you sure you want to cancel?
        </Typography>
        {account?.hasLegacySubscription() && account?.isInTrial() ? (
          <Grid>
            <Typography variant="body1" style={{ marginBottom: 16 }}>
              By canceling you will be reverted back to your Starter Plan at the
              end of your trial on{" "}
              <span style={{ fontWeight: 700 }}>{endDate}</span>.
            </Typography>
          </Grid>
        ) : (
          <Grid>
            <Typography variant="body1" style={{ marginBottom: 16 }}>
              By canceling you will no longer have access to: 
            </Typography>
            <Grid container>
              {features.map((feature, index) => (
                <Grid
                  key={index}
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  className={classes.featureContainer}
                  container
                  item
                >
                  <Grid className={classes.featureIconContainer} item>
                    {feature.icon}
                  </Grid>
                  <Grid direction="column" justify="center" container item>
                    <Typography variant="subtitle1">{feature.title}</Typography>
                    <Typography
                      variant="body4"
                      style={{ color: theme.branding.gray[600] }}
                    >
                      {feature.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {account?.isInTrial() && !showError && (
              <Grid style={{ marginTop: 24 }}>
                <Banner variant="info-white" className={classes.bannerWidth}>
                  <Typography variant="body4">
                    You will continue to have access to your account until the
                    end of your trial, which is{" "}
                    <span style={{ fontWeight: 700 }}>{endDate}</span>.
                  </Typography>
                </Banner>
              </Grid>
            )}
            {showError && (
              <Grid style={{ marginTop: 24 }}>
                <Banner variant="error" className={classes.bannerWidth}>
                  <Typography
                    variant="body4"
                    style={{ color: theme.branding.red.primary }}
                  >
                    An error occurred while trying to cancel your subscription.
                    Please refresh your browser and try again.
                  </Typography>
                </Banner>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          disabled={isSubmitting}
          variant="outlined"
          onClick={handleClose}
          className={classes.keepButton}
        >
          Keep{!isMobile && " Subscription"}
        </Button>
        <Button
          disabled={isSubmitting}
          variant="contained"
          onClick={handleCancelSubscription}
          className={classes.cancelButton}
        >
          Cancel Subscription
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default CancelPlanModal;

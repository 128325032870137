import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import { Helmet } from "react-helmet";
import Stats from "./Stats";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import { getCustomersAction } from "../../store/reducers/customers";
import CustomerList from "../Customers/CustomerList";
import { ButtonV2 } from "@castiron/components";
import { Customer } from "@castiron/domain";
import { defaultTimeZone, download, useTracking } from "@castiron/utils";
import { getService } from "../../firebase";
import { getStripeBalanceAction } from "../../store/reducers/balance";
import { LayoutPageProps } from "../Layout";
import AvatarMenu from "../Menus/AvatarMenu";
import Select from "../Select";

interface CustomerRow extends Customer {
  totalOrders: number;
}

const exportSalesTaxService = getService("orders", "exportsalestax", {
  type: "request",
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: "31px 15px",
    },
  },
  downloadIcon: {
    marginRight: 5,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 24,
    marginTop: 64,
    justifyContent: "space-between",
  },
  link: {
    fontSize: 14,
    color: theme.branding.v2.plum[500],
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
    },
  },
  select: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  taxReportingButton: {
    paddingLeft: 14,
    paddingRight: 14,
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    [theme.breakpoints.up("md")]: {
      gap: 15,
    },
    [theme.breakpoints.down("sm")]: {
      gap: 6,
      justifyContent: "space-between",
    },
  },
  title: {
    lineHeight: "26px",
    fontSize: 30,
    marginBottom: 0,
    fontWeight: 800,
  },
}));

const Analytics: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { trackEvent } = useTracking();

  const [period, setPeriod] = useState(30);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const options = [
    { value: 30, label: "Last 30 days" },
    { value: 60, label: "Last 60 days" },
    { value: 90, label: "Last 90 days" },
  ];

  const { shop, isCustomersLoading, customers } =
    useAppSelector((state) => ({
      isCustomersLoading: state.customers.loading,
      shop: state.shops.shop,
      customers: state.customers.customers,
    }));

  useEffect(() => {

    const getCustomers = async () => {
      dispatch(getCustomersAction(shop.id));
    };

    if (shop?.id) {
      getCustomers();
    }
  }, [shop]);

  useEffect(() => {
    dispatch(getStripeBalanceAction());
  }, []);

  useEffect(() => {
    setPageTitle("Analytics");

    return () => {
      setPageTitle("");
    };
  }, []);

  const salesTaxCSVhandler = async () => {
    setIsSubmitting(true);

    try {
      const csv = await exportSalesTaxService({
        timeZone: shop?.config?.timeZone || defaultTimeZone,
      });
      const date = moment().format("MMDDYYYY");
      const filename = `${shop.websiteUrl}-tax-report-${date}.csv`;
      download(filename, "text/csv", csv);
      trackEvent("Finances Tax Report Downloaded", {});
      setIsSubmitting(false);
    } catch (err) {
      console.debug(err);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const headerCTAs = isMobile
      ? [<AvatarMenu />]
      : [
          <ButtonV2
            variant="outlined"
            className={classes.taxReportingButton}
            onClick={salesTaxCSVhandler}
            loading={isSubmitting}
          >
            <span className={classes.downloadIcon}>
              <GetAppIcon />
            </span>{" "}
            Download Tax Report CSV
          </ButtonV2>,
        ];
    setHeaderCTAs(headerCTAs);
    setFooterCTAs([]);

    return () => {
      setHeaderCTAs([]);
    };
  }, [isMobile, isSubmitting]);

  const custWithTotals: CustomerRow[] = customers.map((c) => ({
    ...c,
    totalOrders: c.metrics ? c.metrics.totalOrders : 0,
  }));

  return (
    <>
      <Spinner show={isCustomersLoading} />
      <div>
        <Helmet>
          <title>Analytics | Nourysh</title>
        </Helmet>
        <Grid justify="center" container>
          <Grid className={classes.container} item xs={12}>
            <Grid container item justify="flex-end">
              <Select
                value={period}
                onChange={(event): void => setPeriod(event.target.value)}
                options={options}
                selectClass={classes.select}
              />
            </Grid>
            <Stats period={period} />
            {customers && customers.length ? (
              <>
                <div className={classes.header}>
                  <div className={classes.textContainer}>
                    <Typography variant="h3" className={classes.title}>
                      Top Customers
                    </Typography>
                    <Link to="/contacts" className={classes.link}>
                      View all customers
                    </Link>
                  </div>
                  {/* <Hidden smDown><SearchInput onChange={handleInputChange} /></Hidden> */}
                </div>
                <CustomerList
                  customerData={custWithTotals}
                  isSubcomponent={true}
                  pageSize={10}
                  hideFooter={true}
                  sortModel={[{ field: "lastOrder", sort: "desc" }]}
                  isMobile={isMobile}
                  showAnalytics={false}
                  source="analytics"
                />
              </>
            ) : (
              <></> // ternary instead of an if check because I kept getting a weird '0' printed on the screen even with the component wasn't showing so using a fragment instead
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Analytics;

import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { getIn, useFormikContext } from "formik";
import _ from "lodash";
import moment from "moment";
import {
  AddressAutoComplete,
  Card,
  DateInput,
  Input,
  RadioInput,
} from "@castiron/components";
import { useAppSelector } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  addressTwoCTA: {
    color: theme.branding.v2.plum[500],
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const fulfillmentDisplays = {
  delivery: "Local Delivery (Extra charges may apply)",
  pickup: "Local Pickup",
  shipping: "Shipping (Extra charges may apply)",
};

const FulfillmentDetailsPreview: React.FC = () => {
  const classes = useStyles();
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const { shop, fulfillments } = useAppSelector((state) => ({
    shop: state.shops.shop,
    fulfillments: state.shops.fulfillments,
  }));

  const [showAddressTwoInput, setShowAddressTwoInnput] = useState(false);

  const fulfillmentOptions = _.uniq(
    fulfillments.filter((o) => o.status === "active")?.map((o) => o.type) || []
  ).map((option) => {
    return {
      display: fulfillmentDisplays[option],
      value: option,
    };
  });

  return (
    <Card noMargin title="Fulfillment Details">
      <Grid container direction="column">
        <Grid item>
          <RadioInput
            label="Please select your preferred fulfillment method:"
            required
            options={fulfillmentOptions}
            onChange={(value) => setFieldValue("type", value)}
            value={getIn(values, "type")}
            error={getIn(touched, "type") && getIn(errors, "type")}
          />
        </Grid>
        <Grid item>
          <DateInput
            label="What date do you need your order?"
            required
            onChange={(date: moment.Moment) =>
              setFieldValue("date", date?.unix())
            }
            selectedDate={
              getIn(values, "date") ? moment.unix(getIn(values, "date")) : null
            }
            fullWidth
          />
        </Grid>
        {(getIn(values, "type") === "delivery" ||
          getIn(values, "type") === "shipping") && (
          <>
            <Grid item>
              <AddressAutoComplete
                label={`${_.capitalize(getIn(values, "type"))} Address`}
                required
                fulfillmentType={getIn(values, "type")}
                useLabel={false}
                addressFields={{
                  address: "fullAddress",
                  addressLine1: "addressLine1",
                  city: "city",
                  region: "region",
                  regionName: "regionName",
                  postalCode: "postalCode",
                  country: "country",
                }}
                isExpanded
              />
            </Grid>
            <Grid item>
              {showAddressTwoInput ? (
                <Input
                  name="addressLine2"
                  label={`${_.capitalize(
                    getIn(values, "type")
                  )} Address line 2`}
                  type="text"
                  fullWidth
                />
              ) : (
                <Typography
                  variant="button"
                  className={classes.addressTwoCTA}
                  onClick={() => setShowAddressTwoInnput(true)}
                >
                  + Add Address Line 2
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default FulfillmentDetailsPreview;

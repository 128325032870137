import { CollapsableCard, Pill } from "@castiron/components";
import { Transaction } from "@castiron/domain";
import { defaultTimeZone } from "@castiron/utils";
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Dinero from "dinero.js";
import moment from "moment";
import React, { useState } from "react";
import { useAppSelector } from "../../hooks";

type Props = {
  transaction: Transaction;
  amountAvailableToRefund: number;
  defaultExpanded?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "24px 0px",
  },
  container: {
    padding: "24px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0px",
    },
  },
  section: { padding: 5 },
}));

const RefundDetails: React.FC<Props> = (props: Props) => {
  const {
    transaction,
    amountAvailableToRefund,
    defaultExpanded = false,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const refunds =
    transaction?.order?.payments?.filter(
      (p) => p?.type === "refund" && p?.status === "succeeded"
    ) || [];
  const refundTotal = refunds.reduce((sum, p) => sum + (p?.amount || 0), 0);

  return (
    <CollapsableCard
      title={
        <Grid container item xs={12}>
          <Typography variant="subtitle1" style={{ marginRight: 5 }}>
            Refund Summary
          </Typography>
          <Pill
            content={`${
              amountAvailableToRefund == 0 ? "Fully" : "Partially"
            } Refunded`}
            variant="green"
          />
        </Grid>
      }
      handleExpand={toggleExpand}
      expanded={expanded}
      noScroll
      removeInnerPadding={true}
      className={classes.card}
    >
      <Grid container className={classes.container}>
        {refunds.map((refund, index) =>
          refund?.refundedAt || refund?.note ? (
            <Grid
              container
              item
              xs={12}
              key={index}
              style={{ padding: "10px 0px" }}
            >
              <Grid
                item
                xs={12}
                style={
                  index > 0
                    ? {
                        borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
                        padding: "10px 0px",
                      }
                    : {}
                }
              />
              {refund?.note && (
                <Grid container item xs={12} style={{ padding: "2px 24px" }}>
                  <Typography variant="body1">{`Reason for Refund: ${refund.note}`}</Typography>
                </Grid>
              )}
              {refund?.amount && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  style={{ padding: "2px 24px" }}
                >
                  <Typography variant="body1">Refund Total</Typography>
                  <Typography variant="body1">
                    {Dinero({ amount: refund?.amount || 0 }).toFormat("$0.00")}
                  </Typography>
                </Grid>
              )}
              {refund?.refundedAt && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  style={{ padding: "2px 24px" }}
                >
                  <Typography variant="body1">Refund Date</Typography>
                  <Typography variant="body1">
                    {moment
                      .unix(refund.refundedAt)
                      .tz(timeZone)
                      .format("M/D/YYYY")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : null
        )}
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ padding: "2px 24px" }}
        >
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Refund Amount
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {Dinero({ amount: refundTotal || 0 }).toFormat("$0.00")}
          </Typography>
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};

export default RefundDetails;

export type TemplateType =
  | "4th-of-july-promo"
  | "4th-of-july-coupon-promo"
  | "fathers-day-promo"
  | "back-to-school-announcement"
  | "back-to-school-reminder"
  | "back-to-school-coupon"
  | "back-to-school-shop"
  | "back-to-school-last-call"
  | "product-promo"
  | "event-promo"
  | "presale-promo"
  | "coupon-promo"
  | "ticketed-event-promo"
  | "fall-announcement"
  | "fall-coupon"
  | "fall-reminder"
  | "shop-fall"
  | "last-call-fall"
  | "halloween-announcement"
  | "halloween-coupon"
  | "halloween-reminder"
  | "shop-halloween"
  | "last-call-halloween"
  | "thanksgiving-announcement"
  | "thanksgiving-coupon"
  | "thanksgiving-reminder"
  | "shop-thanksgiving"
  | "last-call-thanksgiving"
  | "christmas-announcement"
  | "christmas-coupon"
  | "christmas-reminder"
  | "shop-christmas"
  | "last-call-christmas"
  | "black-friday-announcement-1"
  | "black-friday-announcement-2"
  | "black-friday-announcement-3"
  | "small-business-saturday-1"
  | "small-business-saturday-2"
  | "small-business-saturday-3"
  | "cyber-monday-announcement-1"
  | "cyber-monday-announcement-2"
  | "cyber-monday-announcement-3";

export interface SmsTemplate {
  eventName?: string;
  eventTag?: string;
  pageTitle: string;
  pageTitleMobile?: string;
  step1TitleUnselected: string;
  step1TitleSelectedPrefix?: string;
  message: string;
  postSendModalTitlePrefix: string;
  trackEventName: string;
  utmCampaign?: string;
  isCoupon: boolean;
  showCustomProductsOnly: boolean;
  showPresales: boolean;
  showTicketedEvents?: boolean;
}

export const smsTemplates = {
  "product-promo": {
    pageTitle: "Product Promo Text",
    pageTitleMobile: "Product Promo",
    step1TitleUnselected: "Product to Promote",
    message:
      "Don’t miss out - these products are selling fast! Order yours today!",
    postSendModalTitlePrefix: "Product Promo Text",
    trackEventName: "Product Promo",
    utmCampaign: "product_announcement",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "coupon-promo": {
    pageTitle: "Coupon Promo Text",
    pageTitleMobile: "Coupon Promo",
    step1TitleUnselected: "",
    message: "Check out this exclusive offer just for you! 💕",
    postSendModalTitlePrefix: "Coupon Promo Text",
    trackEventName: "Coupon Promo",
    utmCampaign: "coupon_announcement",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "event-promo": {
    pageTitle: "Event Promo Text",
    pageTitleMobile: "Event Promo",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Will we see you there? Check out where we’ll be in person next and shop early:",
    postSendModalTitlePrefix: "Event Promo Text",
    trackEventName: "Event Promo",
    utmCampaign: "event_announcement",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "presale-promo": {
    pageTitle: "Presale Promo Text",
    pageTitleMobile: "Presale Promo",
    step1TitleUnselected: "Presale to Promote",
    message:
      "⏳ Available for a limited time! 🍰 Shop our latest presale before it ends. Shop now:",
    postSendModalTitlePrefix: "Presale Promo Text",
    trackEventName: "Presale Promo",
    utmCampaign: "presale_announcement",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "ticketed-event-promo": {
    pageTitle: "Ticketed Event Promo",
    pageTitleMobile: "Ticketed Event",
    step1TitleUnselected: "Event to Promote",
    message: "Join us for our upcoming event! Act now before tickets sell out!",
    postSendModalTitlePrefix: "Event Promo Text",
    trackEventName: "Ticketed Event Promo",
    utmCampaign: "ticketed_event_promo",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: false,
    showTicketedEvents: true,
  },
  "4th-of-july-promo": {
    eventName: "4th of July",
    eventTag: "4thOfJuly",
    pageTitle: "4th of July Promo Text",
    pageTitleMobile: "4th of July",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Craving gourmet July 4th delights? Don't miss out on these exclusive flavors - place your order while supplies last! 🎇",
    postSendModalTitlePrefix: "4th of July Promo Text",
    trackEventName: "4th of July Promo",
    utmCampaign: "4th_of_july_announcement",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "4th-of-july-coupon-promo": {
    eventName: "4th of July",
    eventTag: "4thOfJuly",
    pageTitle: "4th of July Coupon Promo Text",
    pageTitleMobile: "4th of July",
    step1TitleUnselected: "",
    message: "Need something for your 4th of July party? Shop now to save.",
    postSendModalTitlePrefix: "4th of July Coupon Promo Text",
    trackEventName: "4th of July Coupon Promo",
    utmCampaign: "4th_of_july_announcement",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "fathers-day-promo": {
    eventName: "Father's Day",
    eventTag: "FathersDay",
    pageTitle: "Father's Day Promo Text",
    pageTitleMobile: "Father's Day",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Have you shopped for Dad yet? You’re in luck! Our Father’s Day treats are one size fits all. ⛳️ Order while supplies last:",
    postSendModalTitlePrefix: "Father's Day Promo Text",
    trackEventName: "Father's Day Promo",
    utmCampaign: "fathers_day_announcement",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "back-to-school-announcement": {
    eventName: "Back to School",
    eventTag: "BackToSchool",
    pageTitle: "Back-to-School Announcement",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Back-to-school nights, parent-teacher conferences, oh my! Don’t show up empty handed - place your order today!",
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Announcement",
    utmCampaign: "backtoschool_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "back-to-school-reminder": {
    eventName: "Back to School",
    eventTag: "BackToSchool",
    pageTitle: "Back-to-School Reminder",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "The clock is ticking! Place your order while products are still available:",
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Reminder",
    utmCampaign: "backtoschool_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "back-to-school-coupon": {
    eventName: "Back to School",
    eventTag: "BackToSchool",
    pageTitle: "Back-to-School Coupon",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: "",
    message:
      "Need something for your kiddo’s classroom party? Shop now to save!",
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Coupon",
    utmCampaign: "backtoschool_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "back-to-school-shop": {
    eventName: "Back to School",
    eventTag: "BackToSchool",
    pageTitle: "Back-to-School Shop",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Searching for teacher appreciation gifts? Make a delicious statement with artisanal goodies. Order now!",
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Shop",
    utmCampaign: "backtoschool_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "back-to-school-last-call": {
    eventName: "Back to School",
    eventTag: "BackToSchool",
    pageTitle: "Back-to-School Last Call",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: "Product or Presale to Promote",
    message: "Shop back-to-school offerings before the last bell rings!",
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Last Call",
    utmCampaign: "backtoschool_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "fall-announcement": {
    eventName: "Fall",
    eventTag: "Fall",
    pageTitle: "Fall Announcement",
    pageTitleMobile: "Fall",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "The leaves are falling and autumn flavors are dropping as well! 🍂 Start placing your orders today!",
    postSendModalTitlePrefix: "Fall SMS",
    trackEventName: "Fall Announcement",
    utmCampaign: "fall_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "fall-coupon": {
    eventName: "Fall",
    eventTag: "Fall",
    pageTitle: "Fall Coupon",
    pageTitleMobile: "Fall",
    step1TitleUnselected: "",
    message: "Need something for your fall festivities? Shop now to save.",
    postSendModalTitlePrefix: "Fall SMS",
    trackEventName: "Fall Coupon",
    utmCampaign: "fall_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "fall-reminder": {
    eventName: "Fall",
    eventTag: "Fall",
    pageTitle: "Fall Reminder",
    pageTitleMobile: "Fall",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Don’t be frozen out: get your fall purchases in before winter rolls around!",
    postSendModalTitlePrefix: "Fall SMS",
    trackEventName: "Fall Reminder",
    utmCampaign: "fall_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "shop-fall": {
    eventName: "Fall",
    eventTag: "Fall",
    pageTitle: "Shop Fall Products",
    pageTitleMobile: "Fall",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "My shop has dropped new products just in time for fall. Check it out today!",
    postSendModalTitlePrefix: "Fall SMS",
    trackEventName: "Shop Fall Products",
    utmCampaign: "fall_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "last-call-fall": {
    eventName: "Fall",
    eventTag: "Fall",
    pageTitle: "Last-Call for Fall Products",
    pageTitleMobile: "Fall",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Don't miss out on adding some extra sweetness to the colder months. Place your order before it’s too late!",
    postSendModalTitlePrefix: "Fall SMS",
    trackEventName: "Last-Call for Fall Products",
    utmCampaign: "fall_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "halloween-announcement": {
    eventName: "Halloween",
    eventTag: "Halloween",
    pageTitle: "Halloween Announcement",
    pageTitleMobile: "Halloween",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Don’t be spooked! Halloween is coming. Create frightfully-delicious experiences today! 👻",
    postSendModalTitlePrefix: "Halloween SMS",
    trackEventName: "Halloween Announcement",
    utmCampaign: "halloween_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "halloween-coupon": {
    eventName: "Halloween",
    eventTag: "Halloween",
    pageTitle: "Halloween Coupon",
    pageTitleMobile: "Halloween",
    step1TitleUnselected: "",
    message: "Halloween is “pump-kin” to celebrate: shop now to save! 🎃",
    postSendModalTitlePrefix: "Halloween SMS",
    trackEventName: "Halloween Coupon",
    utmCampaign: "halloween_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "halloween-reminder": {
    eventName: "Halloween",
    eventTag: "Halloween",
    pageTitle: "Halloween Reminder",
    pageTitleMobile: "Halloween",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "We don’t want to frighten you, but Halloween is coming soon! Order your products today.",
    postSendModalTitlePrefix: "Halloween SMS",
    trackEventName: "Halloween Reminder",
    utmCampaign: "halloween_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "shop-halloween": {
    eventName: "Halloween",
    eventTag: "Halloween",
    pageTitle: "Shop Halloween Products",
    pageTitleMobile: "Halloween",
    step1TitleUnselected: "Product or Presale to Promote",
    message: "Candy is “boo-ring.” Check out my Halloween products today! 👻",
    postSendModalTitlePrefix: "Halloween SMS",
    trackEventName: "Shop Halloween Products",
    utmCampaign: "halloween_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "last-call-halloween": {
    eventName: "Halloween",
    eventTag: "Halloween",
    pageTitle: "Last-Call for Halloween Treats",
    pageTitleMobile: "Halloween",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Don't miss out on adding some extra sweetness to the colder months. Place your order before it’s too late!",
    postSendModalTitlePrefix: "Halloween SMS",
    trackEventName: "Last-Call for Halloween Treats",
    utmCampaign: "halloween_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "thanksgiving-announcement": {
    eventName: "Thanksgiving",
    eventTag: "Thanksgiving",
    pageTitle: "Thanksgiving Announcement",
    pageTitleMobile: "Thanksgiving",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Grab the turkey, the stuffing, and my artisanal goods🍂 Start making your Thanksgiving purchases today!",
    postSendModalTitlePrefix: "Thanksgiving SMS",
    trackEventName: "Thanksgiving Announcement",
    utmCampaign: "thanksgiving_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "thanksgiving-coupon": {
    eventName: "Thanksgiving",
    eventTag: "Thanksgiving",
    pageTitle: "Thanksgiving Coupon",
    pageTitleMobile: "Thanksgiving",
    step1TitleUnselected: "",
    message:
      "Need something for Thanksgiving or “Friendsgiving”? Shop now to save!",
    postSendModalTitlePrefix: "Thanksgiving SMS",
    trackEventName: "Thanksgiving Coupon",
    utmCampaign: "thanksgiving_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "thanksgiving-reminder": {
    eventName: "Thanksgiving",
    eventTag: "Thanksgiving",
    pageTitle: "Thanksgiving Reminder",
    pageTitleMobile: "Thanksgiving",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Looking for a cornucopia of inspiration? Order your goods today in time for Thanksgiving!",
    postSendModalTitlePrefix: "Thanksgiving SMS",
    trackEventName: "Thanksgiving Reminder",
    utmCampaign: "thanksgiving_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "shop-thanksgiving": {
    eventName: "Thanksgiving",
    eventTag: "Thanksgiving",
    pageTitle: "Shop Thanksgiving Products",
    pageTitleMobile: "Thanksgiving",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "My shop has dropped new products for Thanksgiving. Check them out today!",
    postSendModalTitlePrefix: "Thanksgiving SMS",
    trackEventName: "Shop Thanksgiving Products",
    utmCampaign: "thanksgiving_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "last-call-thanksgiving": {
    eventName: "Thanksgiving",
    eventTag: "Thanksgiving",
    pageTitle: "Last-Call for Thanksgiving Products",
    pageTitleMobile: "Thanksgiving",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Don't miss out on creating artful memories this Thanksgiving. Place your order before it’s too late!",
    postSendModalTitlePrefix: "Thanksgiving SMS",
    trackEventName: "Last-Call for Thanksgiving Products",
    utmCampaign: "thanksgiving_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "christmas-announcement": {
    eventName: "Christmas",
    eventTag: "Christmas",
    pageTitle: "Christmas Announcement",
    pageTitleMobile: "Christmas",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Get ready to cozy up! Christmas is approaching. Gift heartwarming and festive goods today! ❄️",
    postSendModalTitlePrefix: "Christmas SMS",
    trackEventName: "Christmas Announcement",
    utmCampaign: "christmas_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "christmas-coupon": {
    eventName: "Christmas",
    eventTag: "Christmas",
    pageTitle: "Christmas Coupon",
    pageTitleMobile: "Christmas",
    step1TitleUnselected: "",
    message:
      "Winter holidays are “snow” much fun to celebrate! Shop now to save!",
    postSendModalTitlePrefix: "Christmas SMS",
    trackEventName: "Christmas Coupon",
    utmCampaign: "christmas_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "christmas-reminder": {
    eventName: "Christmas",
    eventTag: "Christmas",
    pageTitle: "Christmas Reminder",
    pageTitleMobile: "Christmas",
    step1TitleUnselected: "Product or Presale to Promote",
    message: "Beat the holiday rush: don’t forget to order your goods today!",
    postSendModalTitlePrefix: "Christmas SMS",
    trackEventName: "Christmas Reminder",
    utmCampaign: "christmas_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "shop-christmas": {
    eventName: "Christmas",
    eventTag: "Christmas",
    pageTitle: "Shop Christmas Products",
    pageTitleMobile: "Christmas",
    step1TitleUnselected: "Product or Presale to Promote",
    message: "Discover unique, handmade holiday items today! Shop now! 🎄",
    postSendModalTitlePrefix: "Christmas SMS",
    trackEventName: "Shop Christmas Products",
    utmCampaign: "christmas_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "last-call-christmas": {
    eventName: "Christmas",
    eventTag: "Christmas",
    pageTitle: "Last-Call for Christmas Treats",
    pageTitleMobile: "Christmas",
    step1TitleUnselected: "Product or Presale to Promote",
    message:
      "Make a Christmas list and check it twice! Place your order before it’s too late! ❄️",
    postSendModalTitlePrefix: "Christmas SMS",
    trackEventName: "Last-Call for Christmas Treats",
    utmCampaign: "christmas_sms",
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  "black-friday-announcement-1": {
    eventName: "Black Friday",
    eventTag: "Black Friday",
    pageTitle: "Black Friday Announcement",
    pageTitleMobile: "Black Friday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Check out my shop for Black Friday! Shop unique, handmade goods for upcoming holidays!",
    postSendModalTitlePrefix: "Black Friday SMS",
    trackEventName: "Black Friday Announcement",
    utmCampaign: "black_friday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "black-friday-announcement-2": {
    eventName: "Black Friday",
    eventTag: "Black Friday",
    pageTitle: "Black Friday Announcement",
    pageTitleMobile: "Black Friday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Don't miss out this Black Friday! Explore items in my shop just in time for the holiday season!",
    postSendModalTitlePrefix: "Black Friday SMS",
    trackEventName: "Black Friday Announcement",
    utmCampaign: "black_friday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "black-friday-announcement-3": {
    eventName: "Black Friday",
    eventTag: "Black Friday",
    pageTitle: "Black Friday Announcement",
    pageTitleMobile: "Black Friday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Looking for a new Black Friday shopping experience? View my shop today!",
    postSendModalTitlePrefix: "Black Friday SMS",
    trackEventName: "Black Friday Announcement",
    utmCampaign: "black_friday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "small-business-saturday-1": {
    eventName: "Small Business Saturday",
    eventTag: "Small Business Saturday",
    pageTitle: "Small Business Saturday Announcement",
    pageTitleMobile: "Small Business Saturday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Celebrate Small Business Saturday by shopping handmade goods from my small business!",
    postSendModalTitlePrefix: "Small Business Saturday SMS",
    trackEventName: "Small Business Saturday Announcement",
    utmCampaign: "small_business_saturday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "small-business-saturday-2": {
    eventName: "Small Business Saturday",
    eventTag: "Small Business Saturday",
    pageTitle: "Small Business Saturday Announcement",
    pageTitleMobile: "Small Business Saturday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Shop small on this Small Business Saturday - check out my shop for one-of-a-kind finds!",
    postSendModalTitlePrefix: "Small Business Saturday SMS",
    trackEventName: "Small Business Saturday Announcement",
    utmCampaign: "small_business_saturday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "small-business-saturday-3": {
    eventName: "Small Business Saturday",
    eventTag: "Small Business Saturday",
    pageTitle: "Small Business Saturday Announcement",
    pageTitleMobile: "Small Business Saturday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Embrace all things Small Business Saturday by exploring unique finds in my shop today!",
    postSendModalTitlePrefix: "Small Business Saturday SMS",
    trackEventName: "Small Business Saturday Announcement",
    utmCampaign: "small_business_saturday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "cyber-monday-announcement-1": {
    eventName: "Cyber Monday",
    eventTag: "Cyber Monday",
    pageTitle: "Cyber Monday Announcement",
    pageTitleMobile: "Cyber Monday",
    step1TitleUnselected: "Product to Promote",
    message:
      "This Cyber Monday, skip the mass-produced and shop unique, handmade goods in my shop! Find specialty gifts online!",
    postSendModalTitlePrefix: "Cyber Monday SMS",
    trackEventName: "Cyber Monday Announcement",
    utmCampaign: "cyber_monday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "cyber-monday-announcement-2": {
    eventName: "Cyber Monday",
    eventTag: "Cyber Monday",
    pageTitle: "Cyber Monday Announcement",
    pageTitleMobile: "Cyber Monday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Embrace Cyber Monday by ditching the ordinary and discovering one-of-a-kind, handmade creations in my shop!",
    postSendModalTitlePrefix: "Cyber Monday SMS",
    trackEventName: "Cyber Monday Announcement",
    utmCampaign: "cyber_monday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  "cyber-monday-announcement-3": {
    eventName: "Cyber Monday",
    eventTag: "Cyber Monday",
    pageTitle: "Cyber Monday Announcement",
    pageTitleMobile: "Cyber Monday",
    step1TitleUnselected: "Product to Promote",
    message:
      "Looking to support a small business on Cyber Monday? Check out my finds in my shop today!",
    postSendModalTitlePrefix: "Cyber Monday SMS",
    trackEventName: "Cyber Monday Announcement",
    utmCampaign: "cyber_monday_sms",
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
};

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { CircularProgress, Grid, Hidden, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { ButtonV2, DiscardButton, RefundMoneyIcon, SaveButton, Typography } from '@castiron/components';
import LogFormikErrors from '@castiron/components/build/Forms/LogFormikErrors';
import { Customer, LineItem, Transaction } from '@castiron/domain';
import { defaultTimeZone, removeEmpty, useTracking } from '@castiron/utils';
import { customerRepository, transactionRepository } from '../../domain';
import { getService } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { getProductsAction } from '../../store/reducers/products';
import AdminForm from '../AdminForm';
import Dropdown from '../Dropdown';
import FulfillmentDetailsBox from '../FulfillmentDetailsBox';
import GiftDetailsBox from '../GiftDetailsBox';
import { LayoutPageProps } from '../Layout';
import HeaderTabs from '../Layout/Header/HeaderTabs';
import RefundDetails from './RefundDetails';
import QuoteNotes from '../Quotes/EditQuote/QuoteNotes';
import ReferralBlock from '../ReferralPrompts/ReferralBlock';
import PromoBlock, { showFirstMonthPromoBlock } from '../ReferralPrompts/PromoBlock';
import Spinner from '../Spinner';
import UnsavedChangesPrompt from '../UnsavedChangesPrompt.tsx';
import DetailsBox from './DetailsBox';
import OrderCustomer from './OrderCustomer';
import QuoteRequestDetailsBox from './QuoteRequestDetailsBox';
import OrderSource from './OrderSource';
import ModalWrapper from '../RootModal/ModalWrapper';
import { getAvailableRefundInfo } from './RefundOrderModal';
import { useConfig } from '@castiron/castiron-firebase';

const printOrderService = getService('orders', 'printorder');
const createOrUpdateCustomerService = getService('customers', 'createorupdatecustomer');

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },
  contentDesktop: {
    paddingTop: 16,
  },
  contentMobile: {
    padding: 16,
  },
  deleteButton: {
    backgroundColor: theme.branding.v2.red[500],
    '&:hover': {
      backgroundColor: theme.branding.v2.red[800],
    },
  },
  deleteModalPaper: {
    maxWidth: '445px',
    padding: '32px 40px',
    gap: '40px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  icon: {
    color: theme.branding.v2.gray[500],
  },
  popover: {
    minWidth: 350,
  },
  subheader: {
    marginBottom: '8px',
  },
  trashIcon: {
    fontSize: '76px',
    lineHeight: '100px',
    textAlign: 'center',
  },
}));

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;
const orderSchema = yup.object().shape({
  products: yup.array().of(yup.object()),
  customer: yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email('Invalid email'),
    mobileNumber: yup.string().matches(phoneRegExp, 'Please enter a valid 10-digit phone number'),
    notes: yup.string(),
    subscribed: yup.boolean(),
  }),
});

const OrderView: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs, setHeaderTransaction } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formikRef = useRef<FormikProps<any>>();

  const [status, setStatus] = useState('open');
  const [customer, setCustomer] = useState<Customer>();
  const [transaction, setTransaction] = useState<Transaction>();
  const [refundTotal, setRefundTotal] = useState<number>(0);
  const [submitting, setSubmitting] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRefundAction, setShowRefundAction] = useState(false);
  const [amountAvailableToRefund, setAmountAvailableToRefund] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const orderedItems: LineItem[] = transaction?.order?.items
    ? transaction?.order.items
    : transaction?.products.map(p => ({
        id: p.product.id,
        title: p.product.title,
        description: p.product.description,
        category: p.product.category,
        price: p.product.price,
        quantity: p.quantity,
        selections: p.selectedVariationValues,
        type: 'standard',
      }));

  const { account, shop, isProductsLoading } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    isProductsLoading: state.products.loading,
  }));

  const ffconfig = useConfig();
  const refundsEnabled = ffconfig?.featureFlag(
    "feature_refunds",
    shop
  );
  const showPromoBlock = showFirstMonthPromoBlock(account);

  const { trackEvent } = useTracking();
  const { id } = useParams<{ id: string }>();

  const getTransaction = async id => {
    const response = await transactionRepository.get(id);
    if (response == null) history.push('/orders');
    setTransaction(response);
  };

  const getCustomer = async id => {
    const customer = await customerRepository.get(id);
    setCustomer(customer);
  };

  useEffect(() => {
    if (id) {
      getTransaction(id);
    }
    setBackLocation(true);

    return () => {
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    const amountAvailableToRefund = getAvailableRefundInfo(transaction)?.total;
    setAmountAvailableToRefund(amountAvailableToRefund);
    setShowRefundAction(amountAvailableToRefund > 0 && refundsEnabled);
    const refunds = transaction?.order?.payments?.filter(p => p?.type === 'refund' && p?.status === 'succeeded') || [];
    const refundTotal = refunds.reduce((sum, p) => sum + (p?.amount || 0), 0);
    setRefundTotal(refundTotal);
  }, [transaction]);

  const statusOptions = [
    {
      label: 'Open',
      onClick: (): void => setStatus('open'),
    },
    {
      label: 'Completed',
      onClick: (): void => setStatus('completed'),
    },
    {
      label: 'Fulfilled',
      onClick: (): void => setStatus('fulfilled'),
    },
    {
      label: 'Canceled',
      onClick: (): void => setStatus('canceled'),
    },
  ];
  const statusSelector = (
    <Dropdown popoverClass={classes.popover} title={`Status: ${_.capitalize(status)}`} options={statusOptions} />
  );

  const printOrder = async () => {
    setPrinting(true);
    const printFrame = document.createElement('iframe');
    printFrame.style.display = 'none';
    document.title = `Order ${transaction?.order?.orderNumber || ''}`;

    const response = await printOrderService({
      transactionId: id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
      title: `Order ${transaction?.order?.orderNumber || ''}`,
    });

    printFrame.srcdoc = response.html;

    document.body.appendChild(printFrame);

    printFrame.onload = () => {
      setTimeout(() => {
        printFrame.contentWindow.focus();
        printFrame.contentWindow.print();

        setPrinting(false);
      });
    };

    trackEvent('Print Order', { ordersPrinted: 1 });
  };

  const deleteOrder = async () => {
    setDeleteLoading(true);
    try {
      await transactionRepository.updateProps(transaction.id, {
        status: 'deleted',
      });

      history.push('/orders?refresh=true');
    } catch (err) {
      console.error(`Error deleting transaction [${transaction.id}]: `, err);
    }
    setShowDeleteModal(false);
    setDeleteLoading(false);
  };

  const deleteOrderModal = (
    <ModalWrapper
      show={showDeleteModal}
      onClose={() => {
        setShowDeleteModal(false);
      }}
      paperClass={classes.deleteModalPaper}
    >
      <Grid container direction="column" justify="center" style={{ gap: '32px' }}>
        <Typography variant="h1" className={classes.trashIcon}>
          🗑️
        </Typography>
        <Grid container item direction="column" justify="center" style={{ gap: '8px' }}>
          <Typography variant="subtitle1">
            Delete Order #{transaction?.order.orderNumber}
            {!!transaction?.customerObj && transaction.customerObj.fullName
              ? `, ${transaction?.customerObj?.fullName}`
              : !!transaction?.customerObj
              ? `, ${transaction.customerObj.firstName || ''} ${transaction.customerObj.lastName || ''}`
              : ''}
            ?
          </Typography>
          <Typography variant="body1">
            Deleting an order is permanent and cannot be undone. Are you sure you want to delete this order?
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-end" style={{ gap: '8px' }}>
        <ButtonV2
          variant="outlined"
          onClick={() => {
            setShowDeleteModal(false);
          }}
        >
          Cancel
        </ButtonV2>
        <ButtonV2
          variant="contained"
          onClick={deleteOrder}
          className={classes.deleteButton}
          disabled={deleteLoading}
          loading={deleteLoading}
        >
          Delete
        </ButtonV2>
      </Grid>
    </ModalWrapper>
  );

  const openRefundModal = () => {
    dispatch(
      openModal({
        modalType: 'REFUND_ORDER_MODAL',
        modalProps: {
          show: true,
          transaction,
          customer,
        },
      }),
    );
  };

  const actionsMenuOptions = [
    {
      icon: printing ? (
        <CircularProgress size={12} />
      ) : (
        <PrintOutlinedIcon className={classes.icon} />
      ),
      label: "Print",
      onClick: printOrder,
      style: {padding: '20px'},
    },
    ...(showRefundAction
      ? [
          {
            icon: <RefundMoneyIcon className={classes.icon} />,
            label: "Refund",
            onClick: openRefundModal,
            style: {padding: '20px'},
          },
        ]
      : []),
    {
      color: "error" as "error",
      icon: (
        <DeleteOutlineOutlinedIcon
          style={{ color: theme.branding.v2.red[500] }}
        />
      ),
      label: "Delete",
      onClick: () => {
        setShowDeleteModal(true);
      },
      style: {padding: '20px'},
    },
  ];

  const actionsMenuDropdown = <Dropdown options={actionsMenuOptions} variant='ellipsis' />;

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([actionsMenuDropdown]);
    } else {
      setHeaderCTAs([statusSelector, actionsMenuDropdown]);
    }

    return () => {
      setHeaderCTAs([]);
    };
  }, [isMobile, status, customer, transaction, shop, printing]);

  useEffect(() => {
    getCustomer(transaction?.customer);
    setStatus(transaction?.order?.fulfillmentOption?.type == 'inperson' ? 'fulfilled' : transaction?.status);
    setPageTitle(`Order ${transaction?.order.orderNumber}`);
    setHeaderTransaction(transaction);
    return () => {
      setHeaderTransaction(null);
    };
  }, [transaction]);

  useEffect(() => {
    const getProducts = async id => {
      dispatch(getProductsAction(id));
    };

    if (shop?.id) getProducts(shop.id);
  }, [shop]);

  const handleSubmit = async values => {
    try {
      setSubmitting(true);

      const newCustomerData = {
        ...customer,
        ...values.customer,
        subscriberOrigination: values.subscribed ? 'admin-update-customer-form' : '',
      };

      const custData = newCustomerData.data ? newCustomerData.data() : newCustomerData;

      let cust;
      if (status === 'fulfilled' && transaction.status !== 'fulfilled') {
        if (custData.email) {
          cust = await createOrUpdateCustomerService(custData);
          setCustomer(cust);
        }

        dispatch(
          openModal({
            modalType: 'FULFILL_ORDER',
            modalProps: {
              show: true,
              transaction: transaction,
              customer: newCustomerData,
            },
          }),
        );
      } else {
        if (custData.email) {
          cust = await createOrUpdateCustomerService(custData);
          setCustomer(cust);
        }

        await transactionRepository.updateProps(
          transaction.id,
          removeEmpty({
            customer: !transaction.customer ? cust?.id : undefined,
            customerObj: !transaction.customerObj ? cust : undefined,
            status,
          }),
        );

        trackEvent('Order updated', { transaction: { id: transaction.id } });
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  Order <strong>{transaction.order.orderNumber}</strong> was updated
                </>
              ),
            },
          }),
        );
        history.push('/orders');
      }

      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation="/orders" />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [submitting]);

  const initialValues = {
    customer: {
      firstName: customer?.firstName,
      lastName: customer?.lastName || '',
      email: customer?.email || '',
      mobileNumber: customer?.mobileNumber || '',
      address: customer?.address || '',
      notes: customer?.notes || '',
      subscribed: customer?.subscribed || false,
    },
    notes: transaction?.notes || '',
    order: {
      notes: {
        text: transaction?.order?.notes?.text || '',
        images: transaction?.order?.notes?.images || [],
      },
    },
  };

  const orderContent = (
    <>
      <Hidden mdUp>
        <Grid container justify="flex-end" className={classes.subheader}>
          <Grid item>{statusSelector}</Grid>
        </Grid>
      </Hidden>
      <Grid spacing={isMobile ? 0 : 6} container>
        <Grid item xs={12} md={7} lg={8}>
          <DetailsBox transaction={transaction} />
          {transaction?.order?.type === 'custom' && transaction?.order?.items[0]?.selections?.length > 0 && (
            <QuoteRequestDetailsBox orderedItems={orderedItems} />
          )}
          {refundTotal > 0 && (
            <RefundDetails
              transaction={transaction}
              amountAvailableToRefund={amountAvailableToRefund}
              defaultExpanded
            />
          )}
          {!isMobile &&
            (showPromoBlock ? <PromoBlock location="order-details" /> : <ReferralBlock location="order-details" />)}
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <GiftDetailsBox order={transaction} />
          <OrderCustomer customer={customer} />
          <FulfillmentDetailsBox
            customer={transaction?.customerObj}
            transaction={transaction}
            onUpdate={() => getTransaction(id)}
          />
          <OrderSource attribution={transaction?.attribution} />
          {isMobile &&
            (showPromoBlock ? <PromoBlock location="order-details" /> : <ReferralBlock location="order-details" />)}
        </Grid>
      </Grid>
    </>
  );

  const tabs = [
    {
      value: 'Details',
      content: (
        <Grid container className={isMobile ? classes.contentMobile : classes.contentDesktop}>
          {orderContent}
        </Grid>
      ),
    },
    {
      value: 'My Notes',
      content: transaction && (
        <Grid container className={isMobile ? classes.contentMobile : classes.contentDesktop}>
          <QuoteNotes transaction={transaction} />
        </Grid>
      ),
    },
  ];

  return (
    <div className={transaction?.order?.type === 'custom' ? '' : classes.container}>
      <Spinner show={isProductsLoading} />
      {deleteOrderModal}
      <Formik
        initialValues={initialValues}
        validationSchema={orderSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ isSubmitting, touched }) => (
          <AdminForm>
            <LogFormikErrors />
            {!isSubmitting && <UnsavedChangesPrompt when={!!touched.customer || !!touched.notes} />}
            {transaction?.order?.type === 'custom' ? (
              <HeaderTabs initialTabValue={'Details'} tabs={tabs} />
            ) : (
              orderContent
            )}
          </AdminForm>
        )}
      </Formik>
    </div>
  );
};

export default OrderView;

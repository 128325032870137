import React, { useEffect, useContext, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Button,
  Chip,
  Spinner,
  SvgIcon,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from "@castiron/components";
import { ChecklistValues, PriceFrequency } from "@castiron/domain";
import Dinero from "dinero.js";
import { Price } from "@castiron/domain";
import clsx from "clsx";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { PlanCardData, PlanContext, PlanContextData } from "../PlanContext";
import PlanComparisonCategoriesFile from "../../../assets/data/PlanComparisonCategories.json";
import { NextStep } from "../PlanFlow";
import { getService } from "../../../firebase";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTracking } from "@castiron/utils";
import { openModal } from "../../../store/reducers/modalConductor";

const createSubscriptionService = getService(
  "subscriptions",
  "createsubscription"
);
const sendBusinessPlanInterestEmail = getService(
  "subscriptions",
  "sendbusinessplaninterest"
);

type Props = {
  nextStep: NextStep;
};
const sortedComparisonCategories =
  PlanComparisonCategoriesFile.planComparisonCategories.sort(
    (a, b) => a.order - b.order
  );

const useStyles = makeStyles((theme: Theme) => ({
  accordionRoot: {
    color: theme.branding.gray[800],

    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0",
    },

    "&.MuiAccordion-root::before": {
      display: "none",
    },
  },
  accordionIcon: {
    color: theme.branding.gray[800],
  },
  accordionItem: {
    margin: "8px 0",
  },
  accordionSummary: {
    backgroundColor: theme.branding.blue.light,

    "& .MuiAccordionSummary-content": {
      margin: "16px 0",
    },

    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "initial",
    },

    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "16px 0",
    },
  },
  accordionTitle: {
    fontSize: 16,
    fontWeight: 700,
    margin: "8px 0",
  },
  cardWrapper: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
  checkboxIcon: {
    color: theme.branding.v2.plum[500],
  },
  chip: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "20px",
    padding: "4px 12px",
    textAlign: "center",
    margin: "0px 24px",
  },
  comparisonButton: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
    marginBottom: 40,
    maxWidth: 228,
    padding: 16,
  },
  comparisonCategoryHeader: {
    backgroundColor: theme.branding.blue.light,
    height: 56,
    margin: "16px 0",
  },
  comparisonCategoryHeaderText: {
    padding: 16,
  },
  comparisonRowItem: {
    height: 64,
    padding: "0 16px",
    textAlign: "center",

    "&.less": {
      height: 48,
    },
    "&.more": {
      height: 72,
    },
  },
  comparisonTable: {
    marginBottom: 40,
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  comparisonText: {
    color: theme.branding.gray[700],
    fontSize: 16,
  },
  comparisonTableTitle: {
    fontSize: 36,
    lineHeight: "56px",
    margin: "0 auto 40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "36px",
      fontWeight: 700,
      margin: "0 auto 24px",
    },
  },
  comparisonWrapper: {
    padding: "0 16px",
  },
  ctaText: {
    margin: "16px 0 4px 0",
    padding: 16,
    fontSize: 16,
    fontWeight: 600,
  },
  ctaHelpText: {
    color: theme.branding.v2.plum[500],
    marginBotton: 24,
    textAlign: "center",
  },
  emptyCheckbox: {
    color: theme.branding.gray[500],
  },
  infoIcon: {
    color: theme.branding.gray[500],
    width: "16.67px",
    height: "16.67px",
    cursor: "pointer",
  },
  loadingIcon: {
    color: theme.branding.gray[100],
  },
  plansDisplayBorder: {
    borderTop: `8px solid ${theme.branding.v2.plum[500]}`,
  },
  pcContainer: {
    backgroundColor: theme.branding.gray[100],
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: "40px 24px",
    margin: "42px 0 0",

    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  pcContainerRecommended: {
    backgroundColor: theme.branding.gray[100],
    border: `2px solid ${theme.branding.v2.plum[500]}`,
    borderRadius: "0 0 12px 12px",
    padding: "40px 24px",
    minHeight: 500,

    [theme.breakpoints.down("md")]: {
      minHeight: 510,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 600,
    },
    [theme.breakpoints.down(765)]: {
      minHeight: 825,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "initial",
    },
  },
  pcDescription: {
    color: theme.branding.gray[700],
    textAlign: "center",
  },
  pcFee: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },

    "& > h6": {
      color: theme.branding.gray[800],
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  pcName: {
    color: theme.branding.gray[800],
    fontSize: 24,
    lineHeight: "36px",
    fontWeight: 700,
  },
  pcNote: {
    color: theme.branding.gray[800],
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
  },
  pcNoteTitle: {
    color: theme.branding.gray[800],
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    margin: "24px 0 8px",
  },
  pcPrice: {
    color: theme.branding.gray[800],
    fontSize: 36,
    fontWeight: 700,
    height: "62.5px",
    textAlign: "center",
    lineHeight: "56px",
    marginTop: 16,

    [theme.breakpoints.down("md")]: {
      height: "initial",
    },
  },
  pcWrapper: {
    padding: "24px 24px 40px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "24px 16px",
    },
  },
  helpText: {
    fontSize: 13,
    lineHeight: "24px",
    color: theme.branding.gray[700],
    fontWeight: 400,
    display: "block",
    textAlign: "center",
  },
  legacySpacer: {
    color: theme.branding.v2.plum[500],
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "24px",
    height: 22,
    textAlign: "center",
  },
  priceDesc: {
    fontSize: 16,
  },
  recommended: {
    borderRadius: 12,
    padding: 2,

    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
  recommendedText: {
    backgroundColor: theme.branding.v2.plum[500],
    color: theme.branding.gray[100],
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 600,
    padding: 8,
    borderRadius: "12px 12px 0 0",
    width: "100%",
    textAlign: "center",
  },
  tableHeaders: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "28px",
  },
  title: {
    color: theme.branding.gray[800],
    fontSize: 36,
    fontWeight: 700,
    lineHeight: "56px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "36px",
    },
  },
  subtitle: {
    color: theme.branding.gray[800],
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
  },
  titleContainer: {
    textAlign: "center",
    margin: "40px auto 0",
    [theme.breakpoints.up("md")]: {
      maxWidth: "62%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "86%",
      margin: "24px auto 0",
    },
  },
  toggleWrapper: {
    marginTop: 24,
    fontWeight: 600,
    lineHeight: "24px",
    "& button": {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
    },
    "& .MuiToggleButtonGroup-root": {
      color: theme.branding.v2.plum[500],
      marginBottom: 8,
    },
  },
  tooltipButton: {
    margin: "16px 0 0 4px",
    padding: "4px 0",
    width: 16,
    height: 16,
  },
  tooltipButtonMobile: {
    margin: "3px 0 0 4px",
    padding: "4px 0",
    width: 16,
    height: 16,
  },
}));

const PlansDisplay: React.FC<Props> = (props: Props) => {
  const { nextStep } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const comparisonRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useTracking();
  const [comparisonOpen, setComparisonOpen] = useState(false);
  const [billingFrequency, setBillingFrequency] =
    useState<PriceFrequency>("yearly");
  const [expanded, setExpanded] = useState(
    sortedComparisonCategories.reduce((acc, category) => {
      acc[category.name] = true;
      return acc;
    }, {} as Record<string, boolean>)
  );
  const [selectedPlan, setSelectedPlan] = useState<PlanCardData | null>(null);
  const [hasCreatedSub, setHasCreatedSub] = useState<boolean>(false);
  const [subCreationLoading, setSubCreationLoading] = useState<boolean>(false);
  const [dynamicContent, setDynamicContent] = useState<{
    recommendedText: string;
    highlightedPlan: "Basic" | "Pro" | "Legacy" | "";
    basicButtonCTA: string;
    proButtonCTA: string;
    title: string;
    subtitle: string;
  }>({
    recommendedText: "",
    highlightedPlan: "",
    basicButtonCTA: "",
    proButtonCTA: "",
    title: "",
    subtitle: "",
  });

  const { planCardData } = useContext<PlanContextData>(PlanContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tooltipText =
    "Transaction Fees are calculated based on each order's subtotal (after coupons), fulfillment charges (shipping or delivery fees), and taxes. We don't charge the Transaction Fee on any tips you receive.";

  const { account, subscription, shop, userState, testClock, discount } =
    useAppSelector((state) => ({
      account: state.shops.account,
      subscription: state.shops.account?.subscription,
      shop: state.shops.shop,
      userState: state.shops.userState,
      testClock: state.debug.stripe?.testClock,
      discount: state.shops.discount,
    }));

  const isPendingCanceled = subscription?.status === "pending-canceled";

  useEffect(() => {
    if (!shop?.checklistCompletions?.includes(ChecklistValues.SelectPlan)) {
      shop?.addToChecklist(ChecklistValues.SelectPlan);
    }
  }, []);

  useEffect(() => {
    // if (userState !== 'newUser' && account?.status !== 'onboarding' && hasCreatedSub) {
    //   history.push('/new');
    // }

    if (account?.subscription?.price) {
      setBillingFrequency(account.subscription.price.frequency);
    }
  }, [userState, hasCreatedSub, account]);

  useEffect(() => {
    const isSameFreq = () => {
      if (
        subscription?.price?.frequency === "yearly" &&
        billingFrequency === "yearly"
      ) {
        return {
          isSame: true,
          current: "Annual",
          opposite: "Monthly",
        };
      } else if (
        subscription?.price?.frequency === "monthly" &&
        billingFrequency === "monthly"
      ) {
        return {
          isSame: true,
          current: "Monthly",
          opposite: "Annual",
        };
      } else {
        return {
          isSame: false,
          current:
            subscription?.price?.frequency === "monthly" ? "Monthly" : "Annual",
          opposite:
            subscription?.price?.frequency === "monthly" ? "Annual" : "Monthly",
        };
      }
    };

    console.debug("userState", userState);
    switch (userState) {
      // --- Subscriptions are no longer in onboarding, this is legacy code just to save for a bit. ---
      // case 'newUser':
      //   setDynamicContent({
      //     recommendedText: '⭐ Recommended For You',
      //     highlightedPlan: '', // in onboarding so get this from cardData.isRecommended
      //     basicButtonCTA: 'Start Free 14-Day Trial',
      //     proButtonCTA: 'Start Free 14-Day Trial',
      //     title: 'Select a Plan to Start a Free 14-Day Trial ',
      //     subtitle: `No matter the size, scope, or needs of your business, we’ve got the plan, tools, and community to help you succeed.`,
      //   });
      //   break;
      case "inTrial":
        setDynamicContent({
          recommendedText: isSameFreq().isSame
            ? `⭐ My Current Plan`
            : `⭐ Currently on ${isSameFreq().current} Plan`,
          highlightedPlan:
            subscription?.plan?.name === "Basic" ? "Basic" : "Pro",
          basicButtonCTA:
            !isSameFreq().isSame && subscription?.plan?.name === "Basic"
              ? `Switch to ${isSameFreq().opposite} Plan`
              : "Select",
          proButtonCTA:
            !isSameFreq().isSame && subscription?.plan?.name === "Pro"
              ? `Switch to ${isSameFreq().opposite} Plan`
              : "Select",
          title: "",
          subtitle: "",
        });
      case "legacyInTrial":
        setDynamicContent({
          recommendedText: `⭐ Your Current Trial`,
          highlightedPlan:
            subscription?.plan?.name === "Basic" ? "Basic" : "Pro",
          basicButtonCTA:
            subscription?.plan?.name === "Basic" ? `My Current Plan` : "Select",
          proButtonCTA:
            subscription?.plan?.name === "Pro" ? `My Current Plan` : "Select",
          title: "",
          subtitle: "",
        });
        break;
      case "legacyNewSubscriber":
        setDynamicContent({
          recommendedText: "⭐ Your Current Plan",
          highlightedPlan: "Legacy",
          basicButtonCTA: "Start Free 14-Day Trial",
          proButtonCTA: "Start Free 14-Day Trial",
          title: "",
          subtitle: "",
        });
        break;
      case "legacyTrialCompleted":
        setDynamicContent({
          recommendedText: "⭐ Your Current Plan",
          highlightedPlan: "Legacy",
          basicButtonCTA: "Select",
          proButtonCTA: "Select",
          title: "",
          subtitle: "",
        });
        break;
      case "currentSubscriber":
        setDynamicContent({
          recommendedText: isSameFreq().isSame
            ? `⭐ My Current Plan`
            : `⭐ Currently on ${isSameFreq().current} Plan`,
          highlightedPlan:
            subscription?.plan?.name === "Basic" ? "Basic" : "Pro",
          basicButtonCTA:
            subscription?.plan?.name === "Basic"
              ? isSameFreq().isSame
                ? "My Current Plan"
                : `Switch to ${isSameFreq().opposite} Plan`
              : "Select",
          proButtonCTA:
            subscription?.plan?.name === "Pro"
              ? isSameFreq().isSame
                ? "My Current Plan"
                : `Switch to ${isSameFreq().opposite} Plan`
              : "Select",
          title: "",
          subtitle: "",
        });
        break;
      case "cancellingSubscriber":
        setDynamicContent({
          recommendedText: `Plan Expires on ${moment(
            subscription?.cancellation?.date
          ).format("MMMM Do")}`,
          highlightedPlan:
            subscription?.plan?.name === "Basic" ? "Basic" : "Pro",
          basicButtonCTA:
            subscription?.plan?.name === "Basic"
              ? "Resume Subscription"
              : "Select",
          proButtonCTA:
            subscription?.plan?.name === "Pro"
              ? "Resume Subscription"
              : "Select",
          title: "",
          subtitle: "",
        });
        break;
      case "trialExpired":
        trackEvent("New User Trial Expired", {
          shopId: shop?.id,
          subscription: account?.subscription,
          userState: userState,
        });

        setDynamicContent({
          recommendedText: "⭐ Recommended For You",
          highlightedPlan: "", // get from cardData.isRecommended
          basicButtonCTA:
            subscription?.plan?.name === "Basic"
              ? "Continue With Basic"
              : "Select",
          proButtonCTA:
            subscription?.plan?.name === "Pro" ? "Continue With Pro" : "Select",
          title: "Your Trial Has Expired.",
          subtitle: "Please select a plan to continue using Nourysh.",
        });
        break;
      case "subscriptionEnded":
        setDynamicContent({
          recommendedText: `⭐ My Most Recent Plan`,
          highlightedPlan:
            subscription?.plan?.name === "Basic" ? "Basic" : "Pro",
          basicButtonCTA:
            subscription?.plan?.name === "Basic"
              ? "Reactivate My Plan"
              : "Select",
          proButtonCTA:
            subscription?.plan?.name === "Pro"
              ? "Reactivate My Plan"
              : "Select",
          title: `Your Nourysh ${subscription?.plan?.name} Plan Has Expired.`,
          subtitle:
            "Reactivate your plan to continue using your Nourysh account.",
        });
        break;
      default:
        setDynamicContent({
          recommendedText: "⭐ Recommended For You",
          highlightedPlan: "", // get from cardData.isRecommended
          basicButtonCTA: "Select",
          proButtonCTA: "Select",
          title: "",
          subtitle: "",
        });
        break;
    }
  }, [userState, billingFrequency]);

  const isCurrentSub = (cardData: PlanCardData) => {
    if (account.isInTrial()) return false;
    const price = cardData?.plan?.prices?.find(
      (p) => p.frequency === billingFrequency
    );
    return (
      cardData?.isCurrentPlan && price?.id === account?.subscription?.price?.id
    );
  };

  const isHighlightedPlan = (cardData: PlanCardData) => {
    return (
      (!dynamicContent.highlightedPlan && cardData?.isRecommended) ||
      dynamicContent.highlightedPlan === cardData?.plan?.name
    );
  };

  const getPrice = (prices: Price[], isBusiness?: boolean) => {
    if (!isBusiness) {
      const monthlyPrice = prices.find(
        (price) => price.frequency === "monthly"
      );
      const yearlyPrice = prices.find((price) => price.frequency === "yearly");
      const annualMonthlyPrice = Math.round(yearlyPrice.amount / 12); // convert to cents then divide by 12 months
      const monthlyPriceFormatted = monthlyPrice
        ? Dinero({ amount: monthlyPrice.amount }).toFormat("$0")
        : 0;
      const annualMonthlyPriceFormatted = annualMonthlyPrice
        ? Dinero({ amount: annualMonthlyPrice }).toFormat(
            annualMonthlyPrice < 100 ? "$0.00" : "$0"
          )
        : 0;
      return billingFrequency === "monthly"
        ? `${monthlyPriceFormatted}`
        : `${annualMonthlyPriceFormatted}`;
    } else {
      return "Custom";
    }
  };

  const handlePlanChange = async (cardData: PlanCardData) => {
    trackEvent("Pricing Page Plan Selected", {
      billingFrequency: billingFrequency,
      subscription: cardData.plan,
      shopId: shop?.id,
      userState: userState,
    });

    // if we're canceling, i.e. selecting the Starter plan
    setSelectedPlan(cardData);
    const price = cardData.plan.prices.find(
      (p) => p.frequency === billingFrequency
    );
    if (cardData.plan.name === "Starter") {
      nextStep(3, {
        plan: cardData.plan,
        price,
        takeRate: cardData.takeRate,
      });
      // if we're uncanceling, i.e. selecting the existing plan
    } else if (
      isPendingCanceled &&
      cardData.plan.name === subscription?.plan?.name
    ) {
      nextStep(1, {
        plan: subscription.plan,
        price: subscription.price,
        takeRate: cardData.takeRate,
      });
    } else {
      nextStep(0, {
        plan: cardData.plan,
        price,
        takeRate: cardData.takeRate,
      });
    }
  };

  const createLegacyTrial = async (cardData: PlanCardData) => {
    trackEvent("Pricing Page Plan Selected", {
      billingFrequency: billingFrequency,
      subscription: cardData.plan,
      shopId: shop?.id,
      userState: userState,
    });

    // sending legacyNewSubscriber straight to the `SubscriptionPayment` step to capture address w/o credit card
    setSelectedPlan(cardData);
    const price = cardData.plan.prices.find(
      (p) => p.frequency === billingFrequency
    );
    nextStep(1, {
      plan: cardData.plan,
      price,
    });
  };

  const subscribe = async (cardData: PlanCardData) => {
    setSelectedPlan(cardData);
    setSubCreationLoading(true);
    try {
      await createSubscriptionService({
        planId: cardData.plan.id,
        priceId: cardData.plan.prices.find(
          (p) => p.frequency === billingFrequency
        ).id,
        discountCode: discount?.code,
        testClock,
      });
      trackEvent("Pricing Page Plan Selected", {
        billingFrequency: billingFrequency,
        subscription: cardData.plan,
        shopId: shop?.id,
        userState: userState,
      });

      setHasCreatedSub(true);
    } catch (err) {
      console.error("Error creating subscription", err);
      setSubCreationLoading(false);
    }
  };

  const getCTAs = (cardData: PlanCardData) => {
    let ctaAction;

    switch (userState) {
      // --- Subscriptions are no longer in onboarding, this is legacy code just to save for a bit. ---
      // case 'newUser':
      //   ctaAction = subscribe;
      //   break;
      case "legacyNewSubscriber":
        ctaAction = createLegacyTrial;
        break;
      default:
        ctaAction = handlePlanChange;
        break;
    }

    return (
      <Button
        className={classes.ctaText}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          cardData.plan.name === "Business"
            ? sendBusinessEmail()
            : ctaAction(cardData);
        }}
        loading={
          subCreationLoading && cardData.plan.name === selectedPlan?.plan.name
        }
        loadingClassName={classes.loadingIcon}
        disabled={
          (subCreationLoading &&
            cardData.plan.name !== selectedPlan?.plan.name) ||
          isCurrentSub(cardData)
        }
      >
        {cardData.plan.name === "Business"
          ? "Contact Us"
          : subCreationLoading && cardData.plan.name === selectedPlan?.plan.name
          ? ""
          : "Select"}
      </Button>
    );
  };

  const checkIfReccomended = (cardData: PlanCardData) => {
    if (dynamicContent.highlightedPlan) {
      return dynamicContent.highlightedPlan === cardData.plan.name;
    }
    return cardData.isRecommended;
  };

  const getComparisonItem = (item) => {
    return (
      <>
        {typeof item.comparison === "string" ? (
          <Typography variant="body1" className={classes.comparisonText}>
            {item.comparison}
          </Typography>
        ) : item.comparison ? (
          <CheckBoxIcon className={classes.checkboxIcon} />
        ) : (
          <CheckBoxOutlineBlankIcon className={classes.emptyCheckbox} />
        )}
      </>
    );
  };

  const checkHeight = (item) => {
    if (!item.description) return "less";
    return "";
  };

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: "yearly",
      label: "Pay Annually",
    },
    {
      value: "monthly",
      label: "Pay Monthly",
    },
  ];

  const handleAccordionChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded });
    };

  const sendBusinessEmail = () => {
    trackEvent("Subscription Business Email Sent", {
      shopId: shop.id,
      currentPlan: subscription?.plan,
    });

    sendBusinessPlanInterestEmail({});

    dispatch(
      openModal({
        modalType: "BUSINESS_CTA_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const DesktopComparisonTable = () => (
    <Grid container item xs={12} className={classes.comparisonTable}>
      <Typography
        variant="h2"
        className={classes.comparisonTableTitle}
        innerRef={comparisonRef}
      >
        Compare Plan Features
      </Typography>
      <Grid container item xs={12}>
        <Grid container item xs={6} />
        <Grid container item xs={6} wrap="nowrap" justify="space-around">
          <Typography variant="subtitle2" className={classes.tableHeaders}>
            Basic
          </Typography>
          <Typography variant="subtitle2" className={classes.tableHeaders}>
            Pro
          </Typography>
          <Typography variant="subtitle2" className={classes.tableHeaders}>
            Business
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6} direction="column" wrap="nowrap">
        {sortedComparisonCategories.map((category, index) => (
          <Grid
            container
            item
            xs={12}
            key={index}
            direction="column"
            wrap="nowrap"
          >
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              className={classes.comparisonCategoryHeader}
            >
              <Typography
                variant="subtitle1"
                className={classes.comparisonCategoryHeaderText}
              >
                {category.name}
              </Typography>
            </Grid>
            {planCardData[0].plan.planComparisonItems
              .filter((item) => item.category === category.id)
              .map((item, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  key={index}
                  direction="column"
                  alignItems="flex-start"
                  justify="center"
                >
                  <Grid
                    container
                    className={clsx([
                      classes.comparisonRowItem,
                      checkHeight(item),
                    ])} //needs less height
                    direction="column"
                    wrap="nowrap"
                    justify="center"
                    alignItems="flex-start"
                    style={{ paddingLeft: 16 }}
                  >
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{ __html: item.name }}
                    />
                    {item.description && (
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                        style={{ textAlign: "left" }}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={6} wrap="nowrap">
        {planCardData.map((cardData, index) => (
          <Grid container key={index} direction="column" wrap="nowrap">
            {sortedComparisonCategories.map((category, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                direction="column"
                wrap="nowrap"
              >
                <div className={classes.comparisonCategoryHeader} />
                {cardData.plan.planComparisonItems
                  .filter((item) => item.category === category.id)
                  .map((item, index) => (
                    <Grid
                      container
                      key={index}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid
                        container
                        className={clsx([
                          classes.comparisonRowItem,
                          checkHeight(item),
                        ])} //needs more height
                        direction="column"
                        wrap="nowrap"
                        justify="center"
                        alignItems="center"
                      >
                        {getComparisonItem(item)}
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const MobileComparisonTable = () => (
    <Grid container item xs={12} className={classes.comparisonTable}>
      <Typography
        variant="h2"
        className={classes.comparisonTableTitle}
        innerRef={comparisonRef}
      >
        Compare Plan Features
      </Typography>
      <Grid container item xs={12} direction="column" wrap="nowrap">
        {sortedComparisonCategories.map((category, index) => (
          <Accordion
            key={index}
            className={classes.accordionRoot}
            expanded={
              expanded[category.id] === undefined ? true : expanded[category.id]
            }
            onChange={handleAccordionChange(category.id)}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  {expanded ? (
                    <ExpandMoreIcon className={classes.accordionIcon} />
                  ) : (
                    <ChevronRightIcon className={classes.accordionIcon} />
                  )}
                </IconButton>
              }
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              className={classes.accordionSummary}
            >
              <Typography variant="subtitle1">{category.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item xs={12} direction="column" wrap="nowrap">
                {planCardData[0].plan.planComparisonItems // keying off a single plan first to get the comparison item subtitle
                  .filter((outerItem) => outerItem.category === category.id)
                  .map((outerItem, index) => (
                    <Grid
                      container
                      key={index}
                      direction="column"
                      wrap="nowrap"
                    >
                      <Grid container item xs={12} alignItems="center">
                        <Typography
                          variant="subtitle1"
                          className={classes.accordionTitle}
                          dangerouslySetInnerHTML={{ __html: outerItem.name }}
                        />
                        {outerItem.description && (
                          <Tooltip
                            title={outerItem.description}
                            className={classes.tooltipButtonMobile}
                          >
                            <IconButton>
                              <SvgIcon className={classes.infoIcon}>
                                <InfoOutlinedIcon />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                      {planCardData.map(
                        (
                          cardData,
                          index // then looping through all plans to get the comparison item
                        ) => (
                          <Grid
                            container
                            key={index}
                            direction="column"
                            wrap="nowrap"
                            alignItems="flex-start"
                          >
                            {cardData.plan.planComparisonItems
                              .filter(
                                (innerItem) =>
                                  innerItem.category === category.id
                              )
                              .filter(
                                (innerItem) => innerItem.name === outerItem.name
                              )
                              .map((innerItem, index) => (
                                <Grid
                                  container
                                  key={index}
                                  alignItems="center"
                                  justify="space-between"
                                  className={classes.accordionItem}
                                >
                                  <Grid item xs={7}>
                                    <Typography variant="body1">
                                      {cardData.plan.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={5}
                                    justify="flex-end"
                                    style={{ textAlign: "right" }}
                                  >
                                    {getComparisonItem(innerItem)}
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        )
                      )}
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );

  const getPlanCards = () => {
    // if userState is one of the legacy plans then we need to show the legacy plan card
    const isLegacy =
      userState === "legacyInTrial" ||
      userState === "legacyNewSubscriber" ||
      userState === "legacyTrialCompleted";
    return (
      <Grid
        container
        item
        xs={12}
        className={classes.pcWrapper}
        wrap="nowrap"
        alignItems="center"
        spacing={3}
        justify="center"
      >
        {isLegacy && (
          <Grid
            container
            item
            className={clsx(
              classes.cardWrapper,
              dynamicContent.highlightedPlan !== "Legacy"
                ? classes.recommended
                : ""
            )}
            md={12}
            xs={12}
          >
            {userState !== "legacyInTrial" && ( // if user is in trial, we want the trial card to be highlighted
              <Typography
                variant="subtitle2"
                className={classes.recommendedText}
              >
                {dynamicContent.recommendedText}
              </Typography>
            )}
            <Grid
              container
              direction="column"
              className={
                userState !== "legacyInTrial"
                  ? classes.pcContainerRecommended
                  : classes.pcContainer
              }
            >
              {isMobile ? (
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="center"
                  wrap="nowrap"
                  direction="column"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                  >
                    <Typography variant="h2" className={classes.pcName}>
                      {subscription?.plan?.tier?.name == "Starter"
                        ? "Starter"
                        : subscription?.plan?.name}
                    </Typography>
                  </Grid>
                  <Typography variant="body2" className={classes.pcDescription}>
                    {subscription?.plan?.tier?.name == "Starter"
                      ? "The essentials to start selling your food products commitment-free."
                      : subscription?.plan?.description}
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="center"
                  wrap="nowrap"
                  direction="column"
                >
                  <Typography variant="h2" className={classes.pcName}>
                    {account?.tier?.name == "Starter"
                      ? "Starter"
                      : subscription?.plan?.name}
                  </Typography>
                  <Typography variant="body2" className={classes.pcDescription}>
                    {account?.tier?.name == "Starter"
                      ? "The essentials to start selling your food products commitment-free."
                      : subscription?.plan?.description}
                  </Typography>
                </Grid>
              )}
              <Typography variant="body1" className={classes.pcPrice}>
                {subscription?.plan?.prices.length > 0 &&
                account?.tier?.name !== "Starter"
                  ? getPrice(subscription?.plan?.prices)
                  : "$0"}
                <span className={classes.priceDesc}>/mo.</span>
              </Typography>
              <Typography variant="body2" className={classes.helpText}>
                {account?.tier?.name == "Starter"
                  ? "No subscription required"
                  : billingFrequency === "monthly"
                  ? "billed monthly"
                  : "billed annually"}
              </Typography>
              <Button
                className={classes.ctaText}
                variant="contained"
                color="primary"
                size="small"
                onClick={null}
                disabled={true}
              >
                My Current Plan
              </Button>
              {userState === "legacyNewSubscriber" && (
                <Typography variant="body2" className={classes.legacySpacer}>
                  -{/* spacer */}
                </Typography>
              )}
              <Grid container direction="column">
                <Typography variant="subtitle1" className={classes.pcNoteTitle}>
                  We think you'll love:
                </Typography>
                {account?.tier?.name == "Starter" ? (
                  <>
                    <Typography variant="body2" className={classes.pcNote}>
                      🌟 Endless fee-free tips
                    </Typography>
                    <Typography variant="body2" className={classes.pcNote}>
                      🛒 E-commerce suite
                    </Typography>
                    <Typography variant="body2" className={classes.pcNote}>
                      💵 Weekly payouts
                    </Typography>
                  </>
                ) : (
                  subscription?.plan?.notes?.map((note, index) => (
                    <Typography
                      variant="body2"
                      className={classes.pcNote}
                      dangerouslySetInnerHTML={{ __html: note }}
                      key={index}
                    />
                  ))
                )}
              </Grid>
              <Grid item container direction="column">
                <Typography variant="subtitle1" className={classes.pcNoteTitle}>
                  Credit Card Rates
                </Typography>
                <Typography variant="body1" className={classes.pcFee}>
                  💳 2.9% + 30¢ USD online
                </Typography>
              </Grid>
              <Grid item container direction="column">
                <Grid container item alignItems="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.pcNoteTitle}
                  >
                    Transaction Fees
                  </Typography>
                  <Tooltip
                    title={tooltipText}
                    className={classes.tooltipButton}
                  >
                    <IconButton>
                      <SvgIcon className={classes.infoIcon}>
                        <InfoOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Typography variant="body1" className={classes.pcFee}>
                  🧾{" "}
                  {account?.legacyTier?.castironTakeRate
                    ? account?.legacyTier?.castironTakeRate
                    : account?.tier?.castironTakeRate}
                  %
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {planCardData.length > 0 &&
          planCardData
            .filter(
              (cardData) => !(isLegacy && cardData.plan.name === "Business")
            )
            .map((cardData, index) => (
              <Grid
                container
                item
                className={clsx(
                  classes.cardWrapper,
                  dynamicContent.highlightedPlan !== "Legacy" &&
                    checkIfReccomended(cardData)
                    ? classes.recommended
                    : ""
                )}
                md={12}
                xs={12}
                key={index}
              >
                {isHighlightedPlan(cardData) && (
                  <Typography
                    variant="subtitle2"
                    className={classes.recommendedText}
                  >
                    {dynamicContent.recommendedText}
                  </Typography>
                )}
                <Grid
                  container
                  direction="column"
                  className={
                    isHighlightedPlan(cardData)
                      ? classes.pcContainerRecommended
                      : classes.pcContainer
                  }
                  style={planCardData?.length === 1 ? { margin: 0 } : {}}
                >
                  {isMobile ? (
                    <Grid
                      container
                      item
                      justify="center"
                      alignItems="center"
                      wrap="nowrap"
                      direction="column"
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justify="center"
                      >
                        <Typography variant="h2" className={classes.pcName}>
                          {cardData.plan.name}
                        </Typography>
                      </Grid>
                      <Typography
                        variant="body2"
                        className={classes.pcDescription}
                      >
                        {cardData.plan.description}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      justify="center"
                      alignItems="center"
                      wrap="nowrap"
                      direction="column"
                    >
                      <Typography variant="h2" className={classes.pcName}>
                        {cardData.plan.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.pcDescription}
                      >
                        {cardData.plan.description}
                      </Typography>
                    </Grid>
                  )}
                  <Typography variant="body1" className={classes.pcPrice}>
                    {cardData.plan.prices.length > 0
                      ? getPrice(
                          cardData.plan.prices,
                          cardData.plan.name === "Business"
                        )
                      : "$0"}
                    {cardData.plan.name !== "Business" && (
                      <span className={classes.priceDesc}>/mo.</span>
                    )}
                  </Typography>
                  <Typography variant="body2" className={classes.helpText}>
                    {cardData.plan.name !== "Business"
                      ? billingFrequency === "monthly"
                        ? "billed monthly"
                        : "billed annually"
                      : "annual & monthly billing available"}
                  </Typography>
                  {getCTAs(cardData)}
                  <Typography variant="body2" className={classes.ctaHelpText}>
                    {userState === "newUser" ||
                    (userState === "legacyNewSubscriber" &&
                      subscription?.plan?.name !== "Starter")
                      ? cardData.plan.name !== "Business"
                        ? "No credit card required."
                        : `We'll create the perfect plan for you!`
                      : ""}
                  </Typography>

                  {isLegacy && cardData.plan.name === "Basic" ? (
                    <Grid container direction="column">
                      <Typography
                        variant="subtitle1"
                        className={classes.pcNoteTitle}
                      >
                        We think you'll love:
                      </Typography>
                      <Typography variant="body2" className={classes.pcNote}>
                        ➕ Everything in{" "}
                        {account?.legacyTier?.name || "Starter"}
                      </Typography>
                      <Typography variant="body2" className={classes.pcNote}>
                        💲 Lower Transaction Fees
                      </Typography>
                      <Typography variant="body2" className={classes.pcNote}>
                        📝 Tax Deductible
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container direction="column">
                      <Typography
                        variant="subtitle1"
                        className={classes.pcNoteTitle}
                      >
                        We think you'll love:
                      </Typography>
                      {cardData.notes.map((note, index) => (
                        <Typography
                          variant="body2"
                          className={classes.pcNote}
                          dangerouslySetInnerHTML={{ __html: note }}
                          key={index}
                        />
                      ))}
                    </Grid>
                  )}
                  <Grid item container direction="column">
                    <Typography
                      variant="subtitle1"
                      className={classes.pcNoteTitle}
                    >
                      Credit Card Rates
                    </Typography>
                    <Typography variant="body1" className={classes.pcFee}>
                      💳 2.9% + 30¢ USD online
                    </Typography>
                  </Grid>
                  {cardData.takeRate > 0 && (
                    <Grid item container direction="column">
                      <Grid container item alignItems="center">
                        <Typography
                          variant="subtitle1"
                          className={classes.pcNoteTitle}
                        >
                          Transaction Fees
                        </Typography>
                        <Tooltip
                          title={tooltipText}
                          className={classes.tooltipButton}
                        >
                          <IconButton>
                            <SvgIcon className={classes.infoIcon}>
                              <InfoOutlinedIcon />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Typography variant="body1" className={classes.pcFee}>
                        🧾 {cardData.takeRate}%
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
      </Grid>
    );
  };

  const handleComparisonOpen = () => {
    setComparisonOpen(!comparisonOpen);
    if (!comparisonOpen) {
      const interval = setInterval(() => {
        if (comparisonRef.current) {
          comparisonRef.current.scrollIntoView({ behavior: "smooth" });
          clearInterval(interval);
        }
      }, 100);
    } else {
      comparisonRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      {/* --- Subscriptions are no longer in onboarding, this is legacy code just to save for a bit. --- */}
      {/* {userState === 'newUser' && (
        <Grid
          container
          direction="column"
          alignItems="center"
          wrap="nowrap"
          justify="center"
          className={classes.titleContainer}
        >
          <Typography variant="h2" className={classes.title}>
            {dynamicContent.title}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {dynamicContent.subtitle}
          </Typography>
        </Grid>
      )} */}
      <Grid
        container
        item
        alignItems="center"
        className={classes.toggleWrapper}
        direction="column"
      >
        <ToggleButton
          value={billingFrequency}
          exclusive
          onChange={(_, value) => {
            if (value) setBillingFrequency(value as "monthly" | "yearly");
          }}
          aria-label="billing frequency"
          buttonOptions={toggleButtonOptions}
        />
      </Grid>
      {/* ----------Plan Card Section---------- */}
      {getPlanCards()}

      {/* ----------Compare Features Section---------- */}
      {/* {!account?.isCastironPlus() && (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction="column"
          className={classes.comparisonWrapper}
          wrap="nowrap"
        >
          <Button className={classes.comparisonButton} onClick={() => handleComparisonOpen()} variant="outlined">
            Compare Plan Features {comparisonOpen ? '-' : '+'}
          </Button>
          {comparisonOpen && (
            <Grid container item xs={12} direction="column">
              {isMobile ? <MobileComparisonTable /> : <DesktopComparisonTable />}
            </Grid>
          )}
        </Grid>
      )} */}
    </Grid>
  );
};

export default PlansDisplay;

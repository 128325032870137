import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import Dinero from "dinero.js";
import _ from "lodash";
import { Grid, List, ListItem } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import moment from "moment-timezone";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as yup from "yup";
import { useTracking } from "@castiron/utils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { StickyFooterProps } from "./OnboardingFooter";
import {
  AddressInput,
  Banner,
  CustomSwitch,
  TextInput,
  Typography,
} from "@castiron/components";
import { getService } from "../../../firebase";
import AdminForm from "../../AdminForm";
import { Formik } from "formik";
import { Address, addressSchema, ChecklistValues } from "@castiron/domain";
import {
  accountRepository,
  planRepository,
  tierRepository,
} from "../../../domain";
import { SelectedSubscription } from "../../SelectPlan/PlanFlow";
import {
  getShopAction,
  setDiscountAction,
} from "../../../store/reducers/shops";
import { getSubscriptionStatus } from "../../../lib/accountUtils";
import { useConfig } from "@castiron/castiron-firebase";
import UrlRedirect from "../../UrlRedirect";
import { useHistory } from "react-router";

const addCouponToSubService = getService("subscriptions", "addcoupontosub");
const createSubscriptionService = getService(
  "subscriptions",
  "createsubscription"
);
const createSetupIntentService = getService(
  "subscriptions",
  "createsetupintent"
);
const changeSubscriptionService = getService(
  "subscriptions",
  "changesubscription"
);
const getBalanceService = getService("stripe", "getbalance");
const sendSubscriptionUpgradeDuringTrialEmail = getService(
  "subscriptions",
  "sendsubupgradeduringtrialemail"
);
const validatePromoCodeService = getService(
  "subscriptions",
  "validatepromocode"
);

interface Props {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  onFinishOnboarding?: () => void;
}

type FormValues = {
  address: {
    fullAddress: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    region: string;
    regionName: string;
    country: string;
    postalCode: string;
  };
  discountCode?: string;
};

type Validator = (values: FormValues) => boolean;

const useStyles = makeStyles((theme: Theme) => ({
  addressLine1: {
    "& div": {
      margin: 0,
    },
  },
  addressLineTwoContainer: {
    height: 54,
    alignSelf: "center",
  },
  addressLine2CTA: {
    color: theme.branding.v2.plum[500],
    "&:hover": {
      cursor: "pointer",
    },
  },
  annualBanner: {
    backgroundColor: "#FCF7FC",
    padding: "21px 16px",
    borderRadius: "12px",
  },
  cardElement: {
    border: "none",
    padding: 14,
    backgroundColor: theme.branding.v2.gray[0],
    color: theme.branding.v2.gray[900],
    width: "100%",
    borderRadius: "inherit",
    "& .MuiInputBase-input": {
      ...theme.typography.body4,
      fontSize: 16,
      padding: 0,
      color: theme.branding.v2.gray[900],
    },
    "& ::placeholder": {
      ...theme.typography.body4,
      fontSize: 16,
      opacity: 1,
    },
  },
  changeLink: {
    color: theme.branding.v2.plum[500],
    cursor: "pointer",
  },
  container: {
    margin: "24px 0px 200px",
    gap: 24,
  },
  creditCardText: {
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  discountCodeContainer: {
    "& svg": {
      color: theme.branding.v2.gray[900],
    },

    "& h6": {
      color: theme.branding.v2.plum[500],
    },
  },
  discountCodeError: {
    color: theme.branding.v2.red[500],
    marginTop: 4,
  },
  discountCodeSuccess: {
    color: theme.branding.v2.green[500],
    marginTop: 4,
  },
  discountCodeTitle: {
    color: theme.branding.v2.gray[900] + " !important",
    marginBottom: "8px",
  },
  discountCodeInput: {
    marginRight: 8,
    width: "100%",
  },
  errorBox: {
    backgroundColor: theme.branding.v2.red[50],
    borderRadius: 12,
    padding: "12px 16px",
    margin: "16px 0px",
  },
  errorColor: {
    color: `${theme.branding.v2.red[500]} !important`,
    "& input": {
      color: `${theme.branding.v2.red[500]} !important`,
    },
  },
  errorList: {
    listStyleType: "disc",
  },
  errorListItem: {
    display: "list-item",
    color: theme.branding.v2.red[500],
    marginLeft: 8,
  },
  header: {
    textAlign: "center",
  },
  leftCardElement: {
    borderBottomLeftRadius: 12,
  },
  longCardElement: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: "12px 12px 0px 0px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 200,
      width: "100%",
    },
  },
  lowerCardElements: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderTop: "none",
    borderRadius: "0 0 12px 12px",
    width: "100%",
  },
  paymentInfo: {
    gap: "24px",
  },
  rightCardElement: {
    height: 44,
    borderBottomRightRadius: 12,
    borderLeft: `1px solid ${theme.branding.v2.gray[200]}`,
  },
}));

const SubscriptionCheckout: React.FC<Props> = (props: Props) => {
  const {
    setLoading,
    setHeader,
    setStickyFooterProps,
    setSubHeader,
    onFinishOnboarding,
  } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

  const { account, discount, shop, subscription, testClock, userState } =
    useAppSelector((state) => ({
      account: state.shops.account,
      discount: state.shops.discount,
      shop: state.shops.shop,
      subscription: state.shops.account.subscription,
      testClock: state.debug.stripe?.testClock,
      userState: state.shops.userState,
    }));

  const ffconfig = useConfig();
  const isFirstMonthPromoEnabled = ffconfig?.featureFlag(
    "feature_first_month_promo",
    shop
  );

  const cardRef = useRef<HTMLBaseElement>();
  const errorRef = useRef<any>();
  const formikRef = useRef<any>();

  let addressText;
  if (account?.billingAddress?.fullAddress) {
    addressText = account.billingAddress.fullAddress;
  } else if (account?.billingAddress?.region) {
    addressText = `${account.billingAddress.city}, ${account.billingAddress.region} ${account.billingAddress.postalCode}`;
  }

  const isPendingCanceled = subscription?.status === "pending-canceled";
  const lineItemPriceFormat = "$0,0.00";

  const [address, setAddress] = useState<Address>();
  const [balanceOverageMessage, setBalanceOverageMessage] =
    useState<ReactNode>();
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [changeAddress, setChangeAddress] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [discountCodeApplied, setDiscountCodeApplied] = useState(false);
  const [discountCodeError, setDiscountCodeError] = useState("");
  const [discountCodeSuccess, setDiscountCodeSuccess] = useState("");
  const [discountCodeInputText, setDiscountCodeInputText] =
    useState<string>("");
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  const [showaddressLine2Input, setShowaddressLine2Input] = useState(false);
  const [stripeErrors, setStripeErrors] = useState([]);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [selectedSub, setSelectedSub] = useState<SelectedSubscription>();

  useEffect(() => {
    dispatch(getShopAction(account.id));
  }, []);

  useEffect(() => {
    setHeader("");
    setSubHeader("");
    setStickyFooterProps({
      onNextClick: async () => {
        if (!formikRef.current.isSubmitting) {
          await formikRef.current.validateForm();
          if (!formikRef.current.isValid) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            console.log({ errors: formikRef.current.errors });
          }
          formikRef.current.submitForm();
        }
      },
      isFinalStep: true,
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader("");
      setSubHeader("");
    };
  }, [formikRef]);

  useEffect(() => {
    findSubscriptionPlan(isAnnual);
  }, [isAnnual]);

  const findSubscriptionPlan = async (annual) => {
    const nouryshPlan = await planRepository.get("Z20eHt7WCS46FcULq7zo");
    const planTier = await tierRepository.get("Uh5M1dq1XmdacFt5uzwm");
    const price = nouryshPlan?.prices?.find((p) =>
      annual ? p?.frequency === "yearly" : p?.frequency === "monthly"
    );

    setSelectedSub({
      plan: nouryshPlan,
      price,
      takeRate: planTier?.castironTakeRate,
    });
  };

  useEffect(() => {
    /* balance is negative when already paid */
    const currentBalanceFormatted = Dinero({
      amount: currentBalance * -1,
    }).toFormat(lineItemPriceFormat);
    if (currentBalance * -1 > selectedSub?.price?.amount) {
      setBalanceOverageMessage(
        `Your ${currentBalanceFormatted} in plan credit will be applied to this and future billing cycles until it runs out. Your card won’t be charged now.`
      );
    } else {
      setBalanceOverageMessage(
        <>
          We’ve prorated the remainder of your Nourysh Plan and applied the
          credit of&nbsp;
          <span style={{ fontWeight: 700 }}>{currentBalanceFormatted}</span> to
          your new plan.
        </>
      );
    }
  }, [selectedSub, currentBalance]);

  useEffect(() => {
    getBalanceService({}).then((resp) => setCurrentBalance(resp.balance));
  }, []);

  useEffect(() => {
    if (discount) {
      setDiscountCodeInputText(discount.code);
      setDiscountCodeApplied(true);
      formikRef?.current?.setFieldValue("discountCode", discount.code);
    }
  }, [discount]);

  const validateThen = async (
    values: FormValues,
    validators: Validator[],
    func: (values: FormValues) => Promise<void>
  ) => {
    const valid = _.every(
      validators.map((v) => v(values)),
      (v) => v
    );

    if (valid) {
      return func(values);
    } else {
      setLoading(false);
    }
  };

  const scrollToError = () => {
    let errorEl = document.getElementById("error");
    errorEl.scrollIntoView({ block: "nearest" });
    return;
  };

  const createNewSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (error) {
      console.error(error);
      return;
    }

    const { clientSecret } = await createSubscriptionService({
      planId: selectedSub?.plan?.id,
      priceId: selectedSub?.price?.id,
      testClock,
      discountCode: discount?.code,
      paymentMethodId: paymentMethod?.id,
      address: {
        addressLine1: values.address.addressLine1,
        addressLine2: values.address.addressLine2,
        city: values.address.city,
        region: values.address.region,
        regionName: values.address.regionName,
        country: values.address.country,
        postalCode: values.address.postalCode,
      },
    });

    if (clientSecret) {
      console.debug("Confirming Card Payment");
      const resp = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      console.debug("Confirmation Response", resp);

      dispatch(setDiscountAction(undefined));

      if (!resp.error) {
        // If credit card succeeds add to checklist
        if (
          !shop?.checklistCompletions.includes(ChecklistValues.AddCreditCard)
        ) {
          await shop.addToChecklist(ChecklistValues.AddCreditCard);
          await dispatch(getShopAction(shop.id));
        } else {
          history.push("/");
          <UrlRedirect url="/" />;
        }

        setLoading(false);
      } else {
        setLoading(false);
        resp.error && setSubmitErrors((prev) => [...prev, resp.error.message]);
        scrollToError();
      }
    } else {
      dispatch(setDiscountAction(undefined));
      setLoading(false);
    }
  };

  const updateSubscription = useCallback(async () => {
    const elementsTouched =
      !!cardComplete.cardNumber &&
      !!cardComplete.cardCvc &&
      !!cardComplete.cardExpiry;

    let clientSecret;
    if (
      selectedSub.plan.id !== account?.subscription?.plan.id ||
      selectedSub.price.id !== account?.subscription?.price.id
    ) {
      const resp = await changeSubscriptionService({
        planId: selectedSub.plan.id,
        priceId: selectedSub.price.id,
        newCard: elementsTouched,
        discountCode: discount?.code,
      });
      clientSecret = resp.clientSecret;
    }

    dispatch(setDiscountAction(undefined));

    return clientSecret;
  }, [account, cardComplete, selectedSub]);

  const updateExistingSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const clientSecret = await updateSubscription();

    if (clientSecret) {
      const resp = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      if (resp.paymentIntent?.status === "succeeded" && !resp.error) {
        dispatch(setDiscountAction(undefined));
        setLoading(false);
      } else {
        window.scroll(0, 0);
        resp.error && setSubmitErrors([resp.error.message]);
        errorRef.current?.scrollTo({ x: 0, y: 0, behavior: "smooth" });
        setLoading(false);
      }
    } else {
      dispatch(setDiscountAction(undefined));
      setLoading(false);
    }
  };

  const updateTrialSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    await updateSubscription();
    const resp = await createSetupIntentService({});
    const clientSecret = resp.clientSecret;

    if (clientSecret) {
      const resp = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      if (resp.setupIntent?.status === "succeeded" && !resp.error) {
        if (
          !shop?.checklistCompletions.includes(ChecklistValues.AddCreditCard)
        ) {
          trackEvent("Owner Added Payment Method");
          await shop.addToChecklist(ChecklistValues.AddCreditCard);
          await dispatch(getShopAction(shop.id));
        }
        if (["inTrial", "legacyInTrial"].includes(userState)) {
          sendSubscriptionUpgradeDuringTrialEmail({});
        }

        if (discount) {
          await addCouponToSubService({
            couponId: discount.code,
          });
          dispatch(setDiscountAction(undefined));
        }

        setLoading(false);
      } else {
        window.scroll(0, 0);
        resp.error && setSubmitErrors([resp.error.message]);
        errorRef.current?.scrollTo({ x: 0, y: 0, behavior: "smooth" });
        dispatch(setDiscountAction(undefined));
        setLoading(false);
      }
    } else {
      dispatch(setDiscountAction(undefined));
      setLoading(false);
    }
  };

  const updatePaymentMethod = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { clientSecret } = await createSetupIntentService({});
    const setupIntentResp = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
          address: {
            line1: values.address.addressLine1,
            line2: values.address.addressLine2,
            city: values.address.city,
            state: values.address.region,
            country: values.address.country,
            postal_code: values.address.postalCode,
          },
        },
      },
    });

    if (
      setupIntentResp.setupIntent?.status === "succeeded" &&
      !setupIntentResp.error
    ) {
      setLoading(false);
    } else {
      console.error("setupIntent failed", setupIntentResp.error);
      window.scroll(0, 0);
      setupIntentResp.error &&
        setSubmitErrors([...submitErrors, setupIntentResp.error.message]);
      setLoading(false);
    }
  };

  const applyDiscountCode = useCallback(async () => {
    setDiscountCodeError("");
    setDiscountCodeSuccess("");
    try {
      setIsValidatingCode(true);
      const validateCodeResp = await validatePromoCodeService({
        code: discountCodeInputText,
      });

      if (validateCodeResp.couponValid === false) {
        setDiscountCodeError("Not a valid coupon code.");
        dispatch(setDiscountAction(undefined));
      }
      if (
        validateCodeResp.appliesToPlans &&
        !validateCodeResp.appliesToPlans?.includes(selectedSub.plan.id)
      ) {
        setDiscountCodeError(
          "Sorry, this coupon can only be used on a Pro Subscription."
        );
        dispatch(setDiscountAction(undefined));
      }
      if (
        !validateCodeResp.appliesToPlans ||
        validateCodeResp.appliesToPlans.includes(selectedSub.plan.id)
      ) {
        if (!discountCodeApplied) {
          setDiscountCodeError("No coupon code entered.");
          dispatch(setDiscountAction(undefined));
        }
      }
      setIsValidatingCode(false);
    } catch (err) {
      console.error("Error applying discount code", err);
      if (err.message.startsWith("No such coupon")) {
        setDiscountCodeError("Not a valid coupon code.");
        dispatch(setDiscountAction(undefined));
      } else {
        setDiscountCodeError("Sorry, there was an error applying this coupon.");
        dispatch(setDiscountAction(undefined));
      }
      setIsValidatingCode(false);
    }
  }, [discountCodeApplied]);

  const submit = async (values: FormValues) => {
    setLoading(true);
    submitErrors && setSubmitErrors([]);

    try {
      if (values?.discountCode) {
        await applyDiscountCode();
      }

      switch (userState) {
        case "newUser":
          await validateThen(
            values,
            [requireAddress, requireCard],
            createNewSubscription
          );
          break;
        case "legacyNewSubscriber":
          await createNewSubscription(values);
          if (isFirstMonthPromoEnabled) {
            await accountRepository.updateProps(account.id, {
              "config.showFirstMonthPromo": true,
            });
          }
          break;
        case "currentSubscriber":
          await validateThen(
            values,
            [requireAddress, requireCard],
            updateExistingSubscription
          );
          break;
        case "inTrial":
        case "legacyInTrial":
          await validateThen(
            values,
            [requireAddress, requireCard],
            updateTrialSubscription
          );
          break;
        case "subscriptionEnded":
        case "trialExpired":
        case "legacyTrialCompleted":
          await validateThen(
            values,
            [requireAddress, requireCard],
            createNewSubscription
          );
          break;
        default:
          await validateThen(
            values,
            [requireAddress, requireCard],
            updatePaymentMethod
          );
      }

      trackEvent("Subscription Payment Method Changed", {
        currentSubscription: account?.subscription,
        newSubscription: selectedSub.plan,
        shopId: shop?.id,
        subscriptionStatus: getSubscriptionStatus(account),
        userState: userState,
      });

      setLoading(false);
      onFinishOnboarding();
    } catch (err) {
      console.error("Error processing subscription", err);
      setSubmitErrors((prev) => [
        ...prev,
        `There was an error processing your subscription: ${err.message}`,
      ]);
      setLoading(false);
    }
  };

  const onStripeFieldChange = (e, fieldType) => {
    e.error && setStripeErrors((prev) => [...prev, e.error]);

    if (fieldType === "number") {
      !e.error &&
        setStripeErrors((prev) =>
          prev.filter((prevError) => {
            return !prevError.code.includes("number");
          })
        );
    } else if (fieldType === "expiry") {
      !e.error &&
        setStripeErrors((prev) =>
          prev.filter((prevError) => {
            return !prevError.code.includes("expiry");
          })
        );
    } else {
      !e.error &&
        setStripeErrors((prev) =>
          prev.filter((prevError) => {
            return !prevError.code.includes("cvc");
          })
        );
    }
  };

  const requireAddress = (values: FormValues) => {
    if (
      !account?.billingAddress?.postalCode &&
      values.address.fullAddress === ""
    ) {
      setSubmitErrors((prev) => [...prev, "Please enter your address"]);
      return false;
    }

    return true;
  };

  const requireCard = (values: FormValues) => {
    const elementsTouched =
      !!cardComplete.cardNumber &&
      !!cardComplete.cardCvc &&
      !!cardComplete.cardExpiry;

    if (!elementsTouched) {
      setSubmitErrors((prev) => [...prev, "Please enter your card details"]);
      return false;
    }

    return true;
  };

  const handleCardElementOnChange = (e) => {
    setCardComplete((cc) => {
      return { ...cc, [e.elementType]: e.complete };
    });
  };

  const renderStripeErrors = () => {
    if (!_.isEmpty(stripeErrors)) {
      return (
        <Grid className={classes.errorBox}>
          <Typography variant="body4" className={classes.errorColor}>
            Please address the following error{stripeErrors.length > 1 && "s"}:
          </Typography>
          <List className={classes.errorList}>
            {stripeErrors.map((error) => (
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography variant="body4" className={classes.errorListItem}>
                  {error.message}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      );
    }
  };

  const onChangeAddress = (setFieldValue) => {
    setChangeAddress(true);
    setAddress({
      fullAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      region: "",
      regionName: "",
      postalCode: "",
      country: "",
    });
    setFieldValue("address", {
      fullAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      region: "",
      regionName: "",
      postalCode: "",
      country: "",
    });
  };

  const onAddressChange = useCallback((addr: Address) => {
    setAddress(addr);
  }, []);

  const calculateNextCharge = (annual) => {
    const nextDate = moment().add(1, "month");
    return nextDate.format("LL");
  };

  const initialValues = {
    address: {
      fullAddress: account?.billingAddress?.fullAddress || "",
      addressLine1: account?.billingAddress?.addressLine1 || "",
      addressLine2: account?.billingAddress?.addressLine2 || "",
      city: account?.billingAddress?.city || "",
      region: account?.billingAddress?.region || "",
      regionName: account?.billingAddress?.regionName || "",
      country: account?.billingAddress?.country || "",
      postalCode: account?.billingAddress?.postalCode || "",
    },
    discountCode: "",
  };

  const paymentSchema = yup.object({
    address: addressSchema(false),
    discountCode: yup.string(),
  });

  return (
    <Grid container direction="column" className={classes.container}>
      {userState === "newUser" && (
        <Grid
          container
          direction="column"
          justify="center"
          wrap="nowrap"
          className={classes.header}
        >
          <Typography variant="body1">Try Nourysh</Typography>
          <Typography
            variant="h4"
            style={{ fontSize: "24px", lineHeight: "36px" }}
          >
            FREE for 30 days
          </Typography>
          <Typography variant="body1">
            {isAnnual ? "Then $216 per year" : "Then $24 per month"}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        className={classes.annualBanner}
      >
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          style={{ gap: "8px" }}
        >
          <CustomSwitch
            customColor={theme.branding.v2.plum[500]}
            onChange={() => setIsAnnual(!isAnnual)}
            value={isAnnual}
          />
          <Typography variant="body1">
            <span style={{ fontWeight: 700, color: "#7D387D" }}>Save $72</span>{" "}
            with annual billing
          </Typography>
        </Grid>
        <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
          $216/year
        </Typography>
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={paymentSchema}
        validateOnMount
        innerRef={formikRef}
      >
        {({ errors, touched, setFieldValue }): ReactElement => {
          return (
            <AdminForm>
              <Grid
                container
                item
                className={classes.paymentInfo}
                direction="column"
              >
                {!_.isEmpty(submitErrors) && submitErrors.length > 1 ? (
                  <Grid className={classes.errorBox} style={{ marginTop: 0 }}>
                    <Typography variant="h4" className={classes.errorColor}>
                      Error:
                    </Typography>
                    <List
                      className={classes.errorList}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      {submitErrors.map((error) => (
                        <ListItem>
                          <Typography
                            variant="body4"
                            className={classes.errorListItem}
                          >
                            {error}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ) : submitErrors.length === 1 ? (
                  <Grid className={classes.errorBox} style={{ marginTop: 0 }}>
                    <Typography variant="h4" className={classes.errorColor}>
                      Error:{" "}
                      <span style={{ fontWeight: 400 }}>{submitErrors[0]}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid container item justify="space-between" direction="column">
                  <Grid item className={classes.creditCardText}>
                    <Typography variant="button">
                      Credit/Debit Card Info
                    </Typography>
                  </Grid>
                  <Grid item>
                    <span ref={cardRef}>
                      <CardNumberElement
                        onChange={(e) => {
                          onStripeFieldChange(e, "number");
                          handleCardElementOnChange(e);
                        }}
                        options={{
                          classes: {
                            base: clsx([
                              classes.cardElement,
                              classes.longCardElement,
                            ]),
                          },
                          placeholder: "Card Number",
                          style: {
                            // for whatever reason you can only edit the placeholder inline
                            base: {
                              fontSize: "16px",
                              "::placeholder": {
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                color: theme.branding.gray[700],
                              },
                            },
                          },
                        }}
                      />
                      <Grid
                        container
                        item
                        className={classes.lowerCardElements}
                      >
                        <Grid item xs={6}>
                          <CardExpiryElement
                            onChange={(e) => {
                              onStripeFieldChange(e, "expiry");
                              handleCardElementOnChange(e);
                            }}
                            options={{
                              classes: {
                                base: clsx([
                                  classes.leftCardElement,
                                  classes.cardElement,
                                ]),
                              },
                              style: {
                                base: {
                                  fontSize: "16px",
                                  "::placeholder": {
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: theme.branding.gray[700],
                                  },
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CardCvcElement
                            onChange={(e) => {
                              onStripeFieldChange(e, "cvc");
                              handleCardElementOnChange(e);
                            }}
                            options={{
                              classes: {
                                base: clsx([
                                  classes.rightCardElement,
                                  classes.cardElement,
                                ]),
                              },
                              style: {
                                base: {
                                  fontSize: "16px",
                                  "::placeholder": {
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: theme.branding.gray[700],
                                  },
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </span>
                    {renderStripeErrors()}
                  </Grid>
                  {!!currentBalance && (
                    <Grid
                      item
                      style={{ marginTop: "12px", marginBottom: "32px" }}
                    >
                      <Banner variant="money">
                        <Typography
                          variant="body2"
                          style={{ color: "inherit" }}
                        >
                          {balanceOverageMessage}
                        </Typography>
                      </Banner>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  direction="column"
                  id="address"
                  style={{ width: "100%" }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    style={{ gap: "8px" }}
                  >
                    <Typography variant="button">Billing Address</Typography>
                    {(!!addressText && !changeAddress && !address) ||
                    isPendingCanceled ? (
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                      >
                        <Typography>
                          {addressText}{" "}
                          {!isPendingCanceled && (
                            <span
                              className={classes.changeLink}
                              onClick={() => onChangeAddress(setFieldValue)}
                            >
                              Change
                            </span>
                          )}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                        style={{ width: "100%" }}
                      >
                        <Grid
                          container
                          className={classes.addressLine1}
                          id="address"
                        >
                          <AddressInput
                            required
                            addressFields={{
                              address: "address.fullAddress",
                              addressLine1: "address.addressLine1",
                              city: "address.city",
                              region: "address.region",
                              regionName: "address.regionName",
                              postalCode: "address.postalCode",
                              country: "address.country",
                            }}
                            onAddressChange={onAddressChange}
                            error={
                              touched.address?.fullAddress &&
                              errors.address?.fullAddress
                            }
                          />
                        </Grid>
                        <Grid item style={{ width: "100%", marginTop: 8 }}>
                          {showaddressLine2Input ? (
                            <Grid
                              container
                              item
                              direction="column"
                              wrap="nowrap"
                              style={{ gap: "8px" }}
                            >
                              <Typography
                                variant="button"
                                style={{ marginTop: 8 }}
                              >
                                Apt #, Suite, Floor
                              </Typography>
                              <TextInput
                                name="address.addressLine2"
                                className={classes.addressLineTwoContainer}
                                placeholder="Apt #, Suite, Floor"
                              />
                            </Grid>
                          ) : (
                            <Typography
                              variant="button"
                              className={classes.addressLine2CTA}
                              onClick={() => setShowaddressLine2Input(true)}
                            >
                              + Add Apt #, Suite, Floor
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  direction="column"
                  id="discountCode"
                  style={{ width: "100%" }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.discountCodeTitle}
                    >
                      Coupon Code
                    </Typography>
                    <Grid
                      container
                      item
                      justify="space-between"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item className={classes.discountCodeInput}>
                        <TextInput
                          name="discountCode"
                          onChange={(e) => {
                            setDiscountCodeInputText(e.target.value);
                            setFieldValue("discountCode", e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      justify="flex-start"
                      alignItems="center"
                    >
                      {discountCodeError && (
                        <Typography
                          variant="body1"
                          className={classes.discountCodeError}
                        >
                          {discountCodeError}
                        </Typography>
                      )}
                      {discountCodeSuccess && (
                        <Typography
                          variant="body1"
                          className={classes.discountCodeSuccess}
                        >
                          {discountCodeSuccess}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AdminForm>
          );
        }}
      </Formik>
      {account.status === "onboarding" && (
        <Grid container justify="center">
          <Typography
            variant="body2"
            style={{ color: theme.branding.v2.gray[700], textAlign: "center" }}
          >
            After your trial ends, you will be charged{" "}
            {isAnnual ? "$216.00 per year" : "$24.00 per month"} starting on{" "}
            {calculateNextCharge(isAnnual)}. You can always cancel before then.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionCheckout;

import React, { useEffect } from 'react';
import { Box, Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Menus/AvatarMenu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  box: {
    borderRadius: 16,
    border: `1px solid ${theme.branding.gray[400]}`,
    padding: 35,
  },
  image: {
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 9,
  },
  subtitle: {
    color: theme.branding.gray[700],
    marginBottom: 32,
    lineHeight: '32px',
  },
}));

const Resources: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setFooterCTAs([]);
  }, [])

  const contentBoxes = [
    {
      paragraph:
        'Learn 2 Serve is a convenient way to sign up to receive state-approved food and alcohol certifications for your cottage cooking business.',
      linkAnchor: 'Get 10% at Learn 2 Serve',
      logo: '/assets/img/resources/logo-resources-360.png',
      link: '',
      href: 'https://www.anrdoezrs.net/click-100366346-12641609',
    },
    {
      paragraph:
        'The Food Liability Insurance Program (FLIP) provides general commercial and product liability coverage for food businesses.',
      linkAnchor: 'Use code “castiron11” for $11 off policies',
      logo: '/assets/img/resources/logo-resources-flip.png',
      link: '',
      href: 'https://www.fliprogram.com/policy/buy/ai/MTYyMg==',
    },
    {
      paragraph:
        'Snappr is a popular on-demand photography network food entreprenuers can utilize to capture professional-grade photos of their creations. ',
      linkAnchor: 'https://www.snappr.com',
      logo: '/assets/img/resources/logo-resources-snappr.png',
      link: '',
      href: 'https://www.snappr.com/',
    },
    {
      paragraph:
        'Restaurant Depot is a low-cost, members-only wholesale Cash & Carry foodservice supplier for independent food businesses.',
      linkAnchor: 'Use member code 43016485 for deals',
      logo: '/assets/img/resources/logo-resources-restaurantdepot.png',
      link: '',
      href: 'https://www.restaurantdepot.com/',
    },
  ];

  useEffect(() => {
    setPageTitle('Resources');
    
    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
    } else {
      setHeaderCTAs([]);
    }
  }, [isMobile]);

  return (
    <div>
      <Helmet>
        <title>Resources | Nourysh</title>
      </Helmet>
      <Grid container>
        <Grid className={classes.container} item xs={12}>
          <Grid spacing={4} container>
            {contentBoxes.map((contentBox, i) => (
              <Grid key={i} item xs={12} md={6}>
                <Box className={classes.box}>
                  {/* <Typography variant="h4">MISSING LOGO</Typography> */}
                  <img src={contentBox.logo} className={classes.image} />
                  <Typography paragraph>{contentBox.paragraph}</Typography>
                  <Link target="_blank" href={contentBox.href}>
                    {contentBox.linkAnchor}
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Resources;

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, FileCopy, Link, UnarchiveOutlined, VisibilityOutlined } from '@material-ui/icons';
import { AutoAwesome } from '@castiron/components';
import GetAppIcon from '@material-ui/icons/GetApp';
import { BaseProduct, ProductPageContext, ProductStatus, TicketedEvent } from '@castiron/domain';
import { defaultTimeZone, download, useTracking } from '@castiron/utils';
import { productRepository } from '../../../../domain';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import { getShopLink } from '../../../../lib/domainUtils';
import { deleteProductAction, duplicateProductAction, getProductsAction } from '../../../../store/reducers/products';
import Dropdown, { DropDownOption } from '../../../Dropdown';
import EllipsisMenu, { EllipsisMenuOption } from '../../../Menus/EllipsisMenu';
import { getService } from '../../../../firebase';
import moment from 'moment';
import Spinner from '../../../Spinner';
import PeopleOutlineOutlined from '@material-ui/icons/PeopleOutlineOutlined';

const exportOrdersByProductService = getService('orders', 'exportordersbyproduct', { type: 'request' });
const exportAttendeeListService = getService('products', 'exportattendeelist', { type: 'request' });

interface Props {
  product: BaseProduct;
  isEditMode: boolean;
  context?: ProductPageContext;
  previewProduct: () => void;
}

const ProductActionsDropdown: React.FC<Props> = (props: Props) => {
  const { product, isEditMode, context, previewProduct } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const shopLink = getShopLink(shop);
  const [copyLinkLabel, setCopyLinkLabel] = useState('Copy Link');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDuplicate = async () => {
    if (!product?.id) return;

    dispatch(duplicateProductAction(product.id)).then(res => {
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                {/* @ts-ignore */}
                Product <strong>{res.payload.title}</strong> was duplicated.
              </>
            ),
          },
        }),
      );
      trackEvent('Product Duplicated', { product: { id: product.id } });
      /* this doesn't work today, not going to worry about it atm, something else to come back to */
      //@ts-ignore
      history.push(`/${context}/edit/${res.payload.id}`);
    });
  };

  const sleep = async msec => {
    return new Promise(resolve => setTimeout(resolve, msec));
  };

  const handleCopy = useCallback(async () => {
    setCopyLinkLabel('Link Copied!');
    navigator.clipboard.writeText(`${shopLink}/product/${product?.id}`);

    setTimeout(() => {
      setCopyLinkLabel('Copy Link');
    }, 1500);

    /* sleep for a bit before letting the drop down close */
    await sleep(1200);
  }, [product, shop]);

  const onDelete = useCallback(
    id => async (): Promise<void> => {
      dispatch(deleteProductAction(id)).then(res => {
        trackEvent('Product Deleted', { product: { id: id } });
        history.push(`/${context}`);
        dispatch(closeModal());
      });
    },
    [],
  );

  const handleShare = () => {
    dispatch(
      openModal({
        modalType: 'PRODUCT_SOCIAL_SHARE_MODAL',
        modalProps: {
          show: true,
          product: product,
        },
      }),
    );
  };

  const downloadAttendeeList = async () => {
    setIsDownloadingReport(true);
    const timeZone = shop?.config?.timeZone || defaultTimeZone;
    const csv = await exportAttendeeListService({
      eventId: product?.id,
      timeZone,
    });

    const filename = `${product?.title} Attendees ${moment
      .unix((product as TicketedEvent)?.eventDetails?.date?.startTime)
      .tz(timeZone)
      .format('M/D/YYYY')}.csv`;
    
    download(filename, 'text/csv', csv);

    trackEvent('Attendees List Downloaded');

    setIsDownloadingReport(false);
  };

  const downloadReport = async () => {
    setIsDownloadingReport(true);

    const csv = await exportOrdersByProductService({
      context: 'product',
      id: product?.id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
    });

    const date = moment().format('MMDDYYYY');
    const filename = `${shop.websiteUrl}-orders-by-product-${date}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent('Orders by Product Report Downloaded');

    setIsDownloadingReport(false);
  };

  const handleStatusChange = async (newStatus: ProductStatus) => {
    await productRepository.updateProps(product.id, { status: newStatus, schedule: {} });
    dispatch(getProductsAction(shop.id));
    history.push(`/${context}`);
  };

  const onDeleteClick = (): void => {
    if (!product?.id) return;

    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          onDelete: onDelete(product.id),
        },
      }),
    );
  };

  const options: DropDownOption[] = [
    {
      label: 'Preview',
      icon: <VisibilityOutlined />,
      onClick: previewProduct,
    },
    isEditMode && {
      label: 'Duplicate',
      icon: <FileCopy />,
      onClick: handleDuplicate,
    },
    isEditMode && {
      label: 'New AI Social Share',
      icon: <AutoAwesome />,
      backgroundColor: theme.branding.v2.yellow[600],
      onClick: handleShare,
    },
    isEditMode && {
      label: copyLinkLabel,
      icon: <Link />,
      onClick: handleCopy,
    },
    isEditMode &&
      product?.type === 'event' && {
        label: 'Download Attendee List',
        icon: <PeopleOutlineOutlined />,
        onClick: downloadAttendeeList,
      },
    isEditMode && {
      label: 'Download Report',
      icon: <GetAppIcon />,
      onClick: downloadReport,
    },
    isEditMode &&
      product?.status !== 'archived' && {
        label: 'Archive',
        icon: <UnarchiveOutlined />,
        onClick: () => handleStatusChange('archived'),
      },
    isEditMode &&
      product?.status === 'archived' && {
        label: 'Unarchive',
        icon: <UnarchiveOutlined />,
        onClick: () => handleStatusChange('active'),
      },
    isEditMode && {
      label: 'Delete',
      color: 'error',
      icon: <Delete />,
      onClick: onDeleteClick,
    },
  ]
    .filter(a => !!a)
    .map(a => a as DropDownOption);
  const ellipsisOptions: EllipsisMenuOption[] = options.map(option => ({
    display: option.label,
    color: option.color,
    backgroundColor: option.backgroundColor,
    icon: option.icon,
    action: option.onClick,
  }));

  return (
    <>
      <Spinner show={isDownloadingReport} size={'fullscreen'} label="Gathering your data…" />
      {isMobile ? <EllipsisMenu options={ellipsisOptions} /> : <Dropdown title="Actions" options={options} />}
    </>
  );
};

export default ProductActionsDropdown;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getProductsAction } from "../../store/reducers/products";
import { LayoutPageProps } from "../Layout";
import ProductList from "./ProductList";
import Spinner from "../Spinner";
import AddProductButton from "./AddProductButton";
import AvatarMenu from "../Menus/AvatarMenu";
import HeaderTabs from "../Layout/Header/HeaderTabs";
import EmptyProduct from "./EmptyProduct";
import OrganizeCategories from "./OrganizeCategories";

const useStyles = makeStyles((theme: Theme) => ({
  bannerColor: {
    color: theme.branding.v2.plum[500],
  },
  buttonContainer: {
    marginTop: 16,
    padding: "16px 24px",
    backgroundColor: theme.branding.v2.gray[0],
    bottom: "0",
    borderTop: `1px solid ${theme.branding.v2.gray[300]}`,
    display: "flex",
    flexDirection: "row",
    position: "sticky",
    width: "100%",
    zIndex: 1000,
  },
  container: {
    width: "100%",
  },
}));

const Products: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const initialTabValue = urlParams.get("tab");

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(initialTabValue || "Products");

  const { account, shop, isProductsLoading, products } = useAppSelector(
    (state) => ({
      account: state.shops.account,
      shop: state.shops.shop,
      isProductsLoading: state.products.loading,
      products: state.products.products,
    })
  );

  const standardProducts = products.filter(
    (product) => product.type === "standard"
  );
  const isProductsEmpty = standardProducts.length === 0;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hasOnboardingProducts =
    standardProducts && standardProducts.some((p) => p.source === "onboarding");

  useEffect(() => {
    setPageTitle("Products");

    return () => {
      setPageTitle("");
    };
  }, []);

  useEffect(() => {
    if (selectedTab !== "Categories") {
      if (isMobile) {
        setHeaderCTAs([<AvatarMenu />]);
        setFooterCTAs([
          <AddProductButton fullWidth variant="contained" context="products" />,
        ]);
      } else {
        setHeaderCTAs([
          <AddProductButton variant="contained" context="products" />,
        ]);
        setFooterCTAs([]);
      }
    }
  }, [isMobile, products]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop?.id) getProducts();
  }, [shop]);

  const allCategories = [
    "Appetizers & Snacks",
    "Cooking Classes",
    "Desserts & Baked Goods",
    "Drinks",
    "Hot Food",
    "Shelf-Stable Products",
    "Whole Ingredients",
  ];

  var categoryNames = [];
  standardProducts.map((p) => {
    if (
      !categoryNames.includes(p?.category?.name) &&
      p?.category?.name != undefined
    ) {
      categoryNames.push(p?.category?.name);
    }
  });

  const tabs = [
    {
      value: "Products",
      content: <ProductList context="products" />,
    },
  ];

  if (categoryNames.length > 0) {
    tabs.splice(1, 0, {
      value: "Categories",
      content: (
        <OrganizeCategories
          setHeaderCTAs={setHeaderCTAs}
          setFooterCTAs={setFooterCTAs}
          AddProductButton={
            <AddProductButton
              fullWidth
              variant="contained"
              context="products"
            />
          }
          context="products"
          products={standardProducts}
        />
      ),
    });
  }

  const productsContent = isProductsEmpty ? (
    <EmptyProduct />
  ) : (
    <HeaderTabs
      initialTabValue={initialTabValue || "Products"}
      tabs={tabs}
      setSelectedTab={setSelectedTab}
    />
  );

  return (
    <Grid justify="center" className={classes.container} container>
      <Helmet>
        <title>Products | Nourysh</title>
      </Helmet>
      <Spinner show={isProductsLoading} />
      <Grid container>{productsContent}</Grid>
    </Grid>
  );
};

export default Products;

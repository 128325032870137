import React from "react";
import _ from "lodash";
import moment from "moment";
import Dinero from "dinero.js";
import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { OrderStatusPill, Typography } from "@castiron/components";
import { Order, Transaction } from "@castiron/domain";
import ActionsMenu from "../../ActionsMenu";
import { useHistory } from "react-router";
import { defaultTimeZone, useTracking } from "@castiron/utils";
import { prepareQuoteSegmentData } from "../QuoteUtils";
import { useAppSelector } from "../../../hooks";
import InfiniteScroll from "react-infinite-scroll-component";

interface Props {
  quotes: Transaction[];
  retrieveMoreQuotes: () => void;
  moreQuotesLeft: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  dateTime: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  separator: {
    /* was #EEEEEE, went with closest I could find */
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  paginatedQuotesContainer: {
    ["& .infinite-scroll-component__outerdiv"]: {
      width: "100%",
    },
  },
  quoteContainer: {
    padding: "24px 0",
    cursor: "pointer",
  },
  quoteIdentifierContainer: {
    marginBottom: "24px",
  },
  quoteIdentifier: {
    color: theme.branding.gray[600],
    fontSize: "14px",
  },
  quoteCustomerName: {
    fontWeight: 600,
  },
  productDescriptionContainer: {
    marginBottom: "24px",
  },
  quoteProductDescription: {
    fontSize: "14px",
  },
  quoteSummaryHeader: {
    color: theme.branding.gray[600],
    fontSize: "14px",
  },
}));

const MobileContent: React.FC<Props> = (props: Props) => {
  const { quotes, retrieveMoreQuotes, moreQuotesLeft } = props;
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const tz = shop.config?.timeZone || defaultTimeZone;

  const onClick = (id: string): void => {
    const currentQuote = quotes.find((quote) => quote.id === id);
    trackEvent("Quote Details Clicked", {
      ...prepareQuoteSegmentData(currentQuote),
    });
    history.push(`/quotes/edit/${id}`);
  };

  const dateFormat = (quote) => {
    let date;
    let startTime;
    let endTime;

    if (
      quote.order.fulfillmentOption?.schedule?.dates[0]?.startTime &&
      quote.order.fulfillmentOption?.schedule?.type === "fixed"
    ) {
      date = moment
        .unix(quote.order.fulfillmentOption.schedule.dates[0].startTime)
        .tz(tz)
        .format("MM/DD/YY");
      startTime = moment
        .unix(quote.order.fulfillmentOption.schedule.dates[0].startTime)
        .tz(tz)
        .format("h:mma");
      endTime = moment
        .unix(quote.order.fulfillmentOption.schedule.dates[0].endTime)
        .tz(tz)
        .format("h:mma");
    } else if (quote.order.fulfillmentOption?.schedule?.dates[0]?.startTime) {
      date = moment
        .unix(quote.order.fulfillmentOption.schedule.dates[0].startTime)
        .tz(tz)
        .format("MM/DD/YY");
    } else if (quote.order.fulfillmentOption?.date) {
      date = moment
        .unix(quote.order.fulfillmentOption.date)
        .tz(tz)
        .format("MM/DD/YY");
    }

    return (
      <Grid container direction="column" className={classes.dateTime}>
        <Typography variant="body2">{date}</Typography>
        <Typography variant="body2">
          {!!startTime ? `${startTime} - ${endTime}` : ""}
        </Typography>
      </Grid>
    );
  };

  const getName = (order: Order) =>
    _.head(order.items)?.type === "invoice"
      ? "Invoice"
      : _.truncate(_.head(order.items)?.title, { length: 50 });

  return (
    <Grid container item className={classes.paginatedQuotesContainer} xs={12}>
      <InfiniteScroll
        dataLength={quotes.length}
        next={retrieveMoreQuotes}
        hasMore={moreQuotesLeft}
        loader={
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        }
      >
        {quotes.map((quote) => (
          <Grid
            key={`quote-${quote.id}`}
            container
            item
            className={`${classes.quoteContainer} ${classes.separator}`}
            xs={12}
            onClick={() => onClick(quote.id)}
          >
            <Grid item className={classes.quoteIdentifierContainer} xs={10}>
              <Typography className={classes.quoteIdentifier}>
                #{quote.order.orderNumber}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ActionsMenu transaction={quote} type="quotes" />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.quoteCustomerName}>
                {quote.customerObj?.firstName || quote.customerObj?.lastName
                  ? `${quote.customerObj?.firstName || ""} ${
                      quote.customerObj?.lastName || ""
                    }`
                  : `${quote.customerObj?.email || ""}`}
              </Typography>
            </Grid>
            <Grid item className={classes.productDescriptionContainer} xs={12}>
              <Typography className={classes.quoteProductDescription}>
                {getName(quote.order)}
              </Typography>
            </Grid>
            <Grid container item xs={4} direction="column">
              <Grid item>
                <Typography className={classes.quoteSummaryHeader}>
                  Due Date
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{dateFormat(quote)}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={4} direction="column">
              <Grid item>
                <Typography className={classes.quoteSummaryHeader}>
                  Total
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {Dinero({ amount: quote?.totals?.total || 0 }).toFormat(
                    "$0.00"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={4} direction="column">
              <Grid item>
                <Typography className={classes.quoteSummaryHeader}>
                  Status
                </Typography>
              </Grid>
              <Grid item>
                <OrderStatusPill transaction={quote} context="quote" />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </InfiniteScroll>
    </Grid>
  );
};

export default MobileContent;

import React, { useState, useEffect, ReactElement, useRef } from "react";
import * as yup from "yup";
import _ from "lodash";
import {
  DialogContent,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { holidays, occasions, ProductPageContext } from "@castiron/domain";
import { ButtonV2, ChipSelectInput, Typography } from "@castiron/components";
import FilterListIcon from "@material-ui/icons/FilterList";
import ModalWrapper from "../RootModal/ModalWrapper";
import ModalHeader from "../RootModal/ModalHeader";
import ModalActions from "../RootModal/ModalActions";
import { Formik, FormikProps } from "formik";
import AdminForm from "../AdminForm";

export type Props = {
  initialStatus?: string;
  initialSpecials?: string[];
  applyFilters: (statusFilter: string, specialsFilters: string[]) => void;
  context?: ProductPageContext;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: "14px 16px",
  },
  formContainer: {
    gap: "24px",
  },
  modalWrapper: {
    width: "100%",
    height: "100%",
    maxWidth: "530px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "80%",
    },
  },
  statusOption: {
    margin: 0,
    "& .MuiRadio-root": {
      gap: "8px",
    },
    "& .Mui-checked": {
      color: theme.branding.v2.plum[500],
    },
  },
}));

const FilterListModal: React.FC<Props> = (props: Props) => {
  const { initialStatus, initialSpecials, applyFilters, context } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const formikRef = useRef<FormikProps<any>>();

  const [showModal, setShowModal] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);

  useEffect(() => {
    setTotalSelected(
      initialStatus === "all"
        ? initialSpecials?.length
        : initialSpecials?.length + 1
    );
  }, [initialStatus, initialSpecials]);

  const statusOptions = [
    {
      value: "all",
      display: "All",
    },
    {
      value: "active",
      display: "Active",
    },
    {
      value: "inactive",
      display: "Inactive",
    },
    {
      value: "archived",
      display: "Archived",
    },
    {
      value: "scheduled",
      display: "Scheduled",
    },
  ];

  const initialState = {
    holidays: _.intersection(initialSpecials, holidays) || [],
    occasions: _.intersection(initialSpecials, occasions) || [],
    status: initialStatus || "all",
  };

  const filterSchema = yup.object().shape({
    holidays: yup.array().of(yup.string()),
    occasions: yup.array().of(yup.string()),
    status: yup.string(),
  });

  const handleSubmit = (values) => {
    const { holidays, occasions, status } = values;

    applyFilters(status, [...holidays, ...occasions]);
    setShowModal(false);
  };

  const handleClose = (setFieldValue) => {
    setFieldValue("status", initialStatus);
    setFieldValue("holidays", initialState.holidays);
    setFieldValue("occasions", initialState.occasions);

    setShowModal(false);
  };

  return (
    <>
      {totalSelected === 0 ? (
        <Grid container item justify="flex-end" xs={5}>
          <ButtonV2
            variant="outlined"
            onClick={() => setShowModal(true)}
            className={classes.button}
          >
            <Typography variant="button">
              {isXsMobile ? (
                <FilterListIcon
                  style={{ color: theme.branding.v2.gray[900] }}
                />
              ) : (
                "Filters"
              )}
            </Typography>
          </ButtonV2>
        </Grid>
      ) : (
        <Grid
          container
          item
          justify={isXsMobile ? "flex-start" : "flex-end"}
          direction="row"
          wrap="nowrap"
          xs={12}
          sm={6}
          style={{ gap: "8px" }}
        >
          <ButtonV2
            variant="outlined"
            onClick={() => setShowModal(true)}
            className={classes.button}
          >
            <Typography
              variant="button"
              style={{ color: theme.branding.v2.plum[500] }}
            >
              {totalSelected > 0 && totalSelected + " "}
              {isXsMobile ? (
                <FilterListIcon style={{ color: "inherit" }} />
              ) : (
                `Filter${totalSelected === 1 ? "" : "s"}`
              )}
            </Typography>
          </ButtonV2>
          <ButtonV2
            variant="text"
            onClick={() => applyFilters("all", [])}
            style={{ color: theme.branding.v2.gray[900] }}
            className={classes.button}
          >
            Clear
          </ButtonV2>
        </Grid>
      )}
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={filterSchema}
        initialValues={initialState}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }): ReactElement => (
          <AdminForm>
            <ModalWrapper show={showModal} paperClass={classes.modalWrapper}>
              <ModalHeader
                handleClose={() => handleClose(setFieldValue)}
                title="Filters"
              />
              <DialogContent>
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  className={classes.formContainer}
                >
                  <Grid container direction="column">
                    <Typography variant="subtitle2">Visibility</Typography>
                    <RadioGroup
                      name="status"
                      value={values.status}
                      onChange={(e) => setFieldValue("status", e.target.value)}
                    >
                      <Grid container item direction="row">
                        {statusOptions.map((opt, index) => (
                          <Grid
                            item
                            xs={6}
                            wrap="nowrap"
                            key={`status-option-${opt}-${index}`}
                          >
                            <FormControlLabel
                              value={opt.value}
                              control={<Radio />}
                              label={opt.display}
                              className={classes.statusOption}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  <ChipSelectInput
                    label="Holidays"
                    secondaryLabel={`Select all major holidays this ${
                      context === "events" ? "event" : "product"
                    } caters to, such as Halloween.`}
                    name="holidays"
                    options={holidays}
                    value={values.holidays}
                  />
                  <ChipSelectInput
                    label="Occasions"
                    secondaryLabel={`Select all ongoing occasions this ${
                      context === "events" ? "event" : "product"
                    } caters to, such as Weddings and Birthdays.`}
                    name="occasions"
                    options={occasions}
                    value={values.occasions}
                  />
                </Grid>
              </DialogContent>
              <ModalActions hideBorder>
                <Grid
                  container
                  direction={isXsMobile ? "column-reverse" : "row"}
                  justify={isXsMobile ? "center" : "flex-end"}
                  style={{ gap: "16px" }}
                >
                  <ButtonV2
                    variant="outlined"
                    fullWidth={isXsMobile}
                    onClick={() => handleClose(setFieldValue)}
                  >
                    Cancel
                  </ButtonV2>
                  <ButtonV2
                    variant="contained"
                    fullWidth={isXsMobile}
                    type="submit"
                    onClick={() => formikRef.current.submitForm()}
                  >
                    Apply Filters
                  </ButtonV2>
                </Grid>
              </ModalActions>
            </ModalWrapper>
          </AdminForm>
        )}
      </Formik>
    </>
  );
};

export default FilterListModal;

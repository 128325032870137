import React, { ReactNode, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import firebase from "firebase/compat/app";
import { useConfig } from "@castiron/castiron-firebase";
import { useTracking } from "@castiron/utils";
import { accountRepository, shopRepository } from "../../domain";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setIsOnboarding,
  setFromOnboarding,
  getShopAction,
} from "../../store/reducers/shops";
import { LayoutPageProps } from "../Layout";
import UrlRedirect from "../UrlRedirect";
import OnboardingWrapper from "./OnboardingComponents/OnboardingWrapper";
import { StickyFooterProps } from "./OnboardingComponents/OnboardingFooter";
import {
  BusinessInfo,
  ProductType,
  SelectATheme,
  MarketType,
  Challenges,
} from "./OnboardingComponents/InfoPages";
import InternationalUserPage from "./OnboardingComponents/InternationalUserPage";
import SubscriptionCheckout from "./OnboardingComponents/SubscriptionCheckout";

const Onboarding: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setDisplayNav } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const [step, setStep] = useState(1);
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [childNode, setChildNode] = useState<ReactNode>("");
  const [finished, setFinished] = useState(false);
  const [stickyFooterProps, setStickyFooterProps] =
    useState<StickyFooterProps>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isInternational, setIsInternational] = useState(false);

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const ffconfig = useConfig();
  const isFirstMonthPromoEnabled = ffconfig?.featureFlag(
    "feature_first_month_promo",
    shop
  );

  const setLoading = useCallback(
    (isLoading) => setIsLoading(isLoading),
    [isLoading]
  );

  useEffect(() => {
    if (shop?.tags?.includes("International")) {
      setIsInternational(true);
      setStep(0);
    } else setStep(account?.onboardingQuestions?.onboardingStep?.step || 1);
  }, []);

  const nextStep = async () => {
    const s = step + 1;

    await accountRepository.updateProps(account.id, {
      "onboardingQuestions.onboardingStep": {
        version: 1,
        step: s,
      },
    });

    trackEvent(`Lead Completed Onboarding Step ${s}`, {
      onboardingVersion: 1,
    });

    setStep(s);
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else if (isInternational) {
      setIsInternational(false);
      setStep(1); //go back to business info page to allow user to change address to US-based
    }
  };

  const handleAddressUpdate = async (international: boolean) => {
    if (international) {
      setIsInternational(true);
      setStep(0); //progress bar and also show back button--basically placing the international "flow" outside of the normal onboarding flow while maintaining the structure

      await accountRepository.updateProps(account.id, {
        status: "inactive",
      });
      await shopRepository.updateProps(shop.id, {
        status: "inactive",
        tags: firebase.firestore.FieldValue.arrayUnion("International"),
      });
    } else {
      setIsInternational(false);

      await accountRepository.updateProps(account.id, {
        status: "onboarding",
      });
      await shopRepository.updateProps(shop.id, {
        status: "prelaunch",
        tags: firebase.firestore.FieldValue.arrayRemove("International"),
      });
    }
  };

  useEffect(() => {
    setDisplayNav(false);

    /* reenable nav when we are done with onboarding */
    return () => setDisplayNav(true);
  }, []);

  const onFinishOnboarding = async () => {
    setFinished(true);
    dispatch(setIsOnboarding(false));
    dispatch(setFromOnboarding(true));

    if (isFirstMonthPromoEnabled) {
      await accountRepository.updateProps(account.id, {
        "config.showFirstMonthPromo": true,
        status: "active",
      });
    } else {
      await accountRepository.updateProps(account.id, {
        status: "active",
      });
    }

    await dispatch(getShopAction(account.id));

    trackEvent("Owner Started Trial");
    history.push("/new");
    <UrlRedirect url="/new?refresh" />;
  };

  useEffect(() => {
    const pageRoute = isInternational
      ? "/signup/international"
      : `/signup/info/${step}`;
    switch (step) {
      case 0:
        setChildNode(
          <InternationalUserPage
            setStickyFooterProps={setStickyFooterProps}
            setLoading={setLoading}
          />
        );
        history.push(pageRoute);
        break;
      case 1:
        setChildNode(
          <BusinessInfo
            setHeader={setHeader}
            setSubHeader={setSubHeader}
            setStickyFooterProps={setStickyFooterProps}
            setLoading={setLoading}
            nextStep={nextStep}
            handleAddressUpdate={handleAddressUpdate}
          />
        );
        history.push(pageRoute);
        break;
      case 2:
        setChildNode(
          <SelectATheme
            setHeader={setHeader}
            setSubHeader={setSubHeader}
            setStickyFooterProps={setStickyFooterProps}
            nextStep={nextStep}
            setLoading={setLoading}
          />
        );
        history.push(pageRoute);
        break;
      case 3:
        setChildNode(
          <ProductType
            step={step}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
            setStickyFooterProps={setStickyFooterProps}
            nextStep={nextStep}
            setLoading={setLoading}
          />
        );
        history.push(pageRoute);
        break;
      case 4:
        setChildNode(
          <MarketType
            step={step}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
            setStickyFooterProps={setStickyFooterProps}
            nextStep={nextStep}
            setLoading={setLoading}
          />
        );
        history.push(pageRoute);
        break;
      case 5:
        setChildNode(
          <Challenges
            step={step}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
            setStickyFooterProps={setStickyFooterProps}
            nextStep={nextStep}
            setLoading={setLoading}
          />
        );
        history.push(pageRoute);
        break;
      case 6:
        setChildNode(
          <SubscriptionCheckout
            setHeader={setHeader}
            setSubHeader={setSubHeader}
            setStickyFooterProps={setStickyFooterProps}
            setLoading={setLoading}
            onFinishOnboarding={onFinishOnboarding}
          />
        );
        history.push(pageRoute);
        break;
      default:
    }
  }, [step, isInternational]);

  return (
    <OnboardingWrapper
      step={step}
      header={header}
      subHeader={subHeader}
      stickyFooterProps={stickyFooterProps}
      isLoading={isLoading}
      prevStep={prevStep}
      nextStep={nextStep}
      isInternational={isInternational}
    >
      {childNode}
    </OnboardingWrapper>
  );
};

export default Onboarding;

import React, {
  useEffect,
  useState,
  useRef,
  ReactElement,
  ReactNode,
} from "react";
import { useParams } from "react-router";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import _ from "lodash";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
  Banner,
  Button,
  IosShareIcon,
  ProductCard,
  DiscardButton,
  SaveButton,
  useFeatures,
} from "@castiron/components";
import { getProductStatus, useTracking } from "@castiron/utils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { openModal } from "../../../store/reducers/modalConductor";
import { getProductsAction } from "../../../store/reducers/products";
import { updateShopAction } from "../../../store/reducers/shops";
import { LayoutPageProps } from "../../Layout";
import ViewShopButton from "../../Layout/Header/ViewShopButton";
import AdminForm from "../../AdminForm";
import { Shop, Special } from "@castiron/domain";
import { specialRepository } from "../../../domain";
import Spinner from "../../Spinner";

interface Props extends LayoutPageProps {}

interface Params {
  name?: string;
}

interface FormValues {
  enabled: boolean;
  description: string;
  headline: string;
  tag: string;
}

interface EventPageContent {
  bannerText: ReactNode;
  description: string;
  headline: string;
  productsTitle: string;
  tag: string;
  eventName: string;
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  bannerLink: {
    textDecoration: "underline",
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  container: {
    "& > form": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 8,
    },
  },
  errorBanner: {
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 24px 0",
    },
  },
  infoBanner: {
    marginBottom: 24,
  },
  mobileShareIcon: {
    color: theme.branding.v2.gray[400],
  },
  noActiveProducts: {
    width: "100%",
    backgroundColor: theme.branding.v2.gray[100],
    padding: 16,
    borderRadius: 12,
  },
  productCard: {
    opacity: "64%",
  },
  productsHeader: {
    marginBottom: 8,
  },
  rightColumn: {
    fontWeight: 600,
    lineHeight: "24px",
  },
  toggleButton: {
    fontWeight: 600,
    lineHeight: "24px",
    "& button": {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0px",
    },
  },
  toggleCaption: {
    fontWeight: 400,
    color: theme.branding.v2.gray[700],
  },
}));

const EventPage: React.FC<LayoutPageProps> = (props: Props) => {
  const {
    setPageTitle,
    setPageIsProFeature,
    setBackLocation,
    setHeaderCTAs,
    setFooterCTAs,
  } = props;
  const { name } = useParams<Params>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formRef = useRef<FormikProps<FormValues>>();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const features = useFeatures();
  const isQuotesEnabled = features.includes("admin.quotes");

  const { shop, products } = useAppSelector((state) => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const [content, setContent] = useState<EventPageContent>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getEvent = async () => {
      const activeShopEvents = await specialRepository.findActiveShopEvents(
        shop?.config?.timeZone
      );
      const event = activeShopEvents.find((s) => s.slug === name);
      if (!event) {
        setIsLoading(false);
        history.push("/store/pages");
      } else {
        const content = event?.shopContent?.page;
        setContent({
          bannerText: (
            <>
              This page is populated by your products
              {isQuotesEnabled ? " and order forms" : ""} tagged with the{" "}
              <b>{event.name}</b> occasion.
            </>
          ),
          description: content?.bannerDescription,
          headline: content?.bannerHeadline,
          productsTitle: `${event.name} Products`,
          tag: event.tag,
          eventName: event.name,
          title: `${event.name} Page`,
        });
        setIsLoading(false);
      }
    };
    getEvent();
  }, []);

  const eventProducts = products.filter(
    (p) =>
      p?.eventTags?.includes(content?.tag) &&
      getProductStatus(p, shop?.config?.timeZone) === "active"
  );
  const hasEventProducts = !_.isEmpty(eventProducts);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleShareClick = () => {
    dispatch(
      openModal({
        modalType: "SHARE_LINKS_MODAL",
        modalProps: {
          show: true,
          subdirectory: `event/${name}`,
        },
      })
    );
  };

  useEffect(() => {
    if (content) {
      window.scrollTo(0, 0);
      trackEvent(`${content?.tag} event page viewed`);

      setPageTitle(content?.eventName);
      setPageIsProFeature(false);
      setBackLocation(true);
      setHeaderCTAs([
        isMobile ? (
          <IosShareIcon
            className={classes.mobileShareIcon}
            onClick={handleShareClick}
          />
        ) : (
          <Button variant="outlined" onClick={handleShareClick}>
            <IosShareIcon />
            &nbsp;&nbsp; Share
          </Button>
        ),
        <ViewShopButton
          subdirectory={`event/${name}`}
          eventTextOverride={`View ${content?.title} Button Clicked`}
          buttonTextOverride="Preview"
        />,
      ]);
    }

    return () => {
      setBackLocation(false);
      setPageTitle("");
      setPageIsProFeature(false);
      setHeaderCTAs([]);
    };
  }, [isMobile, content]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton
        handleSubmit={() => {
          onSubmit(formRef.current.values, content);
        }}
        isSubmitting={isSubmitting}
      />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting, formRef, content]);

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop) getProducts();
  }, [shop]);

  const onSubmit = async (values: FormValues, content) => {
    const { enabled, headline, description, tag } = values;
    const showPopup = !!shop?.shopSubpageData?.events?.find((e) => e.tag == tag)
      ?.showPopup;
    setIsSubmitting(true);
    let events = shop?.shopSubpageData?.events
      ? _.cloneDeep(shop?.shopSubpageData?.events)
      : [];
    const index = events.findIndex((e) => e.tag === tag);
    if (index == 0) {
      events = [{ tag, enabled, headline, description, showPopup }];
    } else if (index !== -1) {
      events.splice(index, 1);
      events.push({ tag, enabled, headline, description, showPopup });
    } else {
      events.push({ tag, enabled, headline, description, showPopup });
    }

    try {
      const newShop = {
        ...shop,
        shopSubpageData: {
          ...shop.shopSubpageData,
          events: events,
        },
      } as Shop;

      await dispatch(updateShopAction({ shop: newShop }));

      trackEvent(`${tag} event page updated`);

      dispatch(
        openModal({
          modalType: "SIMPLE_ALERT",
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                <Typography variant="h4">{`${content?.title} Updated!`}</Typography>
              </>
            ),
          },
        })
      );

      setIsSubmitting(false);
      history.push("/store/pages");
    } catch (error) {
      setIsSubmitting(false);
      console.error(`Error Submitting ${content?.title} Form: `, error);
    }
  };

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];

  const getActiveBannerBlock = () => (
    <Banner variant="info-white" className={classes.infoBanner}>
      <Typography variant="body4">
        <span style={{ fontWeight: 700 }}>Note:</span> {content?.bannerText} To
        make edits to the products themselves, visit your{" "}
        <Link
          to="/products"
          style={{
            color: theme.branding.v2.gray[600],
            textDecoration: "underline",
          }}
        >
          products
        </Link>
        {isQuotesEnabled ? (
          <>
            {" "}
            or{" "}
            <Link
              to="/order-forms"
              style={{
                color: theme.branding.v2.gray[600],
                textDecoration: "underline",
              }}
            >
              order forms
            </Link>
          </>
        ) : (
          <></>
        )}{" "}
        page.
      </Typography>
    </Banner>
  );

  const getInactiveBannerBlock = () => (
    <Banner variant="error" className={classes.errorBanner}>
      <Typography variant="body4" style={{ color: theme.branding.v2.red[900] }}>
        Please add at least one {content?.eventName}{" "}
        <Link
          to="/products"
          style={{
            color: theme.branding.v2.gray[600],
            textDecoration: "underline",
          }}
        >
          product
        </Link>
        {isQuotesEnabled ? (
          <>
            {" "}
            or{" "}
            <Link
              to="/order-forms"
              style={{
                color: theme.branding.v2.gray[600],
                textDecoration: "underline",
              }}
            >
              order form
            </Link>
          </>
        ) : (
          <></>
        )}{" "}
        to change the status to active.
      </Typography>
    </Banner>
  );

  const getToggleButtonBlock = (setFieldValue, values: FormValues) => (
    <Grid container item direction="column" className={classes.toggleButton}>
      <Typography variant="subtitle2">Status</Typography>
      <ToggleButton
        value={values.enabled}
        exclusive
        onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
          setFieldValue("enabled", value);
          trackEvent(`${content?.tag} event page status set to ${value}`);
        }}
        aria-label="page visibility"
        buttonOptions={toggleButtonOptions}
        disabled={!hasEventProducts}
      />
      <Typography variant="caption" className={classes.toggleCaption}>
        Status for this page is automatically set to active when you have at
        least one active{" "}
        <Link
          to="/products"
          style={{ color: theme.branding.v2.plum[500], textDecoration: "none" }}
        >
          product
        </Link>{" "}
        tagged {content?.eventName}.
      </Typography>
    </Grid>
  );

  const initialValues: FormValues = {
    enabled:
      shop?.shopSubpageData?.events?.find((e) => e.tag == content?.tag)
        ?.enabled !== false && hasEventProducts,
    description:
      shop?.shopSubpageData?.events?.find((e) => e.tag == content?.tag)
        ?.description || content?.description,
    headline:
      shop?.shopSubpageData?.events?.find((e) => e.tag == content?.tag)
        ?.headline || content?.headline,
    tag:
      shop?.shopSubpageData?.events?.find((e) => e.tag == content?.tag)?.tag ||
      content?.tag,
  };

  const homeSchema = yup.object().shape({
    isActive: yup.boolean(),
  });

  return isLoading ? (
    <Spinner show={isLoading} size="fullscreen" />
  ) : (
    <Grid container justify="center" className={classes.container}>
      <Helmet>
        <title>{`${content?.title} | Nourysh`}</title>
      </Helmet>

      <Formik
        initialValues={initialValues}
        validationSchema={homeSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
      >
        {({ setFieldValue, touched, errors, values }): ReactElement => (
          <AdminForm>
            <Grid
              container
              item
              xs={12}
              direction={isMobile ? "column" : "row"}
              spacing={!isMobile && 6}
              wrap="nowrap"
            >
              {isMobile && (
                <>
                  {getToggleButtonBlock(setFieldValue, values)}
                  {hasEventProducts
                    ? getActiveBannerBlock()
                    : getInactiveBannerBlock()}
                </>
              )}

              <Grid container item xs={12} md={8} direction="column">
                {!isMobile && hasEventProducts && getActiveBannerBlock()}
                <TextInput
                  error={touched.headline && errors.headline}
                  label="Banner Headline Text"
                  name="headline"
                  maxLength={50}
                />
                <div style={{ marginTop: "24px" }}>
                  <TextInput
                    error={touched.description && errors.description}
                    label="Banner Subhead Text"
                    name="description"
                    maxLength={100}
                  />
                </div>
                <Grid
                  container
                  item
                  direction="column"
                  style={{ marginTop: 40, marginBottom: 40 }}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.productsHeader}
                  >
                    {content?.productsTitle}
                  </Typography>
                  {hasEventProducts ? (
                    <Grid container item spacing={2}>
                      {_.sortBy(eventProducts, [
                        (product) =>
                          getProductStatus(product, shop?.config?.timeZone) !==
                          "active",
                        (product) => product.title.toLowerCase(),
                      ]).map((product) => (
                        <Grid
                          className={classes.productCard}
                          key={product.id}
                          item
                          xs={6}
                          sm={4}
                        >
                          <ProductCard
                            disabled
                            product={product}
                            onClick={() => {}}
                            type="product"
                            timeZone={shop?.config?.timeZone}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className={classes.noActiveProducts}
                    >
                      <Typography variant="body2">
                        No active products to display.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={4}
                direction="column"
                className={classes.rightColumn}
              >
                {!isMobile && getToggleButtonBlock(setFieldValue, values)}
                {!isMobile && !hasEventProducts && getInactiveBannerBlock()}
              </Grid>
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default EventPage;

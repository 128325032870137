import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTracking } from "@castiron/utils";
import { Banner, RadioOrCheckboxGroup, Typography } from "@castiron/components";
import { accountRepository } from "../../../../domain";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import AdminForm from "../../../AdminForm";
import { StickyFooterProps } from "../OnboardingFooter";

interface Props {
  step: number;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  nextStep?: () => void;
}

const formSchema = yup.object().shape({
  challenges: yup.array().of(yup.string()),
});

const useStyles = makeStyles((theme: Theme) => ({}));

const Challenges: React.FC<Props> = (props: Props) => {
  const {
    step,
    setLoading,
    setHeader,
    setStickyFooterProps,
    setSubHeader,
    nextStep,
  } = props;
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<any>>();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const [showError, setShowError] = useState(false);

  const submit = async (values) => {
    setLoading(true);

    if (!_.isEmpty(values.challenges)) {
      await accountRepository.updateProps(account.id, {
        "onboardingQuestions.challenges": values.challenges,
      });

      setLoading(false);
      nextStep();
    } else {
      setLoading(false);
      setShowError(true);
    }
  };

  useEffect(() => {
    setHeader("What challenges are you currently facing?");
    setSubHeader("Select all that apply");
    setStickyFooterProps({
      onNextClick: async () => {
        await formRef.current.submitForm();
        if (!formRef.current.isValid) {
          setShowError(true);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      },
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader("");
      setSubHeader("");
    };
  }, [formRef]);

  const options = [
    {
      label: "Finding customers",
      value: "customer-base",
    },
    {
      label: "Managing production",
      value: "managing-production",
    },
    {
      label: "Getting repeat orders",
      value: "repeat-orders",
    },
    {
      label: "Developing a unique brand",
      value: "unique-brand",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  const handleChange = (value, formikProps: FormikProps<any>) => {
    setShowError(false);

    const currentValues = formikProps.values.challenges || [];
    let newValues = [];
    if (currentValues?.includes(value)) {
      newValues = currentValues.filter((v) => v !== value);
    } else {
      newValues = [...currentValues, value];
    }
    formikProps.setFieldValue("challenges", newValues);
  };

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: "24px", marginBottom: "120px" }}
    >
      <Formik
        initialValues={{
          challenges: account?.onboardingQuestions?.challenges || [],
        }}
        validationSchema={formSchema}
        onSubmit={submit}
        innerRef={formRef}
        enableReinitialize
      >
        {(formikProps) => (
          <AdminForm>
            <Grid container item direction="column" style={{ gap: 16 }}>
              {showError && (
                <Banner variant="error">
                  Please make a selection to continue.
                </Banner>
              )}
              <RadioOrCheckboxGroup
                inputType="checkbox"
                items={options}
                setSelection={(value) => handleChange(value, formikProps)}
                noIcons
                defaultSelected={false}
                customColor={theme.branding.v2.plum[500]}
                selectedItem={formikProps?.values?.challenges}
              />
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default Challenges;

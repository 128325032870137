import {
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React from "react";
import { Button } from "@castiron/components";
import { useTracking } from "@castiron/utils";
import ModalWrapper from "../../RootModal/ModalWrapper";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";
import { accountRepository } from "../../../domain";
import clsx from "clsx";

export type modalType =
  | "contactsModal"
  | "couponModal"
  | "fulfillmentModal"
  | "paymentModal"
  | "productModal";

type StepContent = {
  contentClassName?: string;
  media?: React.ReactNode;
  mediaClassName?: string;
  header?: string;
  body?: string;
};

type Props = {
  onboardingModalType: modalType;
  show: boolean;
  stepContent: StepContent[];
  redirectUrl?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    backgroundColor: theme.branding.gray[100],
    bottom: 0,
    height: 88,
    padding: "16px 24px",
    position: "sticky",
    width: "100%",
  },
  backButton: {
    backgroundColor: theme.branding.gray[100],
    color: theme.branding.gray[800],
    marginRight: 8,
  },
  container: {
    backgroundColor: theme.branding.v2.plum[500],
    height: "100%",
    "& .MuiDialogContent-root": {
      padding: "20px 0 0 0",
    },
  },
  closeIcon: {
    position: "absolute",
    top: 16,
    right: 16,
    cursor: "pointer",
    zIndex: 10,
    fontSize: 32,
    backgroundColor: theme.branding.gray[100],
    borderRadius: "12px",
    width: 56,
    height: 56,

    "&:hover": {
      backgroundColor: theme.branding.gray[300],
    },
  },
  content: {
    maxWidth: "60%",
    margin: "0 auto",
    padding: "30px 20px 0",

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      maxWidth: "100%",
      paddingTop: 64,
    },
  },
  body: {
    color: theme.branding.gray[100],
    textAlign: "center",
  },
  header: {
    color: theme.branding.gray[100],
    fontWeight: 700,
    fontSize: 21,
    lineHeight: "32px",
    marginTop: 24,
    textAlign: "center",
  },
  media: {
    [theme.breakpoints.up("sm")]: {
      "& img": {
        maxHeight: "382px",
      },
      "& iframe": {
        maxWidth: "500px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        maxWidth: "100%",
      },
      "& iframe": {
        maxWidth: "100%",
      },
    },
  },
  modalWrapper: {
    "& .MuiDialog-paper": {
      backgroundColor: theme.branding.v2.plum[500],
      maxWidth: "856px",
      width: "100%",

      [theme.breakpoints.up("sm")]: {
        height: "550px",
      },
    },
  },
  stepCounter: {
    color: theme.branding.v2.plum[500],
    fontSize: 16,
    fontWeight: 600,
  },
}));

const OnboardingModal: React.FC<Props> = (props: Props) => {
  const { onboardingModalType, show, stepContent } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [curStep, setCurStep] = React.useState(1);

  const { account } = useAppSelector((state) => ({
    account: state.shops.account,
  }));

  const handleClose = (): void => {
    let type;
    switch (onboardingModalType) {
      case "contactsModal":
        type = "contactsModalShown";
        break;
      case "couponModal":
        type = "couponModalShown";
        break;
      case "fulfillmentModal":
        type = "fulfillmentModalShown";
        break;
      case "paymentModal":
        type = "paymentModalShown";
        break;
      case "productModal":
        type = "productModalShown";
        break;
    }

    const emptyOnboardingModals = {
      businessDetailsModalShown: false,
      contactsModalShown: false,
      couponModalShown: false,
      fulfillmentModalShown: false,
      productModalShown: false,
      paymentModalShown: false,
    };
    const newOnboardingModals = account.onboardingModals
      ? { ...account.onboardingModals, [type]: true }
      : { ...emptyOnboardingModals, [type]: true };

    accountRepository.updateProps(account.id, {
      onboardingModals: newOnboardingModals,
    });

    dispatch(closeModal());
  };

  const modalTypeToPage = (type): string => {
    switch (type) {
      case "contactsModal":
        return "Contacts";
      case "couponModal":
        return "Coupons";
      case "fulfillmentModal":
        return "Fulfillment";
      case "paymentModal":
        return "Payment";
      case "productModal":
        return "Products";
      default:
        return "";
    }
  };

  const nextStep = (onboardingModalType) => {
    if (curStep < stepContent.length) {
      setCurStep(curStep + 1);
      window.scrollTo(0, 0);
    } else {
      handleClose();
      trackEvent("Finished Open Sequence Modal", {
        page: modalTypeToPage(onboardingModalType),
      });
    }
  };

  const prevStep = () => {
    if (curStep > 1) {
      setCurStep(curStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const buttonText = (index, stepContentLength, type) => {
    if (index == stepContentLength - 1) {
      return "Get Started";
    } else {
      return "Next";
    }
  };

  return (
    <ModalWrapper
      fullScreen={isMobile}
      show={show}
      size="md"
      className={classes.modalWrapper}
      onClose={handleClose}
    >
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      {stepContent.map((step, index) => {
        return (
          <Grid
            key={`onboarding-steps-${index}`}
            container
            direction="column"
            className={classes.container}
            style={{ display: curStep === index + 1 ? "flex" : "none" }}
            wrap="nowrap"
          >
            <DialogContent>
              <Grid
                container
                justify="flex-start"
                className={clsx([
                  classes.content,
                  step.contentClassName ? step.contentClassName : "",
                ])}
                direction="column"
              >
                {step.media && (
                  <div
                    className={clsx([
                      classes.media,
                      step.mediaClassName ? step.mediaClassName : "",
                    ])}
                  >
                    {step.media}
                  </div>
                )}
                {step.header && (
                  <Typography className={classes.header} variant="h5">
                    {step.header}
                  </Typography>
                )}
                {step.body && (
                  <Typography variant="body1" className={classes.body}>
                    {step.body}
                  </Typography>
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Grid container justify="space-between" alignItems="center">
                <Typography variant="body1" className={classes.stepCounter}>{`${
                  index + 1
                }/${stepContent.length}`}</Typography>
                <Grid item>
                  {index != 0 && (
                    <Button
                      onClick={prevStep}
                      variant="outlined"
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    onClick={() => nextStep(onboardingModalType)}
                    variant="contained"
                  >
                    {buttonText(index, stepContent.length, onboardingModalType)}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        );
      })}
    </ModalWrapper>
  );
};

export default OnboardingModal;

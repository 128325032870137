import React, { useEffect, useState } from 'react';
import { Grid, Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Emails from './Emails';
import ProductShare from './Social/ProductShare';
import CouponShare from './Social/CouponShare';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Menus/AvatarMenu';
import ViewShopButton from '../Layout/Header/ViewShopButton';
import HeaderTabs from '../Layout/Header/HeaderTabs';
import { Helmet } from 'react-helmet';
import { Banner, Pill, Typography, useFeatures } from '@castiron/components';
import { useConfig } from '@castiron/castiron-firebase';
import { Special } from '@castiron/domain';
import { specialRepository } from '../../domain';
import { useAppSelector } from '../../hooks';
import Spinner from '../Spinner';
import { getSingleSendEmails } from './Emails/emailTemplates';
import Popups from './Popups';
import Sms from './Sms';
import { getSmsTemplates } from './Sms/smsTemplates';
import Automations from './Automations';

interface Props extends LayoutPageProps {
  subpage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    gap: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
}));

const Marketing: React.FC<Props> = (props: Props) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs, subpage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  //presales feature flag
  const config = useConfig();
  const features = useFeatures();
  const isPresalesEnabled = features.includes('admin.presales') && config.featureFlag('feature_presales', shop);
  const isQuotesEnabled = features.includes('admin.quotes');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeShopEventTags, setActiveShopEventTags] = useState<string[]>([]);
  const [activeShopEvents, setActiveShopEvents] = useState<Special[]>([]);
  const [singleSendEmails, setSingleSendEmails] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([]);

  useEffect(() => {
    const getTemplates = async () => {
      const smsTemps = await Promise.resolve(getSmsTemplates({theme, isPresalesEnabled, activeEventTags: activeShopEventTags, isQuotesEnabled}));
      const emailTemps = await Promise.resolve(getSingleSendEmails({theme, isPresalesEnabled, activeEventTags: activeShopEventTags, isQuotesEnabled}));

      setSmsTemplates(smsTemps);
      setSingleSendEmails(emailTemps);
    };

    getTemplates();
  }, [shop, theme, activeShopEventTags]);

  useEffect(() => {
    const getActiveShopEvents = async () => {
      const activeEvents = await specialRepository.findActiveShopEvents(shop?.config?.timeZone);
      const eventTags = activeEvents.map(e => e.tag);
      setIsLoading(false);
      setActiveShopEventTags(eventTags);
      setActiveShopEvents(activeEvents);
    };
    getActiveShopEvents();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPageTitle(subpage);

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    const headerCTAs = isMobile
      ? [<ViewShopButton subdirectory="signup" />, <AvatarMenu />]
      : [<ViewShopButton subdirectory="signup" />];
    setHeaderCTAs(headerCTAs);
    setFooterCTAs([]);
  }, [isMobile]);

  const renderPageContent = () => {
    switch (subpage) {
      case 'SMS':
        return (
          <Grid container direction="column" className={classes.content}>
            <Sms activeShopEventTags={activeShopEventTags} smsTemplates={smsTemplates} />
          </Grid>
        );
      case 'Email':
        return (
          <Grid container direction="column" className={classes.content}>
            <Emails activeShopEventTags={activeShopEventTags} singleSendEmails={singleSendEmails} />
          </Grid>
        );
      case 'Automations':
        return (
          <Grid container direction="column" className={classes.content}>
            <Automations />
          </Grid>
        );
      case 'Popups':
        return (
          <Grid container direction="column" className={classes.content}>
            <Popups activeShopEvents={activeShopEvents} />
          </Grid>
        );
      case 'Social':
        return (
          <Grid container direction="column" className={classes.content}>
            <ProductShare />
            <CouponShare />
          </Grid>
        );
      default:
        return;
    }
  };

  return isLoading ? (
    <Spinner size="fullscreen" show={true} />
  ) : (
    <>
      <Helmet>
        <title>Marketing | Nourysh</title>
      </Helmet>
      {renderPageContent()}
    </>
  );
};

export default Marketing;

import React from "react";
import {
  IconButton,
  makeStyles,
  Grid,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Theme, useTheme } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import { ButtonV2, StarSolidIcon, Typography } from "@castiron/components";
import ModalWrapper from "../RootModal/ModalWrapper";
import { useAppDispatch } from "../../hooks";
import { closeModal } from "../../store/reducers/modalConductor";
import { useHistory } from "react-router-dom";

export type Props = {
  show: boolean;
  message?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    marginTop: 24,
    padding: "12px 24px",
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  closeIcon: {
    cursor: "pointer",
    height: 24,
    width: 24,
  },
  modalTitle: {
    padding: "26px 24px",
    [theme.breakpoints.up("sm")]: {
      borderBottom: `1px solid ${theme.branding.v2.gray[200]}`,
    },
  },
  starIcon: {
    color: theme.branding.v2.plum[500],
    marginBottom: 16,
    height: 40,
    width: 40,
  },
}));

const PromptStripeSetupModal: React.FC<Props> = (props: Props) => {
  const { show, message } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleSetupClick = (): void => {
    history.push("/store/payments");
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="md" show={show} fullScreen={isMobile}>
      <DialogTitle style={{ padding: 0 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.modalTitle}
        >
          <Typography variant="h3">Complete Shop Setup</Typography>
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "32px 24px 40px" }}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          <Typography variant="body1">
            {message ||
              `Before connecting with your customers, let's complete your shop setup.`}{" "}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 0 }}>
        <Grid
          container
          item
          justify="flex-end"
          className={classes.actionsContainer}
        >
          <ButtonV2
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: 8 }}
          >
            Close
          </ButtonV2>
          <ButtonV2 variant="contained" onClick={handleSetupClick}>
            Complete Setup
          </ButtonV2>
        </Grid>
      </DialogActions>
    </ModalWrapper>
  );
};

export default PromptStripeSetupModal;

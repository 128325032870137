import React, { useEffect } from "react";
import { Grid, Link, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { LayoutPageProps } from "../Layout";
import AvatarMenu from "../Menus/AvatarMenu";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: 17,
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: "25px",
    color: theme.branding.gray[700],
    marginBottom: 14,
  },
}));

const ContactUs: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setPageTitle("Contact Us");

    return () => {
      setPageTitle("");
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
    } else {
      setHeaderCTAs([]);
    }
  }, [isMobile]);

  useEffect(() => {
    setFooterCTAs([]);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact Us | Nourysh</title>
      </Helmet>
      <Grid container>
        <Grid className={classes.container} item xs={12} lg={8}>
          <Typography paragraph className={classes.subtitle}>
            Have a Question or Feedback? We’d Love to Hear from You
          </Typography>
          <Typography paragraph>
            At Nourysh, we’re dedicated to empowering entrepreneurs to grow
            their businesses with confidence. We know that without our creators,
            there is no community, and we always put you first—asking ourselves,
            “Is this what’s best for our small businesses?” before making
            decisions.
          </Typography>
          <Typography paragraph>
            We’re open to your ideas, questions, and feedback on how we can make
            Nourysh better for you. Feel free to reach out anytime at{" "}
            <Link href="mailto:support@nourysh.com">support@nourysh.com</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;

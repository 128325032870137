import React, { useCallback, useState } from "react";
import moment from "moment";
import { Grid, Theme, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Banner, Button, Typography } from "@castiron/components";
import { AttachMoney } from "@material-ui/icons";
import { getService } from "../../../firebase";
import { closeModal } from "../../../store/reducers/modalConductor";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { NextStep } from "../PlanFlow";
import { trackEvent } from "@castiron/utils";

const cancelSubscriptionService = getService(
  "subscriptions",
  "cancelsubscription"
);

type Props = {
  nextStep: NextStep;
};

const useStyles = makeStyles((theme: Theme) => ({
  attachMoneyIconContainer: {
    backgroundColor: theme.branding.gray[200],
    borderRadius: 16,
    color: theme.branding.gray[600],
    height: 48,
    padding: "15px 19px",
    width: 48,
  },
  banner: {
    margin: "24px 0",
  },
  button: {
    padding: 16,
    marginBottom: 32,
  },
  starterPlanContainer: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 16,
    padding: 24,
    margin: "24px 0 32px",
  },
}));

const CancelPlan: React.FC<Props> = (props: Props) => {
  const { nextStep } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { account, subscription } = useAppSelector((state) => ({
    account: state.shops.account,
    subscription: state.shops.account?.subscription,
  }));

  const planCancelDate = moment
    .unix(account?.subscription?.nextPaymentDate)
    .format("MM/DD/YY");

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const onCancel = useCallback(async () => {
    setIsSubmitting(true);
    const cancelResponse = await cancelSubscriptionService({
      reason: "Plan Canceled",
    });
    if (cancelResponse?.success) {
      trackEvent("Owner Canceled Subscription");
      nextStep(4);
    }
    setIsSubmitting(false);
  }, [subscription]);

  return (
    <Grid container style={{ padding: "8px 24px" }} direction="column">
      <Banner variant="star-plum" className={classes.banner}>
        <Typography variant="body2">
          Enjoy all of the benefits of the{" "}
          <b>{subscription?.plan?.name} Plan</b> until {planCancelDate}!
        </Typography>
      </Banner>
      <Typography variant="body1">
        You will be downgraded to the Starter Plan on {planCancelDate}.
      </Typography>
      <Grid
        container
        wrap="nowrap"
        className={classes.starterPlanContainer}
        justify="space-between"
        alignItems="center"
      >
        <Grid container item direction="column">
          <Typography
            variant="h3"
            style={{ color: theme.branding.v2.plum[500], marginBottom: 8 }}
          >
            Starter Plan
          </Typography>
          <Typography variant="body1">$0 / month + 10% / order</Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.attachMoneyIconContainer}
          justify="center"
          alignContent="center"
        >
          <AttachMoney />
        </Grid>
      </Grid>
      <Grid container item justify="flex-end">
        <Button
          variant="outlined"
          className={classes.button}
          style={{ marginRight: 8 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          variant="contained"
          className={classes.button}
          onClick={onCancel}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default CancelPlan;

import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Coupon, couponUtils } from '@castiron/domain';
import ListHeader from '../../ListHeader';
import DataGrid from '../../DataGrid/DataGrid';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import moment from 'moment';
import ActionsMenu from '../../ActionsMenu';
import Dinero from 'dinero.js';
import { useAppSelector } from '../../../hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Chip } from '@castiron/components';
import QuickAdd from '../QuickAdd';

const useStyles = makeStyles((theme: Theme) => ({
  codeDuration: {
    color: '#aaa',
    marginBottom: '8px',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
  couponTableContainer: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  expired: {
    opacity: 0.4,
  },
  header: {
    position: 'relative',
  },
  tableActions: {
    gap: "16px",
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  tableWrapper: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
}));

interface Props {
  editing: boolean;
}

const CouponTable: React.FC<Props> = (props: Props) => {
  const { editing } = props;
  const history = useHistory();
  const filterOptions = ['All', 'Active', 'Scheduled', 'Expired'];
  const [filters, setFilters] = useState(['All']);

  const { storeCoupons } = useAppSelector(state => ({
    storeCoupons: state.coupons.coupons,
  }));

  const { id } = useParams<{ id: string }>();

  const [coupons, setCoupons] = useState<Coupon[]>(storeCoupons);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    const getCoupon = async () => {
      const currentCoupon = storeCoupons.find(coupon => coupon.id === id);
      if (currentCoupon) setSelectionModel([currentCoupon.id]);
    };
    if (id && editing) {
      getCoupon();
    }
  }, [id]);

  useEffect(() => {
    setCoupons(storeCoupons);
  }, [storeCoupons]);

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const columns: GridColumns = [
    {
      field: 'code',
      headerName: 'Code',
      flex: isMobile ? 1 : 1,
      renderCell: (params: GridCellParams): ReactElement => {
        const startDate = moment.unix(params.row.duration.startDate).format('MM/DD/YYYY');
        const endDate = params.row.duration.endDate
          ? moment.unix(params.row.duration.endDate).format('MM/DD/YYYY')
          : 'No End';

        return (
          <div
            style={{ lineHeight: '24px' }}
            className={`${params.row.status === 'expired' ? classes.expired : ''}`}
            onClick={() => history.push(`/coupons/edit/${params.row.id}`)}
          >
            <Typography>{params.row.code}</Typography>
            {isMobile ? (
              <>
                <Typography className={classes.codeDuration} variant="body2">
                  {startDate} -
                </Typography>
                <Typography className={classes.codeDuration} variant="body2">
                  {endDate}
                </Typography>
              </>
            ) : (
              <Typography className={classes.codeDuration} variant="body2">
                {startDate} - {endDate}
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: isMobile ? 1 : 0.5,
      renderCell: (params: GridCellParams): ReactElement => {
        let status;
        if (params.row.status === 'active') status = 'success';
        if (params.row.status === 'scheduled') status = 'warning';
        if (params.row.status === 'expired') status = 'error';

        return (
          <div>
            <Chip colorScheme={status} uppercase bold>
              {params.row.status}
            </Chip>
          </div>
        );
      },
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: isMobile ? 1 : 0.3,
      renderCell: (params: GridCellParams): ReactElement => {
        return (
          <Typography className={`${params.row.status === 'expired' ? classes.expired : ''}`}>
            {params.row.discount.type === 'amount'
              ? `${Dinero({ amount: params.row.discount.value }).toFormat('$0.00')}`
              : `${params.row.discount.value}%`}
          </Typography>
        );
      },
    },
    {
      field: 'totalUses',
      headerName: 'Uses',
      flex: 0.3,
      renderCell: (params: GridCellParams): ReactElement => {
        return (
          <Typography className={`${params.row.status === 'expired' ? classes.expired : ''}`}>
            {params.row.metrics ? params.row.metrics.totalUses : '0'}
          </Typography>
        );
      },
    },
    {
      field: 'totalRevenue',
      headerName: 'Revenue',
      flex: 0.3,
      renderCell: (params: GridCellParams): ReactElement => {
        return (
          <Typography className={`${params.row.status === 'expired' ? classes.expired : ''}`}>
            {params.row.metrics ? `${Dinero({ amount: params.row.metrics.totalRevenue }).toFormat('$0.00')}` : '$0.00'}
          </Typography>
        );
      },
    },
    {
      field: 'actionMenu',
      renderHeader: (): ReactElement => <div style={{ display: 'none' }} />,
      flex: isMobile ? 0.4 : 0.3,
      sortable: false,
      renderCell: (params: GridCellParams): ReactElement => (
        <ActionsMenu id={params.id} type="coupons" coupon={params.row as Coupon} />
      ),
    },
  ];

  const columnsMobile = columns.filter(
    column =>
      column.field === 'code' || column.field === 'status' || column.field === 'value' || column.field === 'actionMenu',
  );

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: string[]): void => {
    const map = {
      active: 'Active',
      scheduled: 'Scheduled',
      expired: 'Expired',
    };
    setFilters(value);
    if (value.includes('All')) setCoupons(storeCoupons);
    else {
      setCoupons(storeCoupons.filter((coupon: Coupon) => value.includes(map[couponUtils.getEffectiveStatus(coupon)])));
    }
  };

  return (
    <Grid container item xs={12} direction="column" className={classes.tableWrapper}>
      <Grid container item xs={12} justify="space-between" wrap="nowrap" className={classes.tableActions}>
        <QuickAdd />
        <ListHeader
          isMobile={isMobile}
          filters={filters}
          onFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          content={{}}
          className={classes.header}
        />
      </Grid>
      <Grid container item xs={12} spacing={2} className={classes.couponTableContainer}>
        <DataGrid
          columns={isMobile ? columnsMobile : columns}
          rows={coupons.map((coupon: Coupon) => ({
            ...coupon,
            status: couponUtils.getEffectiveStatus(coupon),
          }))}
          rowHeight={100}
          onSelectionModelChange={newSelection => {
            setSelectionModel(newSelection.selectionModel);
          }}
          selectionModel={selectionModel}
        />
      </Grid>
    </Grid>
  );
};

export default CouponTable;

import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Dinero from 'dinero.js';
import { Checkbox, Button } from '@castiron/components';
import AdminForm from '../AdminForm';

export type Props = {
  show: boolean;
  refund?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  container: {
    padding: '22px 32px',
  },
  actionsContainer: {
    marginTop: 24,
    display: 'flex',
    gap: 8,
  },
  title: {
    paddingBottom: 11,
    fontWeight: 700,
    fontSize: 22,
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 12,
  },
  price: {
    fontSize: 23,
    fontWeight: 700,
  },
  priceCheckbox: {
    alignItems: 'center',
  },
}));

const CancelOrderModal: React.FC<Props> = (props: Props) => {
  const { show, refund } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const noteSchema = yup.object().shape({
    refund: yup.boolean(),
    restock: yup.boolean(),
    notification: yup.boolean(),
  });

  return (
    <ModalWrapper size="xs" show={show}>
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <div className={classes.container}>
        <Formik
          onSubmit={() => console.log('submitted')}
          validationSchema={noteSchema}
          initialValues={{
            refund: false,
            restock: false,
            notification: false,
          }}
        >
          {({ setFieldValue, values }) => (
            <AdminForm>
              <Typography className={classes.title}>Cancel order</Typography>
              <div className={classes.checkboxesContainer}>
                {refund && (
                  <div>
                    <Typography className={classes.sectionTitle}>Refund payment</Typography>
                    <Field
                      as={Checkbox}
                      checkboxClass={classes.priceCheckbox}
                      onChange={(e) => setFieldValue('refund', e.target.checked)}
                      name="refund"
                      checked={values.refund}
                      label={
                        <Typography className={classes.price}>{Dinero({ amount: 2440 }).toFormat('$0.00')}</Typography>
                      }
                    />
                  </div>
                )}
                <div>
                  <Typography className={classes.sectionTitle}>Inventory</Typography>
                  <Field
                    as={Checkbox}
                    onChange={(e) => setFieldValue('restock', e.target.checked)}
                    name="restock"
                    checked={values.restock}
                    label="Restock items"
                  />
                </div>
                <div>
                  <Typography className={classes.sectionTitle}>Notification</Typography>
                  <Field
                    as={Checkbox}
                    onChange={(e) => setFieldValue('notification', e.target.checked)}
                    name="notification"
                    checked={values.notification}
                    label="Send an email notifying the customer"
                  />
                </div>
              </div>
              <div className={classes.actionsContainer}>
                <Button type="submit" variant="contained">
                  Cancel order
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  Keep order
                </Button>
              </div>
            </AdminForm>
          )}
        </Formik>
      </div>
    </ModalWrapper>
  );
};

export default CancelOrderModal;

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import moment from "moment";
import { ButtonBase, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import StorefrontIcon from "@material-ui/icons/Storefront";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import { Checkbox, OrderStatusPill, Typography } from "@castiron/components";
import { Customer, Transaction } from "@castiron/domain";
import { defaultTimeZone } from "@castiron/utils";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { openModal } from "../../store/reducers/modalConductor";
import OrderActionsMenu from "./OrderActionsMenu";

interface Props {
  transactions: Transaction[];
  selectedOrders: Transaction[];
  setSelectedOrders: Dispatch<SetStateAction<Transaction[]>>;
  retrieveMoreOrders: () => void;
  moreOrdersLeft: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    color: theme.branding.v2.plum[500],
  },
  colorAdjuster: {
    color: theme.branding.gray[600],
  },
  dateTime: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  dueDate: {
    minWidth: 180,
    [theme.breakpoints.down(476)]: {
      paddingBottom: 16,
    },
  },
  emptyOrdersContainer: {
    textAlign: "center",
    width: "100%",
    margin: 16,
  },
  fulfillmentName: {
    color: theme.branding.gray[700],
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    wordBreak: "break-word",
  },
  lightning: {
    color: theme.branding.gray[400],
    height: 18,
  },
  listContainer: {
    padding: "0px 24px",
  },
  orderContainer: {
    padding: 24,
    cursor: "pointer",
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 16,
    marginBottom: 8,
  },
  orderCustomerName: {
    fontWeight: 600,
  },
  orderInfoHeaders: {
    marginBottom: 8,
    color: theme.branding.gray[600],
  },
  orderItems: {
    maxWidth: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    wordBreak: "break-word",
  },
  orderActions: {
    "& .MuiPopover-paper": {
      borderRadius: "16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 8,
      "& .MuiButton-sizeLarge": {
        width: "100%",
        justifyContent: "flex-start",
        padding: "15px 24px",
        borderRadius: 0,
        borderBottom: `1px solid ${theme.branding.gray[300]}`,
      },
    },
  },
  fulfillmentIcon: {
    height: 17,
    color: theme.branding.gray[400],
  },
}));

const orderProducts = (transaction: Transaction) => {
  let orderItems = [];
  transaction.order.items?.map((item) => orderItems.push(item.title));

  const uniqueItems = _.uniq(orderItems);
  const itemsList = uniqueItems.map(
    (item, index, arr) => `${item}${index < arr.length - 1 ? ", " : ""}`
  );

  return itemsList.join(",");
};

const getProductName = (transaction: Transaction) => {
  if (!transaction.order.items || transaction.order.items.length === 0)
    return "N/A";
  if (transaction.order.items[0].type === "invoice") return "Invoice";
  if (transaction.order.type === "custom") {
    return _.truncate(transaction.order.items[0]?.title, { length: 50 });
  } else {
    return orderProducts(transaction);
  }
};

const MobileList: React.FC<Props> = (props: Props) => {
  const {
    transactions,
    selectedOrders,
    setSelectedOrders,
    retrieveMoreOrders,
    moreOrdersLeft,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { shop, customers } = useAppSelector((state) => ({
    shop: state.shops.shop,
    customers: state.customers.customers,
  }));

  const tz = shop.config?.timeZone || defaultTimeZone;

  const onOrderClick = (e, id: string): void => {
    //need this to allow triggering of checkbox onchange inside of div onclick
    if (e.target.nodeName === "INPUT") {
      return;
    }
    history.push(`/orders/edit/${id}`);
  };

  const dateFormat = (transaction: Transaction) => {
    let date;

    if (transaction?.createdAt) {
      date = moment.unix(transaction?.createdAt).tz(tz).format("M/D");
    }

    return (
      <Grid container direction="column" className={classes.dateTime}>
        <Typography variant="body2">{date}</Typography>
      </Grid>
    );
  };

  // const showDateTimeModal = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  //   transaction: Transaction
  // ) => {
  //   event.stopPropagation();
  //   dispatch(
  //     openModal({
  //       modalType: "FULFILLMENT_DATE_TIME",
  //       modalProps: {
  //         transaction,
  //         onUpdate: () => {},
  //       },
  //     })
  //   );
  // };

  const findFulfillmentIcon = (transaction: Transaction) => {
    if (transaction?.order?.fulfillmentOption?.type === "delivery") {
      return <DriveEtaOutlinedIcon className={classes.fulfillmentIcon} />;
    } else if (transaction?.order?.fulfillmentOption?.type === "shipping") {
      return <LocalShippingOutlinedIcon className={classes.fulfillmentIcon} />;
    } else {
      return <StorefrontIcon className={classes.fulfillmentIcon} />;
    }
  };

  const getCustomer = (custId: string) =>
    customers?.find((c) => c.id === custId);

  const getCustomerName = (customer: Customer) => {
    if (
      !customer ||
      (!customer.email && !customer.firstName && !customer.lastName)
    )
      return "None";
    return customer.firstName
      ? `${customer.firstName} ${customer.lastName}`
      : customer.email;
  };

  return (
    <Grid container item xs={12} className={classes.listContainer}>
      {transactions?.length > 0 ? (
        <InfiniteScroll
          dataLength={transactions.length}
          next={retrieveMoreOrders}
          hasMore={moreOrdersLeft}
          loader={
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        >
          {transactions.map((transaction) => {
            /* hack to account for failure to look up transaction from id in ES,
             * should never happen, but happened locally due to weird testing scenarios
             */
            if (!transaction) {
              return <></>;
            }
            const isChecked = !!selectedOrders.find(
              (o) => o.id === transaction.id
            );
            const customer = getCustomer(transaction.customer);
            const customerName = getCustomerName(customer);
            return (
              <Grid
                key={`order-${transaction.id}`}
                container
                item
                xs={12}
                justify="space-between"
                wrap="nowrap"
                onClick={(e) => onOrderClick(e, transaction.id)}
                className={classes.orderContainer}
              >
                <Grid item xs={1} style={{ maxWidth: 30 }}>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => {
                      setSelectedOrders(
                        isChecked
                          ? selectedOrders.filter(
                              (o) => o.id !== transaction.id
                            )
                          : _.uniqBy([...selectedOrders, transaction], "id")
                      );
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  direction="column"
                  style={{ paddingRight: 16 }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    style={{ marginBottom: 14 }}
                  >
                    <Grid
                      container
                      item
                      direction="row"
                      style={{ marginBottom: 4 }}
                    >
                      <Typography variant="body1" style={{ paddingRight: 8 }}>
                        #{transaction.order.orderNumber}
                      </Typography>
                      <OrderStatusPill
                        transaction={transaction}
                        context="order"
                      />
                    </Grid>
                    <Typography variant="body1">{customerName}</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sm={8}
                      direction="column"
                      wrap="nowrap"
                      className={`${classes.dueDate} `}
                    >
                      <Typography
                        variant="placeholder2"
                        className={classes.orderInfoHeaders}
                      >
                        Order Date
                      </Typography>
                      {transaction?.createdAt ? (
                        <Grid container item direction="column">
                          <Typography
                            variant="body4"
                            style={{ marginBottom: 4 }}
                          >
                            {dateFormat(transaction)}
                          </Typography>
                          <Grid
                            container
                            item
                            direction="row"
                            wrap="nowrap"
                            alignItems="center"
                          >
                            {transaction?.order?.type === "custom" ? (
                              <Typography
                                variant="caption"
                                className={classes.fulfillmentName}
                              >
                                Custom
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid container item direction="column" xs={12} sm={4}>
                      <Typography
                        variant="placeholder2"
                        className={classes.orderInfoHeaders}
                      >
                        Products
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.orderItems}
                      >
                        {getProductName(transaction)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={1} justify="flex-end">
                  <OrderActionsMenu
                    shop={shop}
                    transaction={transaction}
                    tz={tz}
                  />
                </Grid>
              </Grid>
            );
          })}
        </InfiniteScroll>
      ) : (
        <Grid item className={classes.emptyOrdersContainer}>
          <Typography variant="body1">No orders</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MobileList;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import {
  ButtonBase,
  Grid,
  makeStyles,
  useTheme,
  Chip,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import {
  Typography,
  SaveButton,
  DiscardButton,
  useFeatures,
} from "@castiron/components";
import { ThemeNameOption, Shop, ChecklistValues } from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { getShopAction, updateShopAction } from "../../../store/reducers/shops";
import { openModal } from "../../../store/reducers/modalConductor";
import { themeNames, findThemeConfig } from "../../../lib/themeUtils";
import { LayoutPageProps } from "../../Layout";
import ThemePreview from "../../ThemePreview";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: 20,
    padding: 4,
    width: "100%",
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px 8px",
    },
  },
  innerContainer: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  nameContainer: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: "24px 0",
  },
  statusChip: {
    position: "absolute",
    right: 8,
    bottom: 8,
    backgroundColor: theme.branding.v2.gray[0],
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    height: 28,
  },
}));

const ThemeSelect: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const features = useFeatures();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const [submitting, setSubmitting] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(
    shop?.config?.shopTheme?.name || "classic-castiron"
  );

  useEffect(() => {
    if (!features.includes("shop.themes")) {
      history.push("/store/appearance");
    }
  }, []);

  useEffect(() => {
    setPageTitle("Select A Theme");
    setBackLocation(true);
    setHeaderCTAs([]);

    return () => {
      setPageTitle("");
      setBackLocation(false);
      setHeaderCTAs([]);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton
        isSubmitting={submitting}
        backLocation="/store/appearance"
        customText="Cancel"
      />,
      <SaveButton isSubmitting={submitting} handleSubmit={onSubmit} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [submitting, selectedTheme]);

  const onSubmit = async () => {
    if (!shop?.config?.shopTheme && selectedTheme === "classic-castiron") {
      setSubmitting(true);
      const themeConfig = findThemeConfig("classic-castiron", theme);

      const newShop: Shop = {
        ...shop,
        config: {
          ...shop?.config,
          shopTheme: themeConfig,
        },
      };

      const updateShopResponse = await dispatch(
        updateShopAction({ shop: newShop })
      );

      if (updateShopResponse.meta.requestStatus === "fulfilled") {
        await shop.addToChecklist(ChecklistValues.Brand);
        await dispatch(getShopAction(shop.id));

        trackEvent("Shop Theme Preset Updated", {
          theme: "classic-castiron",
        });

        dispatch(
          openModal({
            modalType: "SIMPLE_ALERT",
            modalProps: {
              show: true,
              celebrate: true,
              content: "Theme has been updated!",
            },
          })
        );

        setSubmitting(false);
        history.push("/store/appearance");
      }
    } else if (selectedTheme !== shop?.config?.shopTheme?.name) {
      dispatch(
        openModal({
          modalType: "CONFIRM_THEME_MODAL",
          modalProps: {
            show: true,
            selectedTheme: selectedTheme,
            shop,
          },
        })
      );
    } else {
      dispatch(
        openModal({
          modalType: "SIMPLE_ALERT",
          modalProps: {
            show: true,
            celebrate: true,
            content: "Theme has been updated!",
          },
        })
      );
      history.push("/store/appearance");
    }
  };

  const findStatusPill = (value) => {
    if (
      shop?.config?.shopTheme?.name === value ||
      (!shop?.config?.shopTheme?.name && value === "classic-castiron")
    ) {
      return (
        <Chip
          label={<Typography variant="button2">Your Current Theme</Typography>}
          className={classes.statusChip}
        />
      );
    } else if (value === selectedTheme) {
      return (
        <Chip
          label={<Typography variant="button2">Selected Theme</Typography>}
          className={classes.statusChip}
        />
      );
    } else return <></>;
  };

  const themes = themeNames.map((name) => ({
    name,
    value: _.kebabCase(name) as ThemeNameOption,
    previewAsset: (
      <ThemePreview themeName={name}>
        {findStatusPill(_.kebabCase(name))}
      </ThemePreview>
    ),
  }));

  return (
    <Grid container spacing={1} direction="row" className={classes.container}>
      <Helmet>
        <title>Select a Theme | Shop | Nourysh</title>
      </Helmet>
      {themes.map((option, index) => (
        <Grid key={index} container item xs={12} lg={6}>
          <ButtonBase
            className={classes.button}
            style={{
              border: `4px solid ${
                selectedTheme === option.value
                  ? theme.branding.v2.plum[500]
                  : theme.branding.v2.gray[0]
              }`,
            }}
            onClick={() => setSelectedTheme(option.value)}
          >
            <Grid
              container
              direction="column"
              className={classes.innerContainer}
            >
              {option.previewAsset}
              <Grid
                className={classes.nameContainer}
                container
                justify="center"
                alignItems="center"
              >
                <Typography variant="subtitle1">{option.name}</Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  );
};

export default ThemeSelect;

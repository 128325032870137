import { EmptyEventsIcon } from "@castiron/components";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import EmptyPage, { TipsForYou } from "../EmptyPage";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 120,
    width: 120,
  },
  resourceGuide: {
    [theme.breakpoints.down("sm")]: {
      margin: "0px 16px",
    },
  },
}));

const EmptyEvent: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.resourceGuide}>
      <EmptyPage
        icon={<EmptyEventsIcon className={classes.icon} />}
        title="Create a Ticketed Event"
        description="Create your first event and sell tickets to experiences, classes, and other ticketed events."
        page="Events"
      />
    </Grid>
  );
};

export default EmptyEvent;

import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { Grid, useMediaQuery, useTheme, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Banner, ButtonV2, SvgIcon, Typography } from "@castiron/components";
import {
  BaseProduct,
  GenericLayoutOption,
  ProductPageContext,
} from "@castiron/domain";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import _ from "lodash";
import ActionsMenu from "../ActionsMenu";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicator from "@material-ui/icons/DragIndicator";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { shopRepository } from "../../domain";
import { openModal } from "../../store/reducers/modalConductor";
import { getShopAction } from "../../store/reducers/shops";
import Tooltip from "../Tooltip";

interface Props {
  context?: ProductPageContext;
  products: BaseProduct[];
  setHeaderCTAs?: (ctas: ReactNode[]) => void;
  setFooterCTAs?: (ctas: ReactNode[]) => void;
  AddProductButton?: ReactNode;
}

const layoutSchema = yup.object().shape({
  name: yup.string(),
  position: yup.number(),
});

const schema = yup.array().of(layoutSchema);

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    marginBottom: 15,
  },
  bannerColor: {
    color: theme.branding.v2.plum[500],
  },
  container: {
    padding: 16,
  },
  containerBorder: {
    padding: 16,
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  dragDots: {
    "& svg > path": {
      fill: theme.branding.v2.gray[400],
    },
  },
  organizeCategories: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
  },
  statusText: {
    color: theme.branding.v2.gray[500],
  },
}));

const OrganizeCategories: React.FC<Props> = (props: Props) => {
  const { context, products, AddProductButton, setHeaderCTAs, setFooterCTAs } =
    props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobilexs = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const formRef = useRef<FormikProps<any>>();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const shopPageName =
    context === "products"
      ? "Shop Page"
      : context === "order-forms"
      ? "Custom Orders Page"
      : "Event Page";
  const modalText = `${
    context === "products"
      ? "Product"
      : context === "order-forms"
      ? "Order Form"
      : "Event"
  }s Reorganized`;

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const layout =
    context === "products"
      ? shop?.standardCategoryLayout
      : context === "order-forms"
      ? shop?.customCategoryLayout
      : shop?.eventCategoryLayout;
  const bannerText = (
    <Typography variant="body1" className={classes.bannerColor}>
      This is the order your categories will show on your <b>{shopPageName}</b>.
    </Typography>
  );

  var strucutredProducts = [];
  var categoryNames = [];
  var position: 0;
  products.map((p) => {
    const categoryName =
      p?.category?.name === undefined ? "Other" : p?.category?.name;
    const existingOrder = layout?.find((o) => o.name === categoryName);
    var product = {
      name: categoryName,
      active: 0,
      inactive: 0,
      position: existingOrder ? existingOrder.position : position,
    };
    if (!categoryNames.includes(categoryName)) {
      position++;
      categoryNames.push(categoryName);
      p.status === "active" ? product.active++ : product.inactive++;
      strucutredProducts.push(product);
    } else {
      p.status === "active"
        ? strucutredProducts.find((p) => p.name === categoryName).active++
        : strucutredProducts.find((p) => p.name === categoryName).inactive++;
    }
  });

  // sort intially
  strucutredProducts = _.sortBy(strucutredProducts, (p) => p.position);

  if (!layout) {
    strucutredProducts.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (a.name == "OTHER") return 1;
      if (b.name == "OTHER") return -1;
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  }

  // a little function to help us with reordering the result
  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const onDragEnd = (result) => {
    setSubmitted(false);
    if (!result.destination) {
      return;
    }

    const sortedCategories = reorder<GenericLayoutOption>(
      strucutredProducts,
      result.source.index,
      result.destination.index
    );

    strucutredProducts = sortedCategories;

    formRef.current.setFieldValue(
      "strucutredProducts",
      sortedCategories.map((v, index) => ({
        ...v,
        position: index,
      }))
    );
  };

  const submit = async () => {
    const sanitizedProducts = formRef.current.values.strucutredProducts.map(
      (p) => _.omit(p, ["active", "inactive"])
    );
    if (context === "products") {
      await shopRepository.updateProps(shop.id, {
        standardCategoryLayout: sanitizedProducts,
      });
    } else if (context === "order-forms") {
      await shopRepository.updateProps(shop.id, {
        customCategoryLayout: sanitizedProducts,
      });
    } else {
      await shopRepository.updateProps(shop.id, {
        eventCategoryLayout: sanitizedProducts,
      });
    }
    await dispatch(
      openModal({
        modalType: "SIMPLE_ALERT",
        modalProps: {
          show: true,
          celebrate: true,
          content: (
            <>
              <Typography variant="h4">{modalText}</Typography>
            </>
          ),
        },
      })
    );
    await dispatch(getShopAction(shop.id));
  };

  useEffect(() => {
    if (isMobile) {
      setFooterCTAs([
        <ButtonV2
          variant="contained"
          fullWidth
          style={{ height: 46 }}
          onClick={submit}
        >
          Save
        </ButtonV2>,
      ]);
    } else {
      setFooterCTAs([
        <Grid
          container
          item
          xs={12}
          justify="flex-end"
          style={{ paddingRight: 76 }}
        >
          <ButtonV2
            variant="contained"
            onClick={submit}
            style={{ height: 46, width: 160 }}
          >
            Save
          </ButtonV2>
        </Grid>,
      ]);
    }
    return () => {
      isMobile ? setFooterCTAs([AddProductButton]) : setFooterCTAs([]);
    };
  }, [isMobile, shop]);

  return (
    <Formik
      initialValues={{ strucutredProducts }}
      validationSchema={schema}
      onSubmit={submit}
      innerRef={formRef}
    >
      {({ dirty, errors, setFieldValue, touched, values }): ReactElement => (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Banner variant="info-plum" className={classes.banner}>
            {bannerText}
          </Banner>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="organizeCategories" type="CATEGORY">
                {(provided, snapshot) => (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="column"
                    wrap="nowrap"
                    ref={provided.innerRef}
                    className={classes.organizeCategories}
                  >
                    {values.strucutredProducts.map((product, index) => (
                      <Draggable
                        draggableId={product.name}
                        index={index}
                        key={product.name}
                      >
                        {(provided, snapshot) => (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            container
                            item
                            key={product.name}
                            justify="space-between"
                            alignItems="center"
                            xs={11}
                          >
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                              className={
                                index === 0
                                  ? classes.container
                                  : classes.containerBorder
                              }
                            >
                              <Grid item xs={1}>
                                <SvgIcon className={classes.dragDots}>
                                  <DragIndicator />
                                </SvgIcon>
                              </Grid>
                              <Grid item xs={11}>
                                <Grid container justify="space-between">
                                  <Grid item style={{ paddingLeft: 5 }}>
                                    <Grid container justify="flex-start">
                                      <Typography variant="subtitle1">
                                        {isMobilexs
                                          ? product.name.length > 10
                                            ? `${product.name.substring(
                                                0,
                                                10
                                              )}...`
                                            : product.name
                                          : product.name.length > 37
                                          ? `${product.name.substring(
                                              0,
                                              37
                                            )}...`
                                          : product.name}
                                      </Typography>
                                      {product.name === "Other" && (
                                        <Tooltip
                                          title={`All uncategorized ${context?.replace(
                                            "-",
                                            " "
                                          )} are automatically added to this category.`}
                                        />
                                      )}
                                    </Grid>
                                    <Grid>
                                      <Typography
                                        variant="body2"
                                        className={classes.statusText}
                                      >{`${product.active} Active • ${product.inactive} Inactive`}</Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <ActionsMenu
                                      type="organize-categories"
                                      categoryName={product.name}
                                      productCardContext={context}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default OrganizeCategories;

import { useConfig } from '@castiron/castiron-firebase';
import { ButtonV2, PresaleCard, useFeatures } from '@castiron/components';
import { Presale, getProductStatus } from '@castiron/domain';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { getService } from '../../firebase';
import { useAppSelector } from '../../hooks';
import ActionsMenu from '../ActionsMenu';
import { LayoutPageProps } from '../Layout';
import ListHeader from '../ListHeader';
import AvatarMenu from '../Menus/AvatarMenu';
import Spinner from '../Spinner';
import EmptyPresales from './EmptyPresales';
import { presaleRepository } from '../../domain';

const getAllPresalesHandler = getService('presales', 'getallpresalesv2');

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
    gap: 32,
  },
  filters: {
    padding: 0,
    width: '100%',
    '& .MuiBox-root': {
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '& .MuiAutocomplete-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },
  newPresaleButton: {
    padding: '11px 32px',
  },
  presalesContainer: {
    gap: 32,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(192px, 1fr))',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(172px, 1fr))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    },
  },
}));

const Presales: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const filterOptions = ['Active', 'Scheduled', 'Inactive', 'Archived'];

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const config = useConfig();
  const features = useFeatures();
  const isPresalesEnabled = features.includes('admin.presales') && config.featureFlag('feature_presales', shop);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [presales, setPresales] = useState<Presale[]>([]);
  const [filteredPresales, setFilteredPresales] = useState<Presale[]>([]);
  const [filters, setFilters] = useState(['Active', 'Scheduled', 'Inactive']);
  const [isCreatingPresale, setIsCreatingPresale] = useState<boolean>(false);

  const getPresales = async () => {
    const presales = await getAllPresalesHandler({ shopId: shop.id });
    setPresales(presales?.filter(presale => presale.status !== 'deleted'));
    setIsLoading(false);
  };

  useEffect(() => {
    getPresales();
  }, []);

  useEffect(() => {
    const map = {
      active: 'Active',
      scheduled: 'Scheduled',
      inactive: 'Inactive',
      archived: 'Archived',
    };
    setFilteredPresales(
      presales.filter(presale => filters.includes(map[getProductStatus(presale, shop?.config?.timeZone)])),
    );
  }, [presales, filters]);

  useEffect(() => {
    setPageTitle('Presales');

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
      setFooterCTAs([newPresaleButton]);
    } else {
      setHeaderCTAs([newPresaleButton]);
      setFooterCTAs([]);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isPresalesEnabled) window.scrollTo(0, 0);
    else history.push('/');
  }, []);

  const createPresale = async () => {
    setIsCreatingPresale(true);
    try {
      const createdPresale = await presaleRepository.create({
        title: '',
        description: '',
        status: 'inactive',
        fulfillmentIds: [],
        productIds: [],
        shopId: shop.id,
      });

      setIsCreatingPresale(false);
      history.push(`/presales/edit/${createdPresale?.id}`);
    } catch (err) {
      console.log(err);
      setIsCreatingPresale(false);
    }
  };

  const newPresaleButton = (
    <ButtonV2
      variant="contained"
      fullWidth={isMobile}
      onClick={createPresale}
      className={classes.newPresaleButton}
      disabled={isCreatingPresale}
      loading={isCreatingPresale}
    >
      New Presale
    </ButtonV2>
  );

  const handleAddClick = (): void => {
    history.push(`/presales/add`);
  };

  const handleCardClick = (presale): void => {
    history.push(`/presales/edit/${presale.id}`);
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: string[]): void => {
    setFilters(value);
  };

  return (
    <Grid justify="center" className={classes.container} container item>
      <Spinner size="fullscreen" show={isLoading} />
      <Helmet>
        <title>Presales | Nourysh</title>
      </Helmet>
      {_.isEmpty(presales) ? (
        <EmptyPresales />
      ) : (
        <>
          <ListHeader
            onAddClick={handleAddClick}
            showImport
            isMobile={isMobile}
            onFilterChange={handleFilterChange}
            filters={filters}
            filterOptions={filterOptions}
            content={{}}
            className={classes.filters}
          />
          <Grid className={classes.presalesContainer} container item>
            {_.sortBy(filteredPresales, [
              presale => {
                switch (getProductStatus(presale, shop?.config?.timeZone)) {
                  case 'active':
                    return 1;
                  case 'scheduled':
                    return 2;
                  case 'inactive':
                    return 3;
                  case 'archived':
                    return 4;
                  default:
                    return 100;
                }
              },
              presale => presale.title.toLowerCase(),
            ]).map(presale => (
              <PresaleCard
                key={presale.id}
                presale={presale}
                onClick={() => handleCardClick(presale)}
                context="admin"
                actionMenu={
                  <ActionsMenu
                    location="presaleCard"
                    onRefresh={getPresales}
                    presale={presale}
                    type="presales"
                    topRight
                  />
                }
                timeZone={shop?.config?.timeZone}
              />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Presales;

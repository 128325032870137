import React, { ReactNode, useState } from "react";
import { ButtonBase, Grid, makeStyles, useTheme } from "@material-ui/core";
import {
  BaseProduct,
  Coupon,
  Transaction,
  ProductPageContext,
  Presale,
} from "@castiron/domain";
import { Button, ChevronDownIcon, MoreIcon } from "@castiron/components";
import CouponsActionsMenu from "../Coupons/CouponTable/CouponActionsMenu";
import CustomerActionsMenu from "../Customers/CustomerActionsMenu";
import Popover from "../Popover/Popover";
import OrganizeCategoriesActionsMenu from "../Products/OrganizeCategoriesActionsMenu";
import OrganizeProductsActionsMenu from "../Products/OrganizeProductsActionMenu";
import ProductActionsMenu from "../Products/ProductActionsMenu";
import QuoteActionsMenu from "../Quotes/QuoteActionsMenu";
import QuoteCustomerActionsMenu from "../Quotes/QuoteCustomerActionMenu";
import PresaleActionsMenu from "../Presales/PresaleActionsMenu";
import MarketingStatsActionsMenu from "../Messaging/MarketingStats/MarketingStatsActionsMenu";
import { MarketingStatsMessage } from "../Messaging/MarketingStats";

type Props = {
  type:
    | "customers"
    | "products"
    | "presales"
    | "notes"
    | "coupons"
    | "custom-orders"
    | "quotes"
    | "quotes-customer"
    | "organize-categories"
    | "organize-products"
    | "marketingStats";
  id?: any;
  note?: string;
  categoryName?: string;
  coupon?: Coupon;
  product?: BaseProduct;
  transaction?: Transaction;
  editing?: boolean;
  displayAsTextButton?: boolean;
  children?: ReactNode;
  topRight?: boolean;
  location?: "marketingStatsMobile" | "presaleCard" | "productCard" | "";
  productCardContext?: ProductPageContext;
  onDelete?: () => void;
  onEdit?: () => void;
  presale?: Presale;
  onRefresh?: () => void;
  message?: MarketingStatsMessage;
  status?: "sent" | "scheduled";
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  containerTopRight: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    position: "absolute",
    top: 0,
    right: 0,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  menuContainer: {
    borderRadius: "8px",
  },
  moreIconContainer: {
    height: 40,
    width: 40,
    marginTop: 8,
    marginRight: 8,
    borderRadius: 12,
    "&:hover": {
      color: theme.branding.v2.plum[500],
    },
  },
  moreIconContainerPresaleCard: {
    backgroundColor: theme.branding.gray[100],
    height: 40,
    width: 40,
    marginTop: 8,
    marginRight: 8,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: 12,
    "&:hover": {
      color: theme.branding.v2.plum[500],
    },
  },
  moreIconContainerProductCard: {
    backgroundColor: theme.branding.gray[100],
    height: 40,
    width: 40,
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    borderRadius: 12,
    "&:hover": {
      color: theme.branding.v2.plum[500],
    },
  },
  moreIcon: {
    color: theme.branding.v2.gray[500],
    height: 24,
    width: 24,
    "&:hover": {
      color: theme.branding.v2.gray[800],
    },
  },
  chevron: {
    color: theme.branding.v2.plum[500],
  },
}));

const ActionsMenu: React.FC<Props> = (props: Props) => {
  const {
    type,
    id,
    note,
    categoryName,
    coupon,
    product,
    transaction,
    displayAsTextButton,
    children,
    editing,
    topRight = false,
    onDelete,
    onEdit,
    location,
    productCardContext,
    presale,
    onRefresh,
    message,
    status,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className={topRight ? classes.containerTopRight : classes.container}>
      {displayAsTextButton ? (
        <Button variant="outlined" onClick={handleClick}>
          {children}
          <ChevronDownIcon />
        </Button>
      ) : (
        <ButtonBase onClick={handleClick}>
          <Grid
            container
            item
            justify="center"
            alignContent="center"
            alignItems="center"
            className={
              location === "productCard" || location === "marketingStatsMobile"
                ? classes.moreIconContainerProductCard
                : location === "presaleCard"
                ? classes.moreIconContainerPresaleCard
                : classes.moreIconContainer
            }
          >
            <MoreIcon className={classes.moreIcon} />
          </Grid>
        </ButtonBase>
      )}
      <Popover
        onClose={(e) => handleClose(e)}
        anchorEl={anchorEl}
        paperClass={classes.menuContainer}
      >
        {type === "products" && (
          <ProductActionsMenu
            product={product}
            context={productCardContext}
            onClose={handleClose}
          />
        )}
        {type === "presales" && (
          <PresaleActionsMenu
            onClose={handleClose}
            onRefresh={onRefresh}
            presale={presale}
            variant="actionsMenu"
          />
        )}
        {type === "customers" && (
          <CustomerActionsMenu id={id} onClose={handleClose} />
        )}
        {type === "coupons" && (
          <CouponsActionsMenu coupon={coupon} onClose={handleClose} />
        )}
        {type === "quotes" && (
          <QuoteActionsMenu
            transaction={transaction}
            editing={editing}
            onClose={handleClose}
          />
        )}
        {type === "quotes-customer" && (
          <QuoteCustomerActionsMenu
            onDelete={onDelete}
            onEdit={onEdit}
            onClose={handleClose}
          />
        )}
        {type === "organize-categories" && (
          <OrganizeCategoriesActionsMenu
            categoryName={categoryName}
            context={productCardContext}
            onClose={handleClose}
          />
        )}
        {type === "organize-products" && (
          <OrganizeProductsActionsMenu
            context={productCardContext}
            productId={id}
          />
        )}
        {type === "marketingStats" && (
          <MarketingStatsActionsMenu
            message={message}
            status={status}
            onClose={handleClose}
          />
        )}
      </Popover>
    </div>
  );
};

export default ActionsMenu;

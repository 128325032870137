import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAppSelector, useAppDispatch } from "../../../../hooks";
import { ButtonBase, Grid, makeStyles, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Chip, Typography } from "@castiron/components";
import { ThemeNameOption, Shop } from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { accountRepository } from "../../../../domain";
import { updateShopAction } from "../../../../store/reducers/shops";
import { themeNames, findThemeConfig } from "../../../../lib/themeUtils";
import { StickyFooterProps } from "../OnboardingFooter";
import ThemePreview from "../../../ThemePreview";

interface Props {
  setStickyFooterProps: (props: StickyFooterProps) => void;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  nextStep?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: 20,
    padding: 4,
    width: "100%",
  },
  container: {
    padding: "16px 0px 93px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 8px 93px",
    },
  },
  innerContainer: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  nameContainer: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: "24px 0",
    gap: "4px",
  },
}));

const SelectATheme: React.FC<Props> = (props: Props) => {
  const {
    setStickyFooterProps,
    setHeader,
    setSubHeader,
    nextStep,
    setLoading,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const [selectedTheme, setSelectedTheme] = useState<ThemeNameOption>(
    shop?.config?.shopTheme?.name || "classic-castiron"
  );

  useEffect(() => {
    setHeader(`Select a theme for your website.`);
    setSubHeader(
      "Colors, fonts, styles, and themes can always be adjusted more later."
    );

    return () => {
      setHeader("");
      setSubHeader("");
    };
  }, []);

  useEffect(() => {
    setStickyFooterProps({
      onNextClick: onSubmit,
    });

    return () => {
      setStickyFooterProps(undefined);
    };
  }, [selectedTheme]);

  const onSubmit = async () => {
    setLoading(true);
    const themeConfig = findThemeConfig(selectedTheme, theme);

    const newShop: Shop = {
      ...shop,
      config: {
        ...shop?.config,
        shopTheme: themeConfig,
      },
    };

    await dispatch(updateShopAction({ shop: newShop }));
    await accountRepository.updateProps(account.id, {
      "onboardingQuestions.theme": selectedTheme,
    });

    trackEvent("Onboarding Theme Selected", {
      theme: selectedTheme,
    });

    setLoading(false);
    nextStep();
  };

  const themes = themeNames.map((name) => ({
    name: name === "Classic Castiron" ? "Classic Nourysh" : name,
    value: _.kebabCase(name) as ThemeNameOption,
    previewAsset: <ThemePreview themeName={name} />,
  }));

  return (
    <Grid>
      <Grid container spacing={1} direction="row" className={classes.container}>
        {themes.reverse().map((option, index) => (
          <Grid key={index} container item xs={12}>
            <ButtonBase
              className={classes.button}
              style={{
                border: `4px solid ${
                  selectedTheme === option.value
                    ? theme.branding.v2.plum[500]
                    : theme.branding.v2.gray[0]
                }`,
              }}
              onClick={() => setSelectedTheme(option.value)}
            >
              <Grid
                container
                direction="column"
                className={classes.innerContainer}
              >
                {option.previewAsset}
                <Grid
                  className={classes.nameContainer}
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                  wrap="nowrap"
                >
                  <Typography variant="subtitle1">{option.name}</Typography>
                  {option.name === "Classic Nourysh" && (
                    <Chip colorScheme="gray">Default</Chip>
                  )}
                </Grid>
              </Grid>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SelectATheme;

import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import { Coupon } from "@castiron/domain";
import { couponRepository } from "../../../domain";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCouponAction } from "../../../store/reducers/coupons";
import {
  Link,
  Box,
  FormControl,
  FormLabel,
  InputAdornment,
  FormGroup,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { Formik, FormikProps } from "formik";
import Spinner from "../../Spinner";
import {
  Button,
  CustomSwitch,
  Typography,
  Card,
  Input,
  Chip,
} from "@castiron/components";
import { useHistory, useParams } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useTracking } from "@castiron/utils";
import { ChecklistValues } from "@castiron/domain";
import { updateChecklistAction } from "../../../store/reducers/shops";
import UnsavedChangesPrompt from "../../UnsavedChangesPrompt.tsx";
import AdminForm from "../../AdminForm";
import Select from "../../Select";
import CopyIcon from "@material-ui/icons/FilterNone";

interface FormValues {
  code: string;
  discountType: "amount" | "percent";
  discountValue: number;
  endDate?: number;
  isMaximumPerCustomer?: boolean;
  maximumPerCustomer?: number;
  orderMinType?: "orderValue" | "orderQuantity" | "none";
  orderMinValue?: number;
  startDate: number;
}

interface Props {
  editing: boolean;
  setFooterCTAs: (ctas: React.ReactNode[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  asterisk: {
    color: "red",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "space-around",
  },
  container: {
    padding: "20px 0px",

    "& .MuiFormControl-root": {
      marginTop: 8,
    },

    "& .MuiFormLabel-asterisk": {
      color: theme.branding.gray[800],
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.branding.gray[800],
    },
  },
  copyButton: {
    margin: "8px 0 0 8px",
    height: 48,
    width: 48,

    "& svg": {
      "-webkit-transform": "rotate(90deg)",
      "-moz-transform": "rotate(90deg)",
      "-o-transform": "rotate(90deg)",
      "-ms-transform": "rotate(90deg)",
      transform: "rotate(90deg)",
    },
  },
  deleteButton: {
    color: theme.branding.error,
  },
  selects: {
    marginTop: 8,
    borderRadius: "0 12px 12px 0",
    flexBasis: "50%",
    minWidth: "initial",
  },
  header: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    marginBottom: "6px",
  },
  helperText: {
    color: theme.branding.gray[700],
    margin: "4 0 0 0",
    fontSize: 12,
    textAlign: "left",
    fontWeight: 400,
  },
  form: {
    padding: "16px 0px",
  },
  formControl: {
    marginBottom: "15px",
    width: "100%",
  },
  formGroup: {
    justifyContent: "space-between",
  },
  label: {
    color: theme.branding.gray[800],
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: 0,
    lineHeight: "24px",
  },
  inputDivider: {
    "align-self": "center",
    margin: "0 2em",
  },
  subInfo: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    color: theme.branding.gray[800],
  },
  discountInputWrap: {
    display: "flex",
  },
  uniqueLabelWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  codeInfoLink: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: 14,
    color: theme.branding.v2.plum[500],
  },
  success: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.branding.green.primary,
    marginTop: "8px",
  },
  error: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.branding.error,
    marginTop: "8px",
  },
  specialInputs: {
    "& > div:first-child": {
      flexBasis: "50%",
    },
  },
  specialInputRadius: {
    borderRadius: "12px 0 0 12px",
  },
  footerButton: {
    margin: "0px 4px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
  resultMsg: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    position: "absolute",
    top: "-25px",
    right: "20px",
  },
}));

const CouponForm: React.FC<Props> = (props: Props) => {
  const { editing, setFooterCTAs } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));
  const { coupons } = useAppSelector((state) => ({
    coupons: state.coupons.coupons,
  }));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromChecklist = urlParams.get("fromChecklist");

  const [couponEditing, setCouponEditing] = useState<Coupon>();
  const [uniqueCodeError, setUniqueCodeError] = useState<{
    type: string;
    msg: string;
  } | null>(null);
  const [copySuccess, setCopySuccess] = useState<boolean | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const getCoupon = async () => {
      const currentCoupon = await couponRepository.get(id);
      setCouponEditing(null);
      setCouponEditing({ ...currentCoupon });
    };
    if (id && editing) {
      getCoupon();
    }
  }, [id]);

  const handleDelete = async () => {
    setIsSubmitting(true);
    await couponRepository.delete(id);
    setIsSubmitting(false);
    trackEvent("Coupon Deleted", { coupon: { id: id } });
    dispatch(getCouponAction(shop.id));
    history.push("/store/coupons");
  };

  useEffect(() => {
    setFooterCTAs([
      <Button
        onClick={handleDelete}
        disabled={isSubmitting}
        variant="outlined"
        className={classes.deleteButton}
      >
        Delete
      </Button>,
      <Button
        disabled={isSubmitting}
        onClick={() => history.push("/store/coupons")}
        className={classes.footerButton}
        variant="outlined"
      >
        Discard
      </Button>,
      <Button
        onClick={() => {
          formikRef.current?.submitForm();
        }}
        disabled={isSubmitting}
        className={classes.footerButton}
        variant="contained"
      >
        Save
      </Button>,
    ]);
  }, []);

  const storeSchema = yup.object().shape({
    code: yup
      .string()
      .max(20, "Must be less than 20 characters")
      .required("Code is required"),
    discountType: yup.string().required("Discount type is required"),
    discountValue: yup
      .number()
      .required("Discount amount is required")
      .min(1, "Must be greater than 0"),
    endDate: yup.number(),
    isMaximumPerCustomer: yup.boolean(),
    maximumPerCustomer: yup
      .number()
      .nullable()
      .when("isMaximumPerCustomer", {
        is: true,
        then: yup
          .number()
          .min(1, "Must be greater than 0")
          .required("Must enter maximum uses per customer value")
          .typeError("Must specify a number"),
      }),
    orderMinType: yup.string(),
    orderMinValue: yup
      .number()
      .nullable()
      .when("orderMinType", {
        is: "orderValue",
        then: yup
          .number()
          .min(1, "Must be greater than 0")
          .required("Must enter order minimum value"),
      })
      .when("orderMinType", {
        is: "orderQuantity",
        then: yup
          .number()
          .min(1, "Must be greater than 0")
          .required("Must enter order quantity"),
      }),
    startDate: yup.number().required("Start date is required"),
  });

  const initialValues: FormValues = {
    code: couponEditing?.code || "",
    discountType:
      couponEditing?.discount?.type ||
      ("percent" as FormValues["discountType"]),
    discountValue:
      couponEditing?.discount?.value && couponEditing?.discount?.type
        ? couponEditing?.discount?.type === "amount"
          ? couponEditing?.discount?.value / 100
          : couponEditing?.discount?.value
        : null,
    isMaximumPerCustomer: !!couponEditing?.maximumPerCustomer || false,
    maximumPerCustomer: couponEditing?.maximumPerCustomer || null,
    orderMinType: couponEditing?.minimum?.type || "none",
    orderMinValue:
      couponEditing?.minimum?.value && couponEditing?.minimum?.type
        ? couponEditing?.minimum?.type === "orderValue"
          ? couponEditing?.minimum?.value / 100
          : couponEditing?.minimum?.value
        : null,
    startDate: couponEditing?.duration.startDate || moment().unix(),
  };

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.setFieldValue("discountValue", "");
    formikProps.setFieldValue("maximumPerCustomer", "");
    formikProps.setFieldValue("orderMinValue", "");
    formikProps.resetForm();
    if (fromChecklist) history.push("/setup");
    else history.push(`/store/coupons`);
  };

  const onSubmit = async (
    values: FormValues,
    formikProps: FormikProps<FormValues>
  ) => {
    setIsSubmitting(true);
    const newValues = {
      code: values.code,
      discount: {
        type: values.discountType,
        value:
          values.discountType === "amount"
            ? Math.round(values.discountValue * 100)
            : values.discountValue,
      },
      duration: {
        ...(values.endDate && { endDate: values.endDate }),
        startDate: values.startDate,
      },
      metrics: {
        totalRevenue: 0,
        totalUses: 0,
      },
      ...(values.maximumPerCustomer && {
        maximumPerCustomer: values.maximumPerCustomer,
      }),
      ...(values.orderMinType !== "none" &&
        values.orderMinType &&
        values.orderMinValue && {
          minimum: {
            ...(values.orderMinType && { type: values.orderMinType }),
            ...(values.orderMinValue && {
              value:
                values.orderMinType === "orderValue"
                  ? Math.round(values.orderMinValue * 100)
                  : values.orderMinValue,
            }),
          },
        }),
      shopId: shop.id,
      status: "active",
    };

    if (editing) {
      const coupon: Coupon = {
        id: couponEditing.id,
        ...newValues,
      };
      couponRepository.update(coupon).then((c) => {
        dispatch(getCouponAction(shop.id));
        trackEvent("Coupon Edited", { coupon });
      });
    } else {
      await couponRepository.create(newValues).then((c) => {
        console.debug(`Created new Coupon with ID [${c.id}]`);
        dispatch(getCouponAction(shop.id));
        trackEvent("Coupon Created", { coupon: c });
      });
      if (!shop.checklistCompletions?.includes(ChecklistValues.CouponCreate)) {
        dispatch(
          updateChecklistAction({ shop, items: [ChecklistValues.CouponCreate] })
        );
      }
    }
    setIsSubmitting(false);
    resetForm(formikProps);
    history.push(`/store/coupons`);
  };

  const checkCode = (code: string, coupons) => {
    setUniqueCodeError(null);
    coupons.some((coupon) => {
      if (coupon.code === code.toUpperCase()) {
        setUniqueCodeError({
          type: "error",
          msg: `Coupon code must be unique!`,
        });
      }
    });
  };

  const generateRandomCode = (formikProps: FormikProps<FormValues>) => {
    formikProps.setFieldValue(
      "code",
      shop.websiteUrl.replaceAll("-", "").toUpperCase() +
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "")
          .substr(0, 6)
          .toUpperCase()
    );
  };

  function getAdornment(position: "end" | "start", symbol: string) {
    return (
      <InputAdornment position={position}>
        <Typography>{symbol}</Typography>
      </InputAdornment>
    );
  }

  const discountTypeOptions = [
    { label: "Percent", value: "percent" },
    { label: "Dollars", value: "amount" },
  ];

  const orderMinOptions = [
    { label: "None", value: "none" },
    { label: "Min. Value", value: "orderValue" },
    { label: "Min. Quantity", value: "orderQuantity" },
  ];

  const isMaximumPerCustomerSwitch = (formikProps: FormikProps<FormValues>) => {
    return (
      <CustomSwitch
        checked={formikProps.values.isMaximumPerCustomer}
        onChange={(e) => {
          formikProps.setFieldValue("isMaximumPerCustomer", e.target.checked);
          if (!e.target.checked) {
            formikProps.setFieldValue("maximumPerCustomer", null);
          }
        }}
        name="isMaximumPerCustomer"
        color="primary"
      />
    );
  };

  const copyToClipboard = (code) => {
    setCopySuccess(true);
    navigator.clipboard.writeText(code);

    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  return (
    <Box className={classes.container}>
      {(editing && !couponEditing) || isSubmitting ? (
        <Grid justify="center" container>
          <Spinner show={true} size={"relative"} />
        </Grid>
      ) : (
        <Formik
          className={classes.form}
          initialValues={initialValues}
          validationSchema={storeSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <AdminForm>
              {!formikProps.isSubmitting && (
                <UnsavedChangesPrompt when={formikProps.dirty} />
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Card className={classes.container} title="Coupon Details">
                    <FormControl className={classes.formControl}>
                      <div className={classes.uniqueLabelWrap}>
                        <FormLabel required className={classes.label}>
                          Unique Code
                        </FormLabel>
                        <Link
                          className={classes.codeInfoLink}
                          onClick={() => generateRandomCode(formikProps)}
                        >
                          Generate a Code For Me
                        </Link>
                        {copySuccess && (
                          <Chip
                            className={classes.resultMsg}
                            colorScheme="infoDark"
                            bold
                          >
                            Code Copied!
                          </Chip>
                        )}
                      </div>
                      {uniqueCodeError && (
                        <Typography className={classes.error}>
                          {uniqueCodeError.msg}
                        </Typography>
                      )}
                      {/* unique code */} {/* copy code button */}
                      <Grid container wrap="nowrap" xs={12}>
                        <Input
                          fullWidth
                          error={
                            formikProps.touched.code && formikProps.errors.code
                          }
                          id="code"
                          max={20}
                          name="code"
                          placeholder="Eg., CODE123"
                          required
                          value={formikProps.values.code}
                          onChange={(event) => {
                            formikProps.setFieldValue(
                              "code",
                              event.target.value
                                .toUpperCase()
                                .replace(/[\W_]+/g, "")
                            ); // removes non alphanumeric characters
                            checkCode(event.target.value, coupons);
                          }}
                        />
                        {!isMobile && (
                          <Button
                            className={classes.copyButton}
                            onClick={() =>
                              copyToClipboard(formikProps.values.code)
                            }
                            variant="outlined"
                          >
                            <CopyIcon />
                          </Button>
                        )}
                      </Grid>
                      <Typography
                        className={classes.helperText}
                        variant="caption"
                      >
                        This is what you will share with your customers and what
                        they will use at checkout. Each code must be unique and
                        use only letters and numbers.
                      </Typography>
                    </FormControl>
                    <FormControl className={classes.formControl} required>
                      <FormLabel required className={classes.label}>
                        Discount Amount
                      </FormLabel>
                      <Grid
                        container
                        wrap="nowrap"
                        className={classes.discountInputWrap}
                        alignItems="center"
                      >
                        {/* discount value */}
                        <Grid
                          container
                          item
                          xs={12}
                          wrap="nowrap"
                          className={classes.specialInputs}
                        >
                          <Input
                            endAdornment={
                              formikProps.values.discountType === "percent"
                                ? getAdornment("end", "%")
                                : null
                            }
                            error={
                              formikProps.touched.discountValue &&
                              formikProps.errors.discountValue
                            }
                            id="discountValue"
                            min={0}
                            max={
                              formikProps.values.discountType === "percent"
                                ? 100
                                : null
                            }
                            value={formikProps.values.discountValue}
                            required
                            type="text"
                            name="discountValue"
                            className={classes.specialInputRadius}
                            onChange={(event) => {
                              formikProps.values.discountType === "percent"
                                ? formikProps.setFieldValue(
                                    "discountValue",
                                    event.target.value.replace(/[^\d]/gi, "")
                                  ) // only allow digits
                                : formikProps.setFieldValue(
                                    "discountValue",
                                    event.target.value.replace(/[^\d.]/gi, "")
                                  ); // only allow digits and .
                              checkCode(event.target.value, coupons);
                            }}
                            startAdornment={
                              formikProps.values.discountType === "amount"
                                ? getAdornment("start", "$")
                                : null
                            }
                            placeholder="---"
                          />
                          <Select
                            value={formikProps.values.discountType}
                            onChange={(event) =>
                              formikProps.setFieldValue(
                                "discountType",
                                event.target.value
                              )
                            }
                            options={discountTypeOptions}
                            selectClass={classes.selects}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <FormLabel className={classes.label}>
                        Order Minimum
                      </FormLabel>
                      <FormGroup row className={classes.formGroup}>
                        <Grid container wrap="nowrap" alignItems="center">
                          {/* order min value */}
                          <Grid
                            container
                            item
                            xs={12}
                            wrap="nowrap"
                            className={classes.specialInputs}
                          >
                            <Input
                              startAdornment={
                                <InputAdornment position="start">
                                  <Typography>
                                    {formikProps.values.orderMinType ===
                                    "orderValue"
                                      ? "$"
                                      : "#"}
                                  </Typography>
                                </InputAdornment>
                              }
                              className={classes.specialInputRadius}
                              error={
                                formikProps.touched.orderMinValue &&
                                formikProps.errors.orderMinValue
                              }
                              id="orderMinValue"
                              name="orderMinValue"
                              type="text"
                              placeholder="---"
                              min={0}
                              onChange={(event) =>
                                formikProps.setFieldValue(
                                  "orderMinValue",
                                  event.target.value.replace(/[^\d]/gi, "")
                                )
                              } // only allow digits
                              step={
                                formikProps.values.orderMinType === "orderValue"
                                  ? 0.01
                                  : 1
                              }
                            />
                            <Select
                              onChange={(event) => {
                                formikProps.setFieldValue(
                                  "orderMinType",
                                  event.target.value
                                );
                                if (event.target.value === "none") {
                                  formikProps.setFieldTouched(
                                    "orderMinValue",
                                    false
                                  );
                                  formikProps.setFieldValue(
                                    "orderMinValue",
                                    ""
                                  );
                                }

                                setTimeout(function () {
                                  formikProps.validateForm();
                                }, 1000);
                              }}
                              value={formikProps.values.orderMinType}
                              options={orderMinOptions}
                              selectClass={classes.selects}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  {/* active date */}
                  <Card className={classes.container} title="Dates">
                    <FormControl className={classes.formControl}>
                      <FormLabel className={classes.label} required>
                        Active As Of
                      </FormLabel>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disablePast={!editing}
                          disableToolbar
                          variant="inline"
                          format="MM/DD/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          inputVariant="outlined"
                          value={moment.unix(formikProps.values.startDate)}
                          onChange={(value) =>
                            value
                              ? formikProps.setFieldValue(
                                  "startDate",
                                  value.unix()
                                )
                              : null
                          } // ternary prevents error when deleting last character
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <FormLabel className={classes.label}>
                        Must Redeem By (Optional)
                      </FormLabel>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disablePast
                          disableToolbar
                          variant="inline"
                          format="MM/DD/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          inputVariant="outlined"
                          minDate={moment.unix(formikProps.values.startDate)}
                          value={
                            formikProps.values.endDate
                              ? moment.unix(formikProps.values.endDate)
                              : null
                          }
                          onChange={(value) =>
                            value
                              ? formikProps.setFieldValue(
                                  "endDate",
                                  value.unix()
                                )
                              : null
                          } // ternary prevents error when deleting last character
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Card>
                  {/* limit per customer */}
                  <Card
                    className={classes.container}
                    title="Limit Per Customer"
                    sideMessage={isMaximumPerCustomerSwitch(formikProps)}
                  >
                    <FormControl className={classes.formControl}>
                      <Input
                        error={
                          formikProps.touched.maximumPerCustomer &&
                          formikProps.errors.maximumPerCustomer
                        }
                        id="maximumPerCustomer"
                        name="maximumPerCustomer"
                        type="text"
                        disabled={!formikProps.values.isMaximumPerCustomer}
                        value={formikProps.values.maximumPerCustomer}
                        min={0}
                        onChange={(event) =>
                          formikProps.setFieldValue(
                            "maximumPerCustomer",
                            event.target.value.replace(/[^\d]/gi, "")
                          )
                        } // only allow digits
                        step={1}
                        placeholder="---"
                      />
                      <Typography
                        className={classes.helperText}
                        variant="caption"
                      >
                        # of times a customer can use this coupon code.
                      </Typography>
                    </FormControl>
                  </Card>
                </Grid>
              </Grid>
            </AdminForm>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default CouponForm;

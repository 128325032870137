import { Banner, Typography } from '@castiron/components';
import { Special } from '@castiron/domain';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import SinglePopup from './SinglePopup';
import { getProductsAction } from '../../../store/reducers/products';

interface Props {
  activeShopEvents: Special[];
}

export interface PopupContent {
  isEvent?: boolean;
  tag: string;
  name?: string;
  slug?: string;
  icon?: string;
  popup: {
    title: string;
    description: string;
    imageUrl: string;
  };
  eventDefaults?: {
    headline: string;
    description: string;
  };
}

export const popupContent = {
  holidays: {
    tag: 'holidays',
    popup: {
      title: 'Holidays Popup',
      description: 'Direct shoppers to different holiday offerings.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/castiron-prod.appspot.com/o/castiron%2Fpopups%2Fholidays-popup.png?alt=media&token=bd6bb5a9-355c-4346-b75b-4271040a7afe',
    },
  },
  subscribe: {
    tag: 'subscribe',
    popup: {
      title: 'Subscribe Popup',
      description: 'Encourage visitors to become members of your community.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/castiron-prod.appspot.com/o/castiron%2Fpopups%2Fsubscribe-popup.png?alt=media&token=14dc0c80-e30f-47b3-b24e-8f03d7c654b7',
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  popupsContainer: {
    gap: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(315px, 1fr))',
    },
  },
}));

const Popups: React.FC<Props> = (props: Props) => {
  const { activeShopEvents } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { products, shop } = useAppSelector(state => ({
    products: state.products.products,
    shop: state.shops.shop,
  }));

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop) getProducts();
  }, [shop]);

  return (
    <Grid container item style={{ gap: '40px' }}>
      <Grid container item direction="column" style={{ gap: '16px' }}>
        <Typography variant="body1">
          A collection of popup templates to prompt your customers to interact with your shop in several ways.
        </Typography>
        <Banner variant="info-white">
          <Typography variant="body2">Popups only appear once per visit on the home page.</Typography>
        </Banner>
      </Grid>
      <Grid container item direction="column" wrap="nowrap" style={{ gap: '16px' }}>
        <Typography variant="subtitle1">Subscription Popups</Typography>
        <Grid container item className={classes.popupsContainer} xs>
          <SinglePopup activeShopEvents={activeShopEvents} content={popupContent.subscribe} products={products} />
        </Grid>
      </Grid>
      <Grid container item direction="column" wrap="nowrap" style={{ gap: '16px' }}>
        <Typography variant="subtitle1">Event Popups</Typography>
        <Grid container item className={classes.popupsContainer} xs>
          <SinglePopup activeShopEvents={activeShopEvents} content={popupContent.holidays} products={products} />
          {activeShopEvents.map((event, index) => {
            if (event?.shopContent?.popup?.show) {
              const content = {
                isEvent: true,
                tag: event.tag,
                name: event.name,
                slug: event.slug,
                icon: event?.shopContent?.icon,
                popup: {
                  title: event?.shopContent?.popup?.admin?.title,
                  description: event?.shopContent?.popup?.admin?.description,
                  imageUrl: event?.shopContent?.popup?.admin?.imageUrl,
                },
                eventDefaults: {
                  headline: event?.shopContent?.page?.bannerHeadline,
                  description: event?.shopContent?.page?.bannerDescription,
                },
              };
              return <SinglePopup activeShopEvents={activeShopEvents} key={`${event.tag}-${index}`} content={content} products={products} />;
            } else return;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Popups;
